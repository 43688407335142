import React, {useState, useEffect} from "react"
import Header from "../../components/Header"
import Loader from "../../components/Loader/Loader"
import {useDispatch, useSelector} from "react-redux"
import {addCategories} from "../../store/actions/categorieslist"
import {useHistory} from "react-router-dom"
import {Box, Skeleton, Stack} from "@chakra-ui/react"
import {SkeletonCircle, SkeletonText} from "@chakra-ui/react"
import styles from "../OffersFrontend/index.module.css"

const Services = () => {
	const dispatch = useDispatch()
	const [loading, setloading] = useState(false)
	const [imageLoading, setImageLoading] = useState(true) // New state variable
	const {Categorieslist} = useSelector(state => state.products)
	const history = useHistory()

	useEffect(() => {
		setloading(true)
		dispatch(addCategories())
			.then(() => {
				setloading(false)
				document.getElementById("services")?.scrollIntoView({behavior: "smooth"})
				setImageLoading(false)
			}, 4000)
			.catch(err => {
				setloading(false)
				console.log("ERR => while fetching all Categories", err)
			})
	}, [])

	const goToCategory = res => {
		history.push(`/curly-service/${res.slug}`)
	}
	console.log("adding console.log for testing purpose for vercel")
	return (
		<>
			<span id='service'></span>
			<Header title='Services | Curlys Unisex Hair & Makeup Studio' />
			{/* <div>
				<img src='/img/service-image.PNG' className='w-full image-top-space' alt='head_img' />
			</div> */}

			<section
				className={`bg-service-banner  bg-cover flex items-center justify-center relative z-10 h-80 ${styles.bannerSection} `}
				style={{backgroundPosition: "0 90%"}}>
				<div className='container '>
					<div className='w-full text-center relative z-20 text-4xl font-bold text-white'>
						<h3 className='border-b-4 border-white inline pb-1'>Services</h3>
					</div>
				</div>
			</section>

			{loading ? (
				// <Loader className='h-3/6 items-center' />
				<Box className=' m-auto servicePageCenter'>
					<Box className=' py-5 px-5 md:py-20 sm:px-10 md:px-20 lg:px-40 xl:px-40 grid xl:grid-cols-3  lg:grid-cols-3 md:grid-cols-2 lg:gap-6 md:gap-4 sm:gap-4  xl:gap-12'>
						{[...Array(13)].map((_, index) => (
							<Box>
								<Box key={index} boxShadow='lg' className='relative'>
									<SkeletonText mt='4' noOfLines={1} spacing='2' skeletonHeight='80'></SkeletonText>
									<SkeletonText mt='4' noOfLines={1} spacing='2' skeletonHeight='20' />
									<Box className='flex m-auto justify-center'>
										<SkeletonCircle
											bg='white'
											style={{background: "white"}}
											size='10'
											width='200px'
											className='absolute flex m-auto justify-center bottom-6 text-center skeletonCircleWidth'
										/>
									</Box>
								</Box>
							</Box>
						))}
					</Box>
				</Box>
			) : (
				<>
					<div className='data py-5 px-5 md:py-20 servicePageCenter sm:px-10 md:px-20 lg:px-40 xl:px-40 grid xl:grid-cols-3  lg:grid-cols-3 md:grid-cols-2 lg:gap-6 md:gap-4 sm:gap-4  xl:gap-12'>
						{Categorieslist.map(res => {
							return (
								<div onClick={() => goToCategory(res)} key={res.id}>
									<div className='cursor-pointer w-full rounded mb-2'>
										{imageLoading ? (
											<Box padding='2' boxShadow='lg' className='relative '>
												<SkeletonText
													mt='4'
													noOfLines={1}
													spacing='2'
													skeletonHeight='80'></SkeletonText>
												<SkeletonText mt='4' noOfLines={1} spacing='2' skeletonHeight='20' />
												<Box className=' flex m-auto justify-center'>
													<SkeletonCircle
														bg='white'
														style={{background: "white"}}
														size='10'
														width='200px'
														className='absolute flex m-auto justify-center bottom-6 text-center skeltonCircleWidth'
													/>
												</Box>
											</Box>
										) : (
											<>
												<img
													src={
														res.image !== "" || res.image.complete
															? "https://curlys.in/api/" + res.image
															: "/img/curl-copy.png"
													}
													onError={e => {
														e.target.onerror = null
														e.target.src = "/img/curl-copy.png"
													}}
													className='object-cover md:w-full w-full'
													style={{height: "370px"}}
													alt={res.image}
													onLoad={() => setImageLoading(false)}
												/>

												<div className=''>
													<div className='px-6 py-4 hover:text-gold-100 text-black shadow-gold hover:shadow-gold-200'>
														<div className='font-bold text-xl mb-2 text-center whitespace-nowrap'>
															{res.name}
														</div>
													</div>
												</div>
											</>
										)}
									</div>
								</div>
							)
						})}
					</div>
				</>
			)}
		</>
	)
}

export default Services
