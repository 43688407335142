import React, {useState, useEffect, useRef} from "react"
import {useSelector, useDispatch} from "react-redux"
import PrivateLayout from "../../layout/PrivateLayout"
import {getAllUsers} from "../../store/actions/getUser"
import styles from "./index.module.css"
import PageHeader from "../../components/PageHeader"
import PageContainer from "../../components/PageContainer"
import {updateUserPassword} from "../../store/actions/auth"
import Loader from "../../components/Loader/Loader"
import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Stack,
	Input,
	FormLabel,
	FormControl,
	FormErrorMessage,
	useToast
} from "@chakra-ui/react"
import * as Yup from "yup"
import ReactPaginate from "react-paginate"

import UpdateUserModal from "./UpdateUserModal"
import ConfirmStatusModal from "./ConfirmStatusModal"
import {useForm} from "react-hook-form"
import {useYupValidationResolver} from "../../hooks/useValidationResolver"

const validationSchema = Yup.object({
	new_password: Yup.string().required("New Password is required!")
})

const UsersList = ({history}) => {
	const toast = useToast()

	const imageRef = useRef()
	const {isOpen, onOpen, onClose} = useDisclosure()
	const [user, setUser] = useState("")
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()
	const [filters, setFilters] = useState({
		searchTxt: "",
		page: 1
	})
	const listPerPage = 10

	const {getAlluser} = useSelector(state => state.getUsers)

	useEffect(() => {
		setLoading(true)
		dispatch(getAllUsers(filters)).then(() => {
			setLoading(false)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters])

	const listPageCount = Math.ceil(getAlluser && getAlluser?.total / listPerPage)

	const changePage = ({selected}) => {
		setFilters(prev => ({
			...prev,
			page: selected + 1
		}))
	}

	// const onLogout = () => {
	// 	dispatch(logoutUser())
	// 	history.replace("/")
	// }

	const resolver = useYupValidationResolver(validationSchema)
	const {
		register,
		handleSubmit,
		formState: {errors},
		reset
	} = useForm({
		resolver,
		mode: "onBlur"
	})

	const onChangePassword = data => {
		setLoading(true)
		dispatch(
			updateUserPassword({
				id: user?.id,
				new_password: data.new_password
			})
		)
			.then(() => {
				setLoading(false)
				// reset the form
				reset()
				onClose()
				toast({
					title: "Password Changed Successfully",
					status: "success",
					duration: 3000,
					isClosable: true
				})
			})
			.catch(err => {
				console.log(err, "err err")
				toast({
					title: "Something went wrong",
					status: "error",
					duration: 3000,
					isClosable: true
				})
				setLoading(false)
				console.log("ERR => while login user", err)
			})
	}

	const openPasswordModal = UserObj => {
		setUser(UserObj)
		onOpen()
	}

	const updateList = () => {
		dispatch(getAllUsers(filters))
	}

	return (
		<PrivateLayout>
			<PageHeader pageHeading="User's List" title="User's List" />
			<PageContainer>
				<>
					<Stack>
						<div className='w-1/2 sm:w-1/4 flex ml-auto'>
							<Input
								className='mr-2'
								placeholder='Search Here'
								value={filters.searchTxt}
								onChange={e => {
									setFilters(prev => ({
										...prev,
										searchTxt: e.target.value
									}))
								}}
							/>
							{/* <Select className=" ml-2" onChange={(e)=>{
						setFilters((prev)=>({
							...prev,
							limit:e.target.value
						}))
					}}>
                    <option value="10">Limit</option>
					<option value="10">10</option>
					<option value="100">100</option>
					<option value="1000">1000</option>
					</Select> */}
						</div>
					</Stack>
					{loading && <Loader className='items-center w-full' style={{height: "calc(100vh - 16rem)"}} />}
					{!loading && (
					<>
					<Box overflowX='auto' w='100%' className='mb-10'>
						<Table className={`my-4 whitespace-nowrap ${styles.tableBox}`} variant='simple'>
							<Thead>
								<Tr className={styles.trBackground}>
									<Th className={` ${styles.trColor}  text-center`}>Update User</Th>
									<Th className={` ${styles.trColor}  text-center`}>Status</Th>
									<Th className={` ${styles.trColor}  text-center`}>Name</Th>
									<Th className={` ${styles.trColor}  text-center`}>Phone No.</Th>
									<Th className={` ${styles.trColor}  text-center`}>Gender</Th>
									<Th className={` ${styles.trColor}  text-center`}>DOB</Th>
									<Th className={` ${styles.trColor}  text-center`}>Anniversary</Th>
									<Th className={` ${styles.trColor}  text-center`}>User Type</Th>
								</Tr>
							</Thead>
							
								<Tbody>
									{getAlluser?.data?.map(element => {
										return (
											<Tr key={element.id}>
												<Td
													className='justify-center  flex items-center'>
													<span onClick={() => openPasswordModal(element)} className='cursor-pointer bg-green-900 py-1 mr-3  px-3 rounded  text-center text-white'>
														<i className='bx bx-revision text-xl' />
													</span>
													<UpdateUserModal
														user={element}
														imageRef={imageRef}
														UpdateUserList={updateList}
													/>
												</Td>

												<Td className='text-center '>
													
													<ConfirmStatusModal element={element} updatelist={updateList} />

												</Td>

												<Td className='text-center'>{element.name}</Td>
												<Td className='text-center'>{element.phone}</Td>
												<Td className=' text-center'>
													{(element.gender === "1" && "Male") ||
														(element.gender === "2" && "Female") ||
														(element.gender === "" && "Not Available")}
												</Td>
												<Td className={` text-center`}>
													{element.dob === "0000-00-00" ? "Not Available" : element.dob}
												</Td>
												<Td className='text-center'>{element.anniversary}</Td>
												<Td className=' text-center'>{element.user_type}</Td>
												
											</Tr>
										)
									})}
								</Tbody>
						</Table>
					</Box>
					{listPageCount > 1 ? (
						<ReactPaginate
							breakLabel='...'
							nextLabel='Next >'
							onPageChange={changePage}
							pageRangeDisplayed={3}
							pageCount={listPageCount}
							forcePage={filters.page - 1}
							marginPagesDisplayed={2}
							previousLabel='< Previous'
							breakClassName='text-indigo-900'
							previousLinkClassName='bg-green-900 rounded select-none text-white px-3 py-1'
							nextLinkClassName='bg-green-900 rounded select-none text-white px-3 py-1'
							breakLinkClassName='flex itmes-center'
							renderOnZeroPageCount={null}
							activeLinkClassName=' bg-black-2xl rounded text-white py-1 px-3'
							className='flex mb-2 justify-between lg:w-5/12 w-10/12 rounded-md  mx-auto px-4 py-2 '
							nextClassName='text-black'
							previousClassName='text-black'
						/>
					) : (
						""
					)}
					</>)}

					<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
						<ModalOverlay />
						<form
							style={{overflow: "auto"}}
							noValidate
							onSubmit={handleSubmit(onChangePassword)}
							className='bg-white'>
							<ModalContent>
								<ModalHeader>Change Password</ModalHeader>
								<ModalCloseButton />
								<ModalBody>
									<Stack spacing={3}>
										<FormControl id='new_password' isRequired>
											<FormLabel>New Password</FormLabel>
											<Input
												{...register("new_password")}
												placeholder='Enter New Password'
												type='password'
											/>
											<FormErrorMessage>{errors?.new_password?.message}</FormErrorMessage>
										</FormControl>
									</Stack>
								</ModalBody>

								<ModalFooter>
									<Button className='mr-4' onClick={onClose}>
										Close
									</Button>
									<Button isLoading={loading} colorScheme='blue' className='bg-gold' type='submit'>
										Change Password
									</Button>
								</ModalFooter>
							</ModalContent>
						</form>
					</Modal>
				</>
			</PageContainer>
		</PrivateLayout>
	)
}

export default UsersList
