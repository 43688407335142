import React, {useEffect, useState} from "react"

const TARGET_LATITUDE = 30.329945
// 30.329945,76.837499
const TARGET_LONGITUDE = 76.837499

const MapWithRoute = ({lat = TARGET_LATITUDE, lng = TARGET_LONGITUDE}) => {
	const [hasPermissions, setHasPermissions] = useState(false)
	const isTabletOrLarger = window.innerWidth >= 768

	useEffect(() => {
		const showMap = () => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					position => {
						setHasPermissions(true)

						const currentLocation = new window.google.maps.LatLng(
							position.coords.latitude,
							position.coords.longitude
						)

						// Geocode the target address to get its coordinates
						const geocoder = new window.google.maps.Geocoder()
						const targetAddress =
							"Curlys Unisex Hair & Makeup Studio, 5437/1/2, Nicholson Road, Cinema, opp. NIGAR THEATRE, Ambala Cantt, Haryana 134001 31.0011711,74.7289488"
						// const targetAddress = 'Curlys+Unisex+Hair+%26+Makeup+Studio/@30.3403775,76.8390967'

						geocoder.geocode({address: targetAddress}, (results, status) => {
							if (status === window.google.maps.GeocoderStatus.OK && results.length > 0) {
								const targetLocation = results[0].geometry.location

								const mapOptions = {
									center: currentLocation,
									zoom: 12,
									disableDefaultUI: true
								}

								const map = new window.google.maps.Map(document.getElementById("map"), mapOptions)
								const directionsService = new window.google.maps.DirectionsService()
								const directionsRenderer = new window.google.maps.DirectionsRenderer()
								directionsRenderer.setMap(map)

								const request = {
									origin: currentLocation,
									destination: targetLocation,
									travelMode: window.google.maps.TravelMode.DRIVING
								}

								directionsService.route(request, (result, status) => {
									if (status === window.google.maps.DirectionsStatus.OK) {
										directionsRenderer.setDirections(result)
									} else {
										console.error("Error fetching directions:", status)
									}
								})
							} else {
								console.error("Geocoding failed for the target address:", status)
							}
						})
					},

					error => {
						console.error("Error occurred. Error code: " + error.code)
					}
				)
			} else {
				alert("Geolocation is not supported by this browser.")
			}
		}

		const loadGoogleMapsScript = () => {
			const googleMapsScript = document.createElement("script")
			googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${
				process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "AIzaSyAWpcoSvHUF8WGHEx7f2tB4NlLC9YR8i_k"
			}`
			googleMapsScript.async = true
			googleMapsScript.defer = true
			window.document.body.appendChild(googleMapsScript)
			googleMapsScript.addEventListener("load", showMap)
		}

		loadGoogleMapsScript()
	}, [])

	return (
		hasPermissions && (
			<div
				className='cursor-pointer'
				id='map'
				style={{height: hasPermissions ? "100%" : 0, width: hasPermissions ? "100%" : 0}}
			/>
		)
	)
}

export default MapWithRoute




// import React, {useEffect, useState} from "react"

// const TARGET_LATITUDE = 30.34285
// const TARGET_LONGITUDE = 76.84622

// const MapWithRoute = ({lat = TARGET_LATITUDE, lng = TARGET_LONGITUDE}) => {
// 	const [hasPermissions, setHasPermissions] = useState(false)
// 	const [currentLocation, setCurrentLocation] = useState(null)
// 	const isTabletOrLarger = window.innerWidth >= 768

// 	useEffect(() => {
// 		const showMap = () => {
// 			if (navigator.geolocation) {
// 				navigator.geolocation.getCurrentPosition(
// 					position => {
// 						setHasPermissions(true)

// 						const currentLocation = new window.google.maps.LatLng(
// 							position.coords.latitude,
// 							position.coords.longitude
// 						)
// 						// Geocode the target address to get its coordinates
// 						const geocoder = new window.google.maps.Geocoder()
// 						const targetAddress =
// 							"Curlys Unisex Hair & Makeup Studio, 5437/1/2, Nicholson Road, Cinema, opp. NIGAR THEATRE, Ambala Cantt, Haryana 134001 31.0011711,74.7289488"
// 						// const targetAddress = 'Curlys+Unisex+Hair+%26+Makeup+Studio/@30.3403775,76.8390967'

// 						geocoder.geocode({address: targetAddress}, (results, status) => {
// 							if (status === window.google.maps.GeocoderStatus.OK && results.length > 0) {
// 								const targetLocation = results[0].geometry.location

// 								const mapOptions = {
// 									center: currentLocation,
// 									zoom: 12,
// 									disableDefaultUI: true
// 								}

// 								const map = new window.google.maps.Map(document.getElementById("map"), mapOptions)
// 								const directionsService = new window.google.maps.DirectionsService()
// 								const directionsRenderer = new window.google.maps.DirectionsRenderer()
// 								directionsRenderer.setMap(map)

// 								const request = {
// 									origin: currentLocation,
// 									destination: targetLocation,
// 									travelMode: window.google.maps.TravelMode.DRIVING
// 								}

// 								directionsService.route(request, (result, status) => {
// 									if (status === window.google.maps.DirectionsStatus.OK) {
// 										directionsRenderer.setDirections(result)
// 									} else {
// 										console.error("Error fetching directions:", status)
// 									}
// 								})
// 							} else {
// 								console.error("Geocoding failed for the target address:", status)
// 							}
// 						})
// 					},

// 					error => {
// 						console.error("Error occurred. Error code: " + error.code)
// 					}
// 				)
// 			} else {
// 				alert("Geolocation is not supported by this browser.")
// 			}
// 		}

// 		const handlePermissionChange = () => {
// 			const permissionStatus = navigator.permissions.query({name: "geolocation"})
// 			permissionStatus.then(result => {
// 				if (result.state === "granted") {
// 					setHasPermissions(true)
// 					setCurrentLocation(null)
// 				} else {
// 					setHasPermissions(false)
// 					setCurrentLocation(null)
// 					showMap()
// 				}
// 			})
// 		}

// 		const loadGoogleMapsScript = () => {
// 			const googleMapsScript = document.createElement("script")
// 			googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${
// 				process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "AIzaSyAWpcoSvHUF8WGHEx7f2tB4NlLC9YR8i_k"
// 			}`
// 			googleMapsScript.async = true
// 			googleMapsScript.defer = true
// 			window.document.body.appendChild(googleMapsScript)
// 			googleMapsScript.addEventListener("load", showMap)
// 		}

// 		loadGoogleMapsScript()

// 		// Add event listener for permission change
// 		if (navigator.permissions && typeof navigator.permissions.onchange !== "undefined") {
// 			navigator.permissions.onchange = handlePermissionChange
// 		}

// 		//   handlePermissionChange();

// 		return () => {
// 			// Clean up event listener
// 			if (navigator.permissions && typeof navigator.permissions.onchange !== "undefined") {
// 				navigator.permissions.onchange = null
// 			}
// 		}
// 	}, [])

// 	return (
// 		<div className='cursor-pointer' style={{border: "2px solid white", height: "100%", width: "100%"}}>
// 			{hasPermissions && currentLocation && <div id='map' style={{height: "100%", width: "100%"}} />}
// 			{hasPermissions && !currentLocation && (
// 				<div className='grid justify-center items-center h-full' style={{color: "white", display: "grid"}}>
// 					Loading...
// 				</div>
// 			)}
// 		</div>
// 	)
// }
// export default MapWithRoute
