import React, {useRef, useEffect, useState} from "react"
import {ChakraProvider} from "@chakra-ui/react"
import AppRoutes from "./navigation/routes"
import {Helmet, HelmetProvider} from "react-helmet-async"
import SEOComponent from "./SEOComponent"
import PhoneCall from "./components/PhoneCall/PhoneCall"
import EmailSend from "./components/EmailSend/EmailSend"
import WhatsAppLogoField from "./components/WhatsAppLogoField/WhatsAppLogoField"
import WhatsAppLogoFieldClick from "./components/WhatsAppLogoField/WhatsAppLogoFieldClick"
import PhoneCallClick from "./components/PhoneCall/PhoneCallClick"
import EmailSendClick from "./components/EmailSend/EmailSendClick"
import ButtonViewMobile from "./components/ButtonViewMobile/ButtonViewMobile"
//import firebase from "firebase"

function App() {
	// useEffect(() => {
	// 	if (!("Notification" in window)) {
	// 		return alert("This browser does not support desktop notification")
	// 	}

	// 	Notification.requestPermission().then(function (permission) {
	// 		if (permission !== "denied") {
	// 			const msg = firebase.messaging()
	// 			msg.requestPermission()
	// 				.then(() => {
	// 					return msg.getToken()
	// 				})
	// 				.then(data => {
	// 					console.log("token", data)
	// 				})
	// 		}
	// 	})
	// }, [])

	return (
		<HelmetProvider>
			<ChakraProvider resetCSS>
				<div className='phoneEmailSide'>
					<span className='deskShow'>
						<PhoneCall />
						<EmailSend />
						<WhatsAppLogoField />
					</span>
					<span className='mobShow'>
						<ButtonViewMobile />
						{/* <PhoneCallClick />
						<EmailSendClick />
						<WhatsAppLogoFieldClick /> */}
					</span>
				</div>
				<AppRoutes />
			</ChakraProvider>
		</HelmetProvider>
	)
}

export default App
