import React, {useState, useEffect} from "react"
import "./index.css"
import {Box, Skeleton, SkeletonCircle, SkeletonText, Stack} from "@chakra-ui/react"

const ListingImagesSection = ({imagesList}) => {
	// const [popupImageUrl, setPopupImageUrl] = useState(null)
	const [isLoaded, setIsLoaded] = React.useState(true)

	useEffect(() => {
		const loadingTimeout = setTimeout(() => {
			setIsLoaded(false)
		}, 5000)

		return () => clearTimeout(loadingTimeout)
	}, [])

	// const handleImageClick = imageUrl => {
	// 	setPopupImageUrl(imageUrl)
	// }

	// const handleClose = () => {
	// 	setPopupImageUrl(null)
	// }

	return (
		<div className='listing-images-section'>
			<div className='image-container grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 my-10 bg-white rounded-3xl p-5 gap-9 bridal-img-height-parent'>
				{imagesList.map((image, index) => (
					<div
						className='image-item cursor-pointer mb-5 md:mb-0'
						key={index}
						// onClick={() => handleImageClick(image.imgpath)}
						>
						{isLoaded ? (
							<Box boxShadow='lg' className='relative'>
								<SkeletonText noOfLines={1} spacing='2' skeletonHeight='80'></SkeletonText>
								<Box className=' flex m-auto items-center h-full justify-center'>
									<SkeletonCircle
										size='20'
										width='200px'
										className='absolute top-28 flex m-auto justify-center text-center'
									/>
								</Box>
							</Box>
						) : (
							<>
								<div className='image-content text-content-mid  bridal-img-height' id="bridalExtension">
									<h1 className='text-img' key={index}>
										{image.textName}
									</h1>
								</div>
								<img
									src={image.imgpath}
									className='w-full'
									alt={`Image ${index + 1}`}
									onLoad={() => setIsLoaded(false)}
								/>
							</>
						)}
					</div>
				))}
			</div>

			<style jsx>{`
				.image-content {
					position: absolute;
					left: 20px;
					top: 20px;
					box-sizing: border-box;
					display: block;
					transition: transform 0.5s ease-in-out;
					height: calc(100% - 40px);
					width: calc(100% - 40px);
					padding: 20px;
					background-color: rgba(0, 0, 0, 0.4);
					border: 2px solid #c59d5f;
					z-index: 9;
					color: #fff;
					opacity: 0;
					margin: auto;
					border-radius: 10px;
				}
			`}</style>

			{/* {popupImageUrl && (
				<div className='popupListingImg'>
					<div className='popupListingImgIcons'>
						<span
							id='boot-icon'
							onClick={handleClose}
							className='closeBtnPopupListingImg bi bi-x-lg'></span>
						<span>
							<img src={popupImageUrl} alt='Large Image' />
						</span>
					</div>
				</div>
			)} */}
		</div>
	)
}

export default ListingImagesSection
