import React, {useRef, useEffect} from "react"
import {NavLink, useLocation, withRouter} from "react-router-dom"
import {useSelector} from "react-redux"
import MobileHeader from "../MobileHeader"
import styles from "./index.module.css"
import AvtaarDropDown from "./Avtaardropdown"
import {Helmet} from "react-helmet-async"
import {useState} from "react"

const Header = ({title, history}) => {
	const {cartItems} = useSelector(state => state.cart)
	const [localStorageCart, setLocaStorageCart] = useState([])
	const [isOpenServicecard, setsIsOpenServicecard] = useState(false)
	const [isOpenBridalCard, setsIsOpenBridalCard] = useState(false)
	const [isOpenNail, setIsOpenNail] = useState(false)

	const {OfferListFront} = useSelector(state => state.getOfferReducer)

	const {user} = useSelector(state => ({
		user: state.auth.user
	}))
	const {pathname, hash} = useLocation()

	useEffect(() => {
		setLocaStorageCart(JSON.parse(localStorage.getItem("cart")) || [])
	}, [cartItems])

	const handleMouseEnterService = () => {
		setsIsOpenServicecard(true)
	}

	const handleMouseLeaveService = () => {
		setsIsOpenServicecard(false)
	}

	const handleMouseEnterBridal = () => {
		setsIsOpenBridalCard(true)
	}

	const handleMouseLeaveBridal = () => {
		setsIsOpenBridalCard(false)
	}

	const onMouseEnterNail = () => {
		setIsOpenNail(true)
	}

	const onMouseLeaveNail = () => {
		setIsOpenNail(false)
	}

	const handleItemClick = () => {
		setsIsOpenServicecard(false)
		setsIsOpenBridalCard(false)
		setIsOpenNail(false)
	}

	const handleItemClickNails = () => {
		setsIsOpenServicecard(false)
		setsIsOpenBridalCard(false)
		setIsOpenNail(false)

		const servicesElement = document.getElementById("nailsExtensionArt")
		if (servicesElement) {
			servicesElement.scrollIntoView({behavior: "smooth"})
		}
	}

	const dataServiceName = [
		{servicetextName: "Bridal/makeup", routes: "bridal-makeup"},
		{servicetextName: "Nail", routes: "nail"},
		{servicetextName: "facial", routes: "facial"},
		{servicetextName: "Hair", routes: "hair"},
		{servicetextName: "Body care", routes: "body-care"},
		{servicetextName: "Waxing", routes: "waxing"},
		{servicetextName: "Threading", routes: "threading"},
		{servicetextName: "MenicurePedicure", routes: "manicure-pedicure"},
		{servicetextName: "Face Bleach", routes: "face-bleach"},
		{servicetextName: "Men", routes: "men"},
		{servicetextName: "Women", routes: "women"}
	]

	const handleItemClickSerivce = (event, routes) => {
		event.preventDefault()
		setsIsOpenServicecard(false)
		setsIsOpenBridalCard(false)
		setIsOpenNail(false)
		history.push(`/curly-service/${routes}`)
	}

	const dataBridalName = [
		{
			bridalTextName: "Engagement Makeup"
		},
		{
			bridalTextName: "Bridal Makeup"
		},
		{
			bridalTextName: "Party Makeup"
		},
		{
			bridalTextName: "Reception Makeup"
		},
		{
			bridalTextName: "Roka Makeup"
		},
		{
			bridalTextName: "Shoot Makeup"
		}
	]

	const NailsCategories = [
		{
			nailsTextname: "Nail Art"
		},
		{
			nailsTextname: "Nail Extension"
		}
	]

	return (
		<>
			<sapn id='nails'></sapn>

			<Helmet>
				<title>{`${title}`}</title>
				{/* <meta name='description' content={description || ''} />
				<meta name='keyword' content={keyword || ''} />  */}
			</Helmet>

			{/* <header
				className='mini-header'
				style={{
					backgroundColor: "rgb(229,200,136)",
					background:
						"linear-gradient(90deg, rgba(229,200,136,1) 0%, rgba(180,144,94,1) 35%, rgba(180,144,94,1) 71%)"
				}}>
				<div className='container space-x-4 ... mx-auto px-0 md:px-12  flex flex-wrap p-3  md:flex-row items-center sm:justify-end justify-center'>
					<a href='tel: +91-701-503-6702' className='text-white flex items-center'>
						<em className='bx bx-phone-call mx-1'></em>
						<span> +91-701-503-6702</span>
					</a>
					<a href='tel: +91-935-472-1082' className='text-white flex items-center'>
						<em className='bx bx-phone-call mx-1'></em>
						<span> +91-935-472-1082</span>
					</a>
					<a href='mailto:hellocurlys@gmail.com' className='text-white flex items-center'>
						<em className='bx bxl-gmail mx-1'></em>
						<span>hellocurlys@gmail.com</span>
					</a>
				</div>
			</header> */}

			<nav className='z-20 w-full top-0 fixedHeader bg-black-2xl'>
				<header className='text-gray-600 body-font bg-black-2xl'>
					<div
						className={`container mx-auto sm:px-12 px-3  flex flex-wrap p-3 md:flex-row items-center justify-between ${styles.deskCont}`}>
						<NavLink to='/'>
							<span className={styles.logo}>
								<img
									src='/img/main-logo.png'
									alt='Curlys Unisex Hair & Makeup Studio'
									style={{width: "auto"}}
									className='hidden sm:block'
								/>
								<img
									src='/img/curl-fav.png'
									alt='Curlys Unisex Hair & Makeup Studio									'
									style={{width: "auto"}}
									className='block sm:hidden'
								/>
							</span>
						</NavLink>

						<nav
							className={`md:ml-auto md:mr-auto flex flex-wrap uppercase items-center text-base justify-center ${styles.navHome}`}>
							<NavLink to='/'>
								<span
									className={`mr-10  ${
										pathname === "/" && hash !== "#about" ? "text-gold-400" : "text-white"
									}  hover:text-gold-400`}
									onClick={() => {
										setTimeout(() => {
											document.getElementById("sliderHome").scrollIntoView({behavior: "smooth"})
										}, 100)
									}}>
									Home
								</span>
							</NavLink>

							<NavLink
								to='/#about'
								className={` ${
									hash === "#about" ? "text-gold-400" : "text-white"
								} mr-10  text-white hover:text-gold-400`}
								onClick={() => {
									setTimeout(() => {
										document.getElementById("#about").scrollIntoView({behavior: "smooth"})
									}, 100)
								}}

								//   onClick={() => {
								// 	setTimeout(() => {
								// 	  const aboutSection = document.getElementById("about");
								// 	  if (aboutSection) {
								// 		aboutSection.scrollIntoView({ behavior: "smooth" });
								// 		const offset = aboutSection.offsetTop - 100;
								// 		window.scrollTo({
								// 		  top: offset,
								// 		  behavior: "smooth",
								// 		});
								// 	  }
								// 	}, 100);
								//   }}
							>
								About Us
							</NavLink>

							<NavLink
								to='/services'
								className='relative'
								onMouseEnter={handleMouseEnterService}
								onMouseLeave={handleMouseLeaveService}>
								<span
									className={`mr-10 ${
										pathname === "/services" || pathname === "/curly-service"
											? "text-gold-400"
											: "text-white"
									} hover:text-gold-400`}
									onClick={() => {
										setTimeout(() => {
											document.getElementById("service").scrollIntoView({behavior: "smooth"})
										}, 100)
									}}>
									Services
								</span>

								{isOpenServicecard && (
									<div
										className={styles.dropdownContent}

										// onClick={() => {
										// 	setTimeout(() => {
										// 		document.getElementById("ServiceCategory").scrollIntoView({behavior: "smooth"})
										// 	}, 100)
										// }}
									>
										{dataServiceName.map((items, index) => {
											const {servicetextName, routes} = items
											return (
												<span
													key={index}
													onClick={event => handleItemClickSerivce(event, routes)}
													className={`${styles.dropdownHour}`}>
													{servicetextName}
												</span>
											)
										})}
									</div>
								)}
							</NavLink>

							<NavLink
								to='/bridal'
								className='relative'
								onMouseEnter={handleMouseEnterBridal}
								onMouseLeave={handleMouseLeaveBridal}>
								<span
									className={`mr-10 ${
										pathname === "/bridal" ? "text-gold-400" : "text-white"
									} hover:text-gold-400`}
									onClick={() => {
										setTimeout(() => {
											document.getElementById("bridal").scrollIntoView({behavior: "smooth"})
										}, 100)
									}}>
									Bridal
								</span>
								{isOpenBridalCard && (
									<div
										className={styles.dropdownContent}
										onClick={() => {
											setTimeout(() => {
												document
													.getElementById("bridalImg")
													.scrollIntoView({behavior: "smooth"})
											}, 100)
										}}>
										{dataBridalName.map((items, index) => {
											const {bridalTextName} = items
											return (
												<a
													key={index}
													// href='/bridal'
													href={`/bridal?selected=${index}`}
													onClick={handleItemClick}
													className={styles.dropdownHour}>
													{bridalTextName}
												</a>
											)
										})}
									</div>
								)}
							</NavLink>

							<NavLink
								to='/nails'
								className='relative'
								onMouseEnter={onMouseEnterNail}
								onMouseLeave={onMouseLeaveNail}>
								<span
									className={`mr-10 ${
										pathname === "/nails" || pathname === "/nails" ? "text-gold-400" : "text-white"
									} hover:text-gold-400`}
									onClick={() => {
										setTimeout(() => {
											document.getElementById("nails").scrollIntoView({behavior: "smooth"})
										}, 100)
									}}>
									Nails
								</span>

								{isOpenNail && (
									<div className={styles.dropdownContent}>
										{NailsCategories.map((items, index) => {
											const {nailsTextname} = items
											return (
												<a
													key={index}
													onClick={handleItemClickNails}
													href='/nails'
													className={styles.dropdownHour}>
													{nailsTextname}
												</a>
											)
										})}
									</div>
								)}
							</NavLink>

							<NavLink to='/offers'>
								<span
									className={`mr-10  ${
										OfferListFront?.data?.length === 0 ? null : "animate-charcter-second"
									} ${pathname === "/offers" ? "text-gold-400" : "text-white"} hover:text-gold-400`}
									onClick={() => {
										setTimeout(() => {
											document.getElementById("offers").scrollIntoView({behavior: "smooth"})
										}, 100)
									}}>
									Offers
								</span>
							</NavLink>

							<a
								href='
									https://docs.google.com/forms/d/1VQSTUZqg5sBRLuqC9BxtHbJVTz8gq3ddnqDFTl4_gE4/viewform?edit_requested=true'
								target='_blank'
								rel='noreferrer'
								className='inline-flex mx-3 custom-btn-feed custom-btn-feed-inside items-center bg-gold text-white 
									border-0 py-1 px-3 focus:outline-none hover:bg-gold-400 rounded text-base uppercase '>
								Feedback
							</a>
						</nav>

						<div className='flex items-center'>
							<NavLink
								to='/cart'
								className={` text-gray-300 ${
									user ? "block" : "hidden"
								}  relative pl-3 rounded-md font-medium`}>
								<i className='bx bx-building-house text-3xl'></i>
								<p
									style={{width: "18px", height: "18px"}}
									className={`absolute flex items-center top-0 right-1 justify-center text-xs text-center bg-gold rounded-full ${
										cartItems?.length === 0 && localStorageCart?.length === 0 ? "hidden" : "block"
									}  `}>
									{cartItems?.length || localStorageCart?.length}
								</p>
							</NavLink>
							<button
								className={`inline-flex mx-3 items-center bg-gold text-white ${
									!user ? "block" : "hidden"
								} border-0 py-1 px-3 focus:outline-none hover:bg-gold-400 rounded text-base`}>
								<NavLink to='/login'>Login</NavLink>
							</button>
							<button
								className={`inline-flex mx-3 items-center bg-gold ${
									user ? "hidden" : "block"
								} text-white border-0 py-1 px-3 focus:outline-none hover:bg-gold-400 rounded text-base`}>
								<NavLink to='/register'>Signup</NavLink>
							</button>
							{user && <AvtaarDropDown />}
							<MobileHeader />
						</div>
					</div>
				</header>
			</nav>
		</>
	)
}

export default withRouter(Header)
