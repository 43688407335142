const initialData = {
    services:[]
}

export const GetServices = (state = initialData , action)=>{

  switch (action.type){
        case "GET_SERVICES" : return {
            ...state,
            services : action.payload.data
        }
        default :  return state
  }

}