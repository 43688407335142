import React from "react"
// import {CircularProgress} from "@chakra-ui/react"

const Loading = ({color = "blue.300", show}) => {
	return show ? (
		<>
			<div className='bg-black flex justify-center items-center flex-col w-full h-full fixed top-0 right-0 bottom-0 left-0 z-20'>
				{/* <CircularProgress isIndeterminate color={color} /> */}
				<img src='/img/main-logo.png' alt='' style={{width: "auto"}} />
				<div className='detail text-white text-center'>
					<p className='text-2xl'>+91-701-503-6702</p>
					<p className='text-2xl'>+91-935-472-1082</p>
					<p className='text-2xl'>hellocurlys@gmail.com</p>
				</div>
			</div>
		</>
	) : (
		""
	)
}

export default Loading
