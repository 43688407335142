import React, {useState} from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Stack,
	Input,
	FormLabel,
	FormControl,
	FormErrorMessage,
	useToast,
	Select,
	IconButton
} from "@chakra-ui/react"
import * as Yup from "yup"
import {useForm} from "react-hook-form"
import EditIcon from "../../Icons/EditIcon"
import {useDispatch} from "react-redux"
import {useYupValidationResolver} from "../../../hooks/useValidationResolver"
import {updateOffer} from "../../../store/actions/offer"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
const validationSchema = Yup.object({
	name: Yup.string().required("Offer Name is required!"),
	status: Yup.string().required("Status is required!")
})

const EditOfferModal = props => {
	const dispatch = useDispatch()
	const toast = useToast()
	const [loading, setLoading] = useState(false)
	const {isOpen, onOpen, onClose} = useDisclosure()

	const resolver = useYupValidationResolver(validationSchema)
	const {
		register,
		handleSubmit,
		formState: {errors, touchedFields},
		reset
	} = useForm({
		resolver,
		defaultValues: props.offerData,
		mode: "onBlur"
	})

	const onEditOffer = data => {
		const updatedOfferObj = {
			id: props.offerData?.id,
			name: data.name,
			sort: data.sort,
			expiry: data.expiry || "0000-00-00",
			status: data.status
		}
		setLoading(true)
		dispatch(updateOffer(updatedOfferObj)).then(() => {
			setLoading(false)
			onClose()
			props.updateList()
			toast({
				title: "Offer has been Updated!",
				status: "success",
				duration: 3000,
				isClosable: true
			})
			// reset the form
			reset()
		})
	}

	const onError = (errors, e) => console.log(errors, e)

	return (
		<>
			<>
				<IconButton
					className=' ml-3 md:ml-5'
					colorScheme='green'
					aria-label='Edit service'
					onClick={onOpen}
					icon={<EditIcon />}
					size='sm'
				/>
				{/* <Button colorScheme='blue' onClick={onOpen}>
					Add Offer
				</Button> */}

				<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
					<ModalOverlay />
					<form
						style={{overflow: "auto"}}
						noValidate
						onSubmit={handleSubmit(onEditOffer, onError)}
						className='bg-white'>
						<ModalContent>
							<ModalHeader>Edit Offer</ModalHeader>
							<ModalCloseButton />

							<ModalBody pb={6}>
								<Stack spacing={4}>
									<FormControl isInvalid={touchedFields?.name && errors?.name} id='name'>
										<FormLabel>Name</FormLabel>
										<Input {...register("name")} placeholder='Enter Name' type='text' />
										<FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
									</FormControl>
									<FormControl isInvalid={touchedFields?.expiry && errors?.expiry} id='expiry'>
										<FormLabel>Expiry Date</FormLabel>
										<Input {...register("expiry")} placeholder='Enter Expiry Date' type='date' />
										<FormErrorMessage>{errors?.expiry?.message}</FormErrorMessage>
									</FormControl>
									<FormControl isInvalid={touchedFields?.sort && errors?.sort} id='sort'>
										<FormLabel>Sorting</FormLabel>
										<Input {...register("sort")} placeholder='Enter Sorting' type='text' />
										<FormErrorMessage>{errors?.sorting?.message}</FormErrorMessage>
									</FormControl>
									<FormControl isInvalid={touchedFields?.status && errors?.status} id='status'>
										<FormLabel>Status</FormLabel>
										<Select {...register("status")}>
											<option value={1}>Enable</option>
											<option value={0}>Disable</option>
										</Select>
										<FormErrorMessage>{errors?.status?.message}</FormErrorMessage>
									</FormControl>
								</Stack>
							</ModalBody>
							<ModalFooter>
								<Button className='mr-4' onClick={onClose}>
									Close
								</Button>
								<Button isLoading={loading} colorScheme='blue' className='bg-gold' type='submit'>
									Update offer
								</Button>
							</ModalFooter>
						</ModalContent>
					</form>
				</Modal>
			</>
		</>
	)
}

export default EditOfferModal
