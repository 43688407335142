import React from "react"

const PageHeader = ({pageHeading, rightContent, className, flex1Class, classes}) => {
	return (
		<header className='bg-white shadow'>
			<div className='max-w-7xl mx-auto py-4 px-2 sm:px-6 lg:px-8'>
				<div className={`lg:flex lg:items-center lg:justify-between ${className} `}>
					<div className={`flex-1 min-w-0 ${flex1Class}`}>
						<p className='text-xl font-semibold leading-5 text-gray-900 sm:text-3xl sm:truncate'>
							{pageHeading}
						</p>
					</div>
					<div className={`flex items-center lg:mt-0  ${classes}`}>{rightContent}</div>
				</div>
			</div>
		</header>
	)
}

export default PageHeader
PageHeader.defaultProps = {
	classes: "lg:ml-4"
}
