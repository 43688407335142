import React from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Divider
} from "@chakra-ui/react"

import {chakra} from "@chakra-ui/system"
import {formatCurrency} from "../../../utils/currencyFormat"

const ProductsSummary = ({productDetail, services}) => {
	const {isOpen, onOpen, onClose} = useDisclosure()

	return (
		<>
			<span className='text-black text-center cursor-pointer' title="see more" onClick={onOpen}>
				{/* see more */}
				<span id="boot-icon" className="bi bi-eye" style={{ fontSize: '24px', webkitTextStrokeWidth: '0px', opacity: '1' }}></span>
			</span>

			<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
				<ModalOverlay className='mx-w-full bg-gold-701  modalOverlay' />
				<ModalContent className='shadow-slate-1001'>
					<ModalHeader className='header-product'>
						<div className='mt-5 flex   flex-col  bg-gray-100'>
							<span
								className={`bg-black  text-white px-3 py-1 absolute whitespace-nowrap tracking-widest text-xs  left-0 top-0 
										`}>
								<div className={`flex flex-wrap sm:flex-row flex-col`}>
									<p className='text-white cursor-default'>{services.toString()}</p>
								</div>
							</span>
							<chakra.p>{productDetail.name}</chakra.p>
							<div className="sm:flex justify-between ">
							<chakra.p>{formatCurrency(Number(productDetail.price)-Number(productDetail.discount))}</chakra.p>
							{Number(productDetail.discount) >= 1  && <chakra.p className="flex items-center">
							<span className='w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0'>
											<svg
												fill='none'
												stroke='currentColor'
												strokeLinecap='round'
												strokeLinejoin='round'
												strokeWidth='2.5'
												className='w-4 h-4'
												viewBox='0 0 24 24'>
												<path d='M20 6L9 17l-5-5'></path>
											</svg>
										</span>
										<span className="line-through font-medium mr-4">
											{productDetail.price}
										</span>
										<span>
										<span>(₹ {Number(productDetail.discount)} off)</span>
									</span>
							</chakra.p> } 
							</div>
						</div>
					</ModalHeader>
					<Divider orientation='horizontal' />
					<ModalCloseButton className='text-black' />
					<ModalBody className='p-0'>
						<div className='p-5'>
							<ul dangerouslySetInnerHTML={{__html: productDetail.comment}}></ul>
						</div>
					</ModalBody>

					<ModalFooter></ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default ProductsSummary
