import React, {useRef, useEffect, useState} from "react"
import "./index.css"

const PhoneCall = () => {
	const dropdownBtnRef = useRef(null)
	const dropdownContentRef = useRef(null)
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	useEffect(() => {
		const dropdownBtn = dropdownBtnRef.current
		const dropdownContent = dropdownContentRef.current

		const openMenu = () => {
			setIsMenuOpen(true)
		}

		const closeMenu = () => {
			setIsMenuOpen(false)
		}

		dropdownBtn.addEventListener("mouseenter", openMenu)
		dropdownBtn.addEventListener("mouseleave", closeMenu)
		dropdownContent.addEventListener("mouseenter", openMenu)
		dropdownContent.addEventListener("mouseleave", closeMenu)

		return () => {
			dropdownBtn.removeEventListener("mouseenter", openMenu)
			dropdownBtn.removeEventListener("mouseleave", closeMenu)
			dropdownContent.removeEventListener("mouseenter", openMenu)
			dropdownContent.removeEventListener("mouseleave", closeMenu)
		}
	}, [])

	// const dropdownBtnRef = useRef(null)
	// // const dropdownCaretRef = useRef(null);
	// const dropdownContentRef = useRef(null)
	// const [isMenuOpen, setIsMenuOpen] = useState(false)

	// useEffect(() => {
	// 	const dropdownBtn = dropdownBtnRef.current
	// 	//   const dropdownCaret = dropdownCaretRef.current;
	// 	const dropdownContent = dropdownContentRef.current

	// 	const toggleMenu = () => {
	// 		// dropdownCaret.classList.toggle("arrow-rotate");
	// 		dropdownContent.classList.toggle("menu-open")
	// 		dropdownBtn.setAttribute(
	// 			"aria-expanded",
	// 			dropdownBtn.getAttribute("aria-expanded") === "true" ? "false" : "true"
	// 		)
	// 	}

	// 	dropdownBtn.addEventListener("click", toggleMenu)

	// 	return () => {
	// 		dropdownBtn.removeEventListener("click", toggleMenu)
	// 	}
	// }, [])

	return (
		//  <div className=' phoneEmailSide'>
		// 		<button
		// 			ref={dropdownBtnRef}
		// 			className='dropdown-btn'
		// 			aria-label='menu button'
		// 			aria-haspopup='menu'
		// 			aria-expanded={isMenuOpen ? "true" : "false"}
		// 			aria-controls='dropdown-menu'>
		// 			<em className='bx phonecall bx-phone-call mx-1'></em>
		// 			<em className='bx emailSent bxl-gmail mx-1'></em>

		// 		</button>

		// 		<ul
		// 			ref={dropdownContentRef}
		// 			className={`dropdown-content ${isMenuOpen ? "menu-open" : ""}`}
		// 			role='menu'
		// 			id='dropdown-menu'>
		// 			<li style={{"--delay": 1}}>
		// 				<a href='tel: +91-701-503-6702' className='text-white whitespace-nowrap flex items-center'>
		// 					{" "}
		// 					<span> +91-701-503-6702</span>
		// 				</a>
		// 			</li>
		// 			<li style={{"--delay": 2}}>
		// 				<a href='tel: +91-935-472-1082' className='text-white whitespace-nowrap flex items-center'>
		// 					<span> +91-935-472-1082</span>
		// 				</a>
		// 			</li>
		// 			<li style={{"--delay": 3}}>
		// 				<a href='mailto:hellocurlys@gmail.com' className='text-white whitespace-nowrap flex items-center'>
		// 					<span>hellocurlys@gmail.com</span>
		// 				</a>
		// 			</li>
		// 		</ul>
		// 	</div>

		<div>
			<button
				ref={dropdownBtnRef}
				className='dropdown-btn'
				aria-label='menu button'
				aria-haspopup='menu'
				aria-expanded={isMenuOpen ? "true" : "false"}
				aria-controls='dropdown-menu'>
				<em className='bx phonecall bx-phone-call mx-1'></em>
			</button>

			<ul
				ref={dropdownContentRef}
				className={`dropdown-content-phone ${isMenuOpen ? "menu-open" : ""}`}
				role='menu'
				id='dropdown-menu'>
				<li style={{"--delay": 1}}>
					<a href='tel: +91-701-503-6702' className='text-white whitespace-nowrap flex items-center'>
						<span> +91-701-503-6702</span>
					</a>
				</li>
				<li style={{"--delay": 2}} className='mt-1'>
					<a href='tel: +91-935-472-1082' className='text-white whitespace-nowrap flex items-center'>
						<span> +91-935-472-1082</span>
					</a>
				</li>
			</ul>
		</div>
	)
}

export default PhoneCall
