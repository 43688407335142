import {
  ADD_SALARY,
  GET_SALARY_LIST,
  DELELE_SALARY,
  UPDATE_SALARY,
} from "../constants/salary";

const initialState = {
  salary: [],
};

export const salaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SALARY:
      return {
        ...state,
        salary: action.payload,
      };
    case GET_SALARY_LIST:
      return {
        ...state,
        salary: action.payload,
      };
    case DELELE_SALARY:
      return {
        ...state,
        salary: action.payload,
      };
    case UPDATE_SALARY:
      return {
        ...state,
        salary: action.payload,
      };
    default:
      return state;
  }
};
