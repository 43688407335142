import {Route, Redirect} from "react-router-dom"
import {useSelector} from "react-redux"

function AdminRoute({component: Component, ...rest}) {
	const {token, user} = useSelector(state => state.auth)
    //const {user_type} = user;

	return (
		<Route
			{...rest}
			render={props => {
				return token && user && user?.user_type === 1 ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: "/",
							state: {from: props.location}
						}}
					/>
				)
			}}
		/>
	)
}

export default AdminRoute
