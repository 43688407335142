import {UpdateUserStatus} from "../../store/actions/auth"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Text,
	useToast,
	Stack
} from "@chakra-ui/react"
import {useDispatch} from "react-redux"
const ConfirmStatusModal = ({element, updatelist}) => {
	const Dispatcher = useDispatch()
	const toast = useToast()
	const {isOpen, onOpen, onClose} = useDisclosure()

	const ChangeStatusHandler = () => {
		const userObj = {
			id: element.id,
			status: element.status === 0 ? 1 : 0
		}
		Dispatcher(UpdateUserStatus(userObj)).then(() => {
			toast({
				title: "Status Updated Sucessfully",
				status: "success",
				duration: 4000,
				isClosable: true
			})
			updatelist()
			onClose()
		})
	}

	return (
		<>
			<p
				className={`cursor-pointer ${
					element.status === 0 ? "bg-red-900" : "bg-green-900"
				} py-1 px-3 rounded  text-center text-white`}
				onClick={onOpen}>
				<span>{element.status === 0 ? "Deactivated" : "Activated"}</span>
			</p>
			<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
				<ModalOverlay className='bg-gold-701 modalOverlay' />
				<ModalContent className='mx-3'>
					<ModalHeader className='capitalize bg-gold text-center text-2xl font-medium text-white'>
						Status Confirmation
					</ModalHeader>
					<ModalCloseButton className='text-white ' />
					<ModalBody>
						<Stack spacing={3} justifyContent='center' alignItems='center' className='mt-4'>
							<div className=''>
								<i
									className='bx bx-info-circle text-6xl text-red-600'
									style={{transform: "rotateZ(180deg)"}}></i>
							</div>
							<Text className='text-4xl font-bold text-gray-700 mb-3'>Are you sure?</Text>
							<Text className='text-xl font-medium text-gray-500 mb-2'>
								Do you want to Change Status ?
							</Text>
						</Stack>
					</ModalBody>
					<ModalFooter className='justify-center mb-4'>
						<button className='px-6 py-1 rounded  mx-3 bg-gray-200 text-lg' onClick={onClose}>
							No
						</button>
						<button
							className='px-6 py-1 rounded bg-gold mx-3 text-lg text-white'
							onClick={ChangeStatusHandler}>
							Yes
						</button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default ConfirmStatusModal
