import React from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	IconButton
} from "@chakra-ui/react"

const OrderView = ({data, url, print, icon}) => {
	// const dispatch = useDispatch();
	// const toast = useToast();
	const {isOpen, onClose} = useDisclosure()
	// const [isPrint, setIsPrint] = useState(print)
	let isPrint = print

	const URL = process.env.REACT_APP_URL?.slice(0, 21)

	// useEffect(() => {
	//   if (print) {
	//     window.print();
	//   }
	// }, [print]);

	return (
		<>
			<IconButton
				className='mr-3'
				colorScheme='teal'
				aria-label='Edit Expense'
				// onClick={onOpen}
				onClick={() => {
					window.open(`${URL}/billing/${data.uid}?print=${isPrint}`, "_self")
				}}
				icon={icon}
				size='sm'
			/>
			<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className='capitalize'>View Order</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<h5>Date: {data.bill_date}</h5>
						<h5 className='mt-5'>Total: {data.total_amount}</h5>
						<h5 className='mt-5'>Cash: {data.cash}</h5>
						<h5 className='mt-5'>Online: {data.online}</h5>
						<h5 className='mt-5'>Staff: {data.Staff}</h5>
						<h5 className='mt-5'>Services: {data.services}</h5>
					</ModalBody>

					<ModalFooter>
						<Button mr={3} onClick={() => window.print()} colorScheme='teal'>
							Print
						</Button>
						{/* <Button isLoading={loading} type="submit" colorScheme="green">
                Update
              </Button> */}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default OrderView
