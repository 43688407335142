import {Avatar} from "@chakra-ui/avatar"
import {Button} from "@chakra-ui/button"

import React, {useState, useRef, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import PageContainer from "../../components/PageContainer"
import {IMAGE_BASE_URL} from "../../constants/api"
import PrivateLayout from "../../layout/PrivateLayout"
import {useDisclosure} from "@chakra-ui/hooks"
import UpdateProfile from "./UpdateProfile"
import {checkUser} from "../../store/actions/auth"
// import PageHeader from "../../components/PageHeader"
import Loader from "../../components/Loader/Loader"

const Profile = ({history}) => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true)
		dispatch(checkUser()).then(() => {
			setLoading(false)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const {user} = useSelector(state => ({
		user: state.auth.user
	}))

	const {isOpen, onOpen, onClose} = useDisclosure()
	const [profileImage, setProfileImage] = useState({
		preview: user?.image && `${IMAGE_BASE_URL}/${user?.image}`,
		raw: null
	})

	const imageRef = useRef()

	return (
		<PrivateLayout>
			{/* <PageHeader title='Profile' /> */}

			{loading ? (
				<Loader className='items-center' style={{height: "calc(100vh - 10rem)"}} />
			) : (
				<PageContainer>
					<div className='bg-white justify-center flex flex-col rounded-md shadow-md md:w-5/12 mx-auto my-20 p-4'>
						<div className='w-11/12 mx-auto'>
							<h1 className='font-semibold lg:text-2xl mb-4 text-center'>Profile</h1>
							<div className='w-24 mx-auto'>
								<Avatar src={user?.image && `${IMAGE_BASE_URL}/${user?.image}`} size='xl' />
							</div>

							<div
								className={`mt-4 flex flex-row gap-x-4 bg-gray-100 ${
									user.name === "" ? "hidden" : "block"
								} lg:w-full justify-center rounded-md shadow-lg p-2`}>
								<div className='font-semibold lg:text-lg'>NAME:</div>
								<div className='font-bold lg:text-lg'>{user.name}</div>
							</div>

							{/* ====================== Friendy Name Field ====================  */}

							{/* <div
							className={`mt-4 flex flex-row gap-x-4 bg-gray-100 ${
								user.name1 === "" ? "hidden" : "block"
							} lg:w-full justify-center rounded-md shadow-lg p-2`}>
							<div className='font-semibold lg:text-lg'>Friendly Name:</div>
							<div className='font-bold lg:text-lg'>{user.name1}</div>
						</div> */}

							<div
								className={`mt-4 flex flex-row gap-x-4 bg-gray-100 lg:w-full justify-center rounded-md shadow-lg p-2`}>
								<div className='font-semibold lg:text-lg'>DOB:</div>
								<div className='font-bold lg:text-lg'>
									{user.dob === "0000-00-00" || "" ? "Not Set" : user.dob}
								</div>
							</div>

							<div
								className={`mt-4 flex flex-row gap-x-4 bg-gray-100 lg:w-full justify-center rounded-md shadow-lg p-2`}>
								<div className='font-semibold lg:text-lg'>ANNIVERSARY:</div>
								<div className='font-bold lg:text-lg'>
									{user.anniversary === "0000-00-00" || "" ? "Not Set" : user.anniversary}
								</div>
							</div>

							<div
								className={`mt-4 flex flex-row gap-x-4 bg-gray-100 ${
									!user.email ? "hidden" : "block"
								} lg:w-full justify-center rounded-md shadow-lg p-2`}>
								<div className='font-semibold lg:text-lg'>EMAIL:</div>
								<div className='font-bold lg:text-lg'>{user.email}</div>
							</div>

							<div
								className={`mt-4 flex flex-row gap-x-4 bg-gray-100 ${
									user.gender === "" ? "hidden" : "block"
								} lg:w-full justify-center rounded-md shadow-lg p-2`}>
								<div className='font-semibold lg:text-lg'>Gender:</div>
								<div className='font-bold lg:text-lg'>{user.gender === "2" ? "Female" : "Male"}</div>
							</div>
							<div
								className={`mt-4 flex flex-row gap-x-4 bg-gray-100 ${
									user.comment === "" ? "hidden" : "block"
								} lg:w-full justify-center rounded-md shadow-lg p-2`}>
								<div className='font-semibold lg:text-lg'>Comment:</div>
								<div className='font-bold lg:text-lg'>{user.comment}</div>
							</div>
							<div
								className={`mt-4 flex flex-row gap-x-4 bg-gray-100 ${
									user.address === "" ? "hidden" : "block"
								} lg:w-full justify-center rounded-md shadow-lg p-2`}>
								<div className='font-semibold lg:text-lg'>Address:</div>
								<div className='font-bold lg:text-lg'>{user.address}</div>
							</div>

							<div className='mt-4 text-center'>
								<Button colorScheme='blue' onClick={onOpen}>
									Update Profile
								</Button>
							</div>
						</div>
					</div>

					<UpdateProfile
						isOpen={isOpen}
						profileImage={profileImage}
						setProfileImage={setProfileImage}
						onClose={onClose}
						loading={loading}
						setLoading={setLoading}
						user={user}
						imageRef={imageRef}
						history={history}
					/>
				</PageContainer>
			)}
		</PrivateLayout>
	)
}

export default Profile
