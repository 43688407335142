

const currentDate = new Date()
export const getDateDiffrence = date => {
	const fullYear = new Date().getFullYear()
	const formatDate = new Date(date)
	const diffTime = Math.abs(currentDate - formatDate.setFullYear(fullYear))
	const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24))

	if((currentDate.getDate() <= formatDate.getDate()) && (currentDate.getMonth() <= formatDate.getMonth())){
	if (diffDays < 15) {
		return diffDays
	} else {
		return null
	}
}
else {
	return "expires"
}		
}

export const Greetings = () => {
	const ndate = new Date()
	const hours = ndate.getHours()
	return hours < 12 ? "Good Morning" : hours < 18 ? "Good Afternoon" : "Good Evening"
}
