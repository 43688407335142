// import axios from "axios";
import axios from '../../utils/axios';
import { DEFAULT_HEADERS } from "../../utils";
import {
  CATEGORIES_LIST_ENDPOINT,
  GET_PRODUCTS_BY_CATEGORY_ID_ENDPOINT,
  GETCATEGORIES,
} from "../../constants/api";
import {
  CATEGORIES_LIST_ITEM,
  GET_PRODUCTS_BY_CATEGORY_ID,
  GET_CATEGORIES_FOR_FRONTEND,
} from "../constants/categorieslist";


export const addCategories = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(
        CATEGORIES_LIST_ENDPOINT,
        null,
        DEFAULT_HEADERS
      );
      dispatch({
        type: CATEGORIES_LIST_ITEM,
        payload: {
          Categorieslist: data.data,
        },
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};









// This function fetch Services for Front End


export const productsByCategoryID = (searchData) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    const { categoryId, searchText } = searchData;
    try {
      let searchQuery = "";
      if (categoryId) {
        searchQuery = `?search&cat_id=${categoryId}`;
      }
      if (searchText) {
        searchQuery = `?search=${searchText}&cat_id=${categoryId}`;
      }

      const { data } = await axios.post(
        `${GETCATEGORIES}${searchQuery}`,
        null,
        DEFAULT_HEADERS
      );
      dispatch({
        type: GET_CATEGORIES_FOR_FRONTEND,
        payload: {
          productsByCategoryId: data.data,
        },
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};











// This function fetch Services for Back End


export const getProductsByCategoryId = (searchData) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    const { categoryId, searchText, staff } = searchData;
    try {

      //  let searchQuery = `?search&cat_id=${categoryId}&cat_id=${categoryId}&staff=${staff}`

      let searchQuery = "";

      if (categoryId) {
        searchQuery = `?search&cat_id=${categoryId}`;
      }
      if (searchText) {
        searchQuery = `?search=${searchText}&cat_id=${categoryId}`;
      }
      if (staff) {
        // console.log(str);
        searchQuery = `?search=${searchText}&cat_id=${categoryId}&staff=${staff}`;
      }

      const { data } = await axios.post(
        `${GET_PRODUCTS_BY_CATEGORY_ID_ENDPOINT}${searchQuery}`,
        null,
        DEFAULT_HEADERS
      );
      dispatch({
        type: GET_PRODUCTS_BY_CATEGORY_ID,
        payload: {
          productsByCategoryId: data.data,
        },
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};
