import {GET_EMP_USER, GET_INVOICE_USER, GET_ALL_USER, GET_STAFF_USERS, BIRTHDAY_USERS} from "../constants/getUser"

const initialState = {
	getEmpUser: [],
	getUser: [],
	getAlluser: "",
	getStaffUser: [],
	getBirthdayUser: []
}

export const getUserReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_INVOICE_USER:
			return {
				...state,
				getUser: action.payload
			}
		case GET_EMP_USER:
			return {
				...state,
				getEmpUser: action.payload
			}
		case GET_ALL_USER:
			return {
				...state,
				getAlluser: action.payload.data
			}
		case GET_STAFF_USERS:
			return {
				...state,
				getStaffUser: action.payload.data
			}
		case BIRTHDAY_USERS:
			return {
				...state,
				getBirthdayUser: action.payload
			}
		default:
			return state
	}
}
