const initialState = {
    loading: false,
    success: false,
    data: "",
    error: null,

}

export const bookAppointemnt = (state = initialState, action) => {
    switch (action.type) {
        case "BOOK_APPOINTMENT_LOADING": return {
            ...state,
            loading: true,
        }
        case "BOOK_APPOINTMENT_SUCCESS": return {
            ...state,
            loading: false,
            data: action.payload.data,
            success: true,
        }
        case "BOOK_APPOINTMENT_ERROR": return {
            ...state,
            error: action.payload
        }
        default: return state
    }
}
const initialListing = {
    data: []
}
export const getAppointmentListing = (state = initialListing, action) => {
    switch (action.type) {
        case "GET_LISTING": return {
            ...state,
            data: action.payload
        }
        default: return state

    }
}