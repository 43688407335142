import React, {useState, useEffect} from "react"
import {Table, Thead, Tbody, Tfoot, Tr, Th, Td, useToast, Box} from "@chakra-ui/react"
import {useSelector, useDispatch} from "react-redux"
import PrivateLayout from "../../layout/PrivateLayout"
import PageHeader from "../../components/PageHeader"
import PageContainer from "../../components/PageContainer"
import AddSalaryModal from "../../components/Modals/AddSalaryModal"
import DeleteConfirmModal from "../../components/Modals/DeleteConfirmModal"
import EditSalaryModal from "../../components/Modals/EditSalaryModal"
import {ExportToExcel} from "../../components/ExportData/ExportData"
import Loader from "../../components/Loader/Loader"

// ========================== Expense Actions =======================
//  These Actions Coming from Expense Action This Actions Call Expense APi's in This Section thats why i commented these Actions
// import {getAllExpense, deleteExpense} from "../../store/actions/expense"

import {deleteSalary, getSalaryList} from "../../store/actions/salary"
import {getSalarySummary} from "../../utils/list-expense"
import styles from "./index.module.css"
import ViewSalaryModal from "../../components/Modals/ViewSalaryModal"
import {IoIosArrowUp, IoIosArrowDown} from "react-icons/io"
import {getStaffUserList} from "../../store/actions/getUser"
import {getStartOfMonth} from "../../utils/common"
import {DateRangePicker} from "react-dates"
import moment from "moment"

const countries = [
	{value: "ghana", label: "Ghana"},
	{value: "nigeria", label: "Nigeria"},
	{value: "kenya", label: "Kenya"},
	{value: "southAfrica", label: "South Africa"},
	{value: "unitedStates", label: "United States"},
	{value: "canada", label: "Canada"},
	{value: "germany", label: "Germany"}
]

const Salary = ({history}) => {
	const dispatch = useDispatch()
	const toast = useToast()
	const {getUsers, salary, auth} = useSelector(state => state)
	const {getStaffUser} = getUsers
	const {user} = auth
	const [loading, setLoading] = useState()
	const [hidden, setHidden] = useState(false)
	const [showSummary, setShowSummary] = useState(false)
	const [staffePickerItems, setStaffPickerItems] = React.useState(countries)
	const [selectedStaffItems, setSelectedStaffItems] = React.useState([])
	const [readMore, setReadMore] = useState({
		isTrue: false,
		id: ""
	})

	// =============================== state for date object of Date Range  =======================

	const [dateObject, setDateObject] = useState({
		startDate: null,
		endDate: null,
		focusedInput: null
	})

	// =============================== state for Date Range Calender Focus Input Prop  =======================

	const [focusedInput, setFocusIntput] = useState("")

	// ======================= commom Variable For Fetching starting date of Month ==================

	const startMonthDate = getStartOfMonth(new Date())

	// const [filters, setFilters] = useState({
	// 	sortby: "id",
	// 	direction: "asc",
	// 	staff: ""
	// })

	//  ======================== Common Function for Calling GetSalary API =======================

	const getSalary = () => {
		setLoading(true)
		dispatch(
			getSalaryList({
				start_date: dateObject.startDate
					? moment(dateObject.startDate).format("YYYY-MM-DD")
					: moment(startMonthDate).format("YYYY-MM-DD"),
				end_date: dateObject.endDate
					? moment(dateObject.endDate).format("YYYY-MM-DD")
					: moment(new Date()).format("YYYY-MM-DD")
				// staff: filters.staff,
				// services: filters.services
			})
		)
			.then(() => {
				setLoading(false)
			})
			.catch(err => {
				setLoading(false)
			})
	}

	// useEffect(() => {
	// 	if (user.user_type == 3) {
	// 		history.push("/")
	// 	}
	// })

	const result = salary.salary.map(({id, user_id, created_at, updated_at, ...rest}) => ({
		...rest
	}))

	const resultArray = result.map(function (elm) {
		return {
			Date: elm.date,
			Amount: parseInt(elm.amount),
			Total : parseInt(elm.amount) + parseInt(elm.online_amount) , 
			Online_Amount : parseInt(elm.online_amount),
			Comment: elm.comment,
			Staff: elm?.staff?.name || ""
		}
	});

	const amount = resultArray.reduce((acc, curr) => acc + curr.Amount, 0);
	const online_amount = resultArray.reduce((acc, curr) => acc + curr.Online_Amount, 0);
	const total = resultArray.reduce((acc, curr) => acc + curr.Total, 0);

	const lastArray = resultArray.splice(0, resultArray.length,{
		Amount: amount,
		Online_Amount : online_amount,
		Total: total
	});

	const sortOrder = {
		Date: 1,
		Total : 2,
		Amount: 3,
		Online_Amount:4,
		Staff: 5,
		Comment: 6
	}

	const expArray = [...lastArray, ...resultArray];

	// console.log("This is my exp arrray =================> "  ,expArray	)

	const sortedArray = expArray.map(o =>
		Object.assign(
			{},
			...Object.keys(o)
				.sort((a, b) => sortOrder[a] - sortOrder[b])
				.map(x => {
					return {[x]: o[x]}
				})
		)
	)

	useEffect(() => {
		setLoading(true)
		dispatch(getStaffUserList()).then(() => {
			setLoading(false)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		getSalary()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (dateObject.startDate && dateObject.endDate) {
			getSalary()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateObject])

	const arr4 =
		getStaffUser !== undefined &&
		getStaffUser.map(elm => {
			return {
				value: elm.name
			}
		})
	const arr5 =
		getStaffUser !== undefined &&
		getStaffUser.map(elm => {
			return {
				label: elm.name
			}
		})

	useEffect(() => {
		if (arr4) {
			let arr6 = arr4.map((item, i) => Object.assign({}, item, arr5[i]))
			setStaffPickerItems(arr6)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getStaffUser])

	const handleStaffCreateItem = item => {
		setStaffPickerItems(curr => [...curr, item])
		setSelectedStaffItems(curr => [...curr, item])
	}

	const handleStaffSelectedItemsChange = selectedItems => {
		if (selectedItems) {
			setSelectedStaffItems(selectedItems)
		}
	}
	const fileName = "myfile" // here enter filename for your excel file

	//  These Actions Coming from Expense Action This Actions Call Expense APi's in This Section thats why i commented these Actions
	// useEffect(() => {
	// 	setLoading(true)
	// 	dispatch(
	// 		getAllExpense({
	// 			month: filters.months,
	// 			year: filters.years
	// 		})
	// 	)
	// 		.then(() => {
	// 			setLoading(false)
	// 		})
	// 		.catch(err => {
	// 			setLoading(false)
	// 		})
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [filters, dispatch])

	const onDelete = id => {
		dispatch(deleteSalary({id}))
			.then(res => {
				toast({
					title: "Salary has been deleted!",
					status: "success",
					duration: 3000,
					isClosable: true
				})
				getSalary()
			})
			.catch(err => console.log("ERR => error while deleting expense"))
	}

	// const getHandle = e => {
	// 	setFilters(inputs => ({...inputs, [e.target.name]: e.target.value}))
	// }

	const sortFilter = (data, colName) => {
		setLoading(true)
		dispatch(
			getSalaryList({
				start_date: dateObject.startDate
					? moment(dateObject.startDate).format("YYYY-MM-DD")
					: moment(startMonthDate).format("YYYY-MM-DD"),
				end_date: dateObject.endDate
					? moment(dateObject.endDate).format("YYYY-MM-DD")
					: moment(new Date()).format("YYYY-MM-DD"),
				direction: data,
				sortby: colName
			})
		)
			.then(() => {
				setLoading(false)
			})
			.catch(err => {
				setLoading(false)
			})
	}

	useEffect(() => {
		const handleHiddenButton = () => {
			if (user.user_type === 1) {
				setHidden(false)
			} else {
				setHidden(true)
			}
		}
		handleHiddenButton()
	}, [salary, user])

	const summary = getSalarySummary(salary.salary)

	//  =============================== Read More Handler ====================================

	const readMoreHandler = id => {
		setReadMore(prev => ({
			...prev,
			id: id,
			isTrue: !prev.isTrue
		}))
	}

	let numberOfMonths = window.matchMedia("(max-width: 700px)").matches ? "1" : "2"
	let small = window.matchMedia("(max-width: 700px)").matches ? true : false
	let large = window.matchMedia("(max-width: 700px)").matches ? false : true

	// const vansh = salary?.salary?.filter((el)=>el?.staff?.id === 208);

	

	let arrayData = [];
	
		getStaffUser.map(staff => {
			const newObj  = {staff_id: staff?.id, staff_name: staff?.name, total: 0};
			arrayData.push(newObj)
			salary.salary.map(el => {
			if (el.staff){
				if (el?.staff?.id === staff?.id){
					if(newObj?.staff_id === el?.staff?.id){
					const findedIndex = arrayData.findIndex((el)=>el.staff_id === staff?.id);
					 arrayData[findedIndex].total += (parseInt(el.amount) + parseInt(el.online_amount))
					}
				}
			}
		})
	});

// ======================= Common function to get Present Staff users ========================

	// const getListUser = id => {
	// 	const filtredData = salary?.salary?.filter(el => el?.staff?.id === id)
	// 	return filtredData
	// }

	// // ======================== Common Function to get User Total Online and Cash ====================
	const getUserTotal = user => {
		const totalSalary = user?.reduce((acc, curr) => {
			return acc + (parseInt(curr?.amount) + parseInt(curr?.online_amount))
		}, 0)
		return totalSalary
	}

	// const Vansh = getListUser(206)
	// const Surbhi = getListUser(207)
	// const Riva = getListUser(212)
	// const Sameer = getListUser(264)
	// const Ravi = getListUser(208)

	const Other = salary?.salary?.filter(el => el?.staff === null);

	return (
		<PrivateLayout>
			<PageHeader
				pageHeading='Salary'
				rightContent={
					<>
						<div className=' sm:flex items-center'>
							<div className='sm:my-0 my-3'>
								<DateRangePicker
									hideKeyboardShortcutsPanel={true}
									startDateId='startDate'
									regular={large}
									small={small}
									numberOfMonths={numberOfMonths}
									isOutsideRange={() => false}
									displayFormat='DD MMM YYYY'
									endDateId='endDate'
									startDate={dateObject.startDate}
									startDatePlaceholderText={moment(startMonthDate).format("DD MMM YYYY")}
									endDatePlaceholderText={moment(new Date()).format("DD MMM YYYY")}
									endDate={dateObject.endDate}
									onDatesChange={({startDate, endDate}) =>
										setDateObject(prev => ({
											...prev,
											startDate: startDate,
											endDate: endDate
										}))
									}
									readOnly={true}
									inputIconPosition='after'
									focusedInput={focusedInput}
									customInputIcon={<i className='bx bxs-calendar' />}
									// orientation={orientation}
									// appendToBody={true}
									onFocusChange={focusedInput => {
										setFocusIntput(focusedInput)
									}}
								/>
							</div>

							{!hidden && (
								<div className='flex sm:ml-3 ml:0 '>
									<ExportToExcel
										user={user}
										apiData={sortedArray}
										fileName={fileName}
										hidden={hidden}
									/>
									<AddSalaryModal
										getStaffUser={getStaffUser}
										getSalary={getSalary}
										handleStaffCreateItem={handleStaffCreateItem}
										staffePickerItems={staffePickerItems}
										selectedStaffItems={selectedStaffItems}
										handleStaffSelectedItemsChange={handleStaffSelectedItemsChange}
									/>
								</div>
							)}
						</div>
					</>
				}
				title='Salary'
			/>

			<PageContainer>
				<div className='flex items-center pb-2 sm:pb-5'>
					<div className='lg:w-10/12 sm:w-9/12 w-7/12'>
						<hr className='border-indigo-700 border' />
					</div>
					<div className='lg:w-2/12 sm:w-3/12  w-5/12  flex items-center justify-center '>
						<span
							className='cursor-pointer flex items-center '
							onClick={() => setShowSummary(prev => !prev)}>
							<span className='text-blue-800 select-none text-base'>
								{!showSummary ? "Show Summary" : "Hide Summary"}
							</span>
							<i className={`bx bx-chevron-${!showSummary ? "down" : "up"} text-blue-800 text-2xl`} />
						</span>
					</div>
				</div>

				{showSummary && (
					<div className='mb-5'>
						<div className='grid lg:grid-cols-6 sm:grid-cols-3 grid-cols-2 gap-4'>
							{ arrayData && arrayData.map((el) => {
								return(
								<div className='relative w-full my-1' key={el.id}>
									<label>{el.staff_name} Total</label>
									<div className='flex items-center flex-wrap py-1 mr-2 bg-white  border rounded shadow-lg'>
										<div className='flex items-center'>
											<em className='bx px-2 bxs-check-circle  text-2xl text-blue-600'></em>
											<p className='text-sky-500 text-xl'>{el.total}</p>
										</div>
									</div>
								</div>)
							})}
							<div className='relative w-full my-1 '>
								<label>Other Total</label>
								<div className='flex items-center flex-wrap py-1  bg-white  border rounded shadow-lg'>
									<div className='flex items-center'>
										<em className='bx px-2 bxs-check-circle text-2xl text-sky-500'></em>
										<p className='text-sky-500 text-xl'>{getUserTotal(Other)}</p>
									</div>
								</div>
							</div>

							{/* <div className='relative w-full my-1  '>
								<label>Surbhi Total</label>
								<div className='flex items-center flex-wrap py-1 sm:mr-2 mr-0 bg-white  border rounded shadow-lg'>
									<div className='flex items-center'>
									<em className='bx px-2 bxs-check-circle  text-2xl text-blue-600'></em>
										<p className='text-sky-500 text-xl'>{getUserTotal(Surbhi)}</p>
									</div>
								</div>
							</div>
							
							<div className='relative w-full my-1  '>
								<label>Ravi Total</label>
								<div className='flex items-center flex-wrap py-1 mr-2 bg-white  border rounded shadow-lg'>
									<div className='flex items-center'>
									<em className='bx px-2 bxs-check-circle  text-2xl text-blue-600'></em>
										<p className='text-sky-500 text-xl'>{getUserTotal(Ravi)}</p>
									</div>
								</div>
							</div>
							
							<div className='relative w-full my-1  '>
								<label>Riva Total</label>
								<div className='flex items-center flex-wrap py-1 sm:mr-2 mr-0 bg-white  border rounded shadow-lg'>
									<div className='flex items-center'>
									<em className='bx px-2 bxs-check-circle  text-2xl text-blue-600'></em>
										<p className='text-sky-500 text-xl'>{getUserTotal(Riva)}</p>
									</div>
								</div>
							</div>
							<div className='relative w-full my-1  '>
								<label>Sameer Total</label>
								<div className='flex items-center flex-wrap py-1 mr-2 bg-white  border rounded shadow-lg'>
									<div className='flex items-center'>
									<em className='bx px-2 bxs-check-circle  text-2xl text-blue-600'></em>
										<p className='text-sky-500 text-xl'>{getUserTotal(Sameer)}</p>
									</div>
								</div>
							</div>
							 */}
						</div>
					</div>
				)}

				{loading ? (
					<div className='flex justify-center items-center mt-8'>
						<Loader className='items-center' style={{height: "calc(100vh - 16rem)"}} />
					</div>
				) : (
					<>
						<Box overflowX='auto' w='100%'>
							{salary.salary.length > 0 && !loading ? (
								<Table className='my-4 whitespace-nowrap' variant='simple'>
									<Thead>
										<Tr className={styles.trBackground}>
											<Th></Th>
											<Th className={styles.trColor}>Date</Th>
											<Th className={styles.trColor}>Total</Th>
											<Th className={styles.trColor}>
												<div className='flex items-center'>
													Staff
													<div className='ml-2'>
														<span
															onClick={() => sortFilter("asc", "staff")}
															className='cursor-pointer'>
															<IoIosArrowUp />
														</span>
														<span
															onClick={() => sortFilter("desc", "staff")}
															className='cursor-pointer'>
															<IoIosArrowDown />
														</span>
													</div>
												</div>
											</Th>
											<Th className={styles.trColor}>
												<div className='flex items-center'>
													Amount (cash)
													<div className='ml-2'>
														<span
															onClick={() => sortFilter("asc", "amount")}
															className='cursor-pointer'>
															<IoIosArrowUp />
														</span>
														<span
															onClick={() => sortFilter("desc", "amount")}
															className='cursor-pointer'>
															<IoIosArrowDown />
														</span>
													</div>
												</div>
											</Th>
											<Th className={styles.trColor}>
												Amount (online)
												{/* <div className='flex items-center'>
													Amount (online)
													<div className='ml-2'>
														<span
															onClick={() => sortFilter("asc", "amount_online")}
															className='cursor-pointer'>
															<IoIosArrowUp />
														</span>
														<span
															onClick={() => sortFilter("desc", "amount_online")}
															className='cursor-pointer'>
															<IoIosArrowDown />
														</span>
													</div>
												</div> */}
											</Th>
											<Th className={styles.trColor}>Comment</Th>
										</Tr>
									</Thead>
									<Tbody>
										{salary &&
											salary.salary.map((sal, index) => (
												<Tr key={index}>
													<Td>
														<span className='mt-2'>
															<ViewSalaryModal viewsalary={sal} />
														</span>
														{!hidden && (
															<>
																<span className='mt-2'>
																	<EditSalaryModal
																		editSalary={sal}
																		getSalary={getSalary}
																		user={user}
																		getStaffUser={getStaffUser}
																		handleStaffCreateItem={handleStaffCreateItem}
																		staffePickerItems={staffePickerItems}
																		selectedStaffItems={selectedStaffItems}
																		handleStaffSelectedItemsChange={
																			handleStaffSelectedItemsChange
																		}
																	/>
																</span>
																<DeleteConfirmModal
																	user={user}
																	onDelete={() => {
																		onDelete(sal.id)
																	}}
																	type='salary'
																/>
															</>
														)}
													</Td>
													<Td>{sal.date}</Td>
													<Td>
														{parseInt(sal.amount || 0) + parseInt(sal.online_amount || 0)}
													</Td>
													<Td>{sal?.staff?.name || ""}</Td>
													<Td>{sal?.amount}</Td>
													<Td>{sal?.online_amount}</Td>
													<Td className='flex items-center'>
														<span
															className={`cursor-pointer select-none ${
																readMore.id === sal.id && readMore.isTrue
																	? "hidden"
																	: "block"
															}`}
															onClick={() => readMoreHandler(sal.id)}>
															{sal?.comment?.slice(0, 15)}
														</span>
														<span
															className={` whitespace-pre-wrap cursor-pointer select-none ${
																readMore.id === sal.id && readMore.isTrue
																	? "block"
																	: "hidden"
															}`}
															onClick={() => readMoreHandler(sal.id)}>
															{sal?.comment}
														</span>
														<span
															className={`text-indigo-900 ml-1 select-none ${
																!sal.comment ||
																sal?.comment?.length < 15 ||
																(readMore.id === sal.id && readMore.isTrue)
																	? "hidden"
																	: "block"
															} cursor-pointer text-lg`}
															onClick={() => readMoreHandler(sal.id)}>
															...
														</span>
													</Td>
												</Tr>
											))}
									</Tbody>
									<Tfoot>
										<Tr className={styles.trBackground}>
											<Th className={styles.trColor}></Th>
											<Th className={styles.trColor}></Th>
											<Th className={styles.trColor}>
												{(summary.salaryCashTotal || 0) +
													parseInt(summary.salaryOnlineTotal || 0)}
											</Th>
											<Th className={styles.trColor}></Th>
											<Th className={styles.trColor}>{summary.salaryCashTotal + ".00"}</Th>
											<Th className={styles.trColor}>{summary.salaryOnlineTotal + ".00"}</Th>
											<Th className={styles.trColor}></Th>
										</Tr>
									</Tfoot>
								</Table>
							) : (
								<Box marginTop='16' textAlign='center'>
									No salary found!
								</Box>
							)}
						</Box>
					</>
				)}
			</PageContainer>
		</PrivateLayout>
	)
}

export default Salary
