import axios from "../../utils/axios";
import { DEFAULT_HEADERS } from "../../utils";

import {
  ADD_SERVICE,
  
} from "../constants/service";
import {
  ADD_SERVICE_ENDPOINT, UPDATE_SORTING,
} from "../../constants/api";

export const addService = (serviceData) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    // console.log( "This is Main Services Data ==========================> " , serviceData)
    // reject();
    // const data = JSON.parse(serviceData.data);
    // console.log( "This is my Data Parsed Data ==============> " , data);
    try {
      const {
        data
      } = await axios.post(ADD_SERVICE_ENDPOINT, serviceData, DEFAULT_HEADERS);
      dispatch({
        type: ADD_SERVICE,
        payload: {service:data},
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const updateSorting = (sortingData)=> (dispatch)=>{
  return new Promise(async (resolve , reject)=>{
    try {
     await axios.post(UPDATE_SORTING, sortingData);
     resolve()
    }
    catch(error){
      reject(error);
    }
  })
}

