import React, {useEffect, useState} from "react"
import {Table, Thead, Tbody, Tfoot, Tr, Th, Td, VStack, Image, Text, Stack, Spinner, Button} from "@chakra-ui/react"
import {chakra} from "@chakra-ui/system"
// import PageHeader from "../../PageHeader";
import PageContainer from "../../PageContainer"
import {useDispatch, useSelector} from "react-redux"
import {getSingleOrder} from "../../../store/actions/order"
import {getInvoiceUser} from "../../../store/actions/getUser"
import logo from "../../../assests/favicon.png"
import {updateOrder} from "../../../store/actions/order"
import moment from "moment"
import styles from "./index.module.css"
import SuccessModal from "./SuccessModal"

const ProcceedCheckout = ({history, uid, print}) => {
	const dispatch = useDispatch()
	const {order, getUsers} = useSelector(state => state)
	const {getUser} = getUsers
	// const {productsByCategoryId} = products
	const {singleOrder} = order
	const {data} = singleOrder
	const [loading, setLoading] = useState(true)
	const [parsedData, setParcedData] = useState(null)
	const [isModalOpen, setIsModalOpen] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			if (!loading && print === "true") {
				window.print()
			}
		}, 1000)
		// eslint-disable-line react-hooks/exhaustive-deps
	}, [loading, print])

	useEffect(() => {
		setLoading(true)
		dispatch(getInvoiceUser())
			.then(res => {
				setLoading(false)
			})
			.catch(err => setLoading(false))
		// eslint-disable-line react-hooks/exhaustive-deps
	}, [])


	useEffect(() => {
		setLoading(true)
		dispatch(
			getSingleOrder({
				uid: uid
			})
		)
			.then(res => {
				setLoading(false)
			})
			.catch(err => setLoading(false))
				// eslint-disable-line react-hooks/exhaustive-deps
	}, [ uid])

	// useEffect(() => {
	// 	//  dispatch(getOrderList({}))
	// 	// 	.then(res => {
	// 	// 		setLoading(false)
	// 	// 	})
	// 	// 	.catch(err => setLoading(false));
	// 		// eslint-disable-line react-hooks/exhaustive-deps
	// }, [dispatch])

	const id = singleOrder && singleOrder.cust_id
	// const services = singleOrder && singleOrder.services

	const customer = getUser.filter(x => x.id === id)
	// const discount = productsByCategoryId.filter(x => x.name === services)

	const obj = Object.assign({}, ...customer);

	// const disc = Object.assign({}, ...customer)

	// var mystring = data?.replace(/[,.]/g, "")

	//console.log(singleOrder)

	const singleDaTa = singleOrder?.data

	useEffect(() => {
		if (singleOrder) {
			setParcedData(JSON.parse(singleDaTa))
		}
	}, [singleOrder, singleDaTa])

	const dueAmount =
		parseInt(singleOrder?.total_amount) - (parseInt(singleOrder?.cash) + parseInt(singleOrder?.online))

	// console.log( "This is Process Env file APi key " , process.env.REACT_APP_RAZORPAY_KEY_ID)

	const onlinePaymentHandler = () => {
		var options = {
			key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
			amount: dueAmount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
			currency: "INR",
			name: "Curly's",
			description:
				"Best Unisex Salon in Ambala for Bridal MakeUp, Smoothening, Keratin, Hair Spa, Hair Color, Facial, Highlights, Manicure, Pedicure, Hair Care, Skin Care and other beauty parlour services",
			image: logo,
			// "order_id": singleOrder?.uid, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
			handler: function (response) {
				if (response) {
					const updatedOrderData = {
						online: parseInt(singleOrder.online) + parseInt(options.amount / 100),
						cash: parseInt(singleOrder?.cash),
						uid: singleOrder?.uid,
						paymentId: response.razorpay_payment_id
					}

					dispatch(updateOrder(updatedOrderData))
					dispatch(getSingleOrder({uid: uid}))
					setIsModalOpen(true)
				}
			},
			prefill: {
				name: singleOrder?.cust?.name,
				contact: singleOrder?.cust?.phone
			},
			notes: {
				address: "5437-1-2, Nicholson Road Opps. Nigar Cinema 133001 Ambala Cantt. India."
			},
			theme: {
				color: "#3399cc"
			}
		}
		var rzp = new window.Razorpay(options)
		rzp.open()
	}

	return (
		// <PrivateLayout>
		<PageContainer
			className={`max-w-3xl py-5 mb-3 mx-2 mt-3 lg:px-8 bg-white shadow-slate-1001 ${styles.pageContainer}`}>
			{loading ? (
				<Spinner
					size='xl'
					color='blue'
					style={{
						position: "absolute",
						top: 0,
						bottom: 0,
						left: 0,
						right: 0,
						margin: "auto"
					}}
				/>
			) : (
				<div>
					<SuccessModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
					<div className='border-b  flex justify-between pb-3'>
						<Stack spacing={-0.3}>
							<chakra.h1
								className={styles.noPrint}
								fontSize='sm'
								cursor={"pointer"}
								textDecoration={"underline"}
								fontWeight={600}
								onClick={() => history.goBack()}>
								Back to Order
							</chakra.h1>
							<chakra.h1 fontSize='lg' fontWeight={600}>
								Curlys Unisex Hair & Makeup Studio
							</chakra.h1>
							{/* <Text textAlign='left' fontSize='md' py={2}>
								Curlys Unisex Studio
							</Text> */}
							<Text textAlign='left' fontSize='md'>
								5437-1-2, Nicholson Road Opps. Nigar Cinema 133001 Ambala Cantt. India.
							</Text>
							<Text>(M): 7015036702</Text>
						</Stack>

						<VStack>
							<Image src='/img/main-logo.png' width='15rem' height='5rem' alt='logo' />
							<chakra.h1 fontSize='md' color='grey'>
								{singleOrder && moment(singleOrder.bill_date).format("D MMM YYYY hh:mm a")}
							</chakra.h1>
						</VStack>
					</div>
					<div className='mt-2 flex justify-between'>
						<chakra.h1 color='grey' fontSize='sm'>
							Invoice. No: {singleOrder && singleOrder.uid}
						</chakra.h1>
						<chakra.h1 fontSize='sm' color='grey'>
							Receptionist: {singleOrder && singleOrder?.user?.name}
						</chakra.h1>
					</div>
					<div className='mt-5 shadow-slate-1001'>
						<Table variant='simple' className={`whitespace-nowrap ${styles.tableBox}`}>
							<Thead className='bg-gold text-white'>
								<Tr>
									<Th className='text-white p-4'>Item Name</Th>
									<Th className='text-white p-4'>Qty</Th>
									<Th className='text-white p-4'>Amount</Th>
									<Th className='text-white p-4'>Discount</Th>
								</Tr>
							</Thead>
							{parsedData?.services?.map((v, i) => (
								<Tbody key={i}>
									<Tr>
										<Td className='p-4'>{v.services.name}</Td>
										<Td className='p-4'>{v.qty}</Td>
										<Td className='p-4'>{v.price}</Td>
										<Td className='p-4'>{v.discount}</Td>
									</Tr>
								</Tbody>
							))}
							{parsedData?.products?.map((v, i) => (
								<Tbody key={i}>
									<Tr>
										<Td className='p-4'>{v.name}</Td>
										<Td className='p-4'>1</Td>
										<Td className='p-4'>{v.price}</Td>
										<Td className='p-4'>{v.discount}</Td>
									</Tr>
								</Tbody>
							))}
							<Tfoot>
								<Tr>
									<Th className='p-4'></Th>
									<Th className='p-4'></Th>
									<Th className='p-4'>Net Total Rs {singleOrder && singleOrder.total_amount}</Th>
								</Tr>
							</Tfoot>
						</Table>

						{data ? (
							// data.map((v, i) => (
							//   <Table variant="simple" key={i}>
							//     <Thead className="bg-gray-100">
							//       <Tr>
							//         <Th>Item Name</Th>
							//         <Th>Amount</Th>
							//         <Th>Comment</Th>
							//       </Tr>
							//     </Thead>
							//     <Tbody>
							//       <Tr>
							//         <Td>{v.name}</Td>
							//         <Td>{v.price}</Td>
							//         <Td>{v.comment}</Td>
							//       </Tr>
							//     </Tbody>
							//   </Table>
							// ))
							""
						) : (
							<Spinner
								size='xl'
								color='blue'
								style={{
									position: "absolute",
									top: 0,
									bottom: 0,
									left: 0,
									right: 0,
									margin: "auto"
								}}
							/>
						)}
					</div>
					<div className={`md:flex mt-10 w-full ${styles.flexBox}`}>
						<div
							className={`bg-zinc-100 rounded-3xl p-5 md:mr-7 md:w-1/2 shadow-slate-1001 ${styles.boxPrint}`}>
							<chakra.h1 color='black' fontSize='2xl' fontWeight='500'>
								Purchase Summary
							</chakra.h1>
							<div className={`flex justify-between flex-wrap w-full mt-2 ${styles.payBox}`}>
								<div>
									<chakra.h1 fontSize='sm' color='grey'>
										Total Amount
									</chakra.h1>

									<chakra.h1 fontSize='lg' fontWeight='bold' color='black'>
										₹ {singleOrder && singleOrder.total_amount}
									</chakra.h1>
								</div>
								<div>
									<chakra.h1 fontSize='sm' color='grey'>
										Services / Products
									</chakra.h1>

									<chakra.h1 fontSize='lg' fontWeight='bold' color='black'>
										{parsedData?.services?.length} / {parsedData?.products?.length}
									</chakra.h1>
								</div>
							</div>
						</div>
						<div
							className={`bg-zinc-100 rounded-3xl p-5 md:w-1/2 mt-3 md:mt-0 shadow-slate-1001 ${styles.boxPrint}`}>
							<chakra.h1 color='black' fontSize='2xl' fontWeight='500' className='border-b'>
								Payment Details
							</chakra.h1>
							<div className='md:flex md:mb-3'>
								<div className={`flex justify-between flex-wrap w-full ${styles.payBox}`}>
									<div className='mt-2'>
										<chakra.h1 fontSize='sm' color='grey'>
											Cash
										</chakra.h1>
										<chakra.h1 fontSize='sm' color='grey'>
											Online &nbsp;
										</chakra.h1>
									</div>
									<div className='mt-2'>
										<chakra.h1 fontSize='sm' color='grey'>
											₹ {singleOrder && singleOrder.cash + ""}
										</chakra.h1>
										<chakra.h1 fontSize='sm' color='grey'>
											₹ {singleOrder && singleOrder.online + ""}
										</chakra.h1>
									</div>
								</div>
							</div>
							<div className='flex justify-between flex-wrap border-t pt-2 mt-2'>
								<chakra.h1 fontSize='lg' color='grey'>
									Total Amount Paid
								</chakra.h1>

								<chakra.h1 fontSize='lg' fontWeight='bold' color='black'>
									₹{" "}
									{parseInt(singleOrder && singleOrder.cash) +
										parseInt(singleOrder && singleOrder.online) +
										".00"}
								</chakra.h1>
							</div>
							{dueAmount === 0 || print === "true" ? (
								""
							) : (
								<div className={`flex justify-between flex-wrap border-t pt-2 mt-2`}>
									<chakra.h1 fontSize='lg' color='grey'>
										Due Ammount
									</chakra.h1>

									<chakra.h1 fontSize='lg' fontWeight='bold' color='black'>
										₹ {parseInt(singleOrder && dueAmount) + ".00"}
									</chakra.h1>
								</div>
							)}
						</div>
					</div>

					{/* ========================== Payment Handler ===================== */}

					{/* <div className='my-8 '>
						{print === "true" || dueAmount === 0 ? (
							""
						) : (
							<div className=' flex justify-end'>
								<Button className={` ml-auto`} colorScheme='blue' onClick={onlinePaymentHandler}>
									{" "}
									Pay Online{" "}
								</Button>
							</div>
						)}
					</div> */}

					<div className=' md:flex w-full 2'>
						<div className='bg-gray-100 w-full md:p-5 px-5 '>
							<chakra.h1 color='black' fontSize='2xl' fontWeight='500' className=''>
								Customer Details
							</chakra.h1>
							<chakra.h1 fontSize='md' color='grey'>
								Name : {obj.name}
							</chakra.h1>
							<chakra.h1 fontSize='md' color='grey'>
								Mobile: {obj.phone}
							</chakra.h1>
						</div>
						{/* <div className='md:p-5 px-5 py-0 md:w-4/12 bg-black-2xl'>
							<chakra.h1 fontSize='md' color='white'>
								Earned Reward Points : 800
							</chakra.h1>
							<chakra.h1 fontSize='md' color='white'>
								Total Reward Points : 800
							</chakra.h1>
						</div> */}
					</div>
					{/* <div className='bg-green-100 rounded-3xl mt-5 p-5'>
						<chakra.h1 fontSize='md' color='grey'>
							*Net Amount is inclusive of all taxes
						</chakra.h1>
						<chakra.h1 fontSize='md' color='grey'>
							A-15 Astha complex vadtal road Anand
						</chakra.h1>
						<chakra.h1 fontSize='md' color='grey'>
							Get Cashback rewards on your service
						</chakra.h1>
						<chakra.h1 fontSize='md' color='grey'>
							+ Get Exclusive Offers & Deals,
						</chakra.h1>
						<chakra.h1 fontSize='md' color='grey'>
							Give A Call at 12345678 Now
						</chakra.h1>
					</div> */}
					<div className='mt-5 text-center'>
						{/* <chakra.h1 fontSize='md' color='black'>
							Tax Invoice No: 286
						</chakra.h1> */}
						<chakra.h1 fontSize='md' color='black' className='mt-3'>
							Your mobile number {obj.phone} has been registered with Curlys Unisex Hair & Makeup Studio
							Terms.
						</chakra.h1>
						<chakra.h1 fontSize='sm' color='grey'>
							Thank you for accepting Digital receipt!
						</chakra.h1>
						<chakra.h1 fontSize='sm' color='grey' className=''>
							It helps save Trees and Environment.
						</chakra.h1>
					</div>
				</div>
			)}
		</PageContainer>
		// </PrivateLayout>
	)
}

export default ProcceedCheckout
