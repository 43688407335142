import axios from "../../utils/axios";
import {
  USER_SIGN_UP,
  USER_SIGN_IN,
  USER_SIGN_OUT,
  USER_UPDATE_PROFILE,
  USER_SIGN_IN_FAILED,
  ADD_CUSTOMER,
  CHECK_USER,
  REMOVE_CUSTOMER
} from "../constants/auth";

const user = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;
const token = localStorage.getItem("token")
  ? JSON.parse(localStorage.getItem("token"))
  : null;

const initialState = {
  user,
  token,
  addedCustomer: "",
  authenticated : false , 
};

// set the auth header
axios.defaults.headers.Authorization = "Bearer " + token;
export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_SIGN_UP:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        authenticated : true
      };
    case USER_SIGN_IN:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        authenticated:true
      };
    case CHECK_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case USER_SIGN_IN_FAILED:
      return {
        ...state,
        user: null,
        token: null,
      };
    case USER_UPDATE_PROFILE:
      return { ...state, user: action.payload.user };
    case USER_SIGN_OUT:
      return { ...state, user: null, token: null };
    case ADD_CUSTOMER:
      return {
        ...state,
        addedCustomer: action.payload.addedCustomer,
      };
      case REMOVE_CUSTOMER : 
      return {
        ...state,
        addedCustomer : ""
      }
    default:
      return state;
  }
};
