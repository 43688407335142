import React, {useState} from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Stack,
	Input,
	FormLabel,
	FormControl,
	FormErrorMessage,
	useToast,
	Radio,
	RadioGroup,
	Textarea
} from "@chakra-ui/react"
import * as Yup from "yup"
import {useForm} from "react-hook-form"
import {useSelector, useDispatch} from "react-redux"
import {useYupValidationResolver} from "../../../hooks/useValidationResolver"
import {IoIosAdd} from "react-icons/io"
import {addCustomer} from "../../../store/actions/auth"
import {getInvoiceUser} from "../../../store/actions/getUser"

const validationSchema = Yup.object({
	phone: Yup.number()
		.typeError("Phone must be a number")
		.required("Phone number is required!")
		.min(999999999, "Please Enter Valid Phone Number")
		.max(9999999999, "Please Enter Valid Phone Number"),
	name: Yup.string().required("Name is required!"),
	// gender: Yup.string().required("Kindly select gender!"),
	// password: Yup.string().required("Password is required!")
	// email: Yup.string().required("Email is required!"),
	// dob: Yup.string().required("DOB is required!"),
	// comment: Yup.string().required("Comment is required!"),
	// address: Yup.string().required("Address is required!"),
})

const AddCustomerModal = ({selectedItems, setSelectedItems, setTagName}) => {
	const dispatch = useDispatch()

	const {addedCustomer} = useSelector(state => state.auth)
	const [loading, setLoading] = useState(false)
	const toast = useToast()
	const {isOpen, onOpen, onClose} = useDisclosure()
	// const [selectedCategoryId, setselectedCategoryId] = useState("");
	// const resolver = useYupValidationResolver(validationSchema);
	// const {
	//   register,
	//   handleSubmit,
	//   formState: { errors, touchedFields },
	//   reset,
	// } = useForm({
	//   resolver,
	//   mode: "onBlur",
	// });

	const resolver = useYupValidationResolver(validationSchema)
	// const addedCustomer = JSON.parse(localStorage.getItem("addedCustomer"));

	const {
		register,
		handleSubmit,
		formState: {errors, touchedFields},
		reset
	} = useForm({
		resolver,
		mode: "onBlur"
	})

	const onError = (errors, e) => console.log(errors, e)

	const onRegister = data => {
		const obj = {
			phone: data.phone,
				name: data.name,
				email: data.email,
				dob: data.dob || '0000-00-00',
				comment: data.comment,
				gender: (data?.gender ==="male" && "1")  || (data?.gender === "female" && "2") ,
				address: data.address,
				password: Math.floor(Math.random() * 899999 + 100000),
				anniversary : data.anniversary || '0000-00-00',
				device_token: "555",
				signup_type: 1,
		}

		console.log("This is Obj =====================> " , obj)

		
		setLoading(true)
		dispatch(
			addCustomer({
				phone: data.phone,
				name: data.name,
				email: data.email,
				dob: data.dob || '0000-00-00',
				comment: data.comment,
				gender: (data?.gender ==="male" && "1")  || (data?.gender === "female" && "2") || "",
				address: data.address,
				password: Math.floor(Math.random() * 899999 + 100000),
				anniversary : data.anniversary || '0000-00-00',
				device_token: "555",
				signup_type: 1,
			})
		)
			.then(() => {
				dispatch(getInvoiceUser())
				setSelectedItems(addedCustomer)
				setTagName(addedCustomer.name)
				setLoading(false)
				// reset the form
				reset()
				onClose()
				toast({
					title: "Customer added",
					status: "success",
					duration: 3000,
					isClosable: true
				})
			})
			.catch(err => {
				toast({
					title: err,
					status: "error",
					duration: 3000,
					isClosable: true
				})
				setLoading(false)
			})
	}



	return (
		<>
			<>
				<Button
					leftIcon={<IoIosAdd />}
					onClick={onOpen}
					width='full'
					disabled={selectedItems === "" || selectedItems === null ? false : true}>
					Add Customer
				</Button>
				<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='2xl'>
					<ModalOverlay />
					<form
						style={{overflow: "auto"}}
						noValidate
						onSubmit={handleSubmit(onRegister, onError)}
						className='bg-white'>
						<ModalContent>
							<ModalHeader>Add Customer</ModalHeader>
							<ModalCloseButton />

							<ModalBody pb={6}>
								<Stack spacing={4}>
									<div className='md:flex'>
										<FormControl
											mr={3}
											isInvalid={touchedFields?.phone && errors?.phone}
											id='phone'>
											<FormLabel>Mobile Number</FormLabel>
											<Input placeholder='Enter Number' type='number' {...register("phone")} />
											<FormErrorMessage>{errors?.phone?.message}</FormErrorMessage>
										</FormControl>
										{/* <FormControl
											mr={3}
											isInvalid={touchedFields?.password && errors?.password}
											id='password'>
											<FormLabel>Password</FormLabel>
											<Input
												placeholder='Enter Number'
												type='password'
												{...register("password")}
											/>
											<FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
										</FormControl> */}
										<FormControl
											mr={3}
											className='mt-5 md:mt-0'
											isInvalid={touchedFields?.name && errors?.name}
											id='name'>
											<FormLabel>Customer Name</FormLabel>
											<Input placeholder='Enter Name' type='text' {...register("name")} />
											<FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
										</FormControl>
										<FormControl
											className='mt-5 md:mt-0'
											isInvalid={touchedFields?.email && errors?.email}
											id='email'>
											<FormLabel>Email Id</FormLabel>
											<Input placeholder='Enter Email' type='email' {...register("email")} />
											<FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
										</FormControl>
									</div>
									<div className='md:flex'>
										<FormControl mr={5} isInvalid={touchedFields?.dob && errors?.dob} id='dob'>
											<FormLabel>DOB</FormLabel>
											<Input {...register("dob")} type='date' placeholder='Enter DOB' />
											<FormErrorMessage>{errors?.dob?.message}</FormErrorMessage>
										</FormControl>
										<FormControl mr={5} isInvalid={touchedFields?.Anniversary && errors?.Anniversary} id='dob'>
											<FormLabel>Anniversary</FormLabel>
											<Input {...register("anniversary")} type='date' placeholder='Enter Anniversary'/>
											<FormErrorMessage>{errors?.Anniversary?.message}</FormErrorMessage>
										</FormControl>
										<FormControl
											className='mt-5 md:mt-0'
											isInvalid={touchedFields?.gender && errors?.gender}
											id='gender'>
											<FormLabel>Gender</FormLabel>
											<RadioGroup>
												<Stack direction='row'>
													<Radio value='male' id='gender' {...register("gender")}>
														Male
													</Radio>
													<Radio value='female' id='gender' {...register("gender")}>
														Female
													</Radio>
												</Stack>
											</RadioGroup>
											<FormErrorMessage>{errors?.gender?.message}</FormErrorMessage>
										</FormControl>
									</div>
									<FormControl isInvalid={touchedFields?.comment && errors?.comment} id='comment'>
										<FormLabel>Comment</FormLabel>
										<Textarea size='sm' {...register("comment")} placeholder='Add comment' />
										{errors?.comment?.message}
									</FormControl>
									<FormControl isInvalid={touchedFields?.address && errors?.address} id='address'>
										<FormLabel>Address</FormLabel>
										<Textarea size='sm' {...register("address")} placeholder='Enter your address' />
										<FormErrorMessage>{errors?.address?.message}</FormErrorMessage>
									</FormControl>
								</Stack>
							</ModalBody>

							<ModalFooter>
								<Button className='mr-4' onClick={onClose}>
									Close
								</Button>
								<Button isLoading={loading} colorScheme='green' className='bg-gold' type='submit'>
									Submit
								</Button>
							</ModalFooter>
						</ModalContent>
					</form>
				</Modal>
			</>
		</>
	)
}

export default AddCustomerModal
