import axios from "../../utils/axios"
import {MULTIPART_FORM_DATA_HEADERS} from "../../utils"
import {ADD_OFFER_ENDPOINT, GET_OFFERS, UPDATE_OFFER, DELETE_OFFER, GET_OFFER_FRONT} from "../../constants/api"
import {GET_OFFER_FOR_ADMIN_PANEL, GET_OFFER_FOR_FRONT_PANEL} from "../constants/offers"

// ======= Add Offer =====

export const addOffer = offerObj => {
	return async () => {
		const offerData = new FormData()
		offerData.append("name", offerObj.name)
		offerData.append("image", offerObj.image)
		offerData.append("sort", offerObj.sort)
		offerData.append("expiry", offerObj.expiry)
		offerData.append("status", offerObj.status)

		await axios.post(ADD_OFFER_ENDPOINT, offerData, MULTIPART_FORM_DATA_HEADERS)
	}
}

//  ======== Update Offer  ======

export const updateOffer = UpdatedData => {
	return async () => {
		const EditedOfferData = new FormData()
		EditedOfferData.append("id", UpdatedData.id)
		EditedOfferData.append("name", UpdatedData.name)
		EditedOfferData.append("sort", UpdatedData.sort)
		EditedOfferData.append("expiry", UpdatedData.expiry)
		EditedOfferData.append("status", UpdatedData.status)

		await axios.post(UPDATE_OFFER, EditedOfferData, MULTIPART_FORM_DATA_HEADERS)
	}
}

// Delete Offer

export const deleteOffer = id => {
	return async () => {
		await axios.get(`${DELETE_OFFER}?id=${id}`)
	}
}

//  ======== Get Offers List AdminPanel  ======

export const getOfferList = () => {
	return async dispatch => {
		const {data} = await axios.get(GET_OFFERS)
		dispatch({
			type: GET_OFFER_FOR_ADMIN_PANEL,
			payload: data
		})
	}
}

// ======== Get Offers for FrontPanel ======

export const getOffers = () => {
	return async dispatch => {
		const {data} = await axios.get(GET_OFFER_FRONT)
		dispatch({
			type: GET_OFFER_FOR_FRONT_PANEL,
			payload: data
		})
	}
}



// important code
// export const getOffers = () => {
// 	return async dispatch => {
// 		const {data} = await axios.get(GET_OFFER_FRONT)
// 		console.log(data,'data')
// 		if(data){
// 			console.log("in if block")
// 			dispatch({
// 				type: GET_OFFER_FOR_FRONT_PANEL,
// 				refreshing:true
// 			})
// 		}
// 		else{
// 			console.log("in else")
// 			dispatch({
// 				type: GET_OFFER_FOR_FRONT_PANEL,
// 				payload: data,
// 				refreshing : false
// 			})
// 		}
		
// 	}
// }
