import React from "react"
import { Spinner } from "@chakra-ui/react"

const Loader = ({className , style}) => {
	return (
		<div className={`flex justify-center  ${className}`} style={style}>
			<Spinner size='lg' />
		</div>
	)
}

export default Loader
