import React from 'react';
import { useHistory } from 'react-router-dom';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure
} from "@chakra-ui/react"



const AppointmentSuccessModal = ({ismodalOpen, setIsModalOpen , user, offerName}) => {
    const {onClose} = useDisclosure()
 const history = useHistory()
  return(
<Modal isOpen={ismodalOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
										<ModalOverlay className='modalOverlay bg-gold-701' />
										<ModalContent>
											<ModalHeader className='capitalize text-center bg-gold text-white'>
												{" "}
												Your Appointment Details{" "}
											</ModalHeader>
											<ModalCloseButton
												className='text-white'
												onClick={() => setIsModalOpen(false)}/>
											<ModalBody>
												<div className='flex justify-center mt-3'>
													<img src='/img/thank-you.png' alt='' width='100' />
												</div>
												<h1 className='text-center font-bold text-xl'>
													Your Appointment has been booked
												</h1>
												<div className='wrapper  p-2'>
													<div className=' p-3.5 rounded-xl mb-3'>
														<div className='body'>
															<div className='flex flex-wrap justify-between py-1'>
																<h6 className='font-medium text-gray-400'>Name</h6>
																<p className='font-medium text-gray-700'>
																	{user?.name}
																</p>
															</div>
															<div className='flex flex-wrap justify-between py-1'>
																<h6 className='font-medium text-gray-400'>Offer Name</h6>
																<p className='font-medium text-gray-700'>
																	{offerName}
																</p>
															</div>
															<div className='flex flex-wrap justify-between py-1 mt-2'>
																<button
																	className='font-bold rounded-lg bg-gray-300 py-2 px-4'
																	onClick={ ()=> {
                                                                        history.push("/");
                                                                        setIsModalOpen(false)
                                                                    }}>
																	Go To Home
																</button>
																<button
																	className='font-bold text-white rounded-lg bg-gold py-2 px-4'
																	onClick={()=>{
                                                                        history.push("/my-appointments")
                                                                        setIsModalOpen(false)
                                                                    }}>
																	View Appointments
																</button>
															</div>
														</div>
													</div>
												</div>
											</ModalBody>
											<ModalFooter></ModalFooter>
										</ModalContent>
									</Modal>
  );
};

export default AppointmentSuccessModal;
