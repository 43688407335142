import React, {useEffect} from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Stack,
	Input,
	FormLabel,
	FormControl,
	FormErrorMessage,
	useToast
} from "@chakra-ui/react"
import * as Yup from "yup"
import {useForm} from "react-hook-form"
import {useYupValidationResolver} from "../../../hooks/useValidationResolver"
import {IoIosAdd} from "react-icons/io"
const validationSchema = Yup.object({
	name: Yup.string().required("Name is required!"),
	price: Yup.number()
		.typeError("Price must be a number")
		.required("Price is required.")
		.min(0, "Too little")
		.max(5000, "Very costly!")
})
const i = JSON.parse(localStorage.getItem("product"))

const AddServiceModal = ({productDetail, setProductDetail}) => {
	const toast = useToast()
	const {isOpen, onOpen, onClose} = useDisclosure()
	const resolver = useYupValidationResolver(validationSchema)

	const {
		register,
		handleSubmit,
		formState: {errors, touchedFields},
		reset
	} = useForm({
		resolver,
		mode: "onBlur"
	})
	useEffect(() => {
		if (i) {
			localStorage.removeItem("product")
		}
	}, [])

	const onAddProduct = data => {
		Object.assign(data)
		toast({
			title: "Product has been added!",
			status: "success",
			duration: 3000,
			isClosable: true
		})
		setProductDetail([...productDetail, data])
		reset()
		onClose()
	}

	const onError = (errors, e) => console.log(errors, e)

	return (
		<>
			<>
				<Button
					colorScheme='green'
					leftIcon={<IoIosAdd />}
					// hidden={productDetail.length > 0 ? true : false}
					onClick={onOpen}>
					Add Product
				</Button>

				<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
					<ModalOverlay />
					<form
						style={{overflow: "auto"}}
						noValidate
						onSubmit={handleSubmit(
							onAddProduct,
							// productDetail.length > 0 ? onUpdateProduct : onAddProduct,
							onError
						)}
						className='bg-white'>
						<ModalContent>
							<ModalHeader>Add Product</ModalHeader>
							<ModalCloseButton />

							<ModalBody pb={6}>
								<Stack spacing={4}>
									<FormControl isInvalid={touchedFields?.name && errors?.name} id='name'>
										<FormLabel>Name</FormLabel>
										<Input {...register("name")} placeholder='Enter Name' type='text' />
										<FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
									</FormControl>
									<FormControl
										isInvalid={touchedFields?.price && errors?.price}
										id='price'
										className='mb-3'>
										<FormLabel>Price</FormLabel>
										<Input
											{...register("price")}
											placeholder='Enter Price'
											onWheel={event => event.currentTarget.blur()}
											type='number'
										/>
										<FormErrorMessage>{errors?.price?.message}</FormErrorMessage>
									</FormControl>

									<FormControl
										isInvalid={touchedFields?.comment && errors?.comment}
										id='comment'
										className='mb-3'>
										<FormLabel>Comment</FormLabel>
										<Input
											style={{width: "100%"}}
											{...register("comment")}
											placeholder='Enter comment'
											type='text'
											// value={commentValue}
											// onChange={setCommentValue}
											// placeholder=" Enter Comment"
											// type="text"
										/>
										<FormErrorMessage>{errors?.comment?.message}</FormErrorMessage>
									</FormControl>
								</Stack>
							</ModalBody>

							<ModalFooter>
								<Button className='mr-4' onClick={onClose}>
									Close
								</Button>
								<Button colorScheme='blue' className='bg-gold' type='submit'>
									Add
								</Button>
							</ModalFooter>
						</ModalContent>
					</form>
				</Modal>
			</>
		</>
	)
}

export default AddServiceModal
