import React, {useState, useEffect} from "react"
import {
	Table,
	Thead,
	Tbody,	
	Select,
	Tr,
	Th,
	Td,
	IconButton,
	Box,
	Stack,
	Button
} from "@chakra-ui/react"
import {useSelector, useDispatch} from "react-redux"
import PrivateLayout from "../../../layout/PrivateLayout"
import PageHeader from "../../../components/PageHeader"
import PageContainer from "../../../components/PageContainer"
import {getYears, getMonths} from "../../../utils/list-expense"
import styles from "./index.module.css"
import {GetAppointmentListing} from "../../../store/actions/getAppointment"
import {IoIosEye} from "react-icons/io"
import ViewServiceModal from "./ViewServiceModal"
import ReactPaginate from "react-paginate"
import RejectServiceView from "./RejectServiceModal"
import ConfirmApprovelModal from "./ConfirmApprovalModal"
import Loader from "../../../components/Loader/Loader"
const BookingList = ({history}) => {
	const dispatch = useDispatch()
	const months = getMonths()
	const data = useSelector(state => state.getAppointmentListing.data)
	const {user} = useSelector(state => state.auth)
	const [loading, setLoading] = useState()
	const [filters, setFilters] = useState({
		page: 1,
		month: new Date().getMonth() + 1,
		year: new Date().getFullYear()
	})


	const listPerPage = 10

	const SelectChangeHandler = e => {
		setFilters(prev => ({
			...prev,
			[e.target.name]: e.target.value
		}))
	}

	const listPageCount = Math.ceil(data && data?.total / listPerPage);

	const changePage = ({selected}) => {
		setFilters((prev)=>({
			...prev,
            page : selected + 1 
		}))
	}

	useEffect(() => {
		if (user?.user_type === 3 || user?.user_type === 2) {
			history.push("/")
		}
		setLoading(true)
		dispatch(GetAppointmentListing(filters)).then(()=>{
			setLoading(false)
		})

		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters])

	const updateListHandler = () => {
		dispatch(GetAppointmentListing(filters))
	}


	return (
		<PrivateLayout>
			<PageHeader pageHeading='Appointment List' title='Appointment List' />
			<PageContainer>
				<div className="sm:flex w-full sm:justify-end sm:w-1/2 sm:ml-auto">
					<div className="flex items-center">
					<Select name='year' value={filters.year} onChange={SelectChangeHandler}>
						{getYears().map((element, index) => {
							return (
								<option key={index} value={element}>
									{" "}
									{element}
								</option>
							)
						})}
					</Select>
					<Select name='month' value={filters.month} onChange={SelectChangeHandler} className='mx-4'>
						{months.map(element => {
							return (
								<option key={element.id} value={element.name}>
									{element.name}
								</option>
							)
						})}
					</Select>
					</div>
				</div>
					<>
					{loading && <Loader className="items-center  " style={{height : "calc(100vh - 18rem)"}} / >}
						{ (!loading && data?.data?.length > 0)  ? <Box overflowX='auto' w='100%' className='mb-10'>
								<Table className={`my-4 whitespace-nowrap ${styles.tableBox}`} variant='simple'>
									<Thead>
										<Tr className={styles.trBackground}>
											<Th className={` ${styles.trColor}  text-center`}>Actions</Th>
											<Th className={` ${styles.trColor}  text-center`}>Status</Th>
											<Th className={` ${styles.trColor}  text-center`}>Name</Th>
											<Th className={` ${styles.trColor}  text-center`}>Phone No.</Th>
											<Th className={` ${styles.trColor}  text-center`}>Services</Th>
											<Th className={` ${styles.trColor}  text-center`}>Date</Th>
											<Th className={` ${styles.trColor}  text-center`}>E-mail</Th>
											<Th className={` ${styles.trColor}  text-center`}>Comments</Th>
										</Tr>
									</Thead>
									{data?.data?.length > 0 && !loading && <Tbody>
										{data?.data.map((booking, index) => {
												let ParsedServices
												let StringService
												try {
													let servicesData = JSON.parse(booking.services)
													ParsedServices = JSON.parse(servicesData.service)
												} catch {
													StringService = booking.services
												}
                                             
												return (
													<Tr key={index}>
														<Td>
															<RejectServiceView
																updatelist={updateListHandler}
																userId={booking.id}
																status={booking.status}
															/>
															<span>
																<ViewServiceModal ServiceView={booking}>
																	<IconButton
																		className='mr-3'
																		colorScheme='teal'
																		aria-label='Edit Expense'
																		icon={<IoIosEye />}
																		size='sm'
																	/>
																</ViewServiceModal>
															</span>
															<ConfirmApprovelModal
																updatelist={updateListHandler}
																userId={booking.id}
																status={booking.status}
															/>
														</Td>
														<Td className={`${booking.status === 0 ? "block" : "hidden"}`}>
															<p
																className={`font-bold cursor-pointer bg-gold-400 py-1 px-3 rounded text-white`}>
																<span>In Review</span>
															</p>
														</Td>
														<Td className={`${booking.status === 1 ? "block" : "hidden"}`}>
															<p
																className={`font-bold cursor-pointer bg-green-900 py-1 px-3 rounded text-white`}>
																<span>Approved</span>
															</p>
														</Td>
														<Td className={`${booking.status === 2 ? "block" : "hidden"}`}>
															<p className='cursor-pointer bg-red-900 py-1 px-3 rounded text-white'>
																<span>Rejected</span>
															</p>
														</Td>
														<Td>{booking.name ? booking.name : "Not Available"}</Td>
														<Td>{booking.phone}</Td>
														<Td >
															<p
																className='overflow-hidden whitespace-nowrap w-40 cursor-pointer'
																style={{textOverflow: "ellipsis"}}>
																<ViewServiceModal ServiceView={booking}>
																	{!StringService
																		? ParsedServices?.map(
																				element => element.name + ","
																		  )
																		: StringService}
																</ViewServiceModal>
															</p>
															{!ParsedServices && !StringService && "Not Available"}
														</Td>
														<Td>{!booking.date || booking.date==="0000-00-00 00:00:00"  ?  "Not Available" : booking.date}</Td>
														<Td>{booking.email ? booking.email : "Not Available"}</Td>
														<Td>{!booking?.comment || booking.comment==="undefined" ?   "Not Available" : booking?.comment}</Td>
													</Tr>
												)
											})}
									</Tbody>}
								</Table>
						</Box> : <h1 className="text-center mt-12">No Appointments found!</h1> }
						{ !loading && listPageCount > 1 ? (
							<ReactPaginate
							breakLabel='...'
							nextLabel='Next >'
							onPageChange={changePage}
							pageRangeDisplayed={3}
							pageCount={listPageCount}
							forcePage={filters.page - 1}
							marginPagesDisplayed={2}
							previousLabel='< Previous'
							breakClassName="text-indigo-900"
							previousLinkClassName="bg-green-900 rounded select-none text-white px-3 py-1"
							nextLinkClassName="bg-green-900 rounded select-none text-white px-3 py-1"
							breakLinkClassName="flex itmes-center"
							renderOnZeroPageCount={null}
							activeLinkClassName=' bg-black-2xl rounded text-white py-1 px-3'
							className='flex mb-2 justify-between lg:w-5/12 w-10/12 rounded-md  mx-auto px-4 py-2 '
							nextClassName='text-black'
							previousClassName='text-black'
							/>
						) : (
							""
						)}
					</>
			</PageContainer>
		</PrivateLayout>
	)
}

export default BookingList
