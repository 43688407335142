import React, {useEffect, useState} from "react"
import {
	Input,
	FormLabel,
	FormControl,
	Stack,
	IconButton,
	Box,
	useDisclosure,
	useToast,
	Text,
	Select,
	Tag,
	TagLabel,
	TagCloseButton
} from "@chakra-ui/react"
import {Button} from "@chakra-ui/react"
import {Table, Thead, Tbody, Tfoot, Tr, Th, Td} from "@chakra-ui/react"
import {useSelector, useDispatch} from "react-redux"
import {useForm} from "react-hook-form"
import {IoIosAdd, IoIosTrash} from "react-icons/io"
import PrivateLayout from "../../layout/PrivateLayout"
import PageHeader from "../../components/PageHeader"
import PageContainer from "../../components/PageContainer"
import AddCustomerModal from "../../components/Modals/AddCustomerModal"
import AddProductModal from "../../components/Modals/AddProductModal"
import {getInvoiceUser,getStaffUserList} from "../../store/actions/getUser"
import * as Yup from "yup"
import AddService from "./AddService"
import {useYupValidationResolver} from "../../hooks/useValidationResolver"
import {addCategories, getProductsByCategoryId} from "../../store/actions/categorieslist"

import {createOrders} from "../../store/actions/order"
import EditProductModal from "../../components/Modals/EditProductModal"

const validationSchema = Yup.object({
	// date: Yup.string().required("Date is required!"),
	services: Yup.string().typeError("Service can't be empty")
	// staff: Yup.string().typeError("Kindly select staff"),
	// price: Yup.number().typeError("Price can't be empty"),
	// qty: Yup.number().typeError("Qty can't be empty"),
	// discount: Yup.number().typeError("Discount can't be empty"),
	// total: Yup.number().typeError("Total can't be empty"),
})

const countries = [
	{value: "ghana", label: "Ghana"},
	{value: "nigeria", label: "Nigeria"},
	{value: "kenya", label: "Kenya"},
	{value: "southAfrica", label: "South Africa"},
	{value: "unitedStates", label: "United States"},
	{value: "canada", label: "Canada"},
	{value: "germany", label: "Germany"}
]
// const addedCustomer = JSON.parse(localStorage.getItem("addedCustomer"))

const QucikInvoice = ({history}) => {
	const {productsByCategoryId} = useSelector(state => state.products)
	const {getUser,getStaffUser} = useSelector(state => state.getUsers)
	const currentUser = useSelector((state) => state.auth.user);
	const {addedCustomer} = useSelector(state => state.auth)
	// const {createOrder} = useSelector(state => state.order)
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const [searchUser, setSearchUser] = useState([])
	const [searchService, setSearchService] = useState([]);
	const {isOpen, onOpen, onClose} = useDisclosure()
	const [servicesDatas, setServicesDatas] = useState([])
	const [yourPrice , setYourPrice] = useState (0)
	const [yourDiscount , setYourDiscount ] = useState(0)
	const [bill_date, setBillDate] = useState("")
	const [hidden, setHidden] = useState(false)
	const [productDetail, setProductDetail] = useState([])
	const toast = useToast()

	const resolver = useYupValidationResolver(validationSchema)

	// const user = getUser
	const staffUser = getStaffUser
	const [selectedItems, setSelectedItems] = useState("")
	const [selectedService, setSelectedService] = useState("")
	// const [pickerItems, setPickerItems] = useState([])
	// const [pickerServicesItems, setPickerServicesItems] = useState(countries)
	const [staffePickerItems, setStaffPickerItems] = React.useState(countries)
	const [selectedStaffItems, setSelectedStaffItems] = React.useState([])
	const [searchValue, setSearchValue] = React.useState()
	const [serviceValue, setServiceValue] = React.useState()
	const [qtyValue, setQtyValue] = useState(1)
	const [total, setTotal] = useState(0)
	const [discount, setDiscount] = useState(0)
	const [cashPayment, setCashPayment] = useState(0)
	const [onlinePayment, setOnlinePayment] = useState(0)
	const [adjustPayment2, setAdjustPayment2] = useState(0)
	const [paymentMethod, setPaymentMethod] = useState("")
	const [discountValue, setDiscountValue] = useState(parseInt(selectedService?.discount))
	const [tagName, setTagName] = useState("")


	useEffect(() => {
		if (addedCustomer !== "") {
			setTagName(addedCustomer.name)
			setSelectedItems(addedCustomer)
		}
			 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getUser])

	useEffect(() => {
		dispatch(getStaffUserList())
		dispatch(getProductsByCategoryId({categoryId: serviceValue}))
			.then(() => {})
			.catch(err => {
				console.log("ERR => while fetching all Categories", err)
			})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	useEffect(() => {
		if (selectedService !== undefined) {
			setDiscountValue(parseInt(selectedService.discount))
		}
	}, [selectedService])

	// const arr1 =
	// 	user !== undefined &&
	// 	user.map(elm => {
	// 		return {
	// 			value: elm.name
	// 		}
	// 	})
	// const arr2 =
	// 	user !== undefined &&
	// 	user.map(elm => {
	// 		return {
	// 			label: elm.name
	// 		}
	// 	})

	// useEffect(() => {
	// 	if (arr1) {
	// 		let arr3 = arr1.map((item, i) => Object.assign({}, item, arr2[i]))
	// 		setPickerItems(arr3)
	// 	}
	// }, [getUser])

	const arr4 =
		staffUser !== undefined &&
		staffUser.map(elm => {
			return {
				value: elm.id
			}
		})
	const arr5 =
		staffUser !== undefined &&
		staffUser.map(elm => {
			return {
				label: elm.name
			}
		})

	useEffect(() => {
		if (arr4) {
			let arr6 = arr4.map((item, i) => Object.assign({}, item, arr5[i]))
			setStaffPickerItems(arr6)
		}
			 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [staffUser])


// console.log("This is array =============> " , selectedItems)


	// const service1 =
	// 	productsByCategoryId !== undefined &&
	// 	productsByCategoryId.map(elm => {
	// 		return {
	// 			value: elm.name
	// 		}
	// 	})
	// const service2 =
	// 	productsByCategoryId !== undefined &&
	// 	productsByCategoryId.map(elm => {
	// 		return {
	// 			label: elm.name
	// 		}
	// 	})

	// useEffect(() => {
	// 	if (service1) {
	// 		let catData = service1.map((item, i) => Object.assign({}, item, service2[i]))
	// 		setPickerServicesItems(catData)
	// 	}
	// }, [productsByCategoryId])

	useEffect(() => {
		dispatch(addCategories())
			.then(() => {})
			.catch(err => {
				console.log("ERR => while fetching all Categories", err)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleStaffCreateItem = item => {
		setStaffPickerItems(curr => [...curr, item])
		setSelectedStaffItems(curr => [...curr, item])
	}

	const handleStaffSelectedItemsChange = selectedItems => {
		if (selectedItems) {
			setSelectedStaffItems(selectedItems)
		}
	}

	const {
		register,
		handleSubmit,
		reset,
		formState: {errors, touchedFields}
	} = useForm({
		defaultValues: "",
		resolver,
		mode: "onBlur"
	})


	useEffect(() => {
		setLoading(true)
		dispatch(getInvoiceUser())
			.then(() => {
				setLoading(false)
			})
			.catch(err => {
				setLoading(false)
				console.log("ERR => while fetching all Categories", err)
			})
		     // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedItems])

	useEffect(() => {
		var now = (bill_date && new Date(bill_date))|| new Date()
		var utcString = now.toISOString().substring(0, 19)
		var year = now.getFullYear()
		var month = now.getMonth() + 1
		var day = now.getDate()
		var hour = now.getHours()
		var minute = now.getMinutes()
		var localDatetime =
			year +
			"-" +
			(month < 10 ? "0" + month.toString() : month) +
			"-" +
			(day < 10 ? "0" + day.toString() : day) +
			"T" +
			(hour < 10 ? "0" + hour.toString() : hour) +
			":" +
			(minute < 10 ? "0" + minute.toString() : minute) +
			utcString.substring(16, 19)
		var datetimeField = document.getElementById("myDatetimeField")
		datetimeField.value = localDatetime;
		setBillDate(localDatetime)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bill_date]);


	const handleTag = () => {
		setHidden(!hidden)
		setSearchUser([])
		setTagName("")
		setSelectedItems("")
		localStorage.removeItem("addedCustomer")
		setSearchValue("")
	}
	const handleServiceTag = () => {
		setSearchService([])
		setSelectedService("")
		setServiceValue("")
	}

	// const {
	// 	fields: servicesFields,
	// 	append: servicesAppend,
	// 	remove: servicesRemove
	// } = useFieldArray({
	// 	control,
	// 	name: "service"
	// })

	// const {
	// 	fields: productsFields,
	// 	append: productsAppend,
	// 	remove: productsRemove
	// } = useFieldArray({
	// 	control,
	// 	name: "products"
	// })

	const onAddServices = data => {
		if (!selectedService) {
			toast({
				title: "Kindly Select the service first",
				status: "error",
				duration: 1000,
				isClosable: true
			})
		} else {
			const updatedData = {
				...data,
				staff: selectedStaffItems,
				services: selectedService,
				price: (yourPrice || yourDiscount)  ? parseInt(yourPrice)  : selectedService.price * 1,
				// discount: selectedService.discount * 1,
				discount: (yourPrice || yourDiscount)  ? parseInt(yourDiscount)  :  (parseInt(discountValue) * qtyValue),
				total: (yourPrice || yourDiscount)  ? parseInt(yourPrice)  :  (selectedService.price * qtyValue)
			}

			setServicesDatas([...servicesDatas, updatedData])
			toast({
				title: "Service has been added!",
				status: "success",
				duration: 1000,
				isClosable: true
			})
			setSelectedService("")
			// setting Default Value for staff only while adding serivices 
			currentUser.user_type === 2 ?  setSelectedStaffItems([{value:currentUser.id, label:currentUser.name}]) : setSelectedStaffItems([])
			setQtyValue(1)
			setYourDiscount(0)
			setYourPrice(0)
			onClose()
			reset()
		}
	}
	

	useEffect(() => {
		if (servicesDatas || productDetail) {
			 var serviceTotal = servicesDatas.reduce((acc, curr) => acc + curr.total , 0)
			 var serviceDiscount = servicesDatas.reduce((acc, curr) => acc + (curr.discount),0)
			const productTotal = productDetail.reduce((acc, curr) => acc + parseInt(curr.price), 0)
			// const productDiscount = servicesDatas.reduce((acc, curr) => acc + curr.discount, 0)
			setTotal(serviceTotal + productTotal);
			setDiscount(parseInt(serviceDiscount));
		}
	}, [servicesDatas, productDetail])




	const onError = (errors, e) => console.log(errors, e)

	// const onEditService = i => {
	// 	setServiceValue(i.services)
	// 	localStorage.setItem("serviceValue", i.services)
	// 	onOpen()
	// }

	const onUpdateService = i => {
		let service = localStorage.getItem("serviceValue")
		var updatedObj = servicesDatas.map(p => (p.services === service ? {...p, services: selectedService.name} : p))
		setServicesDatas(updatedObj)
		setSelectedService("")
		setSelectedStaffItems([])
		setServiceValue("")
		onClose()
	}

	const onRemoveService = v => {
		const myArray = servicesDatas.filter(function (obj) {
			return obj.total !== v.total
		})
		setServicesDatas(myArray)
		setSearchService([])
		setSelectedService("")
		setPaymentMethod("")
	}
	const onRemoveProduct = v => {
		const myArray = productDetail.filter(function (obj) {
			return obj.name !== v.name
		})
		setProductDetail(myArray)
		setSearchService([])
		setSelectedService("")
		setPaymentMethod("")
	}

	const handleUserSearch = v => {
		if (v) {
			const results = getUser.filter(
				emoji =>
					emoji.name.toLowerCase().includes(v.toLowerCase()) || emoji.phone.includes(v)
			)
			setSearchUser(results)
		} else {
			setSearchUser([])
		}
	}
	const handleServiceSearch = v => {
		if (v) {
			const results = productsByCategoryId.filter(emoji =>
				emoji.name.toLowerCase().includes(v.toLowerCase())
			)
			setSearchService(results)
		} else {
			setSearchService([])
		}
	}

	const ser = servicesDatas.map((v) => {
		return v.services.id
	})


	const stf = servicesDatas.map((v, i) => {
		if (v.staff.length > 0) {
			return v.staff.map(v => v.value)
		} else {
			return ""
		}
	})

	useEffect(() => {
		const currentDate = new Date().toISOString().slice(0, 16)
		const disableDates = () => {
			// document.getElementById("myDatetimeField").min = currentDate;
			document.getElementById("myDatetimeField").max = currentDate
		}
		disableDates()
	}, [])

	const dataOkay = {services: [...servicesDatas], products: [...productDetail]}


	const onFinalSubmit = () => {
		setLoading(true)
		if (servicesDatas.length < 1) {
		  setLoading(false);
		  toast({
		    title: "You need to select a service first",
		    status: "error",
		    duration: 3000,
		    isClosable: true,
		  })
		} else {
  const orderObj = {
		data: dataOkay,
		staff: stf,
		services: ser,
		total_amount: total - discount || 0,
		bill_date,
		cash: parseInt(cashPayment) + parseInt(adjustPayment2) || 0,
		online: parseInt(onlinePayment) || 0,
		cust_id: selectedItems.id,
	    paymentId : ""
}
		dispatch(
			createOrders(orderObj)
		)
			.then(() => {
				setLoading(false)
				setSelectedItems("")
				setTagName("")
				reset();
				setTimeout(() => {
					// history.push(`billing/${res.uid}`);
					history.push("/listing")
				}, 1000)
			})
			.catch(err => {
				setLoading(false)
				toast({
					title: err[1],
					status: "error",
					duration: 3000,
					isClosable: true
				})
			})
		localStorage.removeItem("product")
	}
}


	return (
		<PrivateLayout>
			<PageHeader
				pageHeading='Quick Invoice '
				title='Quick Invoice Page'
				description='description'
				keyword='keyword'
			/>
			<PageContainer>
				<div>
					<div className='md:flex w-full justify-between mt-12'>
						<div className='md:w-1/2'>
							<FormControl id='id'>
								<FormLabel>Search Customer By Name/Contact</FormLabel>
								{tagName && (
									<Tag
										marginY={2}
										marginX={1}
										size='lg'
										borderRadius='full'
										variant='solid'
										colorScheme='blue'>
										<TagLabel>{tagName}</TagLabel>
										<TagCloseButton onClick={() => handleTag()} />{" "}
									</Tag>
								)}
								<Input
									placeholder='Search Customer By Name/Contact'
									type='text'
									id='search'
									value={searchValue}
									onChange={e => {
										setSearchValue(e.target.value)
										handleUserSearch(e.target.value)
									}}
								/>
							</FormControl>

							{searchUser.length > 0 ? (
								<div className='w-full bg-gray-200 rounded-md shadow-md p-2 mt-1'>
									{searchUser &&
										searchUser.map((v, i) => (
											<div
												key={i}
												className='font-semibold hover:bg-gray-500 hover:text-white p-2 rounded-md px-4 cursor-pointer'
												onClick={() => {
													setHidden(false)
													setSelectedItems(v)
													setTagName(v.name1 ||v.name)
													setSearchUser([])
													setSearchValue("")
												}}>
												{v.name1||v.name}
											</div>
										))}
								</div>
							) : (
								""
							)}

							<div className='w-full mt-2'>
								<AddCustomerModal
									selectedItems={selectedItems}
									setSelectedItems={setSelectedItems}
									setTagName={setTagName}
								/>
							</div>
						</div>
						<div className='md:w-2/1 mt-5 md:mt-0'>
							<FormControl>
								<FormLabel className='w-1/4'>Bill Date</FormLabel>
								<Input type='datetime-local' id='myDatetimeField' onChange={(e)=>setBillDate(e.target.value)} />
							</FormControl>
						</div>
					</div>
					<div className='mt-5'>
						<form onSubmit={handleSubmit()}>
							{servicesDatas.length > 0 && (
								<Box overflowX='auto' w='100%'>
									{/* {servicesFields.map((item, index) => {
                  return ( */}
									<Table variant='simple' bg='blue.100'>
										<Thead>
											<Tr>
												<Th>Services</Th>
												<Th>Staff</Th>
												<Th>Price</Th>
												<Th>Qty</Th>
												<Th>Discount</Th>
												<Th>Total</Th>
												<Th></Th>
											</Tr>
										</Thead>
										<Tbody>
											{servicesDatas.length > 0 &&
												servicesDatas.map((v, i) => (
													<Tr key={i}>
														<Td>
															<Text key={i}>{v.services.name}</Text>
														</Td>
														<Td>
															{v.staff.map((v, i) => (
																<Text key={i}>{v.label}</Text>
															))}
														</Td>
														<Td>
															{/* <Input type="number" bg="white" className="w-28" /> */}
															<Text>{v.price}</Text>
														</Td>
														<Td>
															<Text>{v.qty}</Text>
														</Td>
														<Td>
															{/* <Input type="number" bg="white" className="w-28" /> */}
															<Text>{v.discount}</Text>
														</Td>
														<Td>
															{/* <Input type="number" bg="white" className="w-32" /> */}
															<Text>{v.total - parseInt(v.discount)}</Text>
														</Td>
														<Td>
															<IconButton
																colorScheme='blue.100'
																color='black'
																icon={<IoIosTrash />}
																type='button'
																// onClick={() => servicesRemove(index)}
																onClick={() => {
																	onRemoveService(v)
																}}
																className='w-16'
															/>
															{/* <IconButton
                                colorScheme="blue.100"
                                color="black"
                                icon={<IoMdCheckboxOutline />}
                                type="button"
                                onClick={() => onEditService(v)}
                                className="w-16"
                              /> */}
														</Td>
													</Tr>
												))}
										</Tbody>
										<Tfoot>
											<Tr></Tr>
										</Tfoot>
									</Table>
									{/* );
                })} */}
								</Box>
							)}
						</form>
						<form onSubmit={handleSubmit()} className='mt-4'>
							{productDetail.length > 0 && (
								<Box overflowX='auto' w='100%'>
									{/* {servicesFields.map((item, index) => {
                  return ( */}
									<Table variant='simple' bg='blue.100'>
										<Thead>
											<Tr>
												<Th>Name</Th>
												<Th>Price</Th>
												<Th>Commment</Th>
												<Th>Action</Th>
											</Tr>
										</Thead>
										<Tbody>
											{productDetail.length > 0 &&
												productDetail.map((v, i) => (
													<Tr key={i}>
														<Td>
															<Text key={i}>{v.name || ""}</Text>
														</Td>

														<Td>
															{/* <Input type="number" bg="white" className="w-28" /> */}
															<Text>{v.price}</Text>
														</Td>
														<Td>
															<Text>{v.comment}</Text>
														</Td>

														<Td>
															<IconButton
																colorScheme='blue.100'
																color='black'
																icon={<IoIosTrash />}
																type='button'
																// onClick={() => servicesRemove(index)}
																onClick={() => {
																	onRemoveProduct(v)
																}}
																className='w-16'
															/>
															{/* <IconButton
                                colorScheme="blue.100"
                                color="black"
                                icon={<IoMdCheckboxOutline />}
                                type="button"
                                onClick={() => {
                                  open();
                                  localStorage.setItem(
                                    "product",
                                    JSON.stringify(v)
                                  );
                                }}
                                className="w-16"
                              /> */}
															<EditProductModal
																editProduct={v}
																index={i}
																productDetail={productDetail}
																setProductDetail={setProductDetail}
															/>
														</Td>
													</Tr>
												))}
										</Tbody>
										<Tfoot>
											<Tr></Tr>
										</Tfoot>
									</Table>
									{/* );
                })} */}
								</Box>
							)}
						</form>

						<section className='mt-5'>
							<Stack direction='row' spacing={4}>
								<Button
									leftIcon={<IoIosAdd />}
									colorScheme='blue'
									variant='solid'
									type='button'
									onClick={() => {
										setServiceValue("")
										onOpen()
									}}>
									Add Sevices
								</Button>
								<AddProductModal productDetail={productDetail} setProductDetail={setProductDetail} />

								<AddService
									isOpen={isOpen}
									onClose={onClose}
									handleSubmit={handleSubmit}
									onAddServices={onAddServices}
									onError={onError}
									register={register}
									touchedFields={touchedFields}
									errors={errors}
									loading={loading}
									staffePickerItems={staffePickerItems}
									selectedStaffItems={selectedStaffItems}
									setSelectedStaffItems = {setSelectedStaffItems}
									handleStaffCreateItem={handleStaffCreateItem}
									handleStaffSelectedItemsChange={handleStaffSelectedItemsChange}
									handleServiceSearch={handleServiceSearch}
									serviceValue={serviceValue}
									setServiceValue={setServiceValue}
									searchService={searchService}
									setSearchService={setSearchService}
									setSelectedService={setSelectedService}
									selectedService={selectedService}
									handleServiceTag={handleServiceTag}
									qtyValue={qtyValue}
									setQtyValue={setQtyValue}
									onUpdateService={onUpdateService}
									servicesDatas={servicesDatas}
									discountValue={discountValue}
									setDiscountValue={setDiscountValue}
									yourDiscount = {yourDiscount}
									setYourDiscount={setYourDiscount}
									yourPrice={yourPrice}
									setYourPrice={setYourPrice}
									reset = {reset}
								/>
							</Stack>
						</section>
					</div>
					<div className='border-b-2 mt-10 mb-10'></div>
					<div className='md:flex justify-between w-full'>
						<div className='flex lg:w-1/2'>
							<Select
								placeholder='Select Payment'
								mr={3}
								onChange={e => {
									setPaymentMethod(e.target.value);
									setOnlinePayment(0)
									setAdjustPayment2(0)
									setCashPayment(0)
								}}
								disabled={servicesDatas.length > 0 || productDetail.length > 0 ? false : true}>
								<option value='cash'>Cash</option>
								<option value='online'>Online</option>
								<option value='both'>Both</option>
							</Select>
							<div className='w-full'>
								<Input
									placeholder={paymentMethod === "cash" ? "Cash Payment" : "Online Payment"}
									value={paymentMethod === "cash" ? cashPayment : onlinePayment}
									onClick={() => {
										if (paymentMethod === "cash") {
											setCashPayment("")	
										} 
										else {
											setOnlinePayment("")
										}
									}}
									onChange={e => {
										if (paymentMethod === "cash") {
											setOnlinePayment(0);
											setAdjustPayment2(0);
											setCashPayment(e.target.value);
										} else {
											setCashPayment(0);
											setAdjustPayment2(0);
											setOnlinePayment(e.target.value)
										}
									}}
									size='md'
									type='number'
									disabled={paymentMethod !== "" ? false : true}
								/>
								{paymentMethod === "both" ? (
									<Input
										className='mt-2'
										placeholder='Cash Payment'
										value={adjustPayment2}
										onClick={()=>setAdjustPayment2("")}
										onChange={e => setAdjustPayment2(e.target.value)}
										size='md'
										type='number'
										disabled={servicesDatas.length > 0 ? false : true}
									/>
								) : (
									""
								)}
							</div>
						</div>
						<div className='flex justify-end border md:w-1/3  mt-5 md:mt-0'>
							<div className='border-r w-full'>
								<h5 className='border-b text-right p-4 text-gray-600 font-bold'>Total</h5>
								<h5 className='border-b text-right p-4 text-gray-600 font-bold'>Discount</h5>
								<h5 className='text-right p-4 text-gray-600 font-bold'>Due Amount</h5>
							</div>

							<div className='md:w-1/4'>
								<h5 className={`border-b text-right p-4 text-gray-600 font-bold`}>{total}</h5>
								<h5 className='border-b text-right p-4 text-gray-600 font-bold'>{discount}</h5>
								<h5
									className={`border-b text-right p-4 text-gray-600 ${
										total - discount - onlinePayment - cashPayment - adjustPayment2 === 0
											? "text-gray-600"
											: "text-red-600"
									} font-bold text-lg`}>
									{total - discount - onlinePayment - cashPayment - adjustPayment2}
								</h5>
							</div>
						</div>
					</div>
					
					{/* <div>
            {stf.map((_, i) => (
              <div>{_}</div>
            ))}
          </div> */}

					<div className='mt-5 md:mt-0'>
						<Button
							className='xl:-mt-16 mb-2'
							colorScheme='green'
							variant='solid'
							isLoading={loading}
							onClick={onFinalSubmit}
							disabled={((servicesDatas.length > 0 && tagName) ||  (productDetail.length > 0)) && tagName ? false : true}>
							Submit
						</Button>
					</div>
				</div>
			</PageContainer>
		</PrivateLayout>
	)
}

export default QucikInvoice
