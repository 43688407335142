import axios from 'axios';
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux'
import { getStaffUserList } from '../../store/actions/getUser'
import { Tbody, Th, Thead, Tr , Table , Td , Button, useDisclosure, useToast ,Textarea , Modal , ModalBody , ModalFooter , ModalHeader ,ModalOverlay , ModalContent , ModalCloseButton } from '@chakra-ui/react';
import PageContainer from '../../components/PageContainer';
import { getStartOfMonth } from '../../utils/common';
import { DateRangePicker } from 'react-dates';
import PrivateLayout from '../../layout/PrivateLayout';


const MarkAttendence = () => {
  const dispatch = useDispatch();
  const { getStaffUser } = useSelector(state => state?.getUsers);
  const [markedAttendenceData, setMarkedAttendenceData] = useState([]);
  const [attendenceHaveToMarkData, setAttendenceHaveToMarkData] = useState([]);
  const [staffData , setStaffData] = useState({});
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [time ,setTime] = useState(moment().format("HH:mm:ss"));
  const [inputVal ,setInputVal] = useState("");
  const [loading , setLoading] = useState(false);
//    ================== Date Object Used For Date Range Picker ======================


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const [dateObject, setDateObject] = useState({
	startDate: null,
	endDate: null,
})

// =============================== state for Date Range Calender Focus Input Prop  =======================

const [focusedInput , setFocusIntput] = useState("");

  const Toast = useToast();

  useEffect(() => {
    dispatch(getStaffUserList());
    getData();
  }, []);

  useEffect(()=>{
    if(dateObject?.startDate && dateObject?.endDate){
      getData();
    }
  },[dateObject]);

  useEffect(()=>{
    setAttendenceHaveToMarkData(()=>{
      const filtredData = getStaffUser?.filter((staff) => !markedAttendenceData?.some((element) => element?.staff_id === staff?.id));
      console.log({filtredData})
       return filtredData
    })
  }, [markedAttendenceData])

  const getData = async () => {
  //  const url = `https://curlys.in/devapi/api/attendance/list?start_date=${dateObject.startDate?moment(dateObject.startDate).format("YYYY-MM-DD"): moment(new Date()).format("YYYY-MM-DD")}&end_date=${dateObject.startDate}`
   const url = `${apiBaseUrl}/attendance/list?start_date=${dateObject.startDate ? moment(dateObject.startDate).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD")}&end_date=${dateObject.startDate}`;
    const { data } = await axios.get(url);
    const filtredData = data?.data?.filter(element=> (element?.end_time!== '00:00:00'));
    setMarkedAttendenceData(filtredData);
  }


  const handleAttendence = async ()=>{
		// const url = `https://curlys.in/devapi/api/attendance/add`;
    const url = `${apiBaseUrl}/attendance/add`;
	   const body = {
		  staff_id:staffData?.id,
		  start_time:  time,
		  end_time:  "00:00:00",
		  date:moment(new Date()).format("YYYY-MM-DD"),
		  type:1,
		  comment:inputVal
	   }
   
	   const {data} = await axios.post(url ,body);
    //console.log("This is the data form the API ========================> " , data)
	   if(data?.message === "Attendance added successfully."){
		   Toast({
			title: "Check in Marked SuccessFully",
			status: "success",
			duration: 3000,
			isClosable: true
		})
    setInputVal("");
    setTime(moment().format("HH:mm:ss"))
    onClose();
	}
  else if(data?.message === "Attendance updated successfully."){
			Toast({
				title: "Check Out Marked SuccessFully",
				status: "success",
				duration: 3000,
				isClosable: true
			})
      setInputVal("");
      setTime(moment().format("HH:mm:ss"))
      onClose();
	   }

	   else{
		   Toast({
			title: "Something went wrong please try Again",
			status: "error",
			duration: 3000,
			isClosable: true
		})
	   }

	   getData();
}



//  ================================ For Responsive Date Range Calendear ==========================	

let numberOfMonths = window.matchMedia("(max-width: 700px)").matches ? "1" : "2"
let small =  window.matchMedia("(max-width: 700px)").matches ? true : false
let large = window.matchMedia("(max-width: 700px)").matches ? false : true

  return (
    <>
    <PrivateLayout>
    <PageContainer>
      <div className='text-right'>
          <DateRangePicker
									hideKeyboardShortcutsPanel={true}
									startDateId='startDate'
									regular={large}
									small={small}
									numberOfMonths={numberOfMonths}
									isOutsideRange={() => false}
									endDateId='endDate'
									displayFormat="DD MMM YYYY"
									startDate={dateObject.startDate}
									startDatePlaceholderText={moment(new Date()).format("DD MMM YYYY")}
									endDatePlaceholderText={moment(new Date()).format("DD MMM YYYY")}
									endDate={dateObject.endDate}
									onDatesChange={({startDate, endDate}) =>
										setDateObject(prev => ({
											...prev,
											startDate: startDate,
											endDate: endDate
										}))
									}
									readOnly={true}
									inputIconPosition='after'
									focusedInput={focusedInput}
									customInputIcon={<i className='bx bxs-calendar' />}
									// orientation={orientation}
									// appendToBody={true}
									// autoFocus={true}
									onFocusChange={(focusedInput) =>{
										setFocusIntput(focusedInput)
									}}
								/>
                </div>


      <h1 className='font-bold text-center my-4'>Staff That Marked Attendence </h1>
      {markedAttendenceData && (<Table
        className='overflow-auto w-full md:table block'
        style={{ border: "1px solid #EDF2F7", boxShadow: "rgb(100 100 111 / 20%) 0px 7px 29px 0px" }}>
        <Thead style={{ background: "#F0F0F0" }}>
          <Tr>
            <Th>#</Th>
            <Th>Employee Name</Th>
            {/* <Th>Status</Th> */}
            <Th>Date</Th>
            <Th>Check in</Th>
            <Th>Check out</Th>
            <Th>Total Hour</Th>
          </Tr>
        </Thead>
        <Tbody>
          {markedAttendenceData?.map((element, index) => {
            return (
              <Tr key = {element?.id}>
                <Td>{index + 1}</Td>
                <Td className='font-medium'>{element?.staff?.name}</Td>
                {/* <Td>
                <div className='flex'>
                    <Button className='bg-green-400 text-white'>first </Button>
                    <Button className='ml-10 bg-red-400 text-white'>second</Button>
                </div>
            </Td> */}
                <Td>{element?.date}</Td>
                <Td>{element?.start_time}</Td>
                <Td>{element?.end_time !== '00:00:00' ? element?.end_time : 'Not Checkout Yet'}</Td>
                <Td>{element?.start_time - element?.end_time}</Td>
                
                
              </Tr>
              
          )
          })}
        </Tbody>
      </Table>)}

       <h1 className='font-bold text-center my-4'>Staff That Not Marked Attendence</h1>
      {attendenceHaveToMarkData?.length!==0 && (<Table
        className='overflow-auto w-full md:table block'
        style={{ border: "1px solid #EDF2F7", boxShadow: "rgb(100 100 111 / 20%) 0px 7px 29px 0px" }}>
        <Thead style={{ background: "#F0F0F0" }}>
          <Tr>
            <Th>#</Th>
            <Th>Employee Name</Th>
            {/* <Th>Status</Th> */}
            <Th>Staff Id</Th>
            {/* <Th>Check in</Th>
            <Th>Check out</Th> */}
            <Th>Mark Attendence</Th>
          </Tr>
        </Thead>
        <Tbody>
          {attendenceHaveToMarkData?.map((element, index) => {
            return (
              <Tr key = {element?.id}>
                <Td>{index + 1}</Td>
                <Td className='font-medium'>{element?.name}</Td>
                <Td>{element?.id}</Td>
                {/* <Td>{element?.start_time}</Td>
                <Td>{element?.end_time !== '00:00:00' ? element?.end_time : 'Not Checkout Yet'}</Td> */}
                <Td><Button onClick={()=>{setStaffData(element); onOpen()}} className='ml-10 bg-red-400 text-white'>Mark Attendence</Button></Td>
              </Tr>
          )
          })}
        </Tbody>
      </Table>)}
    </PageContainer>
    

<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className='capitalize'> Check In Modal </ModalHeader>
					<ModalCloseButton />
					<ModalBody>
          <div className='w-full my-4'>
						<label className='font-bold  ml-2 '>Pick Check In Time</label>
															<input
																// value={UserData.time}
																// onChange={dateTimeHandler}
																className='rounded-lg mt-2  w-full border h-12 appearance-none block text-gray-700 border border-gray-200  py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
																type='time'
																placeholder='Time'
																name='checkInTime'
																onChange={(e)=>setTime(e.target.value)}
																// defaultValue={updateAttendeceData?.checkInTime}
																value= {time}
															/>
														</div>
                            <label className='font-bold  ml-2 '>Add Comment</label>
					<Textarea onChange={(e)=>setInputVal(e.target.value)} value={inputVal} placeholder='Comment' />
					</ModalBody>
					<ModalFooter>
					<Button colorScheme='gray' className='px-3 py-1 rounded mx-2 ' onClick={onClose}>
							Close
						</Button>
 <Button
							isLoading={loading}
							className='px-3 py-1 rounded bg-gold mx-2 text-white'
							onClick={handleAttendence}>
							Check In
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
      </PrivateLayout>
      </>


  )
}

export default MarkAttendence