import React, {useState} from "react"
import {NavLink, useLocation} from "react-router-dom"
import {useSelector} from "react-redux"
import classes from "classnames"
import ProfileAvatarMenu from "./components/ProfileAvatarMenu"
import Header from "../components/Header"
import Footer from "../components/Footer"

const PrivateLayout = ({children}) => {
	const [showMobileMenu, setShowMobileMenu] = useState(false)
	const {user} = useSelector(state => ({
		user: state.auth.user
	}))
	const location = useLocation()
	let path = location.pathname
	return (
		<div>
			{user?.user_type === 1 || user?.user_type === 2 ? (
				<nav className='bg-black-2xl sticky top-0 z-10 py-5'>
					<div className='max-w-7xl mx-auto px-2 sm:px-6 lg:px-8'>
						<div className='relative flex items-center justify-between h-16'>
							<div className='absolute inset-y-0 left-0 flex items-center lg:hidden'>
								{/* <!-- Mobile menu button--> */}
								<button
									type='button'
									className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
									aria-controls='mobile-menu'
									aria-expanded='false'
									onClick={() => setShowMobileMenu(prev => !prev)}>
									<span className='sr-only'>Open main menu</span>

									<svg
										className='block h-6 w-6'
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										stroke='currentColor'
										aria-hidden='true'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M4 6h16M4 12h16M4 18h16'
										/>
									</svg>

									<svg
										className='hidden h-6 w-6'
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										stroke='currentColor'
										aria-hidden='true'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M6 18L18 6M6 6l12 12'
										/>
									</svg>
								</button>
							</div>
							<div className='flex-1 flex items-center justify-center lg:items-stretch lg:justify-start '>
								<div className='flex-shrink-0 flex items-center h-16 w-auto  mt-2'>
									<NavLink to='/'>
										<img className='block   w-auto' src='/img/main-logo.png' alt='Curlys' />
									</NavLink>
								</div>
								<div className='hidden lg:block sm:ml-6'>
									<div className='flex space-x-4 mt-5'>
										{user?.user_type === 1 && (
											<>
												{/* <NavLink
													to='/dashboard'
													activeClassName='bg-gray-700 text-white'
													className={`text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium`}>
													Dashboard
												</NavLink> */}
											</>
										)}
										<NavLink
													to='/dashboard'
													activeClassName='bg-gray-700 text-white'
													className={`text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium`}>
													Dashboard
												</NavLink>
										<NavLink
											activeClassName='bg-gray-700 text-white'
											exact
											to='/expenses'
											className='text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'>
											Expenses
										</NavLink>
										<NavLink
											activeClassName='bg-gray-700 text-white'
											exact
											to='/mark-attendence'
											className='text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'>
											Attandence
										</NavLink>

										<NavLink
											to='/service'
											activeClassName='bg-gray-700 text-white'
											className='text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'>
											Service
										</NavLink>

										<NavLink
											to='/listing'
											activeClassName='bg-gray-700 text-white'
											className='text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'>
											Listing
										</NavLink>

										<NavLink
											to='/salary'
											activeClassName='bg-gray-700 text-white'
											className='text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'>
											Salary
										</NavLink>

										{user?.user_type === 1 && (
											<>
												<NavLink
													to='/booking-list'
													activeClassName='bg-gray-700 text-white'
													className={`text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium`}>
													Appointments
												</NavLink>
												<NavLink
													to='/userList'
													activeClassName='bg-gray-700 text-white'
													className={`text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium`}>
													Users
												</NavLink>
												<NavLink
													to='/offer-list'
													activeClassName='bg-gray-700 text-white'
													className={`text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium`}>
													Offers
												</NavLink>
											</>
										)}
									</div>
								</div>
							</div>
							<div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
								{/* <!-- Profile dropdown --> */}
								<div className='ml-3 relative'>
									<ProfileAvatarMenu />
								</div>
							</div>
						</div>
					</div>

					{/* <!-- Mobile menu, show/hide based on menu state. --> */}
					<div
						className={classes("lg:hidden ", {
							hidden: !showMobileMenu
						})}
						id='mobile-menu'>
						<div className='px-2 pt-2 pb-3 space-y-1 flex flex-col'>
						<NavLink
										activeClassName='bg-gray-700 text-white'
										exact
										to='/dashboard'
										className='w-fit text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'>
										Dashboard
									</NavLink>
							<NavLink
								to='/expenses'
								activeClassName='bg-gray-700 text-white'
								className='w-fit text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'>
								Expenses
							</NavLink>
							<NavLink
								to='/service'
								activeClassName='bg-gray-700 text-white'
								className='w-fit text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'>
								Service
							</NavLink>
							<NavLink
								to='/listing'
								activeClassName='bg-gray-700 text-white'
								className='w-fit text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'>
								Listing
							</NavLink>
							<NavLink
								to='/salary'
								activeClassName='bg-gray-700 text-white'
								className='w-fit text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'>
								Salary
							</NavLink>
							{user?.user_type === 1 && (
								<>
									{/* <NavLink
										activeClassName='bg-gray-700 text-white'
										exact
										to='/dashboard'
										className='w-fit text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'>
										Dashboard
									</NavLink> */}
									<NavLink
										to='/userList'
										activeClassName='bg-gray-700 text-white'
										className={`w-fit text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium`}>
										Users
									</NavLink>
									<NavLink
										to='/offer-list'
										activeClassName='bg-gray-700 text-white'
										className='w-fit text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'>
										Offers
									</NavLink>
									<NavLink
										to='/booking-list'
										activeClassName='bg-gray-700 text-white'
										className='w-fit text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'>
										Appointments
									</NavLink>
								</>
							)}
						</div>
					</div>
					{/* end of navbar */}
				</nav>
			) : (
				<>
					<Header />
				</>
			)}
			<div>{children}</div>
			{user?.user_type === 3 && <Footer />}
		</div>
	)
}

export default PrivateLayout
