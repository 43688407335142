import React from "react"
import {NavLink} from "react-router-dom"
const EmptyAppointmentPage = () => {
	return (
		<>
			<div className='flex flex-col justify-center items-center'>
				<img src='/img/no-data.png' alt='' width='500px' />
				<h6 className='mt-3 mb-5 sm:text-5xl text-xl text-center font-bold text-gray-500'>
					No Appointments Found
				</h6>
				<p className='text-lg mb-2 text-center'>Please Add Something In Your Room And Get Appointment</p>
				<div className='mt-2'>
					<button className=' bg-gold text-white py-2 px-5 rounded-md text-xl font-normal'>
						<NavLink to='/services'>Get Appointment</NavLink>
					</button>
				</div>
			</div>
		</>
	)
}

export default EmptyAppointmentPage
