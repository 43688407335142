import React, {useState,useEffect} from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	IconButton,
	useToast,
	Stack,
	FormLabel,
	FormControl,
	FormErrorMessage,
	Input,
	Textarea,
	Text
} from "@chakra-ui/react"
import * as Yup from "yup"
import {useForm} from "react-hook-form"
import {useDispatch} from "react-redux"
import {useYupValidationResolver} from "../../../hooks/useValidationResolver"
import EditIcon from "../../Icons/EditIcon"
import {updateExpense} from "../../../store/actions/expense"

const validationSchema = Yup.object({
	date: Yup.string().required("Date is required!"),
	expense: Yup.string().required("Expense is required!"),
	total: Yup.string().required("Total expense is required!"),
	cash: Yup.string().required("Cash expense is required!"),
	online: Yup.string().required("Online expense is required!")
})
const EditExpenseModal = ({expense, updateExpenseList}) => {
	const dispatch = useDispatch()
	const toast = useToast()
	const {isOpen: firstModalIsOpen, onOpen: firstModalOnOpen, onClose: firstModalOnClose} = useDisclosure()
	const {isOpen: secondModalIsOpen, onOpen: secondModalOnOpen, onClose: secondModalOnClose} = useDisclosure()

	const [expenseObj, setExpenseObj] = useState({
		online : parseInt(expense?.online),
		cashPayment: parseInt(expense?.cash),
		cashExpense: parseInt(expense?.expense),
		total : parseInt(expense?.total)
	})

	const [closingBalance, setClosingBalance] = useState(parseInt(expense.closing))

	const [loading, setLoading] = useState(false)
	const resolver = useYupValidationResolver(validationSchema)

	const {
		register,
		handleSubmit,
		formState: {errors, touchedFields},
		reset
	} = useForm({
		defaultValues: expense,
		resolver,
		mode: "onBlur"
	})


	useEffect(() => {
		let oldAmmount 
		let newAmmount
		oldAmmount = parseInt(expense.total||0) - ((parseInt(expense.cash||0) + parseInt(expense.online||0) + parseInt(expense.expense||0)));
		newAmmount = parseInt(expenseObj.total||0) - (parseInt(expenseObj.cashPayment||0) + parseInt(expenseObj.online || 0) + parseInt(expenseObj.cashExpense || 0));
		setClosingBalance(parseInt(expense.closing) + (newAmmount - oldAmmount));
	 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expense , expenseObj])



	const expenseObjHandler = e => {
		setExpenseObj(prev => ({
			...prev,
			[e.target.name]: parseInt(e.target.value)
		}))
	}

	const onUpdateExpense = data => {

		setLoading(true)
		dispatch(
			updateExpense(
				{
					cash: expenseObj.cashPayment||0.0,
					online: expenseObj.online || 0.0,
					expense: expenseObj.cashExpense || 0.0,
					expense_online: data.expense_online || 0.0,
					total: expenseObj.total||0.0,
					date: data.date,
					comment: data.comment,
					closing: closingBalance || expense.closing,
					id: data.id
				},
				data.id
			)
		)
			.then(() => {
				setLoading(false)
				toast({
					title: "Expense has been updated!",
					status: "success",
					duration: 3000,
					isClosable: true
				})
				// reset the form
				reset()
				firstModalOnClose()
				secondModalOnClose()
				updateExpenseList()
				setClosingBalance(expense.closing)
				setExpenseObj(prev => ({
					...prev,
					cashExpense: expense.expense,
					cashPayment: expense.cash,
					total: expense.total
				}))
			})
			.catch(err => {
				setLoading(false)
				console.log("ERR => while adding expense", err)
			})
	}

	const closeAllModals = () => {
		firstModalOnClose()
		setClosingBalance(expense.closing)
		secondModalOnClose()
		reset()
		setExpenseObj(prev => ({
			...prev,
			cashExpense: expense.expense,
			cashPayment: expense.cash,
			total : expense.total
		}))
	}

	return (
		<>
			<IconButton
				className='mr-3'
				colorScheme='green'
				aria-label='Edit Expense'
				onClick={firstModalOnOpen}
				icon={<EditIcon />}
				size='sm'
			/>
			<Modal isOpen={firstModalIsOpen} onClose={firstModalOnClose} scrollBehavior='inside' size='xl'>
				<ModalOverlay />
				<ModalContent className='sm:mx-0 mx-3'>
					<ModalCloseButton />
					<ModalBody>
						<Stack spacing={3} justifyContent='center' alignItems='center' className='mt-4'>
							<div className=''>
								<i
									className='bx bx-info-circle sm:text-7xl text-5xl text-red-600'
									style={{transform: "rotateZ(180deg)"}}></i>
							</div>
							<Text className='sm:text-4xl text-2xl font-bold text-gray-700 mb-3'>Are you sure?</Text>
							<Text className='sm:text-xl text-md font-medium text-gray-500 mb-2'>
								Do you want to edit "Expense" ?
							</Text>
						</Stack>
					</ModalBody>
					<ModalFooter className='justify-center mb-4'>
						<Button mr={3} onClick={firstModalOnClose} className='bg-red-600 text-white'>
							No
						</Button>
						<Button onClick={secondModalOnOpen} colorScheme='green'>
							Yes
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal isOpen={secondModalIsOpen} onClose={secondModalOnClose} scrollBehavior='inside' size='xl'>
				<ModalOverlay />
				<form
					style={{overflow: "auto"}}
					noValidate
					onSubmit={handleSubmit(onUpdateExpense)}
					className='bg-white'>
					<ModalContent>
						<ModalHeader className='capitalize'>Update Expense</ModalHeader>
						<ModalCloseButton onClick={closeAllModals} />
						<ModalBody>
							<div className='w-full flex justify-end'>
								<h1 className='text-lg font-bold text-red-900'>Due Amount : {closingBalance}</h1>
							</div>
							<Stack spacing={3}>
								<FormControl isInvalid={touchedFields?.date && errors?.date} id='email' className="mb-3">
									<FormLabel>Date</FormLabel>
									<Input {...register("date")} placeholder='Select date' type='date' />
									<FormErrorMessage>{errors?.date?.message}</FormErrorMessage>
								</FormControl>



								<FormControl isInvalid={touchedFields?.total && errors?.total} id='total' className='mb-3'>
									<FormLabel>Total</FormLabel>
									<Input
										{...register("total")}
										placeholder='Enter Total Payment'
										type='number'
										name='total'
										value={expenseObj.total}
										onChange={expenseObjHandler}
										onWheel={event => event.currentTarget.blur()}
									/>
									<FormErrorMessage>{errors?.total?.message}</FormErrorMessage>
								</FormControl>

								<FormControl isInvalid={touchedFields?.cash && errors?.cash} id='cash' className='mb-3'>
									<FormLabel>Cash Payment</FormLabel>
									<Input
										{...register("cash")}
										placeholder='Enter cash Payment'
										type='number'
										name='cashPayment'
										value={expenseObj.cashPayment}
										onChange={expenseObjHandler}
										onWheel={event => event.currentTarget.blur()}
									/>
									<FormErrorMessage>{errors?.cash?.message}</FormErrorMessage>
								</FormControl>

								<FormControl
									isInvalid={touchedFields?.online && errors?.online}
									id='online'
									className='mb-3'>
									<FormLabel>Online Payment</FormLabel>
									<Input
										{...register("online")}
										placeholder='Enter online Payment'
										value={expenseObj.online}
										name =  "online"
										onChange={expenseObjHandler}
										type='number'
										onWheel={event => event.currentTarget.blur()}
									/>
									<FormErrorMessage>{errors?.cash?.message}</FormErrorMessage>
								</FormControl>

								<FormControl
									isInvalid={touchedFields?.expense && errors?.expense}
									id='expense'
									className='mb-3'>
									<FormLabel>Cash Expense</FormLabel>
									<Input
										{...register("expense")}
										placeholder='Enter expense'
										type='number'
										name='cashExpense'
										value={expenseObj.cashExpense}
										onChange={expenseObjHandler}
										onWheel={event => event.currentTarget.blur()}
									/>
									<FormErrorMessage>{errors?.expense?.message}</FormErrorMessage>
								</FormControl>

								<FormControl
									isInvalid={touchedFields?.expense_online && errors?.expense_online}
									id='expense_online'
									className='mb-3'>
									<FormLabel> Online Expense</FormLabel>
									<Input
										{...register("expense_online")}
										onWheel={event => event.currentTarget.blur()}
										placeholder='Enter Your Online Expense'
										type='number'
										min='0'
									/>
									<FormErrorMessage>{errors?.expense_online?.message}</FormErrorMessage>
								</FormControl>

								<FormControl isInvalid={touchedFields?.comment && errors?.comment} id='comment'>
									<FormLabel>Comment</FormLabel>
									<Textarea
										rows='4'
										{...register("comment")}
										placeholder='Enter comment...'
										type='text'
									/>
									<FormErrorMessage>{errors?.comment?.message}</FormErrorMessage>
								</FormControl>
							</Stack>
						</ModalBody>

						<ModalFooter>
							<Button mr={3} onClick={closeAllModals}>
								Close
							</Button>
							<Button isLoading={loading} type='submit' colorScheme='green'>
								Update
							</Button>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</>
	)
}

export default EditExpenseModal
