import React, {useEffect, useState} from "react"
import {FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Button, Text, useToast} from "@chakra-ui/react"

import * as Yup from "yup"
import {useForm} from "react-hook-form"
import {useDispatch} from "react-redux"
import Container from "../../components/Container"
import {useYupValidationResolver} from "../../hooks/useValidationResolver"
import {loginUser} from "../../store/actions/auth"
import styles from "./style.module.css"
import {IconButton} from "@chakra-ui/react"
import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons"
const validationSchema = Yup.object({
	phone: Yup.number().typeError("Phone must be a number").required("Phone number is required!"),
	password: Yup.string().required("Password is required!")
})

const Login = ({history}) => {
	const toast = useToast()
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const resolver = useYupValidationResolver(validationSchema)
	const {
		register,
		handleSubmit,
		formState: {errors, touchedFields},
		reset
		// setError,
	} = useForm({
		resolver,
		mode: "onBlur"
	})

	useEffect(() => {
		document.body.classList.add(`${styles.bgBody}`)
		return () => {
			document.body.classList.remove(`${styles.bgBody}`)
		}
	}, [])

	const onLogin = data => {
		setLoading(true)
		dispatch(
			loginUser({
				phone: data.phone,
				password: data.password
			})
		)
			.then(() => {
				setLoading(false)
				// reset the form
				reset()
				history.push(history?.location?.state ? history?.location?.state?.url : "/")
			})

			.catch(error => {
				setLoading(false)
				toast({
					title: error,
					status: "error",
					duration: 3000,
					isClosable: true
				})
			})
	}
	const [isTooltipHidden, setTooltip] = useState(true)

	const onError = (errors, e) => console.log(errors, e)

	const [passwordVisible, setPasswordVisible] = useState(false)
	const togglePasswordVisibility = () => {
		setPasswordVisible(prevVisible => !prevVisible)
	}

	return (
		<>
			{/* <Helmet>
				<title>Login | Curlys Unisex Hair & Makeup Studio</title>
			</Helmet> */}

			<div className={`h-full w-full flex items-center ${styles.bgSection}`}>
				{/* <PageHeader title="Login to Curly's" description='With some meta description' /> */}
				<Container
					className={`md:px-5 sm:px-2 mx-auto flex sm:flex-nowrap flex-wrap  ${styles.container} container  `}>
					<div className='order-2  w-1/2 overflow-hidden sm:mr-10  items-center justify-center relative hidden md:flex'>
						<img src='img/register-img.png' alt='' />
					</div>
					<div className={`order-1 md:w-1/2 w-full flex flex-col mx-auto md:py-8 md:mt-0 ${styles.card} `}>
						<div className={`shadow-slate-999 mx-auto p-10 ${styles.Container}`}>
							<p className='text-center font-semibold text-3xl text-black-3xl mb-8'>Login</p>
							<form className={styles.formArea} noValidate onSubmit={handleSubmit(onLogin, onError)}>
								<FormControl
									isInvalid={touchedFields?.phone && errors?.phone}
									id='phone'
									className='mb-2'>
									{" "}
									<FormLabel>Phone Number</FormLabel>
									<Input {...register("phone")} type='tel' />
									<FormErrorMessage>{errors?.phone?.message}</FormErrorMessage>
									<FormHelperText>We'll never share your phone number</FormHelperText>
								</FormControl>

								{/* <FormControl
									isInvalid={touchedFields?.password && errors?.password}
									id='password'
									className='mb-2'>
									<FormLabel>Password</FormLabel>
									<Input {...register("password")} type='password' />
									<FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
								</FormControl> */}
								<FormControl
									isInvalid={touchedFields?.password && errors?.password}
									id='password'
									className='mb-2 relative'>
									<FormLabel>Password</FormLabel>
									<Input {...register("password")} type={passwordVisible ? "text" : "password"} />
									<FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
									<IconButton
										icon={passwordVisible ? <ViewIcon /> : <ViewOffIcon />}
										onClick={togglePasswordVisibility}
										className='right-0 top-8 eyeIcon-borderd'
										aria-label={passwordVisible ? "Hide password" : "Show password"}
										position='absolute'
										zIndex='1'
										color={passwordVisible ? "blue.500" : "gray.500"}
									/>
								</FormControl>

								<div className='mt-4 relative'>
									<div className='flex justify-between'>
										<Button
											onClick={() => history.push("/")}
											className={`lg:w-1/3 md:1/2 mt-4 bg-gold text-white  hover:no-underline text-center max-w-full`}>
											{" "}
											<i className='bx bxs-home'></i> &nbsp; Go To Home{" "}
										</Button>
										<Button
											className='lg:w-1/3 md:1/2 bg-black-3xl text-white mt-4 px-7 max-w-full'
											disabled={Object.keys(errors).length}
											isLoading={loading}
											type='submit'>
											Login
										</Button>
									</div>

									<div className='mt-4 sm:mt-4 flex justify-between flex-wrap items-center'>
										<Text>
											Need a new account?
											<span
												className='text-blue-700 ml-2 cursor-pointer'
												onClick={() => {
													history.location.state = {...history.location.state}
													history.push({
														pathname: "/register",
														state: {...history.location.state}
													})
												}}>
												Register Now
											</span>
										</Text>
										<Text
											className={`flex items-center ${styles.forgotTooltip}`}
											onClick={() => (isTooltipHidden ? setTooltip(false) : setTooltip(true))}>
											<em className='bx bxs-lock-open text-2xl text-blue-600'></em>
											<span className='text-blue-700 ml-1 cursor-pointer'>Forgot Password</span>
										</Text>
										<div className={`${styles.toolTip} ${isTooltipHidden ? "hidden" : "block"}`}>
											<div className='flex'>
												<p>
													You can email/whatsapp your registered phone no to{" "}
													<a
														href='mailto:hellocurlys@gmail.com'
														target='_blank'
														rel='noreferrer'>
														hellocurlys@gmail.com
													</a>
													/
													<a href='https://wa.me/9354721082' target='_blank' rel='noreferrer'>
														9354721082
													</a>{" "}
													to reset password.
												</p>
												<em
													className='text-xl pl-3 bx bx-x'
													onClick={() => setTooltip(true)}></em>
											</div>
										</div>
									</div>
								</div>
								{/* <div className={`${styles.btnHome} w-full`}>
									<Link as={RouterLink} to='/'>
										{" "}
										<Button
											className={`mt-4 bg-gold text-white w-full hover:no-underline text-center`}>
											{" "}
											<i className='bx bxs-home'></i> &nbsp; Go To Home{" "}
										</Button>
									</Link>
								</div> */}
							</form>
						</div>
					</div>
				</Container>
			</div>
		</>
	)
}

export default Login


// import React, { useEffect, useState } from "react";
// import { FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Button, Text, useToast } from "@chakra-ui/react";
// import * as Yup from "yup";
// import { useForm } from "react-hook-form";
// import { useDispatch } from "react-redux";
// import Container from "../../components/Container";
// import { useYupValidationResolver } from "../../hooks/useValidationResolver";
// import { loginUser } from "../../store/actions/auth";
// import styles from "./style.module.css";
// import { IconButton } from "@chakra-ui/react";
// import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

// const validationSchema = Yup.object({
//   phone: Yup.number().typeError("Phone must be a number").required("Phone number is required!"),
//   password: Yup.string().required("Password is required!"),
// });

// const Login = ({ history }) => {
//   const toast = useToast();
//   const dispatch = useDispatch();
//   const [loading, setLoading] = useState(false);
//   const resolver = useYupValidationResolver(validationSchema);
//   const {
//     register,
//     handleSubmit,
//     formState: { errors, touchedFields },
//     reset,
//   } = useForm({
//     resolver,
//     mode: "onBlur",
//   });

//   const onLogin = (data) => {
//     setLoading(true);
//     dispatch(
//       loginUser({
//         phone: data.phone,
//         password: data.password,
//       })
//     )
//       .then(() => {
//         setLoading(false);
//         // reset the form
//         reset();
//         history.push(history?.location?.state ? history?.location?.state?.url : "/");
//       })
//       .catch((error) => {
//         setLoading(false);
//         toast({
//           title: error,
//           status: "error",
//           duration: 3000,
//           isClosable: true,
//         });
//       });
//   };

//   const [isTooltipHidden, setTooltip] = useState(true);
  
//   const toggleTooltipVisibility = () => {
// 	setTooltip(!isTooltipHidden);
//   };

//   const [passwordVisible, setPasswordVisible] = useState(false);
//   const togglePasswordVisibility = () => {
//     setPasswordVisible((prevVisible) => !prevVisible);
//   };

//   return (
//     <div className={`h-full w-full flex items-center ${styles.bgSection}`}>
//       <Container
//         className={`md:px-5 sm:px-2 mx-auto flex sm:flex-nowrap flex-wrap  ${styles.container} container  `}
//       >
//         <div className="order-2 w-1/2 overflow-hidden sm:mr-10  items-center justify-center relative hidden md:flex">
//           <img src="img/register-img.png" alt="" />
//         </div>
//         <div className={`order-1 md:w-1/2 w-full flex flex-col mx-auto md:py-8 md:mt-0 ${styles.card}`}>
//           <div className={`shadow-slate-999 mx-auto p-10 ${styles.Container}`}>
//             <p className="text-center font-semibold text-3xl text-black-3xl mb-8">Login</p>
//             <form className={styles.formArea} noValidate onSubmit={handleSubmit(onLogin)}>
//               <FormControl isInvalid={touchedFields?.phone && errors?.phone} id="phone" className="mb-2">
//                 <FormLabel>Phone Number</FormLabel>
//                 <Input {...register("phone")} type="tel" />
//                 <FormErrorMessage>{errors?.phone?.message}</FormErrorMessage>
//                 <FormHelperText>We'll never share your phone number</FormHelperText>
//               </FormControl>
//               <FormControl isInvalid={touchedFields?.password && errors?.password} id="password" className="mb-2 relative">
//                 <FormLabel>Password</FormLabel>
//                 <Input {...register("password")} type={passwordVisible ? "text" : "password"} />
//                 <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
//                 <IconButton
//                   icon={passwordVisible ? <ViewIcon /> : <ViewOffIcon />}
//                   onClick={togglePasswordVisibility}
//                   className="right-0 top-8 eyeIcon-borderd"
//                   aria-label={passwordVisible ? "Hide password" : "Show password"}
//                   position="absolute"
//                   zIndex="1"
//                   color={passwordVisible ? "blue.500" : "gray.500"}
//                 />
//               </FormControl>
//               <div className="mt-4 relative">
//                 <div className="flex justify-between">
//                   <Button onClick={() => history.push("/")} className={`lg:w-1/3 md:1/2 mt-4 bg-gold text-white  hover:no-underline text-center max-w-full`}>
//                     <i className="bx bxs-home"></i> &nbsp; Go To Home
//                   </Button>
//                   <Button className="lg:w-1/3 md:1/2 bg-black-3xl text-white mt-4 px-7 max-w-full" disabled={Object.keys(errors).length} isLoading={loading} type="submit">
//                     Login
//                   </Button>
//                 </div>
//                 <div className="mt-4 sm:mt-4 flex justify-between flex-wrap items-center">
//                   <Text>
//                     Need a new account?
//                     <span className="text-blue-700 ml-2 cursor-pointer" onClick={() => history.push("/register")}>
//                       Register Now
//                     </span>
//                   </Text>
//                   <Text className={`flex items-center ${styles.forgotTooltip}`} onClick={() => (isTooltipHidden ? setTooltip(false) : setTooltip(true))}>
//                     <em className="bx bxs-lock-open text-2xl text-blue-600"></em>
//                     <span className="text-blue-700 ml-1 cursor-pointer">Forgot Password</span>
//                   </Text>
//                   <div className={`${styles.toolTip} ${isTooltipHidden ? "hidden" : "block"}`}>
//                     <div className="flex">
//                       <p>
//                         You can email/whatsapp your registered phone no to{" "}
//                         <a href="mailto:hellocurlys@gmail.com" target="_blank" rel="noreferrer">
//                           hellocurlys@gmail.com
//                         </a>
//                         /
//                         <a href="https://wa.me/9354721082" target="_blank" rel="noreferrer">
//                           9354721082
//                         </a>{" "}
//                         to reset password.
//                       </p>
//                       <em className="text-xl pl-3 bx bx-x" onClick={() => setTooltip(true)}></em>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>
//       </Container>
//     </div>
//   );
// };

// export default Login;
