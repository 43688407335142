import React, {useState} from "react"
import Header from "../../components/Header"
import "./index.css"
import styles from "../OffersFrontend/index.module.css"
import {Link} from "@chakra-ui/react"
import GetAppointmentModal from "../../components/Modals/GetAppointmentModal"

const Nails = () => {
	const [isExpanded, setIsExpanded] = useState(false)

	const handleToggle = () => {
		setIsExpanded(!isExpanded)
	}

	return (
		<>
		<span id="nails"></span>
			<Header title='Nails | Curlys Unisex Hair & Makeup Studio' />
			<section 
				className={`bg-Nails-banner bg-cover flex items-center justify-center relative z-10 h-80 ${styles.bannerSection} `}
				style={{backgroundPosition: "0 90%"}}>
				<div className='container '>
					<div className='w-full text-center relative z-20 text-4xl font-bold text-white'>
						<h3 className='border-b-4 border-white inline pb-1'>Nails</h3>
					</div>
				</div>
			</section>

			<section className='aboutCurliesSec u-section-1-main text-gray-600 body-font  md:py-16 py-6'>
				<div className='u-expanded-height-main u-shape-1-mian'></div>

				<div className=' mx-auto flex container container-view-first-sec  lg:flex-row flex-col items-center'>
					<div className='lg:flex-grow lg:w-1/2 lg:pr-24  flex flex-col md:items-start md:text-left mb-0 md:mb-0 items-center text-center'>
						{/* <div className='inline-block mb-4'>
							<h1 className='title-font mb-3 font-bold sm:text-4xl text-2xl   text-gray-800'>
								{" "}
								<span className='text-t-gold'>WELCOME</span>
							</h1>
							<img src='/img/barber.png' alt='barberFotter' />
						</div> */}
						<span className='text-black font-semibold mb-3 text-2xl'>WELCOME</span>
						<h1 className='mb-4 font-semibold sm:text-xl font-line-nails-head  text-t-gold'>
							Nails and Extension Studio in Curly's
						</h1>
						<p className='mb-8 leading-relaxed '>
							Welcome to Curly's Nails and Extensions Studio, your go-to destination for flawless nails
							and beautiful extensions. Our studio offers a wide range of nail services, including nail
							extensions, manicures, pedicures, nail art, and more. With our skilled technicians and
							high-quality products, we guarantee exceptional craftsmanship and stunning results. Step
							into Curly's Nails and Extensions Studio and let us pamper you with our expertise, leaving
							you with gorgeous nails that will make heads turn.
							<br />
						</p>
						<h6 className='mb-4 font-medium sm:text-xl text-lg  '>Best Nails Brand in Curly's</h6>
						{/* <button className='readMore-btn'>Read More</button> */}
					</div>

					<div className='lg:max-w-lg  lg:w-1/2 flex justify-center items-center bg-img-yellow'>
						<div className='bg-nails-img'>
							<img
								className='object-cover img-height-nails1 object-center rounded'
								alt='about-png'
								src='/img/nail.jpg'
							/>
						</div>
					</div>
				</div>
			</section>

			<section id="nailsExtensionArt" className='md:py-16 py-6 containerbgImgNails'>
				<div className='container container-view-first-sec '>
					<div className='flex p-2 items-center bookNailsBtn'>
						<h1 className='text-4xl text-white font-extrabold capitalize getTouchText ml-auto'>
							to Get in Touch with us
							{/* To Make Requests For The Further Information */}
						</h1>
						<GetAppointmentModal book='Book An Appointment' />
					</div>
				</div>
			</section>

			<section className='text-gray-600 body-font container-view-second-sec custom-shape-divider-top-tried '>
				<svg
					data-name='Layer 1'
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 1200 120'
					preserveAspectRatio='none'>
					<path
						d='M1200,0H0V120H281.94C572.9,116.24,602.45,3.86,602.45,3.86h0S632,116.24,923,120h277Z'
						className='shape-fill'></path>
				</svg>

				<div  className=' mx-auto card-common-div flex relative container lg:flex-row flex-col items-center'>
					<div className='card-one-nails card-zoom-effect-nails'>
						<div className='card-inside-div w-24 h-24  justify-center m-auto'>
							<img
								className='object-cover p-4 card-img-1 object-center rounded-full'
								alt='about-png'
								src='/img/nailssvg1.png'
							/>
						</div>
						<span className='text-black font-normal extensionname uppercase mb-3'>Nail Art</span>
						<p className='justify-center text-center font-semibold italic text-xl w-10/12 flex m-auto'>
							Nail art is an exciting form of self-expression, and at Curly's nail art creations, we are
							passionate about helping you showcase your unique style through your manicure.
						</p>
						{/* <Link to='/nails'>
							<span className='mt-5 justify-center underline text-center text-xl font-semibold w-full flex m-auto'>
								MORE
							</span>
						</Link> */}
					</div>

					<div className='card-second-nails card-zoom-effect-nails'>
						<div className='card-inside-div w-24 h-24  justify-center m-auto'>
							<img
								className='object-cover card-img-1 p-4 object-center rounded-full'
								alt='about-png'
								src='/img/nailsscg2.png'
							/>
						</div>
						<span className='text-black font-normal extensionname uppercase mb-3'>Nail Extension</span>
						<p className='justify-center text-center font-semibold italic text-xl w-10/12 flex m-auto'>
							Transform your nails with stunning nail extensions at Curly's and elevate your style to new
							heights for an instant boost of elegance and style .
						</p>
						{/* <Link to='/nails'>
							<span className='mt-5 justify-center underline text-center text-xl font-semibold w-full flex m-auto'>
								MORE
							</span>
						</Link> */}
					</div>
				</div>
			</section>

			<section   className='text-gray-600 body-font container-view-first-sec container md:py-16 py-6 m-auto'>
				<div className='m-auto'>
					<div className='justify-center m-auto'>
						<div className='justify-center img-height-facenails m-auto'>
							<img
								className='object-cover card-img-1 object-center rounded-full'
								alt='about-png'
								src='/img/nailssvg4.jpg'
							/>
						</div>
						<h1 className='my-5 text-3xl font-medium uppercase text-center '>ABOUT OUR SALON</h1>
						<div className='justify-center flex w-full'>
							<p className='py-5 text-5xl text-black about-text-parah-nail font-normal  text-center'>
								Our mission is to inspire, inform and educate our clients about the healthier
								alternatives to Nails styling.
							</p>
						</div>
						<div className='justify-center flex w-full'>
							<p
								className={
									isExpanded
										? "expanded py-5 text-xl text-black  font-normal text-center"
										: "py-5 text-xl text-black  font-normal text-center collapsed"
								}>
								At Curly's, our mission is to inspire, inform, and educate our valued clients about
								healthier alternatives to nail styling. We believe in promoting nail care practices that
								prioritize the well-being of your nails and overall health. Our dedicated team is
								passionate about providing you with the knowledge and resources to make informed choices
								that align with your personal wellness goals. We offer a range of healthier options,
								such as natural nail care, non-toxic polishes, and nail treatments that nourish and
								strengthen your nails. Join us at Curly's as we empower you to embrace a healthier
								approach to nail styling without compromising on style and beauty. Our ultimate goal is
								to ensure that our clients leave our salon not only with stunning nails but also with a
								deeper understanding of nail health and the tools to maintain it. Curly's is your
								destination for beautiful, stylish, and healthier nails.
								{isExpanded && (
									<span className='py-5 text-xl text-black  font-normal text-center'>
										Our skilled nail technicians are well-versed in the latest trends and techniques
										in nail art. Whether you desire intricate designs, delicate patterns, or bold
										and vibrant colors, we can bring your vision to life. From geometric patterns to
										floral motifs, we can customize your nail art to match your bridal theme and
										personal style. If you're looking to enhance the length and shape of your nails,
										our nail extension services are perfect for you. We offer a variety of extension
										options, including gel, acrylic, or even natural-looking extensions. Our
										experienced technicians will meticulously apply the extensions, ensuring they
										blend seamlessly with your natural nails.
									</span>
								)}
							</p>
						</div>

						<div className='justify-center flex w-full'>
							<button className='readMore-btn my-5 text-2xl px-8 py-3' onClick={handleToggle}>
								{isExpanded ? "Read Less" : "Read More"}
							</button>
						</div>
					</div>
				</div>
			</section>

			<section className='text-gray-600 body-font overflow-hidden book-bg-nails focus-within:'>
				<div className='container px-5 sm:py-24 py-12 mx-auto flex flex-wrap'>
					<div className='lg:w-1/2 mx-auto hidden lg:block img-overlay relative container-Anim'>
						<img
							alt='ecommerce'
							className='w-full object-cover object-center rounded image'
							src='img/nailsBigImg.jpg'
						/>
					</div>
					<div className=' lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-0 z-10'>
						<div className='col-wrap bg-white text-center appointmentdiv lg:-ml-28 lg:mr-28 lg:mt-20 sm:p-28 p-12'>
							<h2 className='text-xl mb-7 title-font text-gray-500 tracking-widest'>Appointment</h2>
							<h1 className='text-gray-900 sm:text-4xl text-3xl mb-7 title-font font-medium'>
								BOOK TIME TO GET APPOINTMENT
							</h1>

							<p className='leading-relaxed  mb-7'>
								We’re so excited to have you in Curly's. You can contact us for special offers,
								packages, and many more. call/Whatsapp us at{" "}
								<a className='font-bold' href='tel:+91-70150-36702'>
									+91-70150-36702
								</a>{" "}
								or email us{" "}
								<a className='font-bold' href='mailto:hellocurlys@gmail.com'>
									hellocurlys@gmail.com
								</a>{" "}
								Click button below to Book Appointment .”
							</p>

							<div className='flex'>
								<GetAppointmentModal
									book='Book Now'
									spanText='text-sm'
									className='bg-black-2xl inline-block cursor-pointer mx-auto whitespace-nowrap border-2 border-black-border   transition delay-150 ease-in-out  p-3 px-7 text-white text-sm'
								/>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='section'>
				<div className='container mx-auto max-w-7xl py-10 px-3 container-parah-text'>
					<p className='text-center text-xl'>
						Congratulations on finding your perfect partner! At Curly's Unisex Hair & Makeup Studio, we
						understand that your wedding day is a special occasion where every detail counts, including your
						nails. That's why we offer top-notch nail art and nail extension services to ensure your hands
						look stunning. Our skilled nail technicians are well-versed in the latest trends and techniques
						in nail art. Whether you desire intricate designs, delicate patterns, or bold and vibrant
						colors, we can bring your vision to life. From geometric patterns to floral motifs, we can
						customize your nail art to match your bridal theme and personal style. If you're looking to
						enhance the length and shape of your nails, our nail extension services are perfect for you. We
						offer a variety of extension options, including gel, acrylic, or even natural-looking
						extensions. Our experienced technicians will meticulously apply the extensions, ensuring they
						blend seamlessly with your natural nails. At Curly's Unisex Hair & Makeup Studio, we prioritize
						the health and integrity of your nails. We use high-quality products and follow strict hygiene
						protocols to ensure your safety and satisfaction. On your special day, let us take care of your
						nail art and extensions, ensuring your hands are as beautiful as you are. Trust in our expertise
						and attention to detail to provide you with the perfect finishing touch for your wedding
						ensemble.
					</p>
				</div>
			</section>

			<section className='section-last-nail py-10 px-3'>
				<div className='container container-view-first-sec mx-auto'>
					<div className='lg:flex my-10 bg-white shadow-xl'>
						<div
							className='md:w-2/5 w-full heightViewNails bg-white md:mr-5 mb-5 md:mb-0'
							height='479px'
							width='479px'>
							<img src='img/nailsjp.jpg' alt='' className=' nails-img-h' />
						</div>

						<div className='md:w-3/5 w-full mx-auto flex p-5 justify-center nails-ritten-parah bg-white '>
							<div className='wrapper w-4/5'>
								<h1 className='text-4xl mb-5 font-semibold text-about-nails-parah '>
									By implementing our tools, We are best at providing:
								</h1>
								<ul className='unorder-list-nails'>
									<li className='lits-style-nails'>Best Services </li>
									<li className='lits-style-nails'>Customization Options</li>
									<li className='lits-style-nails'>Vast Design Ideas</li>
									<li className='lits-style-nails'>The Frequent and Economical</li>
									<li className='lits-style-nails'>The Greatfull views</li>
									<li className='lits-style-nails'>Comfortable and Cleanable Environment</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
export default Nails
