import React, {useState} from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	IconButton,
	useToast,
	Stack,
	FormLabel,
	FormControl,
	FormErrorMessage,
	Input,
	Textarea,
	Select
} from "@chakra-ui/react"
import * as Yup from "yup"
import {useForm} from "react-hook-form"
import {useDispatch} from "react-redux"
import {useYupValidationResolver} from "../../../hooks/useValidationResolver"
import EditIcon from "../../Icons/EditIcon"
import {updateSalary} from "../../../store/actions/salary"

const validationSchema = Yup.object({
	date: Yup.string().required("Date is required!"),
	//staff: Yup.string().required("Select staff"),
	// amount: Yup.string().required("Amount is required!"),
	comment: Yup.string()
})
const EditSalaryModal = ({editSalary, getStaffUser, user ,getSalary}) => {
	const dispatch = useDispatch()
	const toast = useToast()
	const {isOpen, onOpen, onClose} = useDisclosure()
	const [loading, setLoading] = useState(false)

	const salaryObject = {
		date: editSalary?.date,
		staff: editSalary?.staff?.id,
		amount: editSalary?.amount,
		comment: editSalary?.comment,
		online_amount : editSalary?.online_amount
	}

	const resolver = useYupValidationResolver(validationSchema)

	const {
		register,
		handleSubmit,
		formState: {errors, touchedFields},
		reset
	} = useForm({
		defaultValues: salaryObject,
		resolver,
		mode: "onBlur"
	})

	const onUpdateExpense = data => {
		setLoading(true)
		dispatch(
			updateSalary(
				{
					id: editSalary.id,
					date: data.date,
					staff: data.staff ? `${data.staff}` : "179",
					amount: data.amount||0,
					comment: data.comment,
					online_amount : data.online_amount||0
				},
				data.id
			)
		)
			.then(() => {
				setLoading(false)
				toast({
					title: "Salary has been updated!",
					status: "success",
					duration: 3000,
					isClosable: true
				})
				getSalary();
				// reset the form
				reset()
				onClose()
			})
			.catch(err => {
				setLoading(false)
				console.log("ERR => while adding Salary", err)
			})
	}

	return (
		<>
			<IconButton
				className='mr-3'
				colorScheme='green'
				aria-label='Edit Expense'
				onClick={onOpen}
				disabled={user.user_type === 1 ? false : true}
				icon={<EditIcon />}
				size='sm'
			/>
			<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
				<ModalOverlay />
				<form
					style={{overflow: "auto"}}
					noValidate
					onSubmit={handleSubmit(onUpdateExpense)}
					className='bg-white'>
					<ModalContent>
						<ModalHeader className='capitalize bg-gold text-center text-2xl font-medium text-white'>
							Update Salary
						</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Stack spacing={3}>
								<FormControl isInvalid={touchedFields?.date && errors?.date} id='date' isRequired>
									<FormLabel>Date</FormLabel>
									<Input {...register("date")} placeholder='Select date' type='date' />
									<FormErrorMessage>{errors?.date?.message}</FormErrorMessage>
								</FormControl>

								<FormControl isInvalid={touchedFields?.staff && errors?.staff} id='staff'>
									<FormLabel>Staff</FormLabel>

									<Select {...register("staff")} placeholder='select Staff'>
										<option value={editSalary?.staff?.id}>{editSalary?.staff?.name}</option>
										{getStaffUser.length > 0 &&
											getStaffUser.map((v, i) => (
												<option key={i} value={v.id}>
													{v.name}
												</option>
											))}
									</Select>

									<FormErrorMessage>{errors?.staff?.message}</FormErrorMessage>
								</FormControl>

								<FormControl
									isInvalid={touchedFields?.amount && errors?.amount}
									id='amount'
									className='mb-3'
									>
									<FormLabel>Amount (cash)</FormLabel>
									<Input {...register("amount")} placeholder='Enter Cash Amount' type='number' min='0' />
									<FormErrorMessage>{errors?.amount?.message}</FormErrorMessage>
								</FormControl>
 
								<FormControl
									isInvalid={touchedFields?.online_amount && errors?.online_amount}
									id='online_amount'
									className='mb-3'
									>
									<FormLabel>Amount (online)</FormLabel>
									<Input {...register("online_amount")} placeholder='Enter Online Amount' type='number' min='0' />
									<FormErrorMessage>{errors?.online_amount?.message}</FormErrorMessage>
								</FormControl>

								<FormControl isInvalid={touchedFields?.comment && errors?.comment} id='comment'>
									<FormLabel>Comment</FormLabel>
									<Textarea
										rows='4'
										{...register("comment")}
										placeholder='Enter comment...'
										type='text'
									/>
									<FormErrorMessage>{errors?.comment?.message}</FormErrorMessage>
								</FormControl>
							</Stack>
						</ModalBody>

						<ModalFooter>
							<Button mr={3} onClick={onClose}>
								Close
							</Button>
							<Button isLoading={loading} type='submit' colorScheme='green' className='bg-gold'>
								Update
							</Button>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</>
	)
}

export default EditSalaryModal
