export const DEFAULT_HEADERS = {
  headers: {
    "Content-Type": "application/json",
    // responseType: 'json',
  },
};
export const MULTIPART_FORM_DATA_HEADERS = {
  headers: {
    "Content-Type": "multipart/form-data",
    // responseType: 'json',
  },
};
