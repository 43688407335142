import React, {  useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Stack,
  Input,
  FormLabel,
  FormControl,
  IconButton,
} from "@chakra-ui/react";
// import { useForm } from "react-hook-form";
// import { useYupValidationResolver } from "../../../hooks/useValidationResolver";
// import ReactQuill from "react-quill";
import {  IoMdCheckboxOutline } from "react-icons/io";
// const validationSchema = Yup.object({
//   name: Yup.string().required("Name is required!"),
//   price: Yup.number()
//     .typeError("Price must be a number")
//     .required("Price is required.")
//     .min(0, "Too little")
//     .max(5000, "Very costly!"),
// });




// const i = JSON.parse(localStorage.getItem("product"));

const EditProductModal = ({setProductDetail, editProduct , index}) => {  

  // const [priceValue , setPriceValue] = useState(editProduct.price);
  // const [commentValue, setCommentValue] = React.useState(editProduct.comment);
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [datas, setDatas] = useState("");
  // const resolver = useYupValidationResolver(validationSchema);

  // const {
  //   register,
  //   // handleSubmit,
  //   formState: { errors, touchedFields }, 
  //   reset,
  // } = useForm({
  //   resolver,
  //   defaultValues: editProduct,
  //   shouldUseNativeValidation: false,
  //   mode: "onBlur",
  // });


  const [editObj,setEditObj] = useState({
    name : editProduct.name,
    price : editProduct.price,
    comment : editProduct.comment
  })
  

  // const onUpdateProduct = (data) => {
  //   setDatas(productDetail);
  //   Object.assign(data, { comment: commentValue });
  //   const a = productDetail.map(function (p) {
  //     if (p.name === datas.name || p.price === datas.price) {
  //       return {
  //         ...p,
  //         name: data.name,
  //         price: data.price,
  //         comment: data.comment,
  //       };
  //     }
  //   });
  //   onClose();
  //   console.log("a", data);
  //   setProductDetail(a); 
  // };



  const changHandler = (e)=>{
    setEditObj((prev)=>({
      ...prev,
      [e.target.name]  : e.target.value
    }))
  }

  const handleSubmit = (e)=>{
    e.preventDefault();
    setProductDetail((prev)=>{
      let data = [...prev];
      data[index] = editObj;
      return data
});
onClose();
  }

  // const onError = (errors, e) => console.log(errors, e);

  return (
    <>
      <>
        <IconButton
          colorScheme="blue.100"
          color="black"
          icon={<IoMdCheckboxOutline />}
          type="button"
          onClick={() => {
            onOpen();
            localStorage.setItem("product", JSON.stringify(editProduct));
          }}
          className="w-16"
        />

        <Modal
          isOpen={isOpen}
          onClose={onClose}
          scrollBehavior="inside"
          size="xl"
        >

          <ModalOverlay />
          <form
            style={{ overflow: "auto" }}
            className="bg-white"
          >
            <ModalContent>
              <ModalHeader>Edit Product</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <Stack spacing={4}>
                <FormLabel>Name</FormLabel> 
       <FormControl> 
                     <Input
                       name="name"
                      placeholder="Enter Name"
                      type="text"
                      value={editObj.name}
                      onChange={changHandler}
                    />
                    </FormControl>

                    <FormControl>
                     <Input
                       name="price"
                      placeholder="Enter Price"
                      onWheel={ event => event.currentTarget.blur() }
                      type="number"
                      value={editObj.price}
                      onChange={changHandler}
                    />
                    </FormControl>


                    <FormControl>
                     <Input
                       name="comment"
                      type="text"
                      value={editObj.comment}
                      onChange={changHandler}
                    />
                    </FormControl>

                  {/* <FormControl
                    isInvalid={touchedFields?.name && errors?.name}
                    id="name"
                   >
                    <FormLabel>Name</FormLabel>
                    <Input
                      {...register("name")}
                      placeholder="Enter Name"
                      type="text"
                    />
                    <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={touchedFields?.price && errors?.price}
                    id="price"
                    className="mb-3"
                  >
                    <FormLabel>Price</FormLabel>
                    <Input
                      {...register("price")}
                      placeholder="Enter Price"
                      type="number"
                    />
                    <FormErrorMessage>
                      {errors?.price?.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={touchedFields?.comment && errors?.comment}
                    id="comment"
                  >
                    <FormLabel>Comment</FormLabel>

                    <Input
                      {...register("comment")}
                      placeholder="Enter Price"
                      type="text"
                    />
                    <ReactQuill
                      {...register("comment")}
                      value={commentValue}
                      onChange={setCommentValue}
                    />
                    <FormErrorMessage>
                      {errors?.comment?.message}
                    </FormErrorMessage>
                  </FormControl> */}

                  
                </Stack>
              </ModalBody>

              <ModalFooter>
                <Button className="mr-4" onClick={onClose}>
                  Close
                </Button>
                <Button  onClick={handleSubmit} colorScheme="blue" type="submit">
                  Update
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      </>
    </>
  );
};

export default EditProductModal;
