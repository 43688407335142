import { CREATE_ORDER, ORDER_LIST, SINGLE_ORDER } from "../constants/order";

const initialState = {
  createOrder: "",
  orderList: "",
  singleOrder: "",
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ORDER:
      return {
        ...state,
        createOrder: action.payload,
      };
    case SINGLE_ORDER:
      return {
        ...state,
        singleOrder: action.payload,
      };
    case ORDER_LIST:
      return {
        ...state,
        orderList: action.payload,
      };
    default:
      return state;
  }
};
