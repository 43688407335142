import React, {useRef, useEffect, useState} from "react"
import "./index.css"

const EmailSend = () => {
	const dropdownBtnRef = useRef(null)
	const dropdownContentRef = useRef(null)
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	useEffect(() => {
		const dropdownBtn = dropdownBtnRef.current
		const dropdownContent = dropdownContentRef.current

		const openMenu = () => {
			setIsMenuOpen(true)
		}

		const closeMenu = () => {
			setIsMenuOpen(false)
		}

		dropdownBtn.addEventListener("mouseenter", openMenu)
		dropdownBtn.addEventListener("mouseleave", closeMenu)
		dropdownContent.addEventListener("mouseenter", openMenu)
		dropdownContent.addEventListener("mouseleave", closeMenu)

		return () => {
			dropdownBtn.removeEventListener("mouseenter", openMenu)
			dropdownBtn.removeEventListener("mouseleave", closeMenu)
			dropdownContent.removeEventListener("mouseenter", openMenu)
			dropdownContent.removeEventListener("mouseleave", closeMenu)
		}
	}, [])

	return (
		<div className=''>
			<button
				ref={dropdownBtnRef}
				className='dropdown-btn'
				aria-label='menu button'
				aria-haspopup='menu'
				aria-expanded={isMenuOpen ? "true" : "false"}
				aria-controls='dropdown-menu'>
				<em className='bx emailSent bxl-gmail mx-1'></em>
			</button>

			<ul
				ref={dropdownContentRef}
				className={`dropdown-content-email ${isMenuOpen ? "menu-open" : ""}`}
				role='menu'
				id='dropdown-menu'>
				<li style={{"--delay": 3}}>
					<a href='mailto:hellocurlys@gmail.com' className='text-white whitespace-nowrap flex items-center'>
						<span>hellocurlys@gmail.com</span>
					</a>
				</li>
			</ul>
		</div>
	)
}

export default EmailSend
