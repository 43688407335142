// import axios from "axios";
import axios from "../../utils/axios"
import {DEFAULT_HEADERS} from "../../utils"
import {
	GET_EMP_USER_ENDPOINT,
	GET_INVOICE_USER_ENDPOINT,
	GET_ALL_USERS,
	GET_STAFF_USER,
	GET_BIRTHDAYS_OF_USERS
} from "../../constants/api"
import {GET_EMP_USER, GET_INVOICE_USER, GET_ALL_USER, GET_STAFF_USERS, BIRTHDAY_USERS} from "../constants/getUser"

export const getInvoiceUser = () => dispatch => {
	return new Promise(async (resolve, reject) => {
		try {
			const {data} = await axios.get(GET_INVOICE_USER_ENDPOINT, null, DEFAULT_HEADERS)
			dispatch({
				type: GET_INVOICE_USER,
				payload: data.users
			})
			resolve()
		} catch (error) {
			reject(error)
		}
	})
}
export const getEmployeeUser = () => dispatch => {
	return new Promise(async (resolve, reject) => {
		try {
			const {data} = await axios.get(GET_EMP_USER_ENDPOINT, null, DEFAULT_HEADERS)
			dispatch({
				type: GET_EMP_USER,
				payload: data.users
			})
			resolve()
		} catch (error) {
			reject(error)
		}
	})
}

export const getAllUsers = filters => {
	return async dispatch => {
		const {data} = await axios.get(`${GET_ALL_USERS}?search=${filters.searchTxt}&limit=10&page=${filters.page}`)

		dispatch({
			type: GET_ALL_USER,
			payload: data
		})
	}
}

export const getStaffUserList = () => {
	return async dispatch => {
		const {data} = await axios.get(GET_STAFF_USER);

		dispatch({
			type: GET_STAFF_USERS,
			payload: data
		})
	}
}

export const getBirthdayUsers = ({start_date , end_date}) => {
	return async dispatch => {
		const {data} = await axios.get(`${GET_BIRTHDAYS_OF_USERS}?start_date=${start_date}&end_date=${end_date}`)
		dispatch({
			type: BIRTHDAY_USERS,
			payload: data.data
		})
	}
}
