import React from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button
} from "@chakra-ui/react"
import {Table, Thead, Tbody, Tr, Th, Td, Box} from "@chakra-ui/react"

const OrderListModal = ({bookingData}) => {
	const {isOpen, onOpen, onClose} = useDisclosure()

	let services
	let stringservices
	let allProductsSubTotal
	let allProductsDiscount
	let allProductsTotal
	try {
		let servicesData =  JSON.parse(bookingData.services)
		services = JSON.parse(servicesData.service);
		allProductsSubTotal = services.reduce((a, v) => (a = parseInt(a) + parseInt(v.price) * parseInt(v.quantity)), 0)
		allProductsDiscount = 	services.reduce(
			(a, v) => (a = parseInt(a) + (parseInt(v.discount)) * parseInt(v.quantity)),
			0
		  )
		allProductsTotal = allProductsSubTotal - allProductsDiscount

		//allProductsTotal = services.reduce((a, v) => (a = parseInt(a) + parseInt(v.price) - parseInt(v.discount)), 0)
	} catch {
		stringservices = bookingData.services
	}

	let advance_payment = services  && services.reduce((acc , curr)=> acc + parseInt(curr?.advance_payment),0);

	// console.log( "fdafsidfhiafiokdsknak " , services)
	return (
		<>
			<span onClick={onOpen} className='cursor-pointer px-1 rounded text-t-gold'>
				Click Here
			</span>
			<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
				<ModalOverlay className='bg-gold-701 modalOverlay' />
				<ModalContent className='max-w-3xl'>
					<ModalHeader className='capitalize bg-gold text-center sm:text-2xl text-base font-medium text-white'>
						Appointment Details
					</ModalHeader>
					<ModalCloseButton className='text-white' />
					<ModalBody>
						<div className='flex-1'>
							<Box overflowX='auto' w='100%'>
								<Table className='my-4' variant='simple'>
									<Thead>
										{!services ? (
											""
										) : (
											<Tr className='h-12 uppercase'>
												<Th className='text-center text-base p-2'>Service</Th>
												<Th className=' text-center text-base p-2 md:table-cell whitespace-nowrap'>
													<span className='text-center '>Quantity</span>
												</Th>
												<Th className=' text-center text-base p-2 md:table-cell whitespace-nowrap'>
													Unit Charges
												</Th>
												<Th className='text-center text-base p-2 whitespace-nowrap md:table-cell'>
													Discount
												</Th>
												<Th className=' text-center text-base p-2 whitespace-nowrap'>
													Total Charges
												</Th>
											</Tr>
										)}
									</Thead>
									<Tbody>
										{!services
											? stringservices
											: services.map(res => {
													return (
														<Tr key={res.id}>
															<Td className='whitespace-nowrap text-left p-2'>
																<div className='flex items-center justify-center'>
																	<p className='mb-2'>{res.name}</p>
																</div>
															</Td>
															<Td className='justify-center md:flex p-2 text-left'>
																<div className='flex flex-row items-center justify-center w-full h-8'>
																	<Button className='w-8 h-8 px-2 m-1 cursor-auto bg-gray-200 md:text-base text-xs'>
																		<span>{res.quantity}</span>
																	</Button>
																</div>
															</Td>
															<Td className='text-center md:table-cell p-2'>
																<span className='text-sm lg:text-base md:text-base text-xs font-medium'>
																	₹ {res.price}
																</span>
															</Td>
															<Td className='text-center p-2'>
																<span className='text-sm lg:text-base md:text-base text-xs font-medium'>
																	{res.discount * res.quantity}
																</span>
															</Td>
															<Td className='text-center p-2'>
																<span className='text-sm lg:text-base font-medium'>
																	₹{" "}
																	{res.price * res.quantity -
																		res.discount * res.quantity}
																</span>
															</Td>
														</Tr>
													)
											  })}
									</Tbody>
								</Table>
							</Box>
							{!services ? (
								""
							) : (
								<div className='my-4 -mx-2 flex sm:flex-row flex-col sm:items-center'>
									{/* { Object.keys(bridalDay).length!==0 &&
									<div className="p-4">
									 <h1><b>Day :</b> {bridalDay?.day}</h1>
									<h1><b>Date :</b> {bridalDay?.date}</h1>
									<h1><b>Price :</b> {bridalDay?.price}</h1>
									<h1><b>Services :</b> {bridalDay?.services}</h1>
									</div>} */}






									{  <div className='sm:ml-auto'>
										<div className='p-4'>
											<div className='flex justify-between '>
												<div className='lg:px-4 mb-1 text-base lg:text-lg font-bold text-center text-gray-500'>
													Subtotal
												</div>
												<div className='lg:px-4 mb-1  lg:text-lg font-bold text-center text-gray-600'>
													₹ {allProductsSubTotal}
												</div>
											</div>
											<div className='flex justify-between pt-1 '>
												<div className='lg:px-4 mb-1  text-base lg:text-lg font-bold text-center text-gray-500'>
													Discount
												</div>
												<div className='lg:px-4 mb-1  lg:text-lg font-bold text-center text-gray-600'>
													₹ {allProductsDiscount}
												</div>
											</div>
											<div className='flex justify-between pt-1 mt-2 border-b border-t'>
												<div className='lg:px-4 mb-1  text-base lg:text-2xl font-bold text-center text-gray-800'>
													Total
												</div>
												<div className='lg:px-4 mb-1  lg:text-2xl font-bold text-center text-gray-900'>
													₹ {allProductsTotal}
												</div>
											</div>
										</div>
									</div>}
								</div>
							)}
						</div>
					</ModalBody>

					<ModalFooter></ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default OrderListModal
