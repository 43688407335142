import React, {useEffect, useState} from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Stack,
	Input,
	FormLabel,
	FormControl,
	FormErrorMessage,
	useToast,
	Select,
	Textarea
} from "@chakra-ui/react"
import * as Yup from "yup"
import {useForm} from "react-hook-form"
import { Bridal_ID } from "../../../constants/BridalServiceID"
import {useSelector, useDispatch} from "react-redux"
import {useYupValidationResolver} from "../../../hooks/useValidationResolver"
import {addService} from "../../../store/actions/service"
import {Editor} from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import MultiSelect from "react-multiple-select-dropdown-lite"
import {convertToRaw, EditorState} from "draft-js"
import draftToHtml from "draftjs-to-html"
const validationSchema = Yup.object({
	name: Yup.string().required("Name is required!"),
	price: Yup.number().typeError("Price must be a number").required("Price is required.").min(0, "Too little"),
	status: Yup.string().required("Status is required!")
});




const AddServiceModal = ({updateList ,getServices}) => {
	const dispatch = useDispatch()
	const {Categorieslist} = useSelector(state => state.products)
	const toast = useToast()
	const [loading, setLoading] = useState(false)
	const [commentValue, setCommentValue] = React.useState("")
	const [categoryValue, setCategoryValue] = React.useState("")
	const {isOpen, onOpen, onClose} = useDisclosure();
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const resolver = useYupValidationResolver(validationSchema)
	const {
		register,
		handleSubmit,
		formState: {errors, touchedFields},
		reset
	} = useForm({
		resolver,
		mode: "onBlur"
	});


	useEffect(()=>{
		isOpen && getServices()
	} ,[isOpen]);
	       
//  ==================== Get Maximum Value of sorting from arrray ==================
	
	const {productsByCategoryId} = useSelector(state => state.products);
    const sortedArray = productsByCategoryId.sort( (prev, next) =>{
		return prev.sorting - next.sorting
	})
	
		let MaxSorting = sortedArray[sortedArray.length - 1];
	

	const onAddService = data => {
		// myArray.sort(function (a, b) {
		// 	return a.Cost - b.Cost
		// })
		
		// var min = myArray[0],
		// 	max = myArray[myArray.length - 1]

		setLoading(true)
		dispatch(
			addService({
				name: data.name,
				discount: data.discount || 0.0,
				// category_id: data.category_id,
				category_id: categoryValue,
				sorting: MaxSorting?.sorting+1,
				// status: statusValue,
				status: data.status,
				price: data.price || 0.0,
				// comment: data.comment,
				comment: commentValue
			})
		)
			.then(() => {
				setLoading(false);
				updateList();
				onClose();
				toast({
					title: "Service has been added!",
					status: "success",
					duration: 3000,
					isClosable: true
				});
				// reset the form
				reset();

			})
			.catch(err => {
				setLoading(false)
				console.log("ERR => while adding expense", err)
			})
	}

	const onError = (errors, e) => console.log(errors, e)
	const optioncategory = Categorieslist.map((res, index) => ({
		value: res.id,
		label: res.name,
	}))

// Common function to Capital first Letter




// console.log(setBridalServices.day1)


	return (
		<>
			<>
				<Button colorScheme='blue' onClick={onOpen}>
					Add Service
				</Button>
				<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
					<ModalOverlay />
					<form
						style={{overflow: "auto"}}
						noValidate
						onSubmit={handleSubmit(onAddService, onError)}
						className='bg-white'>
						<ModalContent>
							<ModalHeader>Add Service</ModalHeader>
							<ModalCloseButton />
							<ModalBody pb={6}>
								<Stack spacing={4}>
									<FormControl isInvalid={touchedFields?.name && errors?.name} id='name'>
										<FormLabel>Name</FormLabel>
										<Input {...register("name")} placeholder='Enter Name' type='text' />
										<FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
									</FormControl>
									<FormControl
										isInvalid={touchedFields?.price && errors?.price}
										id='price'
										className='mb-3'>
										<FormLabel>Price</FormLabel>
										<Input
											{...register("price")}
											placeholder='Enter Price'
											type='number'
											onWheel={event => event.currentTarget.blur()}
										/>
										<FormErrorMessage>{errors?.price?.message}</FormErrorMessage>
									</FormControl>
									<FormControl
										isInvalid={touchedFields?.category_id && errors?.category_id}
										id='category_id'
										className='mb-3'>
										<FormLabel>Category</FormLabel>
										<MultiSelect
											className='Multiselect-AddService'
											value={categoryValue}
											onChange={setCategoryValue}
											options={optioncategory}
										/>
										<FormErrorMessage>{errors?.category_id?.message}</FormErrorMessage>
									</FormControl>

									<FormControl isInvalid={touchedFields?.discount && errors?.discount} id='discount'>
										<FormLabel>Discount</FormLabel>
										<Input
											{...register("discount")}
											placeholder='Enter Discount'
											type='number'
											onWheel={event => event.currentTarget.blur()}
										/>
										<FormErrorMessage>{errors?.discount?.message}</FormErrorMessage>
									</FormControl>
									<FormControl isInvalid={touchedFields?.sorting && errors?.sorting} id='sorting'>
										<FormLabel>Sorting</FormLabel>
										<Input {...register("sorting")} placeholder='Enter Sorting' type='text' />
										<FormErrorMessage>{errors?.sorting?.message}</FormErrorMessage>
									</FormControl>
									<FormControl isInvalid={touchedFields?.status && errors?.status} id='status'>
										<FormLabel>Status</FormLabel>

										<Select {...register("status")}>
											<option value={1}>Enable</option>
											<option value={0}>Disable</option>
										</Select>
										<FormErrorMessage>{errors?.status?.message}</FormErrorMessage>
									</FormControl>

									<FormControl isInvalid={touchedFields?.comment && errors?.comment} id='comment'>
										<FormLabel>Comment</FormLabel>

										<Editor
											className='mt-2'
											editorState={editorState}
											toolbarClassName='toolbarClassName'
											wrapperClassName='wrapperClassName'
											editorClassName='editorClassName'
											defaultContentState='okk'
											defaultEditorState='hello'
											value='hellooo'
											onEditorStateChange={e => {
												setEditorState(e)
												setCommentValue(draftToHtml(convertToRaw(e.getCurrentContent())))
											}}
											toolbar={{
												inline: {inDropdown: true}
											}}
										/>

										<FormErrorMessage>{errors?.comment?.message}</FormErrorMessage>
									</FormControl>
								</Stack>
							</ModalBody>

							<ModalFooter>
								<Button className='mr-4' onClick={onClose}>
									Close
								</Button>
								<Button isLoading={loading} colorScheme='blue' type='submit' className='bg-gold'>
									Add
								</Button>
							</ModalFooter>
						</ModalContent>
					</form>
				</Modal>
			</>
		</>
	)
}

export default AddServiceModal
