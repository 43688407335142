export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL

export const LOGIN_USER_ENDPOINT = API_BASE_URL + "/login"
export const CHECK_USER_ENDPOINT = API_BASE_URL + "/user/details"
export const REGISTER_USER_ENDPOINT = API_BASE_URL + "/register_or_update"
export const CHANGE_PASSWORD_ENDPOINT = API_BASE_URL + "/user/change_password"
export const UPDATE_PASSWORD_ENDPOINT = API_BASE_URL + "/user/update_password"
export const UPDATE_STATUS_ENDPOINT = API_BASE_URL + "/user/update_status"

// EXPENSE
export const ADD_EXPENSE_ENDPOINT = API_BASE_URL + "/expense/add"
export const LIST_EXPENSE_ENDPOINT = API_BASE_URL + "/expense/all"
export const EDIT_EXPENSE_ENDPOINT = API_BASE_URL + "/expense/edit"
export const UPDATE_EXPENSE_ENDPOINT = API_BASE_URL + "/expense/update"
export const DELETE_EXPENSE_ENDPOINT = API_BASE_URL + "/expense/delete"
export const GET_CLOSING_EXPENSE_ENDPOINT = API_BASE_URL + "/expense/lastExpense"

// CART
export const CATEGORIES_LIST_ENDPOINT = API_BASE_URL + "/expense/categories"

// Products

// This Variable for Frontend Categories list
export const GETCATEGORIES = API_BASE_URL + "/expense/culy_services"

// This Variable for Backend Categories list
export const GET_PRODUCTS_BY_CATEGORY_ID_ENDPOINT = API_BASE_URL + "/expense/services"

// Order
export const CREATE_ORDER_ENDPOINT = API_BASE_URL + "/order/create_order"
export const ORDER_LIST_ENDPOINT = API_BASE_URL + "/order/list_order"
export const SINGLE_ORDER_ENDPOINT = API_BASE_URL + "/order/get_order"
export const UPDATE_ORDER_PAYMENT = API_BASE_URL + "/order/update_payment"

// Add to cart
export const ADD_TO_CART_ENDPOINT = API_BASE_URL + "/expense/cart_update"

// Add update service
export const ADD_SERVICE_ENDPOINT = API_BASE_URL + "/expense/service_update"

// Update Sorting services 

export const UPDATE_SORTING = API_BASE_URL + "/expense/updateSorting"

//GET ALL USER ENDPOINT  FOT ADMIN
export const GET_ALL_USERS = API_BASE_URL + "/user/allUsers"

// GET STAFF USERS
export const GET_STAFF_USER = API_BASE_URL + "/user/staffUsers"

// Get Users
export const GET_INVOICE_USER_ENDPOINT = API_BASE_URL + "/user/get_users"
export const GET_EMP_USER_ENDPOINT = API_BASE_URL + "/user/get_users?user_type=2"

//  GET BIRTHDAYS OF USER
export const GET_BIRTHDAYS_OF_USERS = API_BASE_URL + "/user/birthdayUsers"

// Services Staff
export const GET_USER_ENDPOINT = API_BASE_URL + "/user/get_users?user_type=1"

// Salary

export const ADD_SALARY_ENDPOINT = API_BASE_URL + "/salary/add"
export const GET_SALARY_ENDPOINT = API_BASE_URL + "/salary/list"
export const DELETE_SALARY_ENDPOINT = API_BASE_URL + "/salary/delete"
export const UPDATE_SALARY_ENDPOINT = API_BASE_URL + "/salary/edit"

//  ========================== Appointment ==============================

//  Book Appointment
export const BOOK_APPOINTMENT = API_BASE_URL + "/expense/makeAppoitment"

//  Get Appointment  Listing
export const GET_APPOINTMENT_LISTING = API_BASE_URL + "/expense/appointmentList"

// Update Appointment status
export const UPDATE_APPOINTMENT_STATUS = API_BASE_URL + "/expense/updateAppoitment"

//  ========================== Offers ==============================

// Add Offer
export const ADD_OFFER_ENDPOINT = API_BASE_URL + "/offer/add"

// Get Offer for Admin Panel

export const GET_OFFERS = API_BASE_URL + "/offer/list"

// Update Offer

export const UPDATE_OFFER = API_BASE_URL + "/offer/edit"

//Delete offer

export const DELETE_OFFER = API_BASE_URL + "/offer/delete"

// Get Offer for Front Panel

export const GET_OFFER_FRONT = API_BASE_URL + "/offer/offers"
