import React from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
} from "@chakra-ui/react"

const RejectedModal = ({Rejected_text}) => {
	const {isOpen, onOpen, onClose} = useDisclosure()
	return (
		<>
			<p className='cursor-pointer bg-red-900 py-1 px-3 rounded text-white' onClick={onOpen}>
				<span>Rejected</span>
			</p>

			<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className='capitalize'> Reason of Rejection </ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<h1>{Rejected_text ? Rejected_text : "There is no Rejection Reason Present"}</h1>
					</ModalBody>
					<ModalFooter></ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default RejectedModal
