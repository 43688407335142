import {
  ADD_EXPENSE,
  UPDATE_EXPENSE,
  GET_EXPENSE,
  DELETE_EXPENSE,
  GET_CLOSING_EXPENSE
} from "../constants/expense";

const initialState = {
  expenses: [],
  closingExpense : ""
};

export const expenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EXPENSE:
      return {
        ...state,
        expenses: action.payload,
      };
    case UPDATE_EXPENSE:
      return {
        ...state,
        expenses: action.payload,
      };
    case GET_EXPENSE:
      return {
        ...state,
        expenses: action.payload,
      };
    case DELETE_EXPENSE:
      return {
        ...state,
        expenses: action.payload,
      };
      case GET_CLOSING_EXPENSE: 
      return {
        ...state,
        closingExpense : action.payload.data
      }
    default:
      return state;
  }
};
