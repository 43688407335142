import React, {useState, useRef} from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Stack,
	Input,
	FormLabel,
	FormControl,
	FormErrorMessage,
	useToast,
	Select,
	Link
} from "@chakra-ui/react"
import * as Yup from "yup"
import {useForm} from "react-hook-form"
import ProfileAvatar from "../../../components/ProfileAvatar"

import {useDispatch} from "react-redux"
import {useYupValidationResolver} from "../../../hooks/useValidationResolver"

import {addOffer} from "../../../store/actions/offer"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
const validationSchema = Yup.object({
	name: Yup.string().required("Offer Name is required!"),
	status: Yup.string().required("Status is required!")
})

const AddOfferModal = ({updateList}) => {
	const dispatch = useDispatch()
	const toast = useToast()
	const [loading, setLoading] = useState(false)
	const {isOpen, onOpen, onClose} = useDisclosure()
	const [profileImage, setProfileImage] = useState({
		preview: null,
		raw: null
	})

	const resolver = useYupValidationResolver(validationSchema)
	const {
		register,
		handleSubmit,
		formState: {errors, touchedFields},
		reset
	} = useForm({
		resolver,
		mode: "onBlur"
	})

	const imageRef = useRef()

	const onImageSelect = event => {
		setProfileImage({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		})
	}

	const OnAddOffer = data => {
		const offerObj = {
			name: data.name,
			image: profileImage.raw,
			sort: data.sorting,
			expiry: data.expiryDate || "0000-00-00",
			status: data.status
		}
		setLoading(true)
		dispatch(addOffer(offerObj))
			.then(() => {
				setLoading(false)
				onClose()
				updateList()
				setProfileImage({
					preview: null,
					raw: null
				})
				toast({
					title: "Offer has been added!",
					status: "success",
					duration: 3000,
					isClosable: true
				})
				// reset the form
				reset()
			})
			.catch(err => {
				setLoading(false)
				console.log("ERR => while adding expense", err)
			})
	}

	const onError = (errors, e) => console.log(errors, e)

	return (
		<>
			<>
				<Button colorScheme='blue' className='sm:py-1 sm:px-3 py-0 px-2' onClick={onOpen}>
					Add Offer
				</Button>

				<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
					<ModalOverlay />
					<form
						style={{overflow: "auto"}}
						noValidate
						onSubmit={handleSubmit(OnAddOffer, onError)}
						className='bg-white'>
						<ModalContent>
							<ModalHeader>Add Offer</ModalHeader>
							<ModalCloseButton />

							<ModalBody pb={6}>
								<Stack spacing={4}>
									<FormControl isInvalid={touchedFields?.name && errors?.name} id='name'>
										<FormLabel>Name</FormLabel>
										<Input {...register("name")} placeholder='Enter Name' type='text' />
										<FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
									</FormControl>
									<FormControl
										isInvalid={touchedFields?.expiryDate && errors?.expiryDate}
										id='expiryDate'>
										<FormLabel>Expiry Date</FormLabel>
										<Input
											{...register("expiryDate")}
											placeholder='Enter Expiry Date'
											type='date'
										/>
										<FormErrorMessage>{errors?.expiryDate?.message}</FormErrorMessage>
									</FormControl>
									<FormControl isInvalid={touchedFields?.sorting && errors?.sorting} id='sorting'>
										<FormLabel>Sorting</FormLabel>
										<Input {...register("sorting")} placeholder='Enter Sorting' type='text' />
										<FormErrorMessage>{errors?.sorting?.message}</FormErrorMessage>
									</FormControl>
									<FormControl isInvalid={touchedFields?.status && errors?.status} id='status'>
										<FormLabel>Status</FormLabel>
										<Select {...register("status")}>
											<option value={1}>Enable</option>
											<option value={0}>Disable</option>
										</Select>
										<FormErrorMessage>{errors?.status?.message}</FormErrorMessage>
									</FormControl>

									<FormControl>
										<FormLabel>Profile Image</FormLabel>
										<div className='flex itmes-center mt-1'>
											<ProfileAvatar image={profileImage.preview} />
											<input
												accept='image/*'
												ref={imageRef}
												type='file'
												onChange={onImageSelect}
												hidden
											/>
											<Link
												ml={5}
												color='blue.500'
												fontWeight='medium'
												_focus={{shadow: "none"}}
												onClick={() => imageRef.current.click()}>
												Change
											</Link>
										</div>
									</FormControl>
								</Stack>
							</ModalBody>
							<ModalFooter>
								<Button className='mr-4' onClick={onClose}>
									Close
								</Button>
								<Button isLoading={loading} className='bg-gold' colorScheme='blue' type='submit'>
									Add
								</Button>
							</ModalFooter>
						</ModalContent>
					</form>
				</Modal>
			</>
		</>
	)
}

export default AddOfferModal
