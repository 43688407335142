export const targetLocation = {
	lat: 30.3403775,
	lng: 76.8390967
}

export const testUserPosition = {coords: {latitude: 30.328353, longitude: 76.8379002}}

export function generateDirectionsUrl(source = {}, destination = {}) {
	const {lat: sourceLat, lng: sourceLng} = source
	const {lat: destinationLat, lng: destinationLng} = destination
	const baseUrl = "https://www.google.com/maps/dir"
	const sourceQueryParam = `${sourceLat},${sourceLng}`
	const destinationQueryParam = `Curlys+Unisex+Hair+%26+Makeup+Studio,+5437%2F1%2F2,+Nicholson+Road,+Cinema,+opp.+NIGAR+THEATRE,+Ambala+Cantt,+Haryana+134001/@${destinationLat},${destinationLng}`
	const url = `${baseUrl}/${sourceQueryParam}/${destinationQueryParam}`
	return url
}
