import {useEffect, useState} from "react"

const useUserLocation = () => {
	const [location, setLocation] = useState(null)
	useEffect(() => {
		const getCurrentLocation = () => {
			if (navigator.geolocation) {
				window.navigator.geolocation.getCurrentPosition(
					position => {
						const {latitude, longitude} = position.coords
						setLocation({lat: latitude, lng: longitude})
					},
					error => {
						console.error("Error occurred. Error code: " + error.code)
					},
					{
						enableHighAccuracy: true,
						maximumAge: 30000,
						timeout: 27000
					}
				)
			} else {
				console.error("Geolocation is not supported by this browser.")
			}
		}

		getCurrentLocation()
	}, [])

	return location
}

export default useUserLocation
