import React, {useEffect, useState} from "react"
import axios from "axios"
import {Helmet} from "react-helmet-async"
import { useLocation } from "react-router-dom";

const SEOComponent = () => {
	const [seoData, setSeoData] = useState([])
	const location = useLocation();
	useEffect(() => {
		fetchSEOData()
	}, [])

	const fetchSEOData = async () => {
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/seo`)
			setSeoData(response.data.data)
		} catch (error) {
			console.error(error)
		}
	}
	const subPath = location.pathname
	const singleSeo = seoData.find(element => `/${element.url.split("/").reverse()[0]}` === subPath)
	return (
		<div>
			{singleSeo && (
				<Helmet>
					{singleSeo.title && <title>{singleSeo.title}</title>}
					{singleSeo.metaTitle && <meta name='title' content={singleSeo.metaTitle} />}
					{singleSeo.description && <meta name='description' content={singleSeo.description} />}
					{singleSeo.keywords && <meta name='keywords' content={singleSeo.keywords?.join(",")} />}
				</Helmet>
			)}
		</div>
	)
}

export default SEOComponent
