import React, {useEffect, useState} from "react"
import Header from "../../components/Header"
import styles from "./index.module.css"
import NoOffer from "./NoOffer"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {useSelector, useDispatch} from "react-redux"
import {getDateDiffrence} from "../../utils/getDateDiffrence"
import Loader from "../../components/Loader/Loader"
import AppointmentSuccessModal from "../../components/Modals/AppointmentSucessModal/index"
import {getOffers} from "../../store/actions/offer"
import {bookAppointemnt} from "../../store/actions/getAppointment"
import {IMAGE_BASE_URL} from "../../constants/api"
import {
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	Button,
	Stack,
	Skeleton
} from "@chakra-ui/react"
import GetAppointmentModal from "../../components/Modals/GetAppointmentModal"
import moment from "moment"
import PageContainer from "../../components/PageContainer"
import {Box} from "@chakra-ui/react"
import {SkeletonCircle, SkeletonText} from "@chakra-ui/react"

const OfferHome = () => {
	const {OfferListFront} = useSelector(state => state.getOfferReducer)
	// const {OfferListFront , refreshing} = useSelector(state => state.getOfferReducer)
	const dispatch = useDispatch()
	const {isOpen, onOpen, onClose} = useDisclosure()
	const [ismodalOpen, setIsModalOpen] = useState(false)
	const [offerName, setOfferName] = useState("")
	const [imageModal, setImageModal] = useState("")
	const [loading, setLoading] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const {user} = useSelector(state => ({
		user: state.auth.user
	}))

	useEffect(() => {
		setLoading(true)
		dispatch(getOffers()).then(() => {
			setLoading(false)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const settings_3 = {
		className: "slider variable-width",

		infinite: true,
		speed: 1000,
		slidesToShow: OfferListFront?.data?.length > 2 ? 3 : 2,
		slidesToScroll: 1,
		adaptiveHeight: true,
		swipeToSlide: true,
		autoplay: true,
		autoplaySpeed: 10000,

		responsive: [
			{
				breakpoint: 1050,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 2
				}
			}
		]
	}

	const bookAppointmentHandler = offer => {
		setLoading(true)
		setOfferName(offer.name)
		const appointmentObj = {
			name: user?.name,
			phone: user?.phone,
			comment: `I selected This Offer ${offer.name}`
		}
		let services = ""
		dispatch(bookAppointemnt(appointmentObj, services, user?.id)).then(() => {
			setLoading(false)
			setIsModalOpen(true)
		})
	}



	
	// useEffect(()=>{
	// 	if(!OfferListFront){
	// 	setIsLoading(false)
	// 	}
	// 	},[OfferListFront])



	useEffect(() => {
		const loadImage = async () => {
		  await new Promise(resolve => setTimeout(resolve, 6000));
		  setIsLoading(false);
		};
		loadImage();
	  }, []);



	// useEffect(() => {
	// 	const loadingTimeout = setTimeout(() => {
	// 		setIsLoading(false)
	// 	}, 6000)
	// 	return () => clearTimeout(loadingTimeout)
	// }, [])





	return (
		<>
		<span id="offers"></span>
			<Header title='Offers | Curlys Unisex Hair & Makeup Studio' />

			{loading ? (
				<>
					<Loader className='items-center' style={{height: "calc(100vh - 16rem)", opacity: "0"}} />
					{/* <section className='category sm:p-20 p-10'>
						<div className='container m-auto slicker-width-btn'>
							<div className='px-4'>
								<div className='wrapper wrapper-code-offer bg-white p-5 my-3 rounded-xl shadow-slate-998'>
									{[...Array(3)].map((_, index) => (
										<Box padding='2' boxShadow='lg' className='relative '>
											<SkeletonText
												mt='4'
												noOfLines={1}
												spacing='2'
												skeletonHeight='80'></SkeletonText>
											<SkeletonText mt='3' noOfLines={1} spacing='2' skeletonHeight='16' />
											<SkeletonText
												mt='2'
												mb='5'
												width='200px'
												noOfLines={1}
												spacing='2'
												skeletonHeight='12'
											/>
										</Box>
									))}
								</div>
							</div>
						</div>
					</section> */}
				</>
			) : (
				<>
					<section
						id='offers'
						className={`bg-banner-offer bg-cover flex items-center justify-center relative z-10 h-80 ${styles.bannerSection} `}>
						<div className='container '>
							<div className='w-full text-center relative z-20 text-4xl font-bold text-white'>
								<h3 className='border-b-4 border-white inline pb-1'>Special Offer</h3>
							</div>
						</div>
					</section>

					{!loading && OfferListFront?.data?.length === 0 && <NoOffer />}
					{OfferListFront?.data?.length !== 0 && (
						<section className='category sm:p-20 p-10'>
							<div className='container m-auto slicker-width-btn'>
								<Slider {...settings_3}>
									{OfferListFront?.data?.map(element => {
										const expiryDate = getDateDiffrence(element.expiry)
										return (
											<div className='px-4' key={element.id}>
												<div className='wrapper  bg-white p-5 my-3 rounded-xl shadow-slate-998'>
													{isLoading ? (
														<Box padding='2' boxShadow='lg' className='relative '>
															<SkeletonText
																mt='4'
																noOfLines={1}
																spacing='2'
																skeletonHeight='80'></SkeletonText>
															<SkeletonText
																mt='3'
																noOfLines={1}
																spacing='2'
																skeletonHeight='16'
															/>
															<SkeletonText
																mt='2'
																mb='5'
																width='200px'
																noOfLines={1}
																spacing='2'
																skeletonHeight='12'
															/>
														</Box>
													) : (
														<>
															<div className='imageClass'>
																<img
																	onClick={() => {
																		setImageModal(element.img)
																		onOpen()
																	}}
																	className='w-full cursor-pointer object-cover h-full rounded-lg'
																	src={`${IMAGE_BASE_URL}/${element.img}`}
																	alt=''
																/>
															</div>

															<div className='content pt-5'>
																<div className=' justify-between items-center flex-wrap'>
																	<h1 className='text-xl font-medium capitalize'>
																		{element.name}
																	</h1>

																	<p className='text-base text-gray-400 mb-2'>
																		{(expiryDate === null || expiryDate > 7) &&
																			moment(element.expiry).format(
																				"DD MMM YYYY"
																			)}
																		{expiryDate >= 1 &&
																			expiryDate < 7 &&
																			`Offer Expires in ${expiryDate} Day`}
																		{expiryDate === 0 &&
																			" This offer Will Expires Today"}
																		{expiryDate === "expires" && " Offer Expired"}
																	</p>
																</div>
																{user && (
																	<button
																		onClick={() => bookAppointmentHandler(element)}
																		className={`border text-black my-3 py-2 px-3 rounded-md ${styles.bookBtn} `}>
																		Book Appointment
																	</button>
																)}
																{!user && (
																	<GetAppointmentModal
																		spanText='text-black'
																		className={`border text-black my-3 py-2 px-3 rounded-md inline-block ${styles.bookBtn}`}
																		book='Book Appointment'
																		commentText={` I Selcted This Offer ${element.name}`}
																		p
																	/>
																)}
															</div>
														</>
													)}
												</div>
											</div>
										)
									})}
								</Slider>
								<AppointmentSuccessModal
									ismodalOpen={ismodalOpen}
									setIsModalOpen={setIsModalOpen}
									user={user}
									offerName={offerName}
								/>
							</div>
							<Modal
								isOpen={isOpen}
								onClose={onClose}
								scrollBehavior='inside'
								className=' bg-white mx-auto'>
								<div className='modalOpen'>
									<ModalOverlay className='bg-gold-701 modalOverlay ' />
									<ModalContent className={`${styles.modalContent} shadow-none  m-0 max-w-2xl `}>
										<ModalHeader
											className={`capitalize p-0 m-0 text-white py-3 text-center ${styles.modalHeader} `}>
											{" "}
										</ModalHeader>
										<ModalCloseButton className={styles.btn} />
										<ModalBody className='overflow-visible  mt-3'>
											<img
												className=' mx-auto object-contain rounded'
												src={` ${IMAGE_BASE_URL}/${imageModal}`}
												alt=''
											/>
										</ModalBody>
										<ModalFooter></ModalFooter>
									</ModalContent>
								</div>
							</Modal>
						</section>
					)}
				</>
			)}
		</>
	)
}
export default OfferHome
