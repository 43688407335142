import React from "react"
import {
	Box,
	IconButton,
	Divider,
	Drawer,
	DrawerBody,
	DrawerContent,
	Stack,
	useDisclosure,
	VStack
} from "@chakra-ui/react"
import {HamburgerIcon, CloseIcon} from "@chakra-ui/icons"
import {NavLink, useLocation} from "react-router-dom"
import {useSelector} from "react-redux"
import styles from "./index.module.css"

const MobileHeader = () => {
	const {isOpen, onToggle, onClose} = useDisclosure()
	const {OfferListFront} = useSelector(state => state.getOfferReducer)
	// const {user} = useSelector(state => ({
	// 	user: state.auth.user
	// }))

	const {pathname, hash} = useLocation()
	// const onClickHandler = () => {}
	return (
		<div className='mobile-menu'>
			{/* <VStack py={2}>
				<Link>
					<Button
						variant='ghost'
						isFullWidth
						colorScheme='gray'
						rightIcon={<ChevronRightIcon />}
						color='gray.600'
						borderRadius={0}
						iconSpacing='auto'
						px={6}
						onClick={onClickHandler}></Button>
				</Link>
			</VStack> */}
			<Box display={["block", null, null, "none"]} className={styles.boxMain}>
				<IconButton
					variant='ghost'
					_active={{bg: "none"}}
					_focus={{bg: "none", outline: 0}}
					icon={isOpen ? <CloseIcon width={"100%"} /> : <HamburgerIcon width={24} />}
					aria-label='Open Navigation Menu'
					minW={0}
					color={"#b4905e"}
					height='auto'
					width={"30px"}
					onClick={onToggle}
				/>
				<Drawer placement='right' size='full' isOpen={isOpen} onClose={onClose}>
					<DrawerContent className={styles.drawerBox}>
						<DrawerBody p={8} bgColor='white'>
							<VStack alignItems='stretch'>
								<Stack
									display='flex'
									direction={"row"}
									justifyContent={"space-between"}
									alignItems='center'>
									<img src='/img/curl-black.png' alt='' style={{width: "auto"}} />
									<div className={styles.closeBtn}>
										<IconButton
											variant='ghost'
											_active={{bg: "none"}}
											_focus={{bg: "none", outline: 0}}
											icon={<CloseIcon />}
											aria-label='Open Navigation Menu'
											minW={0}
											height='auto'
											onClick={onToggle}
										/>
									</div>
								</Stack>

								{/* <MobileNavLinks links={navLinks} onClick={onToggle} /> */}
								<Divider className='my-3' />
								<nav className='flex flex-col'>
									{" "}
									<NavLink to='/'>
										<span
											className={`text-lg font-medium uppercase my-1 hover:text-gold-400  ${
												pathname === "/" && hash !== "#about" ? "text-gold-400" : "text-black"
											} `}
											onClick={() => {
												setTimeout(() => {
													document
														.getElementById("sliderHome")
														.scrollIntoView({behavior: "smooth"})
												}, 100)
												onToggle()
											}}>
											Home
										</span>
									</NavLink>
									<NavLink
										to='/#about'
										className={` ${
											hash === "#about" ? "text-gold-400" : "text-black"
										} text-lg font-medium uppercase my-1 hover:text-gold-400`}


									  onClick={() => {
									setTimeout(() => {
									  const aboutSection = document.getElementById("#about");
									  if (aboutSection) {
										aboutSection.scrollIntoView({ behavior: "smooth" });
										const offset = aboutSection.offsetTop - 100;
										window.scrollTo({
										  top: offset,
										  behavior: "smooth",
										});
									  }
									}, 100);
									onToggle()
								  }}

										
										>
										About Us
									</NavLink>
									<NavLink to='/services' className='my-1'>
										<span
											className={` ${
												pathname === "/services" || pathname === "/curly-service"
													? "text-gold-400"
													: "text-black"
											} text-lg font-medium uppercase hover:text-gold-400`}
											onClick={() => {
												setTimeout(() => {
													document.getElementById("service").scrollIntoView({behavior: "smooth"})
												}, 100)
												onToggle()
											}}>
											Services
										</span>
									</NavLink>

									<NavLink to='/bridal' className='my-1'>
										<span
											className={`mr-10 ${
												pathname === "/bridal" ? "text-gold-400" : "text-black"
											} text-lg font-medium uppercase hover:text-gold-400`}
											onClick={() => {
												setTimeout(() => {
													document.getElementById("bridal").scrollIntoView({behavior: "smooth"})
												}, 100)
												onToggle()
											}}>
											Bridal
										</span>
									</NavLink>

									<NavLink to='/nails' className='my-1'>
										<span
											className={` ${
												pathname === "/nails" || pathname === "/nails"
													? "text-gold-400"
													: "text-black"
											} text-lg font-medium uppercase hover:text-gold-400`}
											onClick={() => {
												setTimeout(() => {
													document.getElementById("nails").scrollIntoView({behavior: "smooth"})
												}, 100)
												onToggle()
											}}>
											Nails
										</span>
									</NavLink>

									<NavLink to='/offers' className='my-1'>
										<span
											className={`mr-10  ${OfferListFront?.data?.length === 0 ? null : "animate-charcter-second" }  ${
												pathname === "/offers" ? "text-gold-400" : "text-black"
											} text-lg font-medium uppercase hover:text-gold-400`}
											onClick={() => {
												setTimeout(() => {
													document.getElementById("offers").scrollIntoView({behavior: "smooth"})
												}, 100)
												onToggle()
											}}>
											Offers
										</span>
									</NavLink>
								
									{/* <NavLink to='/my-orders' className={` ${user ? "block" : "hidden"} my-1 `}>
								<span
									className={`mr-10 ${
										pathname === "/my-orders"
											? "text-gold-400"
											: "text-black"
									} text-lg font-medium uppercase hover:text-gold-400`}>
									My Orders
								</span>
							</NavLink> */}
									<button
										className={`inline-flex items-center font-medium  
								 border-0 py-1 focus:outline-none hover:text-gold-400  text-lg uppercase`}
										onClick={() => {
											onToggle()
										}}>
										<a
											href='https://docs.google.com/forms/d/1VQSTUZqg5sBRLuqC9BxtHbJVTz8gq3ddnqDFTl4_gE4/viewform?edit_requested=true'
											target='_blank'
											rel='noreferrer'>
											Feedback
										</a>
									</button>
								</nav>
							</VStack>
						</DrawerBody>
					</DrawerContent>
				</Drawer>
			</Box>
		</div>
	)
}

export default MobileHeader
