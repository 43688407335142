import React, {useEffect} from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormErrorMessage,
	Stack,
	FormControl,
	FormLabel,
	Input,
	Button,
	Tag,
	TagLabel,
	TagCloseButton
} from "@chakra-ui/react"
import {CUIAutoComplete} from "chakra-ui-autocomplete"
import {useSelector} from "react-redux"

const AddService = ({
	isOpen,
	onClose,
	handleSubmit,
	onAddServices,
	onError,
	register,
	touchedFields,
	errors,
	loading,
	staffePickerItems,
	selectedStaffItems,
	handleStaffCreateItem,
	handleStaffSelectedItemsChange,
	handleServiceSearch,
	serviceValue,
	setServiceValue,
	searchService,
	setSearchService,
	setHiddenService,
	setSelectedService,
	setSelectedStaffItems,
	yourPrice,
	setYourPrice,
	yourDiscount,
	setYourDiscount,
	selectedService,
	handleServiceTag,
	qtyValue,
	setQtyValue,
	onUpdateService,
	servicesDatas,
	discountValue,
	reset
}) => {
	const user = useSelector(state => state.auth.user)

	// setting Default Value for staff only while first Time Modal Opens
	useEffect(() => {
		if (user?.user_type === 2) {
			setSelectedStaffItems(prev => prev.concat({value: user.id, label: user.name}))
		} else {
			setSelectedStaffItems([])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const modalCloseHandler = () => {
		reset()
		setSearchService([])
		// setting Default Value for staff only while closing services Modal
		user?.user_type === 2 ? setSelectedStaffItems([{value: user.id, label: user.name}]) : setSelectedStaffItems([])
		setSelectedService("")
		setServiceValue("")
		setYourDiscount(0)
		setYourPrice(0)
		setQtyValue(1)
		onClose()
	}

	return (
		<div>
			<Modal isOpen={isOpen} onClose={modalCloseHandler} scrollBehavior='inside' size='xl'>
				<ModalOverlay />
				<form
					style={{overflow: "auto"}}
					noValidate
					onSubmit={handleSubmit(
						servicesDatas.length > 0 && serviceValue !== "" ? onUpdateService : onAddServices,
						onError
					)}
					className='bg-white'>
					<ModalContent>
						<ModalHeader>Add Services</ModalHeader>
						<ModalCloseButton />

						<ModalBody pb={6}>
							<Stack spacing={4}>
								<FormControl isInvalid={touchedFields?.services && errors?.services} id='services'>
									<FormLabel className='b-4'>SERVICES</FormLabel>
									{selectedService && (
										<Tag marginY={2} marginX={1} size='md' variant='solid' colorScheme='blue'>
											<TagLabel>{selectedService.name}</TagLabel>
											<TagCloseButton onClick={() => handleServiceTag()} />{" "}
										</Tag>
									)}

									<Input
										{...register("services")}
										placeholder='Search Service'
										type='text'
										value={serviceValue}
										onChange={e => {
											setServiceValue(e.target.value)
											handleServiceSearch(e.target.value)
										}}
									/>
									<FormErrorMessage>{errors?.services?.message}</FormErrorMessage>
								</FormControl>

								{searchService.length > 0 ? (
									<div className='w-full bg-gray-200  rounded-md shadow-md px-4 mt-1 pt-2'>
										{searchService &&
											searchService.map((v, i) => (
												<div
													key={i}
													className='font-semibold hover:bg-gray-500 hover:text-white p-2 rounded-md cursor-pointer'
													onClick={() => {
														setSelectedService(v)
														setSearchService([])
														setServiceValue("")
													}}>
													{v.name}
												</div>
											))}
									</div>
								) : (
									""
								)}
								<FormControl isInvalid={touchedFields?.staff && errors?.staff} id='staff'>
									<FormLabel className='-mb-4 mt-4'>STAFF</FormLabel>
									<CUIAutoComplete
										{...register("staff")}
										placeholder='Select Staff'
										onCreateItem={handleStaffCreateItem}
										items={staffePickerItems}
										selectedItems={selectedStaffItems}
										onSelectedItemsChange={changes =>
											handleStaffSelectedItemsChange(changes.selectedItems)
										}
									/>

									<FormErrorMessage>{errors?.staff?.message}</FormErrorMessage>
								</FormControl>
								<div className='md:flex -mt-2'>
									<FormControl
										mr={5}
										className='mt-2'
										isInvalid={touchedFields?.price && errors?.price}
										id='price'>
										<FormLabel className=''>PRICE</FormLabel>
										<Input
											placeholder='Enter Price'
											{...register("price")}
											value={yourPrice || yourDiscount ? 0 : selectedService.price}
											onChange={e => e}
										/>
										<FormErrorMessage>{errors?.price?.message}</FormErrorMessage>
									</FormControl>

									<FormControl
										className='mt-2'
										isInvalid={touchedFields?.qty && errors?.qty}
										id='qty'>
										<FormLabel>QTY</FormLabel>
										<Input
											placeholder='Enter QTY'
											type='number'
											{...register("qty")}
											onWheel={event => event.currentTarget.blur()}
											value={qtyValue}
											onChange={e => setQtyValue(e.target.value)}
										/>
										<FormErrorMessage>{errors?.qty?.message}</FormErrorMessage>
									</FormControl>
								</div>

								<div className='md:flex'>
									<FormControl
										mr={5}
										className='mt-2'
										isInvalid={touchedFields?.discount && errors?.discount}
										id='discount'>
										<FormLabel>DISCOUNT</FormLabel>
										<Input
											type='number'
											{...register("discount")}
											placeholder='discount'
											value={yourPrice || yourDiscount ? 0 : discountValue * qtyValue}
										/>
										<FormErrorMessage>{errors?.discount?.message}</FormErrorMessage>
									</FormControl>
									<FormControl
										mr={5}
										className='mt-2'
										isInvalid={touchedFields?.yourPrice && errors?.yourPrice}
										id='discount'>
										<FormLabel>YOUR PRICE</FormLabel>
										<Input
											onClick={() => setYourPrice("")}
											type='number'
											onWheel={event => event.currentTarget.blur()}
											{...register("yourPrice")}
											placeholder='Enter Your Price'
											value={yourPrice}
											onChange={e => {
												setYourPrice(e.target.value)
											}}
										/>
										<FormErrorMessage>{errors?.discount?.message}</FormErrorMessage>
									</FormControl>
								</div>
								<div className='md:flex'>
									<FormControl
										mr={5}
										className='mt-2'
										isInvalid={touchedFields?.yourDiscount && errors?.yourDiscount}
										id='yourDiscount'>
										<FormLabel>YOUR DISCOUNT</FormLabel>
										<Input
											onClick={() => setYourDiscount("")}
											type='number'
											onWheel={event => event.currentTarget.blur()}
											{...register("yourDiscount")}
											placeholder='Enter Your Discount'
											value={yourDiscount}
											onChange={e => {
												setYourDiscount(e.target.value)
											}}
										/>
										<FormErrorMessage>{errors?.yourDiscount?.message}</FormErrorMessage>
									</FormControl>

									<FormControl
										className='mt-2'
										isInvalid={touchedFields?.total && errors?.total}
										id='total'>
										<FormLabel>PAYABLE AMOUNT</FormLabel>
										<Input
											type='number'
											{...register("total")}
											placeholder='Total'
											value={
												yourPrice || yourDiscount
													? parseInt(yourPrice || 0) - parseInt(yourDiscount || 0)
													: selectedService.price * qtyValue - (discountValue * qtyValue || 0)
											}
										/>
										<FormErrorMessage>{errors?.total?.message}</FormErrorMessage>
									</FormControl>
								</div>
							</Stack>
						</ModalBody>

						<ModalFooter>
							<Button className='mr-4' onClick={modalCloseHandler}>
								Close
							</Button>
							<Button isLoading={loading} colorScheme='green' className='bg-gold' type='submit'>
								{servicesDatas.length > 0 && serviceValue !== "" ? "Update" : "Submit"}
							</Button>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</div>
	)
}

export default AddService
