import axios from "../../utils/axios"
import {CREATE_ORDER_ENDPOINT, ORDER_LIST_ENDPOINT, SINGLE_ORDER_ENDPOINT,UPDATE_ORDER_PAYMENT} from "../../constants/api"
import {DEFAULT_HEADERS} from "../../utils"
import {CREATE_ORDER, ORDER_LIST, SINGLE_ORDER} from "../constants/order"
import { REMOVE_CUSTOMER } from "../constants/auth"

export const createOrders = orderData => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try {
			const oderDataAsString = JSON.stringify(orderData.data)
			const validString = oderDataAsString.replaceAll(/\n/g, "\\n")

			const formData = new FormData()
			formData.append("data", validString)
			formData.append("bill_date", orderData.bill_date)
			formData.append("cash", orderData.cash)
			formData.append("cust_id", orderData.cust_id)
			formData.append("online", orderData.online)
			formData.append("total_amount", orderData.total_amount)
			formData.append("services", orderData.services.join(","))

			const staffString = orderData.staff.map(v => {
				return v && v.join(",")
			})
              
			formData.append("staff", staffString.join(","))
			dispatch({
				type : REMOVE_CUSTOMER,
			})

			const {data} = await axios.post(CREATE_ORDER_ENDPOINT, formData, DEFAULT_HEADERS)
			dispatch({
				type: CREATE_ORDER,
				payload: JSON.parse(data.data.data)
			})
			localStorage.setItem("orderCreate", JSON.stringify(data.data.data))
			resolve(data.data)
		} catch (error) {
			reject(error)
		}
	})
}

export const getSingleOrder = uid => dispatch => {
	return new Promise(async (resolve, reject) => {
		try {
			const {data} = await axios.get(`${SINGLE_ORDER_ENDPOINT}?uid=${uid.uid}`, null, DEFAULT_HEADERS)
			dispatch({
				type: SINGLE_ORDER,
				payload: data.data
			})
			resolve()
		} catch (error) {
			reject(error)
		}
	})
}

export const getOrderList =
	({month, year, page, staff, services, sortby}) =>
	dispatch => {
		return new Promise(async (resolve, reject) => {
			try {
				const url = `${ORDER_LIST_ENDPOINT}?month=${month}&year=${year}&limit=10&staff=${staff}&page=${page}&services=${services}&sortby=${sortby}`
				const {data} = await axios.get(url, {
					// params: {
					//   myearonth,
					//   ,
					//   limit,
					//   page,
					//   staff,
					//   services,
					//   sortby,
					// },
					DEFAULT_HEADERS
				})
				dispatch({
					type: ORDER_LIST,
					payload: data.data
				})
				resolve(data.data)
			} catch (error) {
				reject(error)
			}
		})
	}


	export const updateOrder = (updateOrderData) => {

		const formData = new FormData();
		formData.append("online" , updateOrderData.online);
		formData.append("cash", updateOrderData.cash);
		formData.append("uid", updateOrderData.uid);

		if(updateOrderData?.paymentId !== ""){
			formData.append("paymentId",updateOrderData.paymentId)
		}
	return async()=>{
		const url = `${UPDATE_ORDER_PAYMENT}`
		  await axios.post(url, formData , DEFAULT_HEADERS); 
	}

	}

