import React, {Fragment, useRef, useState, useEffect} from "react"
import * as Yup from "yup"
import {useForm} from "react-hook-form"
import {useDispatch, useSelector} from "react-redux"
import {Dialog, Transition} from "@headlessui/react"
import {useYupValidationResolver} from "../../../hooks/useValidationResolver"
import {bookAppointemnt, GetServices} from "../../../store/actions/getAppointment"
import MultiSelect from "react-multiple-select-dropdown-lite"
import moment from "moment"
import {
	Button,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay
} from "@chakra-ui/react"

const GetAppointmentModal = props => {
	
	const {user} = useSelector(state => ({
		user: state.auth.user
	}));

	const [serviceValue, setServiceValue] = React.useState("")
	const [loading, setLoading] = useState(false)
	const [open, setOpen] = useState(false)
	const [bookedAppointmentValue, setBookedAppointmentValue] = useState({
		services: "",
		DateandTime: ""
	})

	// ==================== State to set Data in the Google Calender Console API ==============


	// const [dateForCalender, setDateForCalender] = useState({
	// 	name: "",
	// 	date: "",
	// 	time: ""
	// })


	const [isOpen, setIsOpen] = React.useState(false)
	const onClose = () => setIsOpen(false)
	const cancelRef = React.useRef()

	const Dispather = useDispatch()
	useEffect(() => {
		if (open) {
			Dispather(GetServices())
		}
			// eslint-disable-line react-hooks/exhaustive-deps
	}, [open,Dispather])


	const {services} = useSelector(state => state.getServices)


	const optioncategory = services.map(res => ({
		label: res.name,
		value: res.name
	}))

	const validationSchema = Yup.object({
		phone: Yup.number().typeError("Phone must be a number").required("Phone number is required!").min(999999999, "Please Enter Valid Phone Number")
		.max(9999999999, "Please Enter Valid Phone Number"),
	})
	let dtToday = new Date()
	let month = dtToday.getMonth() + 1
	let day = dtToday.getDate()
	let year = dtToday.getFullYear()
	if (month < 10) month = "0" + month.toString()
	if (day < 10) day = "0" + day.toString()
	const minDate = year + "-" + month + "-" + day

	const resolver = useYupValidationResolver(validationSchema)
	const {
		register,
		handleSubmit,
		formState: {errors},
		reset
	} = useForm({
		resolver: resolver,
		 defaultValues: user,
		mode: "onBlur"
	})

	const onformSubmit = data => {
			// ==================== State to set Data in the Google Calender Console API ==============

			
		// setDateForCalender(prev => ({
		// 	...prev,
		// 	name: data.name,
		// 	date: data.date,
		// 	time: data.time
		// }))
		let user_id = user ? user?.id : ""
		setLoading(true)
		// CommentText that i passed as  parameter is comming from the Offer Page
		Dispather(bookAppointemnt(data, serviceValue, user_id , props.commentText)).then(() => {
			let mainDate
			if (data.date && data.time) {
				const dt = new Date(data.date)
				mainDate = `${dt.toDateString()} ${moment(data.time, ["HH.mm"]).format("hh:mm a")}`
			} else {
				mainDate = ""
			}
			setBookedAppointmentValue(prev => ({
				...prev,
				services: serviceValue.split(",").join(", "),
				DateandTime: mainDate
			}))
			setLoading(false)
			resolver && setOpen(false)
			setIsOpen(true)
			reset()
		})
	}
//  ==================== Function to store Appointment To google Calender Console API ===============
	// const AddCalenderHandler = () => {
	// 	Dispather(SetCalenderEvent(dateForCalender))
	// }

	const cancelButtonRef = useRef(null)


	return (
		<>
			<div onClick={() => setOpen(true)} className={props.className}>
				{props.book !== "hide" ? (
					<span className={`${props.spanText}text-white  cursor-pointer`}>{props.book}</span>
				) : (
					props.icon
				)}
			</div>
			<Transition.Root show={open} as={Fragment}>
				<Dialog
					as='div'
					style={{zIndex: "111"}}
					className='fixed  inset-0 overflow-y-auto'
					initialFocus={cancelButtonRef}
					onClose={setOpen}>
					<div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0'
							enterTo='opacity-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'>
							<Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}

						<span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
							enterTo='opacity-100 translate-y-0 sm:scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 translate-y-0 sm:scale-100'
							leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
							<div style={{     border: '2px solid #b4905e' }} className='inline-block  align-bottom bg-black rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle lg:max-w-5xl md:max-w-3xl sm:max-w-lg sm:w-full'>
								<div className='bg-black px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
									<div className=''>
										<div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center'>
											<div className='lg:text-5xl md:text-3xl sm:text-xl text-white lg:tracking-widest md:tracking-normal sm:tracking-normal'>
											<img
									src='/img/main-logo.png'
									alt='Curlys Unisex Hair & Makeup Studio'
									style={{width: "auto", margin: 'auto'}}
									className='mb-2'
								/>
												BOOK YOUR APPOINTMENT
											</div>
										</div>
									</div>
								</div>
								<div className='bg-black px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
									<form className='w-full max-w-full' onSubmit={handleSubmit(onformSubmit)}>
										<div className='flex flex-wrap -mx-3 '>
											<div className='w-full md:w-1/2 px-3 mb-6 md:mb-4'>
												<input
													{...register("name")}
													className='rounded-md appearance-none block w-full text-gray-700 border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white'
													id='name'
													type='text'
													placeholder='Name'
												/>
											</div>
											<div className='w-full md:w-1/2 px-3 mb-6 md:mb-4'>
												<input
													{...register("phone")}
													className='rounded-md appearance-none block w-full text-gray-700 border border-gray-200 rounded  py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
													id='phone'
													type='number'
													placeholder='Contact Number'

													maxLength={10}
													onInput={(e) => {
													  e.target.value = e.target.value.slice(0, 10).replace(/\D/g, "");
													}}	


												/>
												<p className='text-white'>{errors?.phone?.message}</p>
											</div>
										</div>
										<div className='flex flex-wrap -mx-3'>
											<div className='w-full md:w-1/2 px-3 mb-6 md:mb-4'>
												<input
													{...register("email")}
													className='rounded-md appearance-none block w-full text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white'
													id='email'
													type='email'
													placeholder='Email'
												/>
											</div>
											<div className='w-full md:w-1/2 px-3  mb-6 md:mb-4'>
												<input
													{...register("date")}
													className='rounded-md h-12 appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
													id='txtDate'
													type='date'
													placeholder='Date'
													defaultValue={moment(new Date()).format("YYYY-MM-DD")}
													min={minDate}
												/>
											</div>
										</div>
										<div className='flex flex-wrap -mx-3 '>
											<div className='w-full md:w-1/2 px-3 mb-6 md:mb-4'>
												<input
													{...register("time")}
													className='rounded-md h-12 appearance-none block w-full text-gray-700 border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white'
													id='grid-first-name'
													type='time'
													placeholder='Time'
													defaultValue={moment(new Date()).format("hh:mm")}
												/>
											</div>
											<div className='w-full md:w-1/2 px-3 mb-6 md:mb-4'>
												<MultiSelect
													className='Multiselect-AddService rounded-md  bg-white text-black w-full getAppointmentServices py-1 appearence-none'
													value={serviceValue}
													onChange={setServiceValue}
													options={optioncategory}
												/>
											</div>
										</div>

										<div className='w-full mb-6 md:mb-4 '>
											<textarea
												{...register("comment")}
												className='rounded-md appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
												placeholder='Enter Your Comment here'></textarea>
										</div>
										<div className='bg-black px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
											
											
											<Button
												type='submit'
												isLoading={loading}
												className='w-full bookSubmitBtn inline-flex justify-center rounded-md border text-lg border-transparent shadow-sm px-4 py-2    font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset- sm:ml-3 sm:w-auto sm:text-sm'>
												Submit
											</Button>








											<button
												type='button'
												className='mt-3 bookCancelBtn w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
												onClick={() => setOpen(false)}
												ref={cancelButtonRef}>
												Cancel
											</button>
										</div>
									</form>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
			<div>
				<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
					<AlertDialogOverlay>
						<AlertDialogContent className='max-w-screen-md py-4 px-4 mx-4 sm:mx-0 '>
							<AlertDialogHeader className='text-center font-bold text-2xl'>
								<div>
									<i className='bx text-5xl text-green-600 bxs-badge-check'></i>
								</div>
								<h1>Appointment Booked Sucessfully</h1>
							</AlertDialogHeader>
							<AlertDialogBody>
								<div>
									<h1
										className={` ${
											bookedAppointmentValue.DateandTime ? "block" : "hidden"
										} text-lg border-b-2 pb-2 my-3`}>
										<b>Your Time</b> : {bookedAppointmentValue.DateandTime}
									</h1>
									<h1
										className={`text-lg ${
											bookedAppointmentValue.services ? "block" : "hidden"
										} border-b-2 pb-2 my-3`}>
										<b>Booked Services</b> : {bookedAppointmentValue.services}
									</h1>
								</div>
							</AlertDialogBody>
							<button></button>
							<AlertDialogFooter className='sm:flex-row flex-col'>
								<Button
									className='text-center w-full  sm:mr-2 mb-4 sm:mb-0'
									ref={cancelRef}
									onClick={onClose}>
									Close
								</Button>
								{/* <Button
									className={` ${
										bookedAppointmentValue.DateandTime ? "block" : "hidden"
									}  text-center sm:ml-2 bg-gold text-white w-full`}
									onClick={AddCalenderHandler}>
									<i className='bx text-lg bxs-calendar-plus mr-3'></i> Add To Calender
								</Button> */}
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
			</div>
		</>
	)
}

export default GetAppointmentModal
GetAppointmentModal.defaultProps = {
	book: "Get Appointment",
	spanText: "text-base sm:text-xl "
}

