
import { BOOK_APPOINTMENT, GETCATEGORIES, GET_APPOINTMENT_LISTING, UPDATE_APPOINTMENT_STATUS } from "../../../src/constants/api";
import moment from "moment";
import axios from "../../utils/axios";


// ======================Calender API Code ========================


export const SetCalenderEvent = (data) => {
  return async () => {
    const Client_ID = process.env.REACT_APP_CLIENT_ID;
    const API_KEY = process.env.REACT_APP_API_KEY;
    const DISCOVERY_DOCS = process.env.REACT_APP_DISCOVERY_DOCS;
    const SCOPES = process.env.REACT_APP_SCOPES;
    let mainDate
    if (data.date) {
      const dt = new Date(data.date);
      const date = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`
      const time = data.time;
      const dateTime = `${date} ${time}`
      const timeDate = moment(new Date(dateTime), 'YYYY-MM-DD HH:mm').format();
      mainDate = timeDate.split("+")[0];
    }
    else {
      mainDate = ""
    }
    const gapi = window.gapi;
    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: Client_ID,
        discoveryDocs: [DISCOVERY_DOCS],
        scope: SCOPES
      })
      gapi.client.load('calendar', 'v3');
      gapi.auth2.getAuthInstance().signIn().then(() => {
        const event = {
          'summary': data.name,
          'location': '5437/1/2, Nicholson Road, Opp Nigar Cinema, Punjabi Mohalla, Sadar Bazar, Ambala Cantt, Haryana 134001',
          'description': 'Best Unisex Salon in Ambala for Bridal MakeUp, Smoothening, Keratin, Hair Spa, Hair Color, Facial, Highlights, Manicure, Pedicure, Hair Care, Skin Care and other beauty parlour services.',
          'start': {
            'dateTime': mainDate,
            'timeZone': 'Asia/Kolkata'
          },
          'end': {
            'dateTime': mainDate,
            'timeZone': 'Asia/Kolkata'
          },
          'recurrence': [
            'RRULE:FREQ=DAILY;COUNT=2'
          ],
          'attendees': [
            { 'email': 'hellocurlys@gmail.com' }
          ],
          'reminders': {
            'useDefault': false,
            'overrides': [
              { 'method': 'email', 'minutes': 24 * 60 },
              { 'method': 'popup', 'minutes': 10 }
            ]
          }
        }


        var request = gapi.client["calendar"].events.insert({
          calendarId: "primary",
          resource: event,
        });

        request.execute((event) => {
          const link = event.htmlLink;
          window.open(link, "_blank");
        })
      })
    })
  }
}

// ======================Book Appointment Home Page Using GET APPOINTMENT MODAL========================

export const bookAppointemnt = (userData, serviceValue,user_id , comment) =>{

  console.log( "This is User Data that i send "  , userData)
  let mainDate
  if (userData.date) {
    const dt = new Date(userData.date);
    const date = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`
    const time = userData.time;
    const dateTime = `${date} ${time}`
    const timeDate = moment(new Date(dateTime), 'YYYY-MM-DD HH:mm').format();
    mainDate = timeDate.split("+")[0];
  }

  else {
    mainDate = "0000-00-00T00:00:00"
  }

     return  async (dispatch)=>{
             const formData = {
              name: userData.name,
              email: userData.email,
              phone: userData.phone,
              date: mainDate,
              services:serviceValue,
              comment: (userData.comment||"")  + " "  + (comment || ""),
             }

             if(user_id!== ""){
              formData.user_id= user_id
            }
             try {
              dispatch({
                type: "BOOK_APPOINTMENT_LOADING"
             })
             const {data} = await axios.post(`${BOOK_APPOINTMENT}` , formData);
             dispatch({
              type: "BOOK_APPOINTMENT_SUCCESS",
              payload : data
           })
             }
             catch(err){
              dispatch({
                type: "BOOK_APPOINTMENT_ERROR",
                payload : err
             })
           }
     }
}


// ======================Book Appointment from the Shopping Cart And From the Admins Book Appointment section ========================

export const bookAppointemntCart = (userdata)=>{
  console.log( "This is My user Data ===========================> " , userdata);
  let mainDate
  if (userdata.date!=="" || userdata.time!=="") {
    const dt = new Date(userdata.date);
    const date = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`
    const time = userdata.time;
    const dateTime = `${date} ${time}`
    const timeDate = moment(new Date(dateTime), 'YYYY-MM-DD HH:mm').format();
    mainDate = timeDate.split("+")[0];
  }
  else {
    mainDate = "0000-00-00T00:00:00"
  }
     return async ()=>{
       const formData = {
         name : userdata.name,
         phone : userdata.phone,
         date : mainDate,
         services : JSON.stringify(userdata.services),
         user_id: userdata.user_id,
       }
       if(!!userdata?.status){
           formData.status = userdata?.status
       }
       console.log("This is My form data =====================> " , formData);
            await axios.post(`${BOOK_APPOINTMENT}`,formData);
     }
}


// ====================== Get services When Get Appointement modal open  ========================


export const GetServices = ()=>{
  return async (dispstch)=>{
    const {data} = await axios.post(`${GETCATEGORIES}`);
    dispstch ({
      type: "GET_SERVICES",
      payload: data
    })
  }
}


// =============================== Get Appointment Listing for Admin Panel ==================================


export const GetAppointmentListing = (filters) => {
 let page = filters.page ? filters.page  : 1 ;
 let month = filters.month ? filters.month : "" ;
 let year =  filters.year ? filters.year : "";
  return async (dispatch) => {
    const { data } = await axios.get(`${GET_APPOINTMENT_LISTING}? limit=10&page=${page}&year=${year}&month=${month}`);
    dispatch({
      type: "GET_LISTING",
      payload: data.data
    })
  }
}



// =============================== Update Appointment Status ==================================



export const updateStatus = (data)=> {
    const statusObj = {
      id : data.id, 
      status : data.status,
      status_text : data.text,
    }

     return async ()=>{
        await axios.post(`${UPDATE_APPOINTMENT_STATUS} `, statusObj) 
     }
}

