import {Box, Button, Table, Tbody, Td, Th, Thead, Tr ,Modal, Textarea,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	useToast,} from "@chakra-ui/react"
import React, {useEffect, useState} from "react"
import PageContainer from "../../components/PageContainer"
import PrivateLayout from "../../layout/PrivateLayout"
import {getBirthdayUsers} from "../../store/actions/getUser"
import {useDispatch, useSelector} from "react-redux"
import {IMAGE_BASE_URL} from "../../constants/api"
import moment from "moment"
import {getDateDiffrence, Greetings } from "../../utils/getDateDiffrence"
import Loader from "../../components/Loader/Loader"
import { DateRangePicker } from "react-dates"
import { getStartOfMonth } from "../../utils/common"
import RejectedModal from "../Booking/MyOrders/RejectedModal"
import OrderListModal from "../../components/Modals/OrderListModal"
import axios from "axios"
import { useHistory } from "react-router-dom"

const Dashboard = ({history}) => {


const DashboardData  =  React.memo(() => {
	const [loading, setLoading] = useState(false);
	const [isSummaryHidden , setIsSummaryHidden] = useState(false);
	const [isEnquiryHidden , setIsEnquiryHidden] = useState(true);
	const [isWishesSectionHidden , setIsWishesSectionHidden] = useState(false);
	const {user} = useSelector(state => state.auth);
	const dispatch = useDispatch();


	useEffect(() => {
		setLoading(true)
		dispatch(getBirthdayUsers({
			start_date: dateObject.startDate
			 ? moment(dateObject.startDate).format("YYYY-MM-DD")
			 : moment(startMonthDate).format("YYYY-MM-DD"),
		 end_date: dateObject.startDate
			 ? moment(dateObject.endDate).format("YYYY-MM-DD")
			 : moment(new Date()).format("YYYY-MM-DD")
	})).then(() => {
			setLoading(false)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


//  ======================== Function for Getting First Date ============================
       const startMonthDate = getStartOfMonth(new Date())

	//    ================== Date Object Used For Date Range Picker ======================

	const [dateObject, setDateObject] = useState({
		startDate: null,
		endDate: null,
		focusedInput: null
	})

	// =============================== state for Date Range Calender Focus Input Prop  =======================

const [focusedInput , setFocusIntput] = useState("");


useEffect(()=>{
	if(dateObject.startDate && dateObject.endDate){
		setLoading(true)
		dispatch(getBirthdayUsers({
			start_date: dateObject.startDate
						? moment(dateObject.startDate).format("YYYY-MM-DD")
						: moment(startMonthDate).format("YYYY-MM-DD"),
					end_date: dateObject.startDate
						? moment(dateObject.endDate).format("YYYY-MM-DD")
						: moment(new Date()).format("YYYY-MM-DD")
		})).then(()=>{
			setLoading(false)
		}).catch( err=>{
			setLoading(false)
		})
	}
	 // eslint-disable-next-line react-hooks/exhaustive-deps
	 
},[dateObject])



	//  ================================ For Responsive Date Range Calendear ==========================

	let numberOfMonths = window.matchMedia("(max-width: 700px)").matches ? "1" : "2"
	let small =  window.matchMedia("(max-width: 700px)").matches ? true : false
	let large = window.matchMedia("(max-width: 700px)").matches ? false : true

	const {getBirthdayUser} = useSelector(state => state.getUsers)
	return (
		<>
				{loading && <Loader className='h-screen w-full absolute z-5 bg-white items-center' />}
					<Box className='mx-auto'>
					<div className="lg:flex justify-between align-center py-10 ">
					<p className='text-2xl md:text-3xl font-medium mb-4 italic'>
							{Greetings()}, {user ? user.name : "Guest"}
					</p>
					<DateRangePicker
									hideKeyboardShortcutsPanel={true}
									startDateId='startDate'
									regular={large}
									small={small}
									numberOfMonths={numberOfMonths}
									isOutsideRange={() => false}
									endDateId='endDate'
									displayFormat="DD MMM YYYY"
									startDate={dateObject.startDate}
									startDatePlaceholderText={moment(startMonthDate).format("DD MMM YYYY")}
									endDatePlaceholderText={moment(new Date()).format("DD MMM YYYY")}
									endDate={dateObject.endDate}
									onDatesChange={({startDate, endDate}) =>
										setDateObject(prev => ({
											...prev,
											startDate: startDate,
											endDate: endDate
										}))
									}
									readOnly={true}
									inputIconPosition='after'
									focusedInput={focusedInput}
									customInputIcon={<i className='bx bxs-calendar' />}
									// orientation={orientation}
									// appendToBody={true}
									// autoFocus={true}
									onFocusChange={(focusedInput) =>{
										setFocusIntput(focusedInput)
									}}
								/>
					</div>

					<div className="flex items-center pb-2 sm:pb-5">
					  <div className="lg:w-10/12 sm:w-9/12 w-7/12">
                        <hr className="border-indigo-700 border" />
					  </div>
					  <div className="lg:w-2/12 sm:w-3/12  w-5/12  flex items-center justify-center ">
						  <span className="cursor-pointer flex items-center " onClick={()=> setIsEnquiryHidden((prev)=>!prev)}>
					  <span className="text-blue-800 select-none text-base"  >{ isEnquiryHidden ? "Show Enquiries" : "Hide Enquiries"}</span>
					  <i className={`bx bx-chevron-${isEnquiryHidden ? 'down' : 'up'} text-blue-800 text-2xl`}/>
					  </span>
					  </div>
					</div>

					{!isEnquiryHidden && <div className="sm:flex justify-between  flex-wrap">
					                        {getBirthdayUser?.upCommingEnquiry?.map(booking => {
													let services
													let bookingservices
													let length
													try {
														let servicesData = JSON.parse(booking.services);
														  services = JSON.parse(servicesData?.service);
													} catch {
														bookingservices = booking.services
														var splitservices = !bookingservices ? [] : bookingservices.split(",");
														length = splitservices.length
													}
													return (
												<div className='wrapper sm:w-1/2 p-2' key={booking.id}>
												<div className='bg-white shadow-slate-1001 p-3.5 rounded-xl mb-3'>
													<div className='head flex flex-wrap justify-between border-b pb-2 mb-2 border-dotted border-gray-400'>
														<p className='font-bold text-lg'>
															Appointment ID - {booking.id}
														</p>
														<p
															className={`font-bold cursor-pointer bg-green-900 py-1 px-3 rounded text-white ${
																booking.status === 1 ? "block" : "hidden"
															}`}>
															<span>Approved</span>
														</p>
														<p
															className={` ${
																booking.status === 2 ? "block" : "hidden"
															} font-bold`}>
															<RejectedModal Rejected_text={booking.status_text} />
														</p>
														<p
															className={`font-bold cursor-pointer bg-gold-400 py-1 px-3 rounded text-white ${
																booking.status === 0 ? "block" : "hidden"
															}`}>
															<span>In Review</span>
														</p>
													</div>
													<div className='body'>
														<div className='flex flex-wrap justify-between py-1'>
															<h6 className='font-medium text-gray-400'>Name</h6>
															<p className='font-medium text-gray-700'>
																{booking.name ? booking.name : "Not Available "}
															</p>
														</div>
														<div className='flex flex-wrap justify-between py-1'>
															<h6 className='font-medium text-gray-400'>
																Appointment Date
															</h6>
															<p className='font-medium text-gray-700'>
																{booking.date === "0000-00-00 00:00:00" || !booking.date
																	? "Not Available "
																	: moment(booking.date).format("DD MMM YYYY")}
															</p>
														</div>
														<div className='flex flex-wrap justify-between py-1'>
															<h6 className='font-medium text-gray-400'>
																Appointment Time
															</h6>
															<p className='font-medium text-gray-700'>
																{booking.date === "0000-00-00 00:00:00" || !booking.date
																	? "Not Available "
																	: moment(booking.date).format("hh:mm a")}
															</p>
														</div>

                                                       

													
														

														{/* <div className='flex flex-wrap justify-between py-1'>
															<h6 className='font-medium text-gray-400'>
																Appointment Time
															</h6>
															<p className='font-medium text-gray-700'>
																{booking.date === "0000-00-00 00:00:00" || !booking.date
																	? "Not Available "
																	: moment(booking.date).format("hh:mm a")}
															</p>
														</div> */}

														{(!bookingservices) &&  
                                                         <div className='flex flex-wrap justify-between py-1'>
														 <h6 className='font-medium text-gray-400'>
															 Price
														 </h6>
														 <p className='font-medium text-gray-700'>
															 {services?.reduce((acc , curr)=> acc + ((parseInt(curr?.price) * curr?.quantity) - (parseInt(curr.discount)*curr?.quantity)),0) || "Not Avaliable"}
														 </p>
													 </div>
														}
														{<div className='flex flex-wrap justify-between py-1'>
															<h6 className='font-medium text-gray-400'>
																No. of Services
															</h6>
															<p className='font-medium text-gray-700'>
																{services ? services.length || 0 : length || 0}
															</p>
														</div>}
														<div className='flex flex-wrap justify-between py-1'>
															<p
																className={`text-gray-600 font-bold ${
																	!booking.services ? "hidden" : "block"
																}  `}>
																To View Details Please{" "}
																<OrderListModal bookingData={booking} />
															</p>
														</div>
													</div>
												</div>
											</div>)})}
											</div>}







					<div className="flex items-center pb-2 sm:pb-5">
					  <div className="lg:w-10/12 sm:w-9/12 w-7/12">
                        <hr className="border-indigo-700 border" />
					  </div>
					  <div className="lg:w-2/12 sm:w-3/12  w-5/12  flex items-center justify-center ">
						  <span className="cursor-pointer flex items-center " onClick={()=> setIsSummaryHidden((prev)=>!prev)}>
					  <span className="text-blue-800 select-none text-base"  >{ isSummaryHidden ? "Show Summary" : "Hide Summary"}</span>
					  <i className={`bx bx-chevron-${isSummaryHidden ? 'down' : 'up'} text-blue-800 text-2xl`}/>
					  </span>
					  </div>
					</div>
						
						{!isSummaryHidden &&
						(<div>
												<div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4'>
							<div className='flex justify-between items-center flex-wrap bg-white border rounded p-4 shadow-lg'>
								<div className='flex items-center'>
									<em className='bx bxs-check-circle text-2xl text-pink-600'></em>
									<p className='pl-2 text-lg whitespace-nowrap'>Total Enquire</p>
								</div>

								<p className='text-pink-600 text-xl'>{getBirthdayUser.TotalEnquire}</p>
							</div>
							<div className='flex justify-between items-center flex-wrap bg-white border rounded p-4 shadow-lg'>
								<div className='flex items-center'>
									<em className='bx bxs-check-circle text-2xl text-blue-600'></em>
									<p className='pl-2 text-lg whitespace-nowrap'>Total Enquire Approved</p>
								</div>
								<p className='text-blue-600 text-xl'>{getBirthdayUser.TotalEnquireApproved}</p>
							</div>
							<div className='flex justify-between items-center flex-wrap bg-white border rounded p-4 shadow-lg'>
								<div className='flex items-center'>
									<em className='bx bxs-check-circle text-2xl text-sky-900'></em>
									<p className='pl-2 text-lg whitespace-nowrap'>Total Enquire Reject</p>
								</div>
								<p className='text-sky-900 text-xl'>{getBirthdayUser.TotalEnquireReject}</p>
							</div>
							<div className='flex justify-between items-center flex-wrap bg-white border rounded p-4 shadow-lg'>
								<div className='flex items-center'>
									<em className='bx bxs-check-circle text-2xl text-sky-500'></em>
									<p className='pl-2 text-lg whitespace-nowrap'>Total Enquire Review</p>
								</div>
								<p className='text-sky-500 text-xl'>{getBirthdayUser.TotalEnquireReview}</p>
							</div>
							<div className='flex justify-between items-center flex-wrap bg-white border rounded p-4 shadow-lg'>
								<div className='flex items-center'>
									<em className='bx bxs-check-circle text-2xl text-sky-900'></em>
									<p className='pl-2 text-lg whitespace-nowrap'>Total Order</p>
								</div>{" "}
								<p className='text-sky-900 text-xl'>{getBirthdayUser.TotalOrder}</p>
							</div>
							<div className='flex justify-between items-center flex-wrap bg-white border rounded p-4 shadow-lg'>
								<div className='flex items-center'>
									<em className='bx bxs-check-circle text-2xl text-pink-600'></em>
									<p className='pl-2 text-lg whitespace-nowrap'>Total Services</p>
								</div>{" "}
								<p className='text-pink-600 text-xl'>{getBirthdayUser.TotalServices}</p>
							</div>
						</div>
						</div>)}
					</Box>

					<div className="flex items-center  mt-4">
					  <div className="lg:w-10/12 sm:w-9/12 w-7/12">
                        <hr className="border-indigo-700 border" />
					  </div>
					  <div className="lg:w-2/12 sm:w-3/12  w-5/12  flex items-center justify-center ">
						  <span className="cursor-pointer flex items-center " onClick={()=> setIsWishesSectionHidden((prev)=>!prev)}>
					  <span className="text-blue-800 select-none text-base"  >{ isWishesSectionHidden ? "Show Section" : "Hide Section"}</span>
					  <i className={`bx bx-chevron-${isWishesSectionHidden ? 'down' : 'up'} text-blue-800 text-2xl`}/>
					  </span>
					  </div>
					</div>

					{!loading && !isWishesSectionHidden && (
						 <Box className=' md:flex justify-between py-10'>
							<Box m={2} bg='white' className='shadow md:w-2/4 w-full mb-8'>
								<div
									className='head text-center bg-cover bg-center relative  bg-no-repeat p-5'
									style={{backgroundImage: "url(img/birthday-header.jpg)"}}>
									<h3 className='text-2xl font-bold'> Birthdays List</h3>
									<p>Wish your friend's a happy birthday!</p>
								</div>
								<div className='body p-5'>
									{getBirthdayUser?.users?.length === 0 && <h1 className="text-center text-base sm:text-2xl">No Birthday's in Next 15 Days</h1>}
									{getBirthdayUser?.users?.length !== 0 &&  getBirthdayUser?.users?.map(user => {
										// Give Date in the Format of (YYYY-MM-DD)
										const birthdayDate =
											user.dob !== "0000-00-00" ? getDateDiffrence(user.dob) : null
										// const annivarsaryDate =
										// 	user.annivarsaryDate !== "0000-00-00"
										// 		? getDateDiffrence(user.anniversary)
										// 		: null
										console.log( "This is Birthday Date" , birthdayDate)
										const age =
											parseInt(new Date().getFullYear()) -
											parseInt(moment(user.dob).format("YYYY"))
										return (
											<div
												className=' mb-4 bg-indigo-500 text-white p-5 border-b shadow-slate-1001 rounded-xl '
												key={user.id}>
												<h1
													className={`text-2xl pb-3 mb-3 text-center font-bold border-b border-white ${
														birthdayDate === 0 ? "block" : "hidden"
													}`}>
													{" "}
													Today {user.name}'s Birthday{" "}
												</h1>
												{/* <h1
													className={`text-2xl pb-3 mb-3 text-center font-bold border-b border-white ${
														annivarsaryDate === 0 ? "block" : "hidden"
													}`}>
													{" "}
													Today {user.name}'s Annivarsary{" "}
												</h1> */}
												<div className='flex items-center'>
													<div className={`img ${!user.image ? "hidden" : "block"}`}>
														<img
															src={user.image && `${IMAGE_BASE_URL}/${user.image}`}
															alt=''
															width='150px'
														/>
													</div>
													<div className='content pl-7 w-full'>
														<h3 className='text-xl mb-2 font-bold text-white'>
															{user.name}
														</h3>
														<p
															className={` mb-2 ${
																!user.dob || user.dob === "0000-00-00"
																	? "hidden"
																	: "block"
															}`}>
															<b>Age : </b> {age} years old
														</p>

														<p className="mb-2">

														 
     <a href="tel:user.phone" className='text-white'>
<span className=""> <b>Mobile Number :</b> {user.phone}</span>
</a>
</p>

														<p
															className={` ${
																(birthdayDate === null ||
																birthdayDate > 15 ||
																birthdayDate === 0 || birthdayDate === "expires")
																	? "hidden"
																	: "block"
															}  mb-3 `}>
															{" "}
															{birthdayDate === 1
																? `${birthdayDate} Day Left For Birthday of ${user.name}`
																: `${birthdayDate} Days Left For Birthday of ${user.name}`}
														</p>
														{/* <p
															className={` ${
																annivarsaryDate === null ||
																annivarsaryDate > 15 ||
																annivarsaryDate === 0
																	? "hidden"
																	: "block"
															}  mb-3 `}>
															{" "}
															{annivarsaryDate === 1
																? `${annivarsaryDate} Day Left For Birthday of ${user.name}`
																: `${annivarsaryDate} Days Left For Annivarsary of ${user.name}`}
														</p> */}
														<p className={`mb-2`}>
															{" "}
															<b> Date of Birth : </b>{" "}
															{!user.dob || user.dob === "0000-00-00"
																? "Not Avaliable"
																: moment(user.dob).format("LL")}{" "}
														</p>
														{/* <p className='mb-2'>
															{" "}
															<b> Date of Anniversary : </b>{" "}
															{!user.anniversary || user.anniversary === "0000-00-00"
																? "Not Avaliable"
																: moment(user.anniversary).format("LL")}{" "}
														</p> */}
													</div>
												</div>
											</div>
										)
									})}
								</div>
								{/* <div className='footer text-center bg-gray-50 py-3 text-black-900 font-bold'>
									<p>See All Upcoming Birthday's</p>
								</div> */}
							</Box>
							<Box m={2} bg='white' className='shadow md:w-2/4 w-full mb-8'>
								<div
									className='head text-center bg-cover bg-center relative  bg-no-repeat p-5'
									style={{backgroundImage: "url(img/birthday-header.jpg)"}}>
									<h3 className='text-2xl font-bold'> Anniversary List</h3>
									<p>Wish your friend's a happy anniversary !</p>
								</div>
								<div className='body p-5'>
								{getBirthdayUser?.anniversary?.length === 0 && <h1 className="text-center text-base sm:text-2xl">No Anniversary's in Next 15 Days</h1>}
									{getBirthdayUser?.anniversary?.length !== 0   && getBirthdayUser?.anniversary?.map(user => {
										// Give Date in the Format of (YYYY-MM-DD)
										const annivarsaryDate =
											user.annivarsaryDate !== "0000-00-00"
												? getDateDiffrence(user?.anniversary)
												: null;
										const anniversary =
										parseInt(moment(new Date()).format("YYYY")) - parseInt(moment(user?.anniversary).format("YYYY"))

										return (
											<div
												className=' mb-4 bg-indigo-500 text-white p-5 border-b shadow-slate-1001 rounded-xl '
												key={user.id}>
												{/* <h1
													className={`text-2xl pb-3 mb-3 text-center font-bold border-b border-white ${
														birthdayDate === 0 ? "block" : "hidden"
													}`}>
													{" "}
													Today {user.name}'s Birthday{" "}
												</h1> */}
												<h1
													className={`text-2xl pb-3 mb-3 text-center font-bold border-b border-white ${
														annivarsaryDate === 0 ? "block" : "hidden"
													}`}>
													{" "}
													Today {user.name}'s Annivarsary{" "}
												</h1>
												<div className='flex items-center'>
													<div className={`img ${!user.image ? "hidden" : "block"}`}>
														<img
															src={user.image && `${IMAGE_BASE_URL}/${user.image}`}
															alt=''
															width='150px'
														/>
													</div>
													<div className='content pl-7 w-full'>
														<h3 className='text-xl mb-2 font-bold text-white'>
															{user.name}
														</h3>
														 <p
															className={` mb-2`}>
															<p><b>Anniversary : </b> {(anniversary===25 && "Silver Jubilee") || (anniversary===50 && "Golden Jubilee") ||  moment.localeData().ordinal(anniversary===0 ? 1 : anniversary)} Anniversary</p>
														</p>
                                                         <p className="mb-2">

														 
														<a href="tel:user.phone" className='text-white'>
														<span className=""> <b>Mobile Number :</b> {user.phone}</span>
														</a>
														</p>
														{/* <p
															className={` ${
																birthdayDate === null ||
																birthdayDate > 15 ||
																birthdayDate === 0
																	? "hidden"
																	: "block"
															}  mb-3 `}>
															{" "}
															{birthdayDate === 1
																? `${birthdayDate} Day Left For Birthday of ${user.name}`
																: `${birthdayDate} Days Left For Birthday of ${user.name}`}
														</p> */}
														<p
															className={` ${
																(annivarsaryDate === null ||
																annivarsaryDate > 15 ||
																annivarsaryDate === 0 || annivarsaryDate === "expires")
																	? "hidden"
																	: "block"
															}  mb-3 `}>
															{" "}
															{annivarsaryDate === 1
																? `${annivarsaryDate} Day Left For Anniversary of ${user.name}`
																: `${annivarsaryDate} Days Left For Annivarsary of ${user.name}`}
														</p>
														{/* <p className={`mb-2`}>
															{" "}
															<b> Date of Birth : </b>{" "}
															{!user.dob || user.dob === "0000-00-00"
																? "Not Avaliable"
																: moment(user.dob).format("LL")}{" "}
														</p> */}
														<p className='mb-2'>
															{" "}
															<b> Date of Anniversary : </b>{" "}
															{!user.anniversary || user.anniversary === "0000-00-00"
																? "Not Avaliable"
																: moment(user.anniversary).format("LL")}{" "}
														</p>
													</div>
												</div>
											</div>
										)
									})}
								</div>
							</Box>
						</Box>
					)}
		</>
	)
})

const AttedendenceData = ()=>{
	const history = useHistory();
	const {isOpen, onOpen, onClose} = useDisclosure();
	const {user} = useSelector(state => state.auth);
	const [time , setTime] = useState("");
	const [isCheckedIn , setIsCheckedIn] = useState(false);
	const [attendenceData , setAttendenceData] = useState([]);
	const [prevData , setPrevData] = useState("");
	const [inputVal , setInputVal] = useState("");
	const [loading , setLoading] = useState(false);
	const [attedenceUpdateModal , setAttendenceUpdateMadal] = useState(false);
	const [userAttendeceDataHavetoUpdate , setUserAttendeceDataHavetoUpdate] = useState(""); 
	const [updateAttendeceData , setUpdateAttendenceData] = useState({
		checkInTime : "",
		CheckOutTime : "",
		attendenceType : "" // status 1 for Check in and status 2 for checkout
	})
	const [attendenceType , setAttendenceType] = useState(1)  // status 1 for Check in and status 2 for checkout
//    ================== Date Object Used For Date Range Picker ======================

const [dateObject, setDateObject] = useState({
	startDate: null,
	endDate: null,
})

// =============================== state for Date Range Calender Focus Input Prop  =======================

const [focusedInput , setFocusIntput] = useState("");


      useEffect(()=>{
	 getCheckInData(user?.id)
	  getData();
	const interval = setInterval(() => {
		const date = new Date()
	  let hours = date.getHours();
	  let minutes = date.getMinutes();
	  let seconds = date.getSeconds(); 
	  let Meridiem = hours > 12 ? "PM" : "AM"
	  if(hours > 12 ){
		  hours = hours - 12
	  }
	  if(minutes < 10 ){
		  minutes = ` 0${minutes}`
	  }
	  if(seconds < 10 ){
		seconds = ` 0${seconds}`
	   }
	   if(hours < 10 ){
		hours = ` 0${hours}`
	   }
	  const mainTime = `${hours} : ${minutes} : ${seconds}  ${Meridiem}`; 
	  setTime(mainTime)
  
	}, 1000);
	return () => {
	  clearInterval(interval);
	};
},[]);


useEffect(()=>{
	if(dateObject?.startDate && dateObject?.endDate){
		getData();
	}
},[dateObject]);


const Toast = useToast();
const handleAttendence = async ()=>{
		const url = `https://curlys.in/devapi/api/attendance/add`;
		let date = new Date();
		let hours = date.getHours();
		let minutes = date.getMinutes();
		let seconds = date.getSeconds(); 
		const mainTime = `${hours}:${minutes}:${seconds}`
	   const body = {
		  staff_id:user?.id,
		  start_time: attendenceType===1 ? mainTime : "00:00:00",
		  end_time: attendenceType===2 ? mainTime : "00:00:00",
		  date:moment(date).format("YYYY-MM-DD"),
		  type:attendenceType,
		  comment:inputVal
	   }
	  //  setIsCheckedIn(2);
	  setInputVal("")
	   onClose();
  
	   const {data} = await axios.post(url ,body);
	   if(data?.message === "Attendance added successfully."){
		   setIsCheckedIn(true);
		   Toast({
			title: "Check in Marked SuccessFully",
			status: "success",
			duration: 3000,
			isClosable: true
		})
	}
		else if(data?.message === "Attendance updated successfully."){
			Toast({
				title: "Check Out Marked SuccessFully",
				status: "success",
				duration: 3000,
				isClosable: true
			})
	   }
	   else{
		   setIsCheckedIn(false);
		   Toast({
			title: "Something went wrong please try Again",
			status: "error",
			duration: 3000,
			isClosable: true
		})
	   }
	   getCheckInData(user?.id);
	   getData();
}


const updateAttendenceHandler = async() =>{
	const url = `https://curlys.in/devapi/api/attendance/add`;
	const body = {
		staff_id:userAttendeceDataHavetoUpdate?.staff?.id,
		start_time: updateAttendeceData?.checkInTime,
		end_time: updateAttendeceData?.CheckOutTime,
		date:moment(userAttendeceDataHavetoUpdate?.date).format("YYYY-MM-DD"),
		type:updateAttendeceData?.attendenceType === 1 ? 1  : 2 ,
		comment:userAttendeceDataHavetoUpdate?.comment
	 }
	 console.log("This is Body ======================> "  ,body)
	//  setIsCheckedIn(2);
	 const {data} = await axios.post(url ,body);
	 console.log("This is Data while hitting Update API =========================> " , data)
	   if(data?.message === "Attendance updated successfully."){
		   if(updateAttendeceData?.attendenceType === 1){
			Toast({
				title: "Check In Updated SuccessFully",
				status: "success",
				duration: 3000,
				isClosable: true
			})
		   }
		   else{
			Toast({
				title: "Check Out Updated SuccessFully",
				status: "success",
				duration: 3000,
				isClosable: true
			})
		   }
	 }
	 else{
		 setIsCheckedIn(false);
		 Toast({
		  title: "Something went wrong please try Again",
		  status: "error",
		  duration: 3000,
		  isClosable: true
	  });
	 }
	 setUpdateAttendenceData("");
	 setAttendenceUpdateMadal(false);
	//  getCheckInData(user?.id);
	 getData();
}



const getData = async ()=>{	
	const url = `https://curlys.in/devapi/api/attendance/list?start_date=${dateObject.startDate?moment(dateObject.startDate).format("YYYY-MM-DD"): moment(startMonthDate).format("YYYY-MM-DD")}&end_date=${dateObject.startDate
	? moment(dateObject.endDate).format("YYYY-MM-DD")
	: moment(new Date()).format("YYYY-MM-DD")}`
	const {data} = await axios.get(url);
	setAttendenceData(data?.data)
}

const getCheckInData = async (Id)=> {
	const date = moment(new Date()).format("YYYY-MM-DD")
	const url = `https://curlys.in/devapi/api/attendance/get?date=${date}&staff_id=${Id}`;
	const {data} = await axios.get(url);

	if(data?.data===null){
		setPrevData(data?.data)
        setIsCheckedIn(false);
	}
	else if(!!data?.data && data?.data?.start_time!==0 && data?.data?.end_time==="00:00:00"){
		setIsCheckedIn(true)
		setPrevData(data?.data)
	}
	else if(!!data?.data && data?.data?.start_time!=='00:00:00' && data?.data?.end_time !== '00:00:00' ){
		setIsCheckedIn(false);
		setPrevData(data?.data);
	}
	else {
		setIsCheckedIn(false);
		setPrevData(data?.data)
	}
}

const typeHandler = (type)=>{
	if(prevData && prevData?.end_time!=="00:00:00"){
		Toast({
			title: "You Have Already Marked Your Attendence",
			status: "error",
			duration: 3000,
			isClosable: true
		})
	}

	else{
		if(type==="check-in"){
			onOpen();
			setAttendenceType(2);
		}
		else{
			onOpen();
			setAttendenceType(1)
		}
	}
}

const updateModalValuesHandler = (e)=>{
	setUpdateAttendenceData((prev)=>({
		...prev,
        [e.target.name] : e.target.value
	}))
}

const checkInCheckOutUpdateHandler = (element , type)=>{
		setUserAttendeceDataHavetoUpdate(element)
		setUpdateAttendenceData((prev)=>({
			...prev,
			checkInTime :element?.start_time, //moment(element?.start_time).format('HH:mm:ss'),
			CheckOutTime : element?.end_time, //moment(element?.end_time).format('HH:mm:ss') 
		    attendenceType : type === 1 ? 1 : 2 
		}));
		setAttendenceUpdateMadal(true);
}

//  ======================== Function for Getting First Date ============================
             const startMonthDate = getStartOfMonth(new Date())



	//  ================================ For Responsive Date Range Calendear ==========================	

	let numberOfMonths = window.matchMedia("(max-width: 700px)").matches ? "1" : "2"
	let small =  window.matchMedia("(max-width: 700px)").matches ? true : false
	let large = window.matchMedia("(max-width: 700px)").matches ? false : true

	return(
		<>
		{/* ========================== Staff Part ================================== */}
		
		{(time && user?.user_type === 2 ) && 
		(<div className="md:flex justify-between">
		<div >
			<div className="border-solid border-2 border-black-900 py-1 px-4">
		<h1 className="text-xl font-bold">
				{time}
			</h1>
			</div>
		</div>
		{prevData && (<div><h1 className="font-bold">Your Check in Time  : {prevData?.start_time}</h1><h1 className="font-bold ">Your Checkout Time : {prevData?.end_time!=="00:00:00" ? prevData?.end_time : 'Not Yet Checked Out'}</h1></div>)}
		<div >
	  {isCheckedIn ? <Button className='ml-10 bg-red-400 text-white' onClick={()=>typeHandler("check-in")}>Check Out</Button> :  <Button className='bg-green-400 text-white ' onClick={()=>typeHandler("check-out")}>Check In</Button>}
	   </div>
		</div>)
		}

			<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className='capitalize'> {attendenceType === 1 ? "Check In Modal " : "Check Out Modal"} </ModalHeader>
					<ModalCloseButton />
					<ModalBody>
					<Textarea onChange={(e)=>setInputVal(e.target.value)} value={inputVal} placeholder='Comment' />
					</ModalBody>
					<ModalFooter>
					<Button colorScheme='gray' className='px-3 py-1 rounded mx-2 ' onClick={onClose}>
							Close
						</Button>
						{ attendenceType === 1  ? (<Button
							isLoading={loading}
							className='px-3 py-1 rounded bg-gold mx-2 text-white'
							onClick={handleAttendence}>
							CheckIn
						</Button>) : 
						(<Button
							isLoading={loading}
							className='px-3 py-1 rounded bg-gold mx-2 text-white'
							onClick={handleAttendence}>
							CheckOut
						</Button>)}
					</ModalFooter>
				</ModalContent>
			</Modal>

			{/* ================================= Admin Part ================================== */}

	 {  user?.user_type === 1 &&  attendenceData.length !== 0 &&  (<div>
	   <div className='my-4 flex justify-between'>
		   {/* <div>
		   <Button  onClick={()=>history.push('/mark-attendence')}>Mark Attendance</Button>
		   </div> */}
	   
                        </div>
                          </div>)}
 


      <Modal isOpen={attedenceUpdateModal} onClose={()=>setAttendenceUpdateMadal(false)} scrollBehavior='inside' size='xl'>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className='capitalize'> Update Attendence Modal</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
					<h1 className="my-5 font-bold">You are Currently Updating {userAttendeceDataHavetoUpdate?.staff?.name}'s Attendence of Date {moment(userAttendeceDataHavetoUpdate?.date).format("DD MMM YYYY")}</h1>
						<div className="">
						{updateAttendeceData?.attendenceType === 1 &&  (<div className='w-full'>
						<label className='font-bold  ml-2 '>Pick Check In Time</label>
															<input
																// value={UserData.time}
																// onChange={dateTimeHandler}
																className='rounded-lg mt-2  w-full border h-12 appearance-none block text-gray-700 border border-gray-200  py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
																type='time'
																placeholder='Time'
																name='checkInTime'
																onChange={updateModalValuesHandler}
																// defaultValue={updateAttendeceData?.checkInTime}
																value= {updateAttendeceData?.checkInTime}
															/>
														</div>)}
														{ updateAttendeceData?.attendenceType === 2 && (<div className='w-full sm:ml-3'>
						<label className='font-bold  ml-2 '>Pick Check Out Time</label>
															<input
																// value={UserData.time}
																// onChange={dateTimeHandler}
																className='rounded-lg mt-2  w-full border h-12 appearance-none block text-gray-700 border border-gray-200  py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
																type='time'
																placeholder='Time'
																name='CheckOutTime'
																onChange={updateModalValuesHandler}
																// defaultValue={updateAttendeceData?.CheckOutTime}
																value={updateAttendeceData?.CheckOutTime}
																// defaultValue={moment(new Date()).format("hh:mm")}
															/>
														</div>)}
	</div>
					</ModalBody>
					<ModalFooter>
					<Button colorScheme='gray' className='px-3 py-1 rounded mx-2 ' onClick={()=>setAttendenceUpdateMadal(false)}>
							Close
						</Button>
						<Button onClick={updateAttendenceHandler}>
						Update Attendence
						</Button>
					       
					</ModalFooter>
				</ModalContent>
			</Modal>



				</>

	)
}



const {user} = useSelector(state => state.auth);
console.log(user, "here")

return(
	
<>
{user?.user_type === 1 ?  <PrivateLayout>
<PageContainer>
<AttedendenceData/>
 <DashboardData/>
 </PageContainer>
 </PrivateLayout> : <PrivateLayout>
 <AttedendenceData/>
 
 </PrivateLayout>
 

 
 }

 </>
)
}

export default Dashboard
