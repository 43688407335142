import React, {useState} from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Stack,
	Input,
	Textarea,
	FormLabel,
	FormControl,
	FormErrorMessage,
	useToast,
	Select
} from "@chakra-ui/react"
import * as Yup from "yup"
import {useForm} from "react-hook-form"
import {useSelector, useDispatch} from "react-redux"
import {useYupValidationResolver} from "../../../hooks/useValidationResolver"
import {addSalary} from "../../../store/actions/salary"

const validationSchema = Yup.object({
	date: Yup.string().required("Date is required!"),
	//staff: Yup.string().required("Select staff"),
	// amount: Yup.string().required("Amount is required!"),
	comment: Yup.string()
})

const AddSalaryModal = ({getStaffUser , getSalary}) => {
	const dispatch = useDispatch()
	const toast = useToast()

	const {user} = useSelector(state => ({
		user: state.auth.user
	}))
	const [loading, setLoading] = useState(false)
	const {isOpen, onOpen, onClose} = useDisclosure()
	const resolver = useYupValidationResolver(validationSchema)
	const {
		register,
		handleSubmit,
		formState: {errors, touchedFields},
		reset
	} = useForm({
		resolver,
		mode: "onBlur"
	})
	const onAddSalary = data => {

		setLoading(true)
		dispatch(
			addSalary({
				date: data.date,
				staff: data.staff || "179",
				amount: data.amount || 0,
				online_amount : data.online_amount || 0 , 
				comment: data.comment
			})
		)
			.then(() => {
				setLoading(false)
				onClose();
				getSalary();
				toast({
					title: "Salary has been added!",
					status: "success",
					duration: 3000,
					isClosable: true
				})
				// reset the form
				reset()
			})
			.catch(err => {
				setLoading(false)
				console.log("ERR => while adding expense", err)
			})
	}
	var curr = new Date()
	curr.setDate(curr.getDate())
	var date = curr.toISOString().substr(0, 10)

	return (
		<>
			<>
				<Button colorScheme='blue' onClick={onOpen} disabled={user.user_type === 2 ? true : false}>
					Add
				</Button>

				<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
					<ModalOverlay />
					<form
						style={{overflow: "auto"}}
						noValidate
						onSubmit={handleSubmit(onAddSalary)}
						className='bg-white'>
						<ModalContent>
							<ModalHeader>Add Salary</ModalHeader>
							<ModalCloseButton />

							<ModalBody>
								<Stack spacing={3}>
									<FormControl isInvalid={touchedFields?.date && errors?.date} id='date' isRequired>
										<FormLabel>Date</FormLabel>
										<Input
											{...register("date")}
											placeholder='Select date'
											type='date'
											defaultValue={date}
										/>
										<FormErrorMessage>{errors?.date?.message}</FormErrorMessage>
									</FormControl>

									<FormControl isInvalid={touchedFields?.staff && errors?.staff} id='staff'>
										<FormLabel>Staff</FormLabel>

										<Select {...register("staff")} placeholder='Select Staff'>
											{getStaffUser.length > 0 &&
												getStaffUser.map((v, i) => (
													<option key={i} value={v.id}>
														{v.name}
													</option>
												))}
										</Select>

										<FormErrorMessage>{errors?.staff?.message}</FormErrorMessage>
									</FormControl>

									<FormControl
										isInvalid={touchedFields?.amount && errors?.amount}
										id='amount'
										className='mb-3'
										>
										<FormLabel>Amount (cash)</FormLabel>
										<Input
											{...register("amount")}
											placeholder='Enter Cash Amount'
											type='number'
											min='0'
										/>
										<FormErrorMessage>{errors?.amount?.message}</FormErrorMessage>
									</FormControl>

									<FormControl
										isInvalid={touchedFields?.online_amount && errors?.online_amount}
										id='amount_online'
										className='mb-3'
										>
										<FormLabel>Amount (online)</FormLabel>
										<Input
											{...register("online_amount")}
											placeholder='Enter Online Amount'
											type='number'
											min='0'
										/>
										<FormErrorMessage>{errors?.online_amount?.message}</FormErrorMessage>
									</FormControl>


									<FormControl isInvalid={touchedFields?.comment && errors?.comment} id='comment'>
										<FormLabel>Comment</FormLabel>
										<Textarea
											rows='4'
											{...register("comment")}
											placeholder='Enter comment...'
											type='text'
										/>
										<FormErrorMessage>{errors?.comment?.message}</FormErrorMessage>
									</FormControl>
								</Stack>
							</ModalBody>
							<ModalFooter>
								<Button className='mr-4' onClick={onClose}>
									Close
								</Button>
								<Button isLoading={loading} colorScheme='blue' className='bg-gold' type='submit'>
									Add
								</Button>
							</ModalFooter>
						</ModalContent>
					</form>
				</Modal>
			</>
		</>
	)
}

export default AddSalaryModal
