import { combineReducers } from "redux";
import { authReducer } from "./auth";
import { expenseReducer } from "./expense";
import { categoriesListReducer } from "./categorieslist";
import { cartReducer } from "./cart";
import { serviceReducer } from "./service";
import { getUserReducer } from "./getUser";
import { orderReducer } from "./order";
import { salaryReducer } from "./salary";
import { staffUser } from "./staffUser";
import { GetServices } from "./getServices";
import { bookAppointemnt, getAppointmentListing } from "./bookAppointment"
import {getOfferReducer} from "./offer"

const rootReducer = combineReducers({
  auth: authReducer,
  expense: expenseReducer,
  products: categoriesListReducer,
  cart: cartReducer,
  service: serviceReducer,
  getUsers: getUserReducer,
  order: orderReducer,
  salary: salaryReducer,
  staffUser,
  getServices: GetServices,
  bookAppointemnt: bookAppointemnt,
  getAppointmentListing: getAppointmentListing,
  getOfferReducer : getOfferReducer
});

export { rootReducer };
