import React, {useState, useEffect} from "react"
import {Tab, TabList, Tabs} from "@chakra-ui/tabs"
import {Input, Skeleton, Stack} from "@chakra-ui/react"
import {useDispatch, useSelector} from "react-redux"
import Header from "../../components/Header"
import PageHeader from "../../components/PageHeader"
import Product from "./Product"
import {addCategories, productsByCategoryID} from "../../store/actions/categorieslist"
// import {Spinner} from "@chakra-ui/react"
import {useParams} from "react-router-dom"
import Loader from "../../components/Loader/Loader"
import styles from "./style.module.css"
// import Footer from "../../components/Footer"
// import debounce from "lodash.debounce"
const Products = ({history}) => {
	//  ========== From the Url Params ==========
	const {slug} = useParams()
	const dispatch = useDispatch()
	const [productsLoading, setProductsLoading] = useState(false)
	const [serachNamefilters, setSerachNamefilters] = useState("")
	const {Categorieslist, ProductsByIdForFrontEnd} = useSelector(state => state.products)
	const [selectedCategoryIndex, setSelectedCategoryIndex] = useState("")
	const [productsItmes, setProductsItems] = useState([])

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		})

		dispatch(addCategories())
			.then(() => {
				document.getElementById("products")?.scrollIntoView({behavior: "smooth"})
			})
			.catch(err => {
				console.log("ERR => while fetching all Categories", err)
			})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slug])

	useEffect(() => {
		setProductsLoading(true)
		const selectedCategory =
			Categorieslist[selectedCategoryIndex]?.id || Categorieslist.find(product => product.slug === slug)?.id
		dispatch(productsByCategoryID({categoryId: selectedCategory}))
			.then(() => {
				setProductsLoading(false)
				document.getElementById("products")?.scrollIntoView({behavior: "smooth"})
			})
			.catch(err => {
				setProductsLoading(false)
				console.log("ERR => while fetching all Categories", err)
			})
		setSerachNamefilters("")
		setProductsItems([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCategoryIndex])

	// const callApi = ({searchText, categoryId, categoryList}) => {
	// 	const selectedCategory = categoryList[categoryId]?.id || ""
	// 	dispatch(
	// 		productsByCategoryID({
	// 			categoryId: selectedCategory,
	// 			searchText
	// 		})
	// 	)
	// }

	// const handler = useCallback(
	// 	debounce(({searchText, categoryId, categoryList}) => {
	// 		callApi({searchText, categoryId, categoryList})
	// 	}, 500),
	// 	[]
	// )

	// const onChangeSearch = e => {
	// 	// perform any event related action here
	// 	setSerachNamefilters(e.target.value)
	// 	handler({
	// 		searchText: e.target.value,
	// 		categoryId: selectedCategoryIndex,
	// 		categoryList: Categorieslist
	// 	})
	// }

	const onChangeSearch = e => {
		setSerachNamefilters(e.target.value)
		const filteredData = ProductsByIdForFrontEnd.filter(product =>
			product?.name?.toLowerCase().includes(e.target.value.toLowerCase())
		)
		setProductsItems(filteredData)
	}

	return (
		<>
			{/* <Helmet>
				<title>Services for you | Curlys Unisex Hair & Makeup Studio</title>
			</Helmet> */}
			<Header id='products' title='Services for you | Curlys Unisex Hair & Makeup Studio' />
			<PageHeader
				pageHeading='Services for you'
				rightContent={
					<div className='mt-3 md:mt-0' 	>
						<Input
							type='text'
							value={serachNamefilters}
							onChange={onChangeSearch}
							variant='outline'
							placeholder='Search'
						/>
					</div>
				}
			/>

			<div className='my-3'>
				<div className='flex  flex-col servicePageCenter'>
					<div className={`${styles.categorySection} h-4/5 w-full xl:w-5/6 m-auto mt-8 `}>
						<h1 className='text-center text-3xl font-bold'>Categories </h1>
						<Tabs
							className='mt-4'
							defaultIndex={Categorieslist?.findIndex(product => product.slug === slug) || 0}>
							<TabList
								className={`flex flex-row  whitespace-nowrap lg:justify-between flex-nowrap overflow-y-auto pb-5 ${styles.tabList}`}>
								{Categorieslist.map((element, index) => {
									return (
										<Tab
											tabIndex={index}
											key={element.id}
											onClick={() => {
												setSelectedCategoryIndex(index)
												history.push(`/curly-service/${element.slug}`)
											}}
											value={element.id}
											className='mx-2 md:mx-1  mb-2 rounded'>
											<h1 className='text-xs md:text-base '>{element.name} </h1>
										</Tab>
									)
								})}
							</TabList>
						</Tabs>
					</div>

					<div className='w-full xl:w-5/6 m-auto mt-5'>
						{productsLoading ? (
							// <Loader
							// 	className='items-center w-full justify-center '
							// 	style={{height: "calc(100vh - 15rem)"}}
							// />
							<>
							</>
						) : (
							<Product
								productsItmes={productsItmes.length !== 0 ? productsItmes : ProductsByIdForFrontEnd}
							/>
						)}
					</div>
				</div>
			</div>
			{/* <Footer /> */}
		</>
	)
}

export default Products
