import React, {useEffect} from "react"
import {FormControl, FormErrorMessage, FormLabel} from "@chakra-ui/form-control"
import {Input} from "@chakra-ui/input"
import {Flex, Link, Stack} from "@chakra-ui/layout"
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay
} from "@chakra-ui/modal"
import {useState} from "react"
import {IMAGE_BASE_URL} from "../../constants/api"
import ProfileAvatar from "../../components/ProfileAvatar"
import {Select} from "@chakra-ui/select"
import {updateUserByAdmin} from "../../store/actions/auth"
import {useForm} from "react-hook-form"
import {useYupValidationResolver} from "../../hooks/useValidationResolver"
import * as Yup from "yup"
import {useDisclosure} from "@chakra-ui/hooks"
import {Button} from "@chakra-ui/button"
import {useToast} from "@chakra-ui/toast"
import {useDispatch} from "react-redux"

const validationSchema = Yup.object({
	name: Yup.string().required("Name is required!")
})

const UpdateUserModal = ({user, imageRef, UpdateUserList}) => {
	const [profileImage, setProfileImage] = useState({
		preview: user?.image && `${IMAGE_BASE_URL}/${user?.image}`,
		raw: null
	})
	const resolver = useYupValidationResolver(validationSchema)
	const toast = useToast()
	const {isOpen, onOpen, onClose} = useDisclosure()
	const dispatch = useDispatch()
	const addToast = error => {
		toast({
			title: error,
			status: "error",
			duration: 3000,
			isClosable: true
		})
	}

	const [date, setDate] = useState(user?.dob || "")
	const [loading, setLoading] = useState(false)
	const [anniversaryDate, setAnniversaryDate] = useState(
		user?.anniversary === "0000-00-00" || "" ? "" : user?.anniversary
	)

	const setAnniversaryHandler = e => {
		const currentDate = new Date()
		const month = currentDate.getMonth() + 1
		const targetedValue = e.target.value
		const mainDate = currentDate.getFullYear() + "-" + month + "-" + currentDate.getDate()
		if (mainDate < targetedValue) {
			addToast("Invalid Anniversary Date")
		} else {
			setAnniversaryDate(targetedValue)
		}
	}

	const setDateHandler = e => {
		const currentDate = new Date()
		const month = currentDate.getMonth() + 1
		const targetedValue = e.target.value
		const mainDate = currentDate.getFullYear() + "-" + month + "-" + currentDate.getDate()
		if (mainDate < targetedValue) {
			addToast("Invalid DOB")
		} else {
			setDate(targetedValue)
		}
	}

	const {
		register,
		handleSubmit,
		formState: {errors, touchedFields},
		reset
	} = useForm({
		defaultValues: user,
		resolver,
		mode: "onBlur"
	})

	useEffect(() => {
		reset(user)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user])

	const onUpdateProfile = data => {
		const currentDate = new Date()
		currentDate.getTime() <= data.date && alert("This is big from the current Date ")
		setLoading(true)
		dispatch(
			updateUserByAdmin({
				id: user.id,
				name: data.name,
				name1: data.name1,
				email: data.email,
				dob: date,
				anniversary: anniversaryDate,
				gender: data.gender || 1,
				comment: data.comment,
				address: data.address,
				image: profileImage.raw,
				device_token: "555",
				signup_type: 1
			})
		)
			.then(response => {
				setLoading(false)
				// reset the form
				reset()
				setDate("")
				setAnniversaryDate("")
				UpdateUserList()
				onClose()
				toast({
					title: "Profile Updated",
					status: "success",
					duration: 3000,
					isClosable: true
				})
			})
			.catch(err => {
				setLoading(false)
				toast({
					title: "Something went wrong",
					status: "error",
					duration: 3000,
					isClosable: true
				})
				console.log("ERR => while login user", err)
			})
	}

	const onError = (errors, e) => console.log(errors, e)
	const onImageSelect = event => {
		setProfileImage({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		})
	}

	return (
		<>
			<i className='bx bxs-edit text-2xl cursor-pointer ' onClick={onOpen} />
			<div>
				<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
					<ModalOverlay />
					<form
						style={{overflow: "auto"}}
						noValidate
						onSubmit={handleSubmit(onUpdateProfile, onError)}
						className='bg-white'>
						<ModalContent>
							<ModalHeader>Update Profile</ModalHeader>
							<ModalCloseButton />
							<ModalBody>
								<Stack spacing={3}>
									<FormControl isInvalid={touchedFields?.name && errors?.name} id='name'>
										<FormLabel>Full Name</FormLabel>
										<Input {...register("name")} placeholder='Enter your name' />
										<FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
									</FormControl>

									<FormControl isInvalid={touchedFields?.name1 && errors?.name1} id='name1'>
										<FormLabel>Friendly Name</FormLabel>
										<Input {...register("name1")} placeholder='Enter your Friendly name' />
										<FormErrorMessage>{errors?.name1?.message}</FormErrorMessage>
									</FormControl>

									<FormControl isInvalid={touchedFields?.email && errors?.email} id='email'>
										<FormLabel>Email</FormLabel>
										<Input {...register("email")} placeholder='Enter your email' />
										<FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
									</FormControl>

									<FormControl isInvalid={touchedFields?.dob && errors?.dob} id='dob'>
										<FormLabel>DOB</FormLabel>
										<Input
											// {...register("dob")}
											value={date}
											onChange={setDateHandler}
											type='date'
											placeholder='Enter your DOB'
										/>
										<FormErrorMessage>{errors?.dob?.message}</FormErrorMessage>
									</FormControl>

									<FormControl isInvalid={touchedFields?.anniversary && errors?.dob} id='dob'>
										<FormLabel>ANNIVERSARY</FormLabel>
										<Input
											// {...register("dob")}
											value={anniversaryDate}
											onChange={setAnniversaryHandler}
											type='date'
											placeholder='Enter your DOB'
										/>
										<FormErrorMessage>{errors?.anniversary?.message}</FormErrorMessage>
									</FormControl>

									<FormControl isInvalid={touchedFields?.gender && errors?.gender} id='gender'>
										<FormLabel>Gender</FormLabel>
										<Select placeholder='Select Gender' {...register("gender")}>
											<option value='1'>Male</option>
											<option value='2'>Female </option>
										</Select>
										<FormErrorMessage>{errors?.gender?.message}</FormErrorMessage>
									</FormControl>

									<FormControl isInvalid={touchedFields?.comment && errors?.comment} id='comment'>
										<FormLabel>Comment</FormLabel>
										<Input {...register("comment")} placeholder='Add comment' />
										<FormErrorMessage>{errors?.comment?.message}</FormErrorMessage>
									</FormControl>
									<FormControl isInvalid={touchedFields?.address && errors?.address} id='address'>
										<FormLabel>Address</FormLabel>
										<Input {...register("address")} placeholder='Enter your address' />
										<FormErrorMessage>{errors?.address?.message}</FormErrorMessage>
									</FormControl>

									<FormControl>
										<FormLabel>Profile Image</FormLabel>
										<Flex alignItems='center' mt={1}>
											<ProfileAvatar image={profileImage.preview} />
											<input
												accept='image/*'
												ref={imageRef}
												type='file'
												onChange={onImageSelect}
												hidden
											/>
											<Link
												ml={5}
												color='blue.500'
												fontWeight='medium'
												_focus={{shadow: "none"}}
												onClick={() => imageRef.current.click()}>
												Change
											</Link>
										</Flex>
									</FormControl>
								</Stack>
							</ModalBody>

							<ModalFooter>
								<Button className='mr-4' onClick={onClose}>
									Close
								</Button>
								<Button
									isLoading={loading}
									disabled={Object.keys(errors).length}
									colorScheme='blue'
									className='bg-gold'
									type='submit'>
									Update
								</Button>
							</ModalFooter>
						</ModalContent>
					</form>
				</Modal>
			</div>
		</>
	)
}

export default UpdateUserModal
