   const initialState = {
       staffUser :  []
   }
   export  const staffUser = (state= initialState,action)=>{
    switch (action.type) {
         case "GET_STAFF" :   
         return {
             ...state,
             staffUser :  action.payload   
         }
         default :  return state
    }
   }
   