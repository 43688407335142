import {BrowserRouter as Router, Switch, Route} from "react-router-dom"
import AdminRoute from "./AdminRoute"

// Public Route imports
import Home from "../pages/Home"
import Login from "../pages/Login"
import Register from "../pages/Register"
import Services from "../pages/Services"
import Bridal from "../pages/Bridal"
import Nails from "../pages/Nails"

import Republic from "../pages/RepublicDay"
import Installation from "../pages/Installation"

// Private Routes imports

import ListExpense from "../pages/Expense/ListExpense"
import Products from "../pages/Products"
import ShoppingCart from "../pages/ShoppingCart"
import Service from "../pages/Service"
import QucikInvoice from "../pages/QuickInvoice"
import ProcceedCheckout from "../components/Modals/ProcceedCheckout"
import Profile from "../pages/Profile/Profile"
import BillPage from "../pages/BillPage/BillPage"
import Listing from "../pages/Listing"
import Salary from "../pages/Salary"
import Todo from "../pages/Todo"
import UsersList from "../pages/UsersList/UsersList"
import StaffRoute from "./StaffRoute"
import UserRoute from "./UserRoute"
import ErrorPage from "../components/ErrorPage"
import BookingList from "../pages/Booking/BookingList"
import MyOrders from "../pages/Booking/MyOrders"
import Dashboard from "../pages/Dashboard"
import Offers from "../pages/Offers"
import OffersFrontend from "../pages/OffersFrontend"
import MarkAttendence from "../pages/Dashboard/MarkAttendence"
import Footer from "../components/Footer"
import SEOComponent from "../SEOComponent"

const AppRoutes = () => {
	// const footerRoutes = [
	// 	"/",
	// 	"/cart",
	// 	"/my-appointments",
	// 	"/curly-service/:slug",
	// 	"/bridal",
	// 	"/services",
	// 	"/offers",
	// 	"/republic-day",
	// 	"/installation"
	// ]

	return (
		<Router>
			<SEOComponent />
			<Switch>
				{/* User Route */}
				{/* <UserRoute path='/products' component={Products} /> */}
				<UserRoute path='/cart' component={ShoppingCart} />
				<UserRoute path='/profile' component={Profile} />
				<UserRoute path='/my-appointments' component={MyOrders} />

				{/* Staff Routes  */}
				<StaffRoute path='/quickInvoice' component={QucikInvoice} />
				<StaffRoute path='/expenses' component={ListExpense} />
				<StaffRoute path='/service' component={Service} />
				{/* <StaffRoute path='/mark-attendence' component={MarkAttendence} /> */}
				<StaffRoute path='/listing' component={Listing} />
				<StaffRoute path='/salary' component={Salary} />
				<StaffRoute path='/profile' component={Profile} />
				<StaffRoute path='/cart' component={ShoppingCart} />
				<StaffRoute path='/dashboard' component={Dashboard} />

				{/* Admin Routes */}
				{/* <AdminRoute path='/dashboard' component={Dashboard} /> */}
				<AdminRoute path='/expenses' component={ListExpense} />
				<AdminRoute path='/cart' component={ShoppingCart} />
				<AdminRoute path='/userList' component={UsersList} />

				<AdminRoute path='/mark-attendence' component={MarkAttendence} />
				<AdminRoute path='/service' component={Service} />
				<AdminRoute path='/quickInvoice' component={QucikInvoice} />
				<AdminRoute path='/offer-list' component={Offers} />
				<AdminRoute path='/cart' component={ShoppingCart} />
				<AdminRoute path='/demo' component={ProcceedCheckout} />
				<AdminRoute path='/profile' component={Profile} />
				<AdminRoute path='/listing' component={Listing} />
				<AdminRoute path='/salary' component={Salary} />
				<AdminRoute path='/booking-list' component={BookingList} />

				{/* Public Routes */}

				<Route exact path='/' component={Home} />
				<Route path='/login' component={Login} />
				<Route path='/register' component={Register} />
				<Route path='/curly-service/:slug' component={Products} />
				<Route path='/billing/:id' component={BillPage} />
				<Route path='/services' component={Services} />
				<Route path='/offers' component={OffersFrontend} />
				<Route path='/bridal' component={Bridal} />
				<Route path='/nails' component={Nails} />
				<Route path='/republic-day' component={Republic} />
				<Route path='/installation' component={Installation} />
				<Route component={ErrorPage} />
			</Switch>
			<Footer />
			{/* {footerRoutes.includes(window.location.pathname) && <Footer />} */}
		</Router>
	)
}
export default AppRoutes
