import React, {useState,useEffect} from "react"
import PageContainer from "../../components/PageContainer"
import PageHeader from "../../components/PageHeader"
import {useDispatch, useSelector} from "react-redux"
import {Button} from "@chakra-ui/react"
import {Table, Thead, Tbody, Tr, Th, Td, Box} from "@chakra-ui/react"
import {incProductQuantity, decProductQuantity, fetchCartDetails ,  EmptyCart} from "../../store/actions/cart"
import {formatCurrency} from "../../utils/currencyFormat"
import Header from "../../components/Header"
import {bookAppointemntCart} from "../../store/actions/getAppointment"
import EmptyCartPage from "./EmptyCart"
import { Spinner } from "@chakra-ui/react"
import Loader from "../../components/Loader/Loader"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure
} from "@chakra-ui/react"
import {useHistory} from "react-router-dom"
// import Footer from "../../components/Footer"

const ShoppingCart = () => {
	const {cartItems} = useSelector(state => state.cart);
	const { onClose} = useDisclosure();
	const [loading, setLoading] = useState(false)

	const {user} = useSelector(state => ({
		user: state.auth.user
	}))
	const dispatch = useDispatch()

    useEffect(()=>{
	   setLoading(true)
       dispatch(fetchCartDetails()).then(()=>{
		setLoading(false)
	   })
	  
	   // eslint-disable-next-line react-hooks/exhaustive-deps
	},[])



	const [ismodalOpen, setIsModalOpen] = useState(false)


	// ======================= Loading state For Quanity Increase Or Decrease ===================

	const [quantityLoading ,setQuantityLoading] = useState({
		id : "" , 
		loading: false
	})

    
	const [UserData, setUserData] = useState({
		date: "",
		time: "",
		name: user?.name,
		phone: user?.phone,
		services: cartItems,
		user_id: user?.id
	})

	const history = useHistory()

	const allProductsSubTotal = cartItems.reduce(
		(a, v) => (a = parseInt(a) + parseInt(v.price) * parseInt(v.quantity)),
		0
	)

	const allProductsDiscount = cartItems.reduce(
		(a, v) => (a = parseInt(a) + parseInt(v.discount) * parseInt(v.quantity)),
		0
	)

	const allProductsTotal = allProductsSubTotal - allProductsDiscount

	// ===================== date Logic ========================== 

	let dtToday = new Date()
	let month = dtToday.getMonth() + 1
	let day = dtToday.getDate()
	let year = dtToday.getFullYear()
	if (month < 10) month = "0" + month.toString()
	if (day < 10) day = "0" + day.toString()
	const minDate = year + "-" + month + "-" + day

	const dateTimeHandler = e => {
		setUserData(prev => ({
			...prev,
			[e.target.name]: e.target.value
		}))
	}

	const BooKAppointmentHandler = () => {
		const data = {
			...UserData,
			name: user.name,
			phone: user.phone,
			services: {
				service : JSON.stringify(cartItems),
				bridalDay : JSON.stringify({})
			},
			user_id: user.id
		}
		setLoading(true)
		dispatch(bookAppointemntCart(data)).then(() =>{
			setLoading(false)
			setIsModalOpen(true)
		})
	}


	const goToHomeHandler = () => {
		dispatch(EmptyCart()).then(() => {
			setUserData(prev => ({
				...prev,
				name: "",
				phone: "",
				date: "",
				time: "",
				services: ""
			}))
		})
		setIsModalOpen(false)
		history.push("/")
	}

	const ViewOrderHandler = () => {
		dispatch(EmptyCart()).then(() => {
			setUserData(prev => ({
				...prev,
				name: "",
				phone: "",
				date: "",
				time: "",
				services: ""
			}))
		})
		setIsModalOpen(false)
		history.push("/my-appointments")
	}

	return (
		<>
			<Header title='My Room | Curlys Unisex Hair & Makeup Studio'/>
			<PageHeader pageHeading='Services In My Room' />
			{loading && <Loader className='items-center ' style={{height : "calc(100vh - 16rem )"}}/>}
			<PageContainer>
				{!loading && (
					<div className='flex justify-center my-6'>
						<div className='flex flex-col w-full md:p-8 p-8 text-gray-800 bg-white shadow-lg pin-r pin-y'>
							{!loading && cartItems.length === 0 ? (
								<EmptyCartPage />
							) : (
								<div className='flex-1'>
									<Box overflowX='auto' w='100%'>
										<Table className='my-4' variant='simple'>
											<Thead>
												<Tr className='h-12 uppercase'>
													{/* <Th className="hidden md:table-cell" /> */}
													{/* <Th className="text-center text-base"></Th> */}
													<Th className='text-left text-base'>Service</Th>
													<Th className=' text-left text-base md:table-cell whitespace-nowrap'>
														<span className='text-left '>Quantity</span>
													</Th>
													<Th className=' text-left text-base md:table-cell whitespace-nowrap'>
														Unit Charges
													</Th>
													<Th className='text-left text-base whitespace-nowrap md:table-cell'>
														Discount
													</Th>
													<Th className=' text-left text-base whitespace-nowrap'>
														Total Charges
													</Th>
												</Tr>
											</Thead>
											<Tbody>
												{cartItems.map((res,index) => {
													return (
														<Tr key={res.id}>
															<Td className='whitespace-nowrap text-left'>
																<div className='flex items-center '>
																	<p className='mb-2'>{res.name}</p>
																</div>
															</Td>

															<Td className='justify-center md:flex '>
																<div className='flex flex-row items-center  w-full h-8'>
																
																	<Button
																	disabled = {quantityLoading.loading}
																		className=' w-8 h-8 bg-gold text-white shadow-none'
																		aria-label='Search database'
																		onClick={() => {
																			setQuantityLoading((prev)=>({...prev , id:res.id , loading : true }))
																			dispatch(decProductQuantity(res)).then(()=>{
																			setQuantityLoading((prev)=>({...prev , loading : false }))
																			})
																		}}>
																		<span> - </span> 
																	</Button>
																	<Button  className='w-8 h-8 px-2 m-1 cursor-auto shadow-none bg-gray-200 md:text-base text-xs'>
																		<span>
																			{(quantityLoading.id === res.id && quantityLoading.loading) ? <Spinner size='sm'/> : res.quantity}
																		</span>
																	</Button>

																	<Button
																		className='bg-gold shadow-none text-white w-8 h-8'
																		disabled = {quantityLoading.loading}
																		aria-label='Search database'
																		onClick={() =>{
																			   setQuantityLoading((prev)=>({...prev , id:res.id , loading : true }))
																			dispatch(incProductQuantity(res)).then(()=>{
																				setQuantityLoading((prev)=>({...prev , loading : false}))
																			})
																		}}>
																		<span>+</span>
																	</Button>
																</div>
															</Td>
															<Td className='text-left md:table-cell'>
																<span className='text-sm lg:text-base md:text-base text-xs font-medium'>
																	{formatCurrency(Number(res.price))}
																</span>
															</Td>
															<Td className='text-left'>
																<span className='text-sm lg:text-base md:text-base text-xs font-medium'>
																	{formatCurrency(res.discount * res.quantity)}
																</span>
															</Td>
															<Td className='text-left'>
																<span className='text-sm lg:text-base font-medium'>
																	{formatCurrency(
																		res.quantity * res.price -
																			res.discount * res.quantity
																	)}
																</span>
															</Td>
														</Tr>
													)
												})}
											</Tbody>
										</Table>
									</Box>
									<hr className='pb-6 mt-6' />
									<p className='text-red-600 text-center font-medium text-sm'>
										*Note: This price may vary till appointment confirmation.*
									</p>
									<div className='my-4 mt-6 -mx-2 lg:flex'>
										<div className='lg:px-2 lg:w-1/2 lg:mx-auto'>
											<div className='p-4 bg-gray-100 rounded-full'>
												<h1 className='ml-2 text-center text-lg font-bold uppercase'>
													My Room Details
												</h1>
											</div>
											<div className='p-4'>
												{/* <p className="mb-6 italic">
                        Shipping and additionnal costs are calculated based on
                        values you have entered
                      </p> */}
												<div className='flex justify-between border-b'>
													<div className='lg:px-4 lg:py-2 m-2 text-base lg:text-lg font-bold text-center text-gray-800'>
														Subtotal
													</div>
													<div className='lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-center text-gray-900'>
														{formatCurrency(allProductsSubTotal)}
													</div>
												</div>
												<div className='flex justify-between pt-4 border-b'>
													<div className='lg:px-4 lg:py-2 m-2 text-base lg:text-lg font-bold text-center text-gray-800'>
														Discount
													</div>
													<div className='lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-center text-gray-900'>
														{formatCurrency(allProductsDiscount)}
													</div>
												</div>
												<div className='flex justify-between pt-4 border-b'>
													<div className='lg:px-4 lg:py-2 m-2 text-base lg:text-lg font-bold text-center text-gray-800'>
														Total
													</div>
													<div className='lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-center text-gray-900'>
														{formatCurrency(allProductsTotal)}
													</div>
												</div>

												<div className='w-full mt-10'>
													<h1 className='text-center text-xl sm:text-3xl mt-12 font-bold'>
														Book Appointment
													</h1>
													<div className='flex sm:flex-row flex-col sm:justify-between mt-6'>
														<div className='w-full  sm:mr-3 '>
															<label className='font-bold ml-2'>Pick Date</label>
															<input
																className='rounded-lg mt-2 mb-6 w-full border h-12 appearance-none block text-gray-700 border border-gray-200  py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
																value={UserData.date}
																onChange={dateTimeHandler}
																type='date'
																name='date'
																placeholder='Date'
																// defaultValue={moment(new Date()).format("YYYY-MM-DD")}
																min={minDate}
															/>
														</div>
														<div className='w-full sm:ml-3'>
															<label className='font-bold  ml-2 '>Pick Time</label>
															<input
																value={UserData.time}
																onChange={dateTimeHandler}
																className='rounded-lg mt-2  w-full border h-12 appearance-none block text-gray-700 border border-gray-200  py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
																type='time'
																placeholder='Time'
																name='time'
																// defaultValue={moment(new Date()).format("hh:mm")}
															/>
														</div>
													</div>
													<button
														onClick={BooKAppointmentHandler}
														className='bg-gold mt-8 block mx-auto px-4 py-2 text-white rounded font-base'>
														Book Appointment
													</button>
												</div>
												{/* <OrderDetailModal userModal = {UserData} ismodalOpen={modalState} / > */}
											</div>
										</div>
									</div>

									<Modal isOpen={ismodalOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
										<ModalOverlay className='modalOverlay bg-gold-701' />
										<ModalContent>
											<ModalHeader className='capitalize text-center bg-gold text-white'>
												{" "}
												Your Appointment Details{" "}
											</ModalHeader>
											<ModalCloseButton
												className='text-white'
												onClick={() => {
													dispatch(EmptyCart()).then(() => {
														setIsModalOpen(false)
													})
												}}
											/>
											<ModalBody>
												<div className='flex justify-center mt-3'>
													<img src='/img/thank-you.png' alt='' width='100' />
												</div>
												<h1 className='text-center font-bold text-xl'>
													Your Appointment has been booked
												</h1>
												<div className='wrapper  p-2'>
													<div className=' p-3.5 rounded-xl mb-3'>
														<div className='body'>
															<div className='flex flex-wrap justify-between py-1'>
																<h6 className='font-medium text-gray-400'>Name</h6>
																<p className='font-medium text-gray-700'>
																	{UserData?.name}
																</p>
															</div>
															<div className='flex flex-wrap justify-between py-1'>
																<h6 className='font-medium text-gray-400'>
																	Appointment Date
																</h6>
																<p className='font-medium text-gray-700'>
																	{UserData?.date ? UserData?.date : "Not Mentioned"}
																</p>
															</div>
															<div className='flex flex-wrap justify-between py-1'>
																<h6 className='font-medium text-gray-400'>
																	Appointment Time
																</h6>
																<p className='font-medium text-gray-700'>
																	{UserData.time ? UserData.time : "Not Mentioned"}
																</p>
															</div>
															<div className='flex flex-wrap justify-between py-1'>
																<h6 className='font-medium text-gray-400'>Services</h6>
																<p className='font-medium text-gray-700'>
																	{UserData?.services?.map(v => v.name).toString()}
																</p>
															</div>
															<div className='flex flex-wrap justify-between py-1 mt-2'>
																<button
																	className='font-bold rounded-lg bg-gray-300 py-2 px-4'
																	onClick={goToHomeHandler}>
																	Go To Home
																</button>
																<button
																	className='font-bold text-white rounded-lg bg-gold py-2 px-4'
																	onClick={ViewOrderHandler}>
																	View Appointments
																</button>
															</div>
														</div>
													</div>
												</div>
											</ModalBody>
											<ModalFooter></ModalFooter>
										</ModalContent>
									</Modal>
								</div>
							)}
						</div>
					</div>
				)}
			</PageContainer>
			{/* <Footer /> */}
		</>
	)
}

export default ShoppingCart
