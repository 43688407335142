import React, {useState, useEffect} from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../Home/index.css"
const Carousel = ({images}) => {
	const [count, setCount] = useState(0)

	useEffect(() => {
		const targetCount = 1000
		const animationDuration = 4000
		const intervalDuration = 10
		const steps = animationDuration / intervalDuration
		const increment = Math.ceil(targetCount / steps)

		let currentCount = 0
		let step = 0

		const intervalId = setInterval(() => {
			currentCount += increment
			step++

			if (currentCount >= targetCount || step >= steps) {
				clearInterval(intervalId)
				currentCount = targetCount
			}

			setCount(currentCount)
		}, intervalDuration)

		return () => {
			clearInterval(intervalId)
		}
	}, [])

	const settings = {
		className: "slider variable-width",
		dots: true,
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		swipeToSlide: true,
		autoplay: true,
		autoplaySpeed: 10000
	}

	return (
		<>
		<div className='slide-container  object-cover '>
			<Slider {...settings}>
				{images.map((image, index) => (
					<img className='object-cover min-w-full !w-full' key={index} src={image} alt={`Slide ${index}`} />
				))}
			</Slider>
			</div>
		</>
	)
}
export default Carousel





// import React, {useState, useEffect} from "react"

// const Carousel = ({images}) => {
// 	const [currentSlide, setCurrentSlide] = useState(0)
// 	const [isTransitioning, setIsTransitioning] = useState(false)

// 	const nextSlide = () => {
// 		if (!isTransitioning) {
// 			setIsTransitioning(true)
// 			setCurrentSlide(prevSlide => (prevSlide + 1) % images.length)
// 		}
// 	}

// 	const prevSlide = () => {
// 		if (!isTransitioning) {
// 			setIsTransitioning(true)
// 			setCurrentSlide(prevSlide => (prevSlide - 1 + images.length) % images.length)
// 		}
// 	}

// 	const handleTransitionEnd = () => {
// 		setIsTransitioning(false)
// 	}

// 	useEffect(() => {
// 		const interval = setInterval(nextSlide, 300000)

// 		return () => clearInterval(interval)
// 	}, [])

// 	return (
// 		<div className='carousel overflow-hidden'>
// 			<div
// 				className='slide-container flex object-cover min-w-full'
// 				style={{
// 					transform: `translateX(-${currentSlide * 100}%)`,
// 					transition: isTransitioning ? "transform 0.5s ease-in-out" : "none"
// 				}}
// 				onTransitionEnd={handleTransitionEnd}>
// 				{images.map((image, index) => (
// 					<img className="object-cover min-w-full !w-full" key={index} src={image} alt={`Slide ${index}`} /> 
// 				))}
// 			</div>

// 			<div className='flex items-center '>
// 				<span
// 					id='boot-icon'
// 					onClick={prevSlide}
// 					className='bi bi-arrow-left-circle-fill'
// 					style={{
// 						fontSize: "40px",
// 						webkitTextTtrokeWidth: "0px",
// 						opacity: "1",
// 						color: "rgb(212, 164, 110)"
// 					}}></span>

// 				<span
// 					onClick={nextSlide}
// 					id='boot-icon'
// 					className='ml-auto flex justify-end bi bi-arrow-right-circle-fill'
// 					style={{
// 						fontSize: "40px",
// 						webkitTextTtrokeWidth: "0px",
// 						opacity: "1",
// 						color: "rgb(212, 164, 110)"
// 					}}></span>
// 			</div>
// 		</div>
// 	)
// }

// export default Carousel



