import React, {useState, useEffect, useCallback} from "react"
import {Table, Thead, Tbody, Tfoot, Select, Tr, Th, Td, Box, Input} from "@chakra-ui/react"
import {useSelector, useDispatch} from "react-redux"
import PrivateLayout from "../../layout/PrivateLayout"
import PageHeader from "../../components/PageHeader"
import PageContainer from "../../components/PageContainer"
import Loading from "../../components/Loading"
import AddServiceModal from "../../components/Modals/AddServiceModal"
import EditServiceModal from "../../components/Modals/EditServiceModal"
import {getStaffUserList} from "../../store/actions/getUser"
import Loader from "../../components/Loader/Loader"

import {addCategories, getProductsByCategoryId} from "../../store/actions/categorieslist"
import {addService ,updateSorting} from "../../store/actions/service"
import debounce from "lodash.debounce"
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"

const Service = ({history}) => {
	const dispatch = useDispatch()
	const {Categorieslist, productsByCategoryId} = useSelector(state => state.products)

	//const {getStaffUser} = useSelector(state => state.getUsers);


	const [loading, setLoading] = useState()
	const [selectedCategoryId, setselectedCategoryId] = useState("")
	const [staff, setStaff] = useState("")
	const [serachNamefilters, setSerachNamefilters] = useState("");
	const [sortedServices , setSortedServices] = useState(productsByCategoryId);
	const [sortData , setSortData] = useState({
		 sortType : "",
		 sortOrder : "",
	})

	// useEffect(() => {
	//   if (user.user_type == 2 || user.user_type == 3) {
	//     history.push("/");
	//   }
	// }, [user]);

	useEffect(() => {
		setLoading(true)
		dispatch(getStaffUserList())
		dispatch(addCategories())
			.then(() => {
				setLoading(false)
			})
			.catch(err => {
				setLoading(false)
				console.log("ERR => while fetching all Categories", err)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		dispatch(getProductsByCategoryId({categoryId: selectedCategoryId}))
			.then(() => {})
			.catch(err => {
				console.log("ERR => while fetching all Categories", err)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const sortArray = (sortType,sortorder)=>{
		setSortedServices((prev)=>{
		 const prevData = [...prev];
		 let sortedArray ;
		 if(sortType === "id"){
			 sortedArray = prevData.sort((prev ,next)=> sortorder==="ascending" ? prev?.id-next?.id : next?.id-prev?.id );
		 }
		 else if(sortType === "sorting"){
            sortedArray = prevData.sort((prev , next)=> sortorder==="ascending" ? prev?.sorting-next?.sorting : next?.sorting-prev?.sorting);
		 }
		 return sortedArray 
		})
   }



useEffect(()=>{
	setSortedServices(productsByCategoryId);
	if(sortData.sortOrder && sortData.sortType) {
		sortArray(sortData?.sortType , sortData?.sortOrder);
	}
},[productsByCategoryId])


const getServices = ()=>{
	setLoading(true)
		dispatch(
			getProductsByCategoryId({
				categoryId: "",
				searchText : "",
				staff :"",
			})
		).then(() => {
			setLoading(false)
		})
}




	const categoryName = catId => {
		const categorId = catId.split(",")
		const categories = categorId.map(id => Categorieslist.find(res => res.id === +id)?.name)
		return categories.join(",")
	}
	const statusBox = statusData => {
		if (statusData === 0) {
			return (
				<div className='flex items-center'>
					<div className='w-4 h-4 bg-red-600 mr-2'></div>
					<span>Disable</span>
				</div>
			)
		} else {
			return (
				<div className='flex items-center'>
					<div className='w-4 h-4 bg-green-600 mr-2'></div>
					<span>Enable</span>
				</div>
			)
		}
	}

	const callApi = ({searchText, categoryId, categoryList, staff}) => {
		const selectedCategory = categoryId || ""
		setLoading(true)
		dispatch(
			getProductsByCategoryId({
				categoryId: selectedCategory,
				searchText,
				staff
			})
		).then(() => {
			setLoading(false)
		})
	}

	const handler = useCallback(
		debounce(({searchText, categoryId, categoryList, staff}) => {
			callApi({searchText, categoryId, categoryList, staff})
		}, 500),
		[]
	) 
	// eslint-disable-line react-hooks/exhaustive-deps

	// const staffHandler = e => {
	// 	setStaff(e.target.value)
	// 	handler({
	// 		categoryId: selectedCategoryId,
	// 		categoryList: Categorieslist,
	// 		staff: e.target.value,
	// 		searchText: serachNamefilters
	// 	})
	// }

	const categoryHandler = e => {
		setselectedCategoryId(e.target.value);
		console.log(e.target.value)
		handler({
			searchText: serachNamefilters,
			categoryId: e.target.value,
			categoryList: Categorieslist,
			staff: staff
		})
	}

	const updateList = () => {
		handler({
			searchText: serachNamefilters,
			categoryId: selectedCategoryId,
			categoryList: Categorieslist,
			staff: staff
		});
	}

	const onChangeSearch = e => {
		// perform any event related action here
		setSerachNamefilters(e.target.value)
		handler({
			searchText: e.target.value,
			categoryId: selectedCategoryId,
			categoryList: Categorieslist,
			staff: staff
		})
	}

	// const [services, setServices] = useState(productsByCategoryId)
	const handleDragEnd = results => {
		// console.log("This is My Results ==========================> " , results)
		if(results?.source && results?.destination){
			let destinationIndex = results?.destination.index;
			let sourceIndex = results?.source?.index;
			const arrayData = sourceIndex > destinationIndex ? productsByCategoryId.slice(destinationIndex,sourceIndex) : productsByCategoryId.slice(sourceIndex+1,destinationIndex+1)
			const sourceObj = productsByCategoryId.find((el)=> el.name===results.draggableId);
	
			//   arrayData.map((el)=>{
			// 	console.log( "Old Object ==============>" , el.sorting  + " " + el.name)
			//   });
	
			// console.log( "This is Arrray Data =========================>" , arrayData)

			const prevData = productsByCategoryId[destinationIndex-1];
			const nextData = productsByCategoryId[destinationIndex+1];

		// 	console.log( "This is My Previous Data ============================> " , prevData);
		// 	console.log("This is My next Data =============================>" , nextData);

        //        let currentObjSorting = {
		// 				 ...sourceObj,
		// 				 sorting:  (destinationIndex===0 && 1)  || (sourceIndex > destinationIndex ? prevData.sorting + 1 : nextData?.sorting-1 || prevData.sorting+1)    //: destinationIndex-1
		// 	   }

		// 	   console.log("Old Sorting====>" , sourceObj.sorting  , "New Sorting===> " , currentObjSorting.sorting);

	
		// 	 arrayData.map((elm)=>{
		// 		 const obj ={
		// 			...elm,
		// 			sorting: sourceIndex > destinationIndex ? elm.sorting+1 : elm.sorting-1
		// 		 }	
		// 		 console.log( elm.sorting  ,"old==================> new" , obj.sorting +  "  "  +obj.name)
		// })
			
	// const obj = {
	// 	...sourceObj,
	// 			 sorting:  destinationIndex+1
	// }
	// console.log( sourceObj.sorting , " This is Object that i have to Updated ===============> " , obj.sorting);


    const sortingArray = [];
	     
	     sortingArray.push({
			 id:sourceObj.id,
			sorting:  (destinationIndex === 0 && 1)  || (sourceIndex > destinationIndex ? prevData?.sorting + 1 : nextData?.sorting-1|| prevData.sorting+1)
		 })
		 arrayData.map((elm)=>{
             sortingArray.push({
				 id:elm.id,
				 sorting :  sourceIndex > destinationIndex ? elm.sorting+1 : elm.sorting-1
			 })
		 });
		 dispatch(updateSorting(sortingArray)).then(()=>{
			getServices();
		 })

	
			//  dispatch(addService({
			// 	 ...sourceObj,
			// 	 sorting:  (destinationIndex===0 && 1)  || (sourceIndex > destinationIndex ? prevData.sorting + 1 : nextData.sorting-1)     //: destinationIndex-1
			//  }));
	
			// arrayData.map((elm)=>{
			// 		dispatch(addService({
			// 			...elm,
			// 			sorting:  sourceIndex > destinationIndex ? elm.sorting+1 : elm.sorting-1
			// 		}));
			// });
			// setTimeout(() => {
			// setLoading(true)
			// dispatch(getProductsByCategoryId({category_id : selectedCategoryId})).then(()=>{
			// 	setLoading(false)
			// })
			// }, 1000);
		}
	}



	return (
		<PrivateLayout>
			{loading && <Loader className='h-screen absolute z-10 w-full items-center' />}
			<PageHeader
			id="ServiceCategory"
				pageHeading='All Service'
				rightContent={
					<div className='flex-none md:flex'>
						<div className='md:mr-5 flex-none md:flex'>
							<div className='mt-3 md:mt-0 md:mr-3'>
								<Input
									type='text'
									value={serachNamefilters}
									onChange={onChangeSearch}
									variant='outline'
									placeholder='Search Here'
								/>
							</div>
                       
							{/* <div className='mt-3 md:mt-0 md:mr-5'>
								<Select placeholder='Staff' name='services' value={staff} onChange={staffHandler}>
									{getStaffUser?.map((element, index) => {
										return (
											<option key={element.id} value={element.name}>
												{element.name}
											</option>
										)
									})}
								</Select>
							</div> */}

							<div className='mt-3 md:mt-0 '>
								<Select
									// index={selectedCategoryId}
									onChange={categoryHandler}
									// onChange={(event) => {
									//   setselectedCategoryId(event.target.value);
									// }}
								>
									<option defaultValue='' hidden>
										Category
									</option>
									{Categorieslist.map((res, index) => (
										<option value={res.id} key={res.id}>
											{res.name}
										</option>
									))}
								</Select>
							</div>
						</div>
						<div className='mt-3 md:mt-0 '>
							<AddServiceModal updateList={updateList} getServices={getServices} />
						</div>
					</div>
				}
				title='Service'
			/>
			<PageContainer className="max-w-7xl mt-6 lg:px-4">
				{loading ? (
					<div className='flex justify-center items-center mt-8'>
						<Loading />
					</div>
				) : (
					<>
						<Box overflowX='auto' w='100%' style={{height : "600px", scrollBehavior:"unset"}} className="invisible-scrollbar">
							{productsByCategoryId.length > 0 && !loading ? (
								<DragDropContext onDragEnd={results => handleDragEnd(results)} >
									<Table className='my-4' variant='simple'>
										<Thead>
											<Tr>
												<Th></Th>
												<Th>Name</Th>
												<Th>Price</Th>
												<Th>Category</Th>
												<Th><div className='flex items-center'>
													ID
													<div className='ml-2'>
														<span
															onClick={()=>{sortArray( "id", "ascending"); setSortData((prev)=>({
																...prev,
																sortType : "id",
																sortOrder : "ascending"
															}))}}
															className='cursor-pointer'>
															<IoIosArrowUp />
														</span>
														<span
															onClick={()=>{sortArray( "id", "decending"); setSortData((prev)=>({
																...prev,
																sortType : "id",
																sortOrder : "decending"
															}))}}
															className='cursor-pointer'>
															<IoIosArrowDown />
														</span>
													</div>
												</div></Th>
												<Th>Discount</Th>
												<Th><div className='flex items-center'>
													Soritng
													<div className='ml-2'>
														<span
															onClick={()=>{sortArray( "sorting", "ascending"); setSortData((prev)=>({
																...prev,
																sortType : "sorting",
																sortOrder : "ascending"
															}))}}
															className='cursor-pointer'>
															<IoIosArrowUp />
														</span>
														<span
															onClick={()=>{sortArray( "sorting", "decending"); setSortData((prev)=>({
																...prev,
																sortType : "sorting",
																sortOrder : "decending"
															}))}}
															className='cursor-pointer'>
															<IoIosArrowDown />
														</span>
													</div>
												</div></Th>
												<Th>Status</Th>
												{/* <Th>Comment</Th> */}
											</Tr>
										</Thead>
										<Droppable droppableId='tbody' >
											{provided => (
												<Tbody ref={provided.innerRef} {...provided.droppableProps}>
													{sortedServices.map((res, index) => (
														<Draggable draggableId={res.name} index={index} key={res.id} isDragDisabled={(!selectedCategoryId ||selectedCategoryId==="14") ? false : true }>
															{provided => (
																<Tr
																	key={index}
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}>
																	<Td>
																		<EditServiceModal
																			service={res}
																			updateList={updateList}
																		/>
																	</Td>
																	<Td>{res.name}</Td>
																	<Td>{res.price}</Td>
																	<Td>{categoryName(res.category_id)}</Td>
																	<Td>{res.id}</Td>
																	<Td>{res.discount}</Td>
																	<Td>{res.sorting}</Td>
																	<Td>{statusBox(res.status)}</Td>
																	{/* <Td>
													<div
														dangerouslySetInnerHTML={{__html: res.comment}}
														className='div-pTag'
													/> */}
																	{/* <MDEditor.Markdown source={res.comment} /> */}
																	{/* </Td> */}
																</Tr>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</Tbody>
											)}
										</Droppable>
										<Tfoot>
											<Tr>
												<Th></Th>
												<Th></Th>
												<Th></Th>
												<Th></Th>
												<Th></Th>
												<Th></Th>
												<Th></Th>
											</Tr>
										</Tfoot>
									</Table>
								</DragDropContext>
							) : (
								<Box marginTop='16' textAlign='center'>
									No Service found!
								</Box>
							)}
						</Box>
					</>
				)}
			</PageContainer>
		</PrivateLayout>
	)
}

export default Service
