import React, {useState} from "react"
import {updateStatus} from "../../../store/actions/getAppointment"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Text,
	IconButton,
	useToast,
    Stack,
	Button
} from "@chakra-ui/react"
import {useDispatch} from "react-redux"

const ConfirmApprovelModal = ({userId ,updatelist}) => {


	 
    const [loading,setLoading] = useState(false)
	const Dispatcher = useDispatch()
	const toast = useToast()
	const {isOpen, onOpen, onClose} = useDisclosure()

	const ApprooveRequestHandler = () => {
		console.log(userId)
		const AprooveObj = {
			id: userId,
			status: 1,
			text: ""
		}
		setLoading(true)
		Dispatcher(updateStatus(AprooveObj)).then(() => {
			setLoading(false)
			toast({
				title: "Approved Successfully",
				status: "success",
				duration: 4000,
				isClosable: true
			})
			updatelist();
			onClose();
			
		})
	}

	return (
		<>
			<IconButton
				className='mr-3 bg-green-700'
				// disabled={status === 0 ? false : true}
				aria-label='Aproove'
				icon={<i className='bx bx-check text-white text-3xl bg-transparent'></i>}
				size='sm'
				onClick={onOpen}
			/>
			<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
				<ModalOverlay className='bg-gold-701 modalOverlay' />
				<ModalContent>
					<ModalHeader className='capitalize bg-gold text-center text-2xl font-medium text-white'>
						Approval Confirmation
					</ModalHeader>
					<ModalCloseButton className='text-white ' />
					<ModalBody>
					<Stack spacing={3} justifyContent='center' alignItems='center' className='mt-4'>
							<div className=''>
								<i
									className='bx bx-info-circle text-7xl text-red-600'
									style={{transform: "rotateZ(180deg)"}}></i>
							</div>
							<Text className='text-4xl font-bold text-gray-700 mb-3'>Are you sure?</Text>
							<Text className='text-xl font-medium text-gray-500 mb-2'>
								Do you want to Approve "Service" ?
							</Text>
						</Stack>
					</ModalBody>
					<ModalFooter className="justify-center mb-4">
						<Button className='px-4 py-1 rounded bg-gold mx-3 text-lg text-white' onClick={onClose}>
							No
						</Button>
						<Button isLoading={loading} className='px-4 py-1 rounded bg-gold mx-3 text-lg text-white' onClick={ApprooveRequestHandler}>
							Yes
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default ConfirmApprovelModal
