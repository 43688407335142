import React from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	IconButton
} from "@chakra-ui/react"
import {IoIosEye} from "react-icons/io"
const ViewSalaryModal = ({viewsalary}) => {
	const {isOpen, onOpen, onClose} = useDisclosure()

	return (
		<>
			<IconButton
				className='mr-3'
				colorScheme='teal'
				aria-label='Edit Expense'
				onClick={onOpen}
				icon={<IoIosEye />}
				size='sm'
			/>
			<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className='capitalize bg-gold text-center text-2xl font-medium text-white'>
						View Salary
					</ModalHeader>
					<ModalCloseButton className='text-white' />
					<ModalBody>
						<h5>Date: {viewsalary.date}</h5>
						<h5 className='mt-5'>Staff: {viewsalary?.staff?.name || ''}</h5>
						<h5 className='mt-5'>Amount: {viewsalary.amount}</h5>
						<h5 className='mt-5'>Comment: {viewsalary.comment}</h5>
					</ModalBody>

					<ModalFooter>
						<Button mr={3} onClick={() => window.print()} colorScheme='teal' className='bg-gold'>
							Print
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default ViewSalaryModal
