import axios from "../../utils/axios";
import { MULTIPART_FORM_DATA_HEADERS } from "../../utils";
import {
  UPDATE_CART_ITEMS,
  FETCH_CART_DETAIL,
} from "../constants/cart";
import {CHECK_USER_ENDPOINT } from "../../constants/api"

import { ADD_TO_CART_ENDPOINT } from "../../constants/api";


export const addCartItem = (cartItem) => (dispatch, getState) => {

  return new Promise(async (resolve, reject) => {
    try {
      const {
        cart: { cartItems },
      } = getState();
      let apiCartItems = [...cartItems];
      const foundCartItem = cartItems.find((item) => item.id === cartItem.id);
      if (foundCartItem) {
        return null;
        // cartItem.quantity = cartItem.quantity + 1;
        // apiCartItems = [cartItem];
      } else {
        cartItem.quantity = 1;
        apiCartItems = [...apiCartItems, cartItem];
      }
      const apiCartItemsAsString = JSON.stringify(apiCartItems);
      const validString = apiCartItemsAsString.replaceAll(/\n/g, '\\n');
      const formData = new FormData();
      formData.append("data", validString)

      const { data } = await axios.post(
        ADD_TO_CART_ENDPOINT,
        formData,
        MULTIPART_FORM_DATA_HEADERS
      );
      // const cartItemsData = JSON.parse(data.data.data);
      dispatch({
        type: UPDATE_CART_ITEMS,
        payload: {
          cartItems: JSON.parse(data.data.data),
        },
      });
      localStorage.setItem("cart", data.data.data);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};


export const incProductQuantity = (cartItem) => (dispatch, getState) => {

  return new Promise(async (resolve, reject) => {
    try {
      const {
        cart: { cartItems },
      } = getState();
      const foundCartItem = cartItems.find((item) => item.id === cartItem.id);
      // increment quantity
      foundCartItem.quantity = foundCartItem.quantity + 1;

      const apiCartItemsAsString = JSON.stringify(cartItems);
      const validString = apiCartItemsAsString.replaceAll(/\n/g, '\\n');

      const formData = new FormData();
      formData.append("data", validString)

      const { data } = await axios.post(
        ADD_TO_CART_ENDPOINT,
        formData,
        MULTIPART_FORM_DATA_HEADERS
      );
      dispatch({
        type: UPDATE_CART_ITEMS,
        payload: {
          cartItems : JSON.parse(data.data.data),
        },
      });

      localStorage.setItem("cart", data.data.data);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const decProductQuantity = (cartItem) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const {
        cart: { cartItems },
      } = getState();

      const foundCartItem = cartItems.find((item) => item.id === cartItem.id);
      // Decrement quantity
      foundCartItem.quantity = foundCartItem.quantity - 1;

      const FiltredCartItems = cartItems.filter((element)=>element.quantity !== 0);
      const apiCartItemsAsString = JSON.stringify(FiltredCartItems);
      const validString = apiCartItemsAsString.replaceAll(/\n/g, '\\n');

      const formData = new FormData();
      formData.append("data", validString)

      const { data } = await axios.post(
        ADD_TO_CART_ENDPOINT,
        formData,
        MULTIPART_FORM_DATA_HEADERS
      );

    const responseData = JSON.parse(data.data.data);
    const FiltredData = responseData.filter((element)=>element.quantity !==0 ); 
      dispatch({
        type: UPDATE_CART_ITEMS,
        payload: {
          cartItems : FiltredData,
        },
       });

      localStorage.setItem("cart", data.data.data);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};



export const fetchCartDetails = ()=>{
  return async (dispatch)=>{
    const {data} = await axios.post(CHECK_USER_ENDPOINT);

 if(data?.user?.cart){
  dispatch({
    type : FETCH_CART_DETAIL,
    payload : JSON.parse(data.user.cart.data)
  })
  localStorage.setItem("cart", data.user.cart.data)
 }
 else {
  dispatch({
    type : FETCH_CART_DETAIL,
    payload : []
  })
  localStorage.setItem("cart", JSON.stringify([]))
 }

   
  }            
}

export const EmptyCart = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    // Empty Cart of user 

    try {
      const apiCartItemsAsString = JSON.stringify([]);
      const validString = apiCartItemsAsString.replaceAll(/\n/g, '\\n');

      const formData = new FormData();
      formData.append("data", validString)


      const {data}  =  await axios.post(ADD_TO_CART_ENDPOINT , formData , MULTIPART_FORM_DATA_HEADERS)
      dispatch({
        type: "EMPTY_CART",
        payload: {
          cartItems: JSON.parse(data.data.data),
        },
      });
      localStorage.setItem("cart",data.data.data);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};




