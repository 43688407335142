import {GET_OFFER_FOR_ADMIN_PANEL, GET_OFFER_FOR_FRONT_PANEL} from "../constants/offers"


const intitalState = {
	OfferListAdmin: [],
	OfferListFront: []
}

export const getOfferReducer = (state = intitalState, action) => {
	switch (action.type) {
		case GET_OFFER_FOR_ADMIN_PANEL:
			return {
				...state,
				OfferListAdmin: action.payload
			}
		case GET_OFFER_FOR_FRONT_PANEL:
			return {
				...state,
				OfferListFront: action.payload
			}
		default:
			return state
	}
}

// important code
// const intitalState = {
// 	OfferListAdmin: [],
// 	OfferListFront: [],
// 	refreshing: true
// }

// export const getOfferReducer = (state = intitalState, action) => {
// 	console.log(action.refreshing,'action')
// 	switch (action.type) {
// 		case GET_OFFER_FOR_ADMIN_PANEL:
// 			return {
// 				...state,
// 				OfferListAdmin: action.payload,
// 			}
// 		case GET_OFFER_FOR_FRONT_PANEL:
// 			console.log(state.refreshing , "fbesklbvtfgh,w erfhveyilh ")
// 			return {
// 				...state,
// 				OfferListFront: action.payload,
// 				refreshing : action.refreshing
// 			}
// 		default:
// 			return state
// 	}
// }
