import React, {useRef, useEffect, useState} from "react"
import "./index.css"

const ButtonViewMobile = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen)
	}

	return (
		<div className='dropdown dropwoenLandscape'>
			<button
				className='dropdown-btn'
				aria-label='menu button'
				aria-haspopup='menu'
				aria-expanded={isMenuOpen ? "true" : "false"}
				aria-controls='dropdown-menu'
				onClick={toggleMenu}>
				<span className={`arrow ${isMenuOpen ? "" : "arrow-rotate"}`}></span>
			</button>
			<ul className={`dropdown-content ${isMenuOpen ? "menu-open" : ""}`} role='menu' id='dropdown-menu'>
				<li style={{"--delay": 1}}>
					<a href='tel: +91-935-472-1082' className='text-white whitespace-nowrap flex items-center'>
						<em className='bx phonecall bx-phone-call'></em>
						<span> +91-935-472-1082</span>
					</a>
				</li>
				{/* <li style={{"--delay": 2}}>
					<a href='tel: +91-701-503-6702' className='text-white whitespace-nowrap flex items-center'>
						<em className='bx phonecall bx-phone-call'></em>
						<span> +91-701-503-6702</span>
					</a>
				</li> */}
				<li style={{"--delay": 3}}>
					<a href='mailto:hellocurlys@gmail.com' className='text-white whitespace-nowrap flex items-center'>
						<em className='bx emailSent bxl-gmail '></em>
						<span>hellocurlys@gmail.com</span>
					</a>
				</li>
				<li style={{"--delay": 4}}>
					<a
						href='https://wa.me/+917015036702'
						target='_blank'
						rel='noreferrer'
						className='text-white whitespace-nowrap flex items-center'>
						<i className='bx WhatsAppLg bxl-whatsapp'></i>
						<span>+917015036702</span>
					</a>
				</li>
			</ul>
		</div>
	)
}

export default ButtonViewMobile
