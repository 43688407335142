import axios from "../../utils/axios";
import { DEFAULT_HEADERS, MULTIPART_FORM_DATA_HEADERS } from "../../utils";
import {
  USER_SIGN_IN,
  USER_SIGN_OUT,
  USER_SIGN_UP,
  USER_CHANGE_PASSWORD,
  USER_UPDATE_PROFILE,
  ADD_CUSTOMER,
  CHECK_USER,
} from "../constants/auth";
import {
  CHANGE_PASSWORD_ENDPOINT,
  CHECK_USER_ENDPOINT,
  UPDATE_PASSWORD_ENDPOINT,
  LOGIN_USER_ENDPOINT,
  REGISTER_USER_ENDPOINT,
  UPDATE_STATUS_ENDPOINT
} from "../../constants/api";



export const registerUser = (userData) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      // prepare form data
      const formData = new FormData();
      formData.append("phone", userData.phone);
      formData.append("name", userData.name);
      formData.append("password", userData.password);
      formData.append("image", userData?.image);
      formData.append("device_token", userData.device_token);
      formData.append("signup_type", userData.signup_type);
      const  {data} = await axios.post(
        REGISTER_USER_ENDPOINT,
        formData,
        MULTIPART_FORM_DATA_HEADERS
      );



     if(data.message === "Successful."){
          axios.defaults.headers.Authorization = "Bearer " + data?.token;
             localStorage.setItem("token", JSON.stringify(data?.token));
             localStorage.setItem("user", JSON.stringify(data?.user));
      dispatch({
        type: USER_SIGN_UP,
        payload: {
          user: data?.user,
          token: data?.token,
        },
      });
      resolve()
     }
     else{
       reject(data.error.phone[0])
     }
    } catch (error) {
      reject(error);
    }
  });
};


export const loginUser = (userData) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
       const res =  await axios.post(`${LOGIN_USER_ENDPOINT}`, userData, DEFAULT_HEADERS)
     if(res?.data?.message==="Successfully."){
           axios.defaults.headers.Authorization = "Bearer " + res?.data?.token;
          dispatch({
                          type: USER_SIGN_IN,
                          payload: {
                            user: res.data.user,
                            token: res.data.token,
                          },
                        });
          //  store the user and token in the localStorage
          localStorage.setItem("token", JSON.stringify(res.data.token));
          localStorage.setItem("user", JSON.stringify(res.data.user));
          res?.data?.user?.cart ? localStorage.setItem("cart",res?.data?.user?.cart?.data) : localStorage.setItem( "cart" , JSON.stringify([]));
          localStorage.setItem("userRoles", JSON.stringify(res.data.user.user_type));
          resolve()
     }
     else {
       console.log(res?.data)
        reject(res?.data?.error)
     }
          
  });
};

export const updateUser = (userData) => (dispatch) => {

  return new Promise(async (resolve, reject) => {
    try {
      // prepare form data
      const formData = new FormData();
      formData.append("id", userData.id);
      formData.append("name", userData.name);
      formData.append ("name1",userData.name1);
      formData.append("email" , userData.email)
      formData.append("dob", userData.dob);
      formData.append("anniversary", userData.anniversary);
      formData.append("gender", userData.gender);
      formData.append("comment", userData.comment);
      formData.append("address", userData.address);
      formData.append("image", userData.image);
      formData.append("device_token", userData.device_token);
      formData.append("signup_type", userData.signup_type);
      const { data } = await axios.post(
        REGISTER_USER_ENDPOINT,
        formData,
        MULTIPART_FORM_DATA_HEADERS
      );
      console.log( "data from the API " , data)

      // set the headers in axios
      // axios.defaults.headers.Authorization =
      //   "Bearer " + localStorage.getItem("token");

      // store the user and token in the localStorage
      // localStorage.setItem("token", JSON.stringify(data.token));
      localStorage.setItem("user", JSON.stringify(data.user));

      dispatch({
        type: USER_UPDATE_PROFILE,
        payload: {
          user: data.user,
          token: data.token,
        },
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};








export const updateUserByAdmin = (userData) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      // prepare form data
      const formData = new FormData();
      formData.append("id", userData.id);
      formData.append("name", userData.name);
      formData.append ("name1",userData.name1);
      formData.append ("email",userData.email);
      formData.append("dob", userData.dob);
      formData.append("anniversary", userData.anniversary);
      formData.append("gender", userData.gender);
      formData.append("comment", userData.comment);
      formData.append("address", userData.address);
      formData.append("image", userData.image);
      formData.append("device_token", userData.device_token);
      formData.append("signup_type", userData.signup_type);
      

      //  if(userData?.status){
      //   formData.append("status", userData.status);
      //  }
       await axios.post(
        REGISTER_USER_ENDPOINT,
        formData,
        MULTIPART_FORM_DATA_HEADERS
      );

      // set the headers in axios

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};








export const changeUserPassword = (userData) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(
        `${CHANGE_PASSWORD_ENDPOINT}`,
        userData,
        DEFAULT_HEADERS
      );

      // set the headers in axios
      axios.defaults.headers.Authorization = "Bearer " + data.token;

      dispatch({
        type: USER_CHANGE_PASSWORD,
        // payload: {
        //   user: data.user,
        //   token: data.token,
        // },
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};




// Update user Password For Admin 
export const  updateUserPassword = (password)=>{
  return async()=>{
         await axios.post(`${UPDATE_PASSWORD_ENDPOINT}`,password)
  }
}



// Update user Status For Admin 

export const UpdateUserStatus =  (userObj)=>{
       return async ()=>{
           await axios.post(`${UPDATE_STATUS_ENDPOINT}`,userObj)
       }
}

export const autoSignIUser = (user, access_token) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      // set the headers in axios
      // axios.defaults.headers.Authorization = 'Bearer ' + access_token;
      // dispatch({
      //   type: USER_SIGN_IN,
      //   payload: {
      //     user: JSON.parse(user),
      //     token: access_token,
      //   },
      // });
      resolve();
    } catch (err) {
      reject(err);
    }
  });
};

export const logoutUser = () => (dispatch) => {
  try {

    localStorage.removeItem("token");
		localStorage.removeItem("user");
		localStorage.removeItem("userRoles");
		localStorage.removeItem("cart");
		window.location.href = "/";

    dispatch({
      type: USER_SIGN_OUT,
    })


   
  } catch (error) {
    console.log(error);
  }
};

export const addCustomer = (userData) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      // prepare form data
      const formData = new FormData();
      formData.append("phone", userData.phone);
      formData.append("email", userData.email);
      formData.append("name", userData.name);
      formData.append("password", userData.password);
      formData.append("dob", userData.dob);
      formData.append("anniversary", userData.anniversary);
      formData.append("device_token", userData.device_token);
      formData.append("signup_type", userData.signup_type);

if(userData.gender){
  formData.append("gender" , userData.gender);
}

      const { data } = await axios.post(
        REGISTER_USER_ENDPOINT,
        formData,
        MULTIPART_FORM_DATA_HEADERS
      );

   if(data.message === "Successful."){
    dispatch({
      type: ADD_CUSTOMER,
      payload: {
        addedCustomer: data.user,
      },
    });
    localStorage.setItem("addedCustomer", JSON.stringify(data.user));
    resolve();
   }
   else{
    reject(data.error.phone[0])
   }
    } catch (error) {
      reject(error);
    }
  });
};



export const checkUser = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      // prepare form data
      const { data } = await axios.post(
        CHECK_USER_ENDPOINT,
        MULTIPART_FORM_DATA_HEADERS
      );

      dispatch({
        type: CHECK_USER,
        payload: {
          user: data.user,
        },
      });

      localStorage.setItem("user", JSON.stringify(data.user));

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};


