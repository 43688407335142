import React from "react"
// import Footer from "../../components/Footer"
import Header from "../../components/Header"
import styles from "./index.module.css"

const Installation = () => {
	return (
		<>
			<Header title='Bridal | Curlys Unisex Hair & Makeup Studio' />
			<section className={`${styles.androidSection} py-10`}>
				<div className='container mx-auto max-w-7xl'>
					<h2 className='text-center text-2xl uppercase font-medium mb-10'>
						How to add a Curly's App icon on your <span className='text'>Android</span> phone ?
					</h2>
					<div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4'>
						<div className=' w-full flex justify-center items-center flex-col'>
							<h3 className='text-lg mb-3 font-medium'>Step 1</h3>
							<div className='content h-12 mb-10'>
								<p className='text-lg text-center'>
									Enter website address into the address field and then tap three dots
								</p>
							</div>
							<img src='/img/android-1.jpg' alt='' />
						</div>
						<div className='w-full flex justify-center items-center flex-col'>
							<h3 className='text-lg mb-3 font-medium'>Step 2</h3>
							<div className='content h-12 mb-10'>
								<p className='text-lg text-center'>When tap three dots now tap Add to Home screen</p>
							</div>
							<img src='/img/android-2.jpg' alt='' />
						</div>
						<div className=' w-full flex justify-center items-center flex-col'>
							<h3 className='text-lg mb-3 font-medium'>Step 3</h3>
							<div className='content h-12 mb-10'>
								<p className='text-lg text-center'>Now tap on Add button</p>
							</div>
							<img src='/img/android-3.jpg' alt='' />
						</div>
						<div className='w-full flex justify-center items-center flex-col'>
							<h3 className='text-lg mb-3 font-medium'>Step 4</h3>
							<div className='content h-12 mb-10'>
								<p className='text-lg text-center'>Now you can see website icon</p>
							</div>
							<img src='/img/android-4.jpg' alt='' />
						</div>
					</div>
				</div>
			</section>
			<section className={`${styles.androidSection} py-10`}>
				<div className='container mx-auto max-w-7xl'>
					<h2 className='text-center text-2xl uppercase font-medium mb-10'>
						How to add a Curly's App icon on your <span className='text'>IOS</span> ?
					</h2>
					<div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4'>
						<div className='w-full flex justify-center items-center flex-col'>
							<h3 className='text-lg mb-3 font-medium'>Step 1</h3>
							<div className='content h-12 mb-10'>
								<p className='text-lg text-center'>
									Enter website address into the address field and then tap the share icon
								</p>
							</div>
							<img src='/img/ios-1.png' alt='' />
						</div>
						<div className='w-full flex justify-center items-center flex-col'>
							<h3 className='text-lg mb-3 font-medium'>Step 2</h3>
							<div className='content h-12 mb-10'>
								<p className='text-lg text-center'>
									When tap share icon scroll down & tap Add to Home screen
								</p>
							</div>
							<img src='/img/ios-2.png' alt='' />
						</div>
						<div className='w-full flex justify-center items-center flex-col'>
							<h3 className='text-lg mb-3 font-medium'>Step 3</h3>
							<div className='content h-12 mb-10'>
								<p className='text-lg text-center'>Now tap on Add button</p>
							</div>
							<img src='/img/ios-3.png' alt='' />
						</div>
						<div className='w-full flex justify-center items-center flex-col'>
							<h3 className='text-lg mb-3 font-medium'>Step 4</h3>
							<div className='content h-12 mb-10'>
								<p className='text-lg text-center'>Now you can see website icon</p>
							</div>
							<img src='/img/ios-4.png' alt='' />
						</div>
					</div>
				</div>
			</section>
			{/* <Footer /> */}
		</>
	)
}
export default Installation
