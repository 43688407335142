import React, {useState, useRef, useEffect} from "react"
import {
	FormControl,
	FormLabel,
	FormErrorMessage,
	FormHelperText,
	Input,
	Button,
	Link,
	Flex,
	Text,
	useToast
} from "@chakra-ui/react"
import * as Yup from "yup"
import {useForm} from "react-hook-form"
import {useDispatch} from "react-redux"
import {Link as RouterLink} from "react-router-dom"
import Container from "../../components/Container"
import {useYupValidationResolver} from "../../hooks/useValidationResolver"
import styles from "./style.module.css"
import {registerUser} from "../../store/actions/auth"
import ProfileAvatar from "../../components/ProfileAvatar"
import {IconButton} from "@chakra-ui/react"
import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons"

const validationSchema = Yup.object({
	phone: Yup.number()
		.typeError("Phone must be a number")
		.required("Phone number is required!")
		.min(999999999, "Please Enter Valid Phone Number")
		.max(9999999999, "Please Enter Valid Phone Number"),
	name: Yup.string().required("Name is required!"),
	password: Yup.string().required("Password is required!"),
	confirmPassword: Yup.string()
		.required("Confirm password is required!")
		.test("passwords-match", "Passwords must match!", function (value) {
			return this.parent.password === value
		})
})

const Register = ({history}) => {
	const toast = useToast()
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const [passwordVisible, setPasswordVisible] = useState(false)
	const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
	const togglePasswordVisibility = () => {
		setPasswordVisible(prevVisible => !prevVisible)
	}
	const confirmPasswordVisibility = () => {
		setConfirmPasswordVisible(prevVisible => !prevVisible)
	}
	const [profileImage, setProfileImage] = useState({
		preview: null,
		raw: null
	})
	const resolver = useYupValidationResolver(validationSchema)

	const imageRef = useRef()

	const addToast = error => {
		toast({
			title: error,
			status: "error",
			duration: 3000,
			isClosable: true
		})
	}

	const {
		register,
		handleSubmit,
		formState: {errors, touchedFields},
		reset
	} = useForm({
		resolver,
		mode: "onBlur"
	})
	useEffect(() => {
		document.body.classList.add(`${styles.bgBody}`)
		return () => {
			document.body.classList.remove(`${styles.bgBody}`)
		}
	}, [])
	const onRegister = data => {
		setLoading(true)
		dispatch(
			registerUser({
				phone: data.phone.toString(),
				name: data.name,
				password: data.password,
				image: profileImage.raw,
				device_token: "555",
				signup_type: 1
			})
		)
			.then(() => {
				setLoading(false)
				// reset the form
				reset()
				history.push(history?.location?.state ? history?.location?.state?.url : "/")
			})
			.catch(err => {
				setLoading(false)
				addToast(err)
			})
	}
	const onError = (errors, e) => console.log(errors, e)

	const onImageSelect = event => {
		setProfileImage({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		})
	}

	return (
		<>
			{/* <Helmet>
				<title>Register | Curlys Unisex Hair & Makeup Studio</title>
			</Helmet> */}
			<div className={` sm:h-auto  h-full w-full flex  items-center ${styles.section}`}>
				{/* <PageHeader title='Register' /> */}

				<Container
					className={` md:px-5 sm:px-2 mx-auto flex sm:flex-nowrap flex-wrap  ${styles.container} container`}>
					<div className='w-1/2 overflow-hidden sm:mr-10  items-center justify-center relative hidden md:flex'>
						<img src='img/register-img.png' alt='' />
					</div>
					<div className='md:w-1/2 w-full flex flex-col md:ml-auto md:py-8 md:mt-0 '>
						<div
							className={`px-10 py-10 bg-white shadow-slate-999 mx-auto md:mx-0 rounded-xl max-w-full ${styles.card}`}>
							<form noValidate onSubmit={handleSubmit(onRegister, onError)}>
								<p className='text-center  md:mt-0 font-semibold text-3xl text-black-3xl  mb-3'>
									Register
								</p>
								<FormControl isInvalid={touchedFields?.name && errors?.name} id='name' className='mb-3'>
									<FormLabel>Full Name</FormLabel>
									<Input {...register("name")} />
									<FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
									<FormHelperText></FormHelperText>
								</FormControl>
								<FormControl
									isInvalid={touchedFields?.phone && errors?.phone}
									id='phone'
									className='mb-3'>
									<FormLabel>Phone Number</FormLabel>
									<Input {...register("phone")} type='number' />
									<FormErrorMessage>{errors?.phone?.message}</FormErrorMessage>
									<FormHelperText>We'll never share your phone number</FormHelperText>
								</FormControl>

								<FormControl
									isInvalid={touchedFields?.password && errors?.password}
									id='password'
									className='mb-3 relative'>
									<FormLabel>Password</FormLabel>

									<Input {...register("password")} type={passwordVisible ? "text" : "password"} />
									<FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
									<IconButton
										icon={passwordVisible ? <ViewIcon /> : <ViewOffIcon />}
										onClick={togglePasswordVisibility}
										className='right-0 top-8 eyeIcon-borderd'
										aria-label={passwordVisible ? "Hide password" : "Show password"}
										position='absolute'
										zIndex='1'
										color={passwordVisible ? "blue.500" : "gray.500"}
									/>
								</FormControl>

								<FormControl
									isInvalid={touchedFields?.confirmPassword && errors?.confirmPassword}
									id='confirmPassword'
									className='mb-3 relative'>
									<FormLabel>Confirm Password</FormLabel>
									<Input
										{...register("confirmPassword")}
										type={confirmPasswordVisible ? "text" : "password"}
									/>
									<FormErrorMessage>{errors?.confirmPassword?.message}</FormErrorMessage>
									<IconButton
										icon={confirmPasswordVisible ? <ViewIcon /> : <ViewOffIcon />}
										onClick={confirmPasswordVisibility}
										className='right-0 top-8 eyeIcon-borderd'
										aria-label={confirmPasswordVisible ? "Hide password" : "Show password"}
										position='absolute'
										zIndex='1'
										color={confirmPasswordVisible ? "blue.500" : "gray.500"}
									/>
								</FormControl>

								{/* Upload Photo */}
								<FormControl>
									<FormLabel>Profile Image</FormLabel>
									<Flex alignItems='center' mt={3}>
										<ProfileAvatar image={profileImage.preview} />
										<input
											accept='image/*'
											ref={imageRef}
											type='file'
											onChange={onImageSelect}
											hidden
										/>
										<Link
											className='no-underline bg-gray-400 text-white py-1 px-3 rounded-md'
											ml={5}
											color='blue.500'
											fontWeight='medium'
											_focus={{shadow: "none"}}
											onClick={() => imageRef.current.click()}>
											Upload Photo
										</Link>
									</Flex>
								</FormControl>

								<div className='flex justify-between flex-wrap'>
									<Button
										onClick={() => history.push("/")}
										className={`lg:w-1/3 md:1/2 mt-4 bg-gold text-white  hover:no-underline text-center max-w-full`}>
										{" "}
										<i className='bx bxs-home'></i> &nbsp; Go To Home{" "}
									</Button>

									<Button
										className='lg:w-1/3 md:1/2 bg-black-3xl text-white mt-4 px-7 max-w-full'
										disabled={Object.keys(errors).length}
										isLoading={loading}
										type='submit'>
										Register
									</Button>
								</div>
								<div className='mt-4 sm:mt-4 text-right'>
									<Text>
										Already have an account?
										<Link ml={1} as={RouterLink} color='blue.600' to='/login'>
											Login Now
										</Link>
									</Text>
								</div>
							</form>
						</div>
					</div>
				</Container>
			</div>
		</>
	)
}

export default Register
