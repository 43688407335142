import axios from "../../utils/axios";
import { DEFAULT_HEADERS } from "../../utils";
import {
  ADD_EXPENSE,
  GET_EXPENSE,
  GET_CLOSING_EXPENSE
} from "../constants/expense";
import {
  ADD_EXPENSE_ENDPOINT,
  LIST_EXPENSE_ENDPOINT,
  DELETE_EXPENSE_ENDPOINT,
  UPDATE_EXPENSE_ENDPOINT,
  GET_CLOSING_EXPENSE_ENDPOINT,
  
} from "../../constants/api";

export const addExpense = (expenseData) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      // const {data: { data: singleExpense},} = await axios.post(ADD_EXPENSE_ENDPOINT, expenseData, DEFAULT_HEADERS);
      const {data} = await axios.post(ADD_EXPENSE_ENDPOINT, expenseData, DEFAULT_HEADERS);
      if(data.message === "Successful."){
        const {
          expense: { expenses },
        } = getState();
        dispatch({
          type: ADD_EXPENSE,
          payload: [...expenses?.expenses, data?.data],
        });
         resolve();
      }
      else if(data.message === "Error"){
          reject(data)
      }
    } catch (error) {
      console.log(error)
    }
  });
};

export const getAllExpense = ({start_date, end_date,direction,sortby }) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${LIST_EXPENSE_ENDPOINT}`;
      const { data } = await axios.post(
        url,
        {
          start_date,
          end_date,
        direction,
        sortby
        },
        DEFAULT_HEADERS
      );

      // console.log(data,"this is data from the expense all")

      dispatch({
        type: GET_EXPENSE,
        payload: data.data,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const updateExpense = (updatedData, expenseId) => (dispatch) => {
  // return
  return new Promise(async (resolve, reject) => {
    try {
     await axios.post(
        UPDATE_EXPENSE_ENDPOINT,
        {
          ...updatedData,
          id:expenseId
        },
        DEFAULT_HEADERS
      );
      dispatch(getAllExpense({ month: new Date().getMonth() + 1, year: new Date().getFullYear() }));
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};




export const deleteExpense = (expenseId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      await axios.post(
        DELETE_EXPENSE_ENDPOINT,
        { id: expenseId },
        DEFAULT_HEADERS
      );
      dispatch(getAllExpense({ month: new Date().getMonth() + 1, year: new Date().getFullYear() }));
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};



export const getClosingExpense = ()=>{
     return async (dispatch)=>{
          const {data} = await axios.post(GET_CLOSING_EXPENSE_ENDPOINT);
              dispatch({
                type : GET_CLOSING_EXPENSE,
                payload : data
              })


     }
}
