import React, {useState, useEffect} from "react"
import {Table, Thead, Tbody, Tr, Th, Td, Box, } from "@chakra-ui/react"
import {useSelector, useDispatch} from "react-redux"
import PrivateLayout from "../../layout/PrivateLayout"
import PageHeader from "../../components/PageHeader"
import PageContainer from "../../components/PageContainer"
import Loading from "../../components/Loading"
import Loader from "../../components/Loader/Loader"
import AddOfferModal from "../../components/Modals/AddOffersModal"
import {getOfferList} from "../../store/actions/offer"
import {IMAGE_BASE_URL} from "../../constants/api"
import moment from "moment"
import EditOfferModal from "../../components/Modals/EditOfferModal"
import DeleteOfferConfirmationModal from "../../components/Modals/DeleteOfferConfirmationModal"
import { getDateDiffrence } from "../../utils/getDateDiffrence"

const Offers = ({history}) => {
	const dispatch = useDispatch()
	const {user} = useSelector(state => state.auth)

	const {OfferListAdmin} = useSelector(state => state.getOfferReducer)

	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (user?.user_type === 3 || user?.user_type === 2) {
			history.push("/")
		}
		setLoading(true)
		dispatch(getOfferList()).then(() => {
			setLoading(false)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const updateList = () => {
		setLoading(true)
		dispatch(getOfferList()).then(() => {
			setLoading(false)
		})
	}

	const statusBox = statusData => {
		if (statusData === 0) {
			return (
				<div className='flex items-center justify-center'>
					<div className='w-4 h-4 bg-red-600 mr-2'></div>
					<span>Disable</span>
				</div>
			)
		} else {
			return (
				<div className='flex items-center justify-center'>
					<div className='w-4 h-4 bg-green-600 mr-2'></div>
					<span>Enable</span>
				</div>
			)
		}
	}

	return (
		<PrivateLayout>
			{loading && <Loader className='h-screen absolute z-10 w-full items-center' />}
			<PageHeader
				pageHeading='All Offers'
				className='flex items-center'
				rightContent={
					<div>
						<AddOfferModal updateList={updateList} />
					</div>
				}
				title='Offers'
			/>
			<PageContainer>
				{loading ? (
					<div className='flex justify-center items-center mt-8'>
						<Loading />
					</div>
				) : (
					<>
						<Box overflowX='auto' w='100%'>
							{OfferListAdmin?.data?.length > 0 && !loading ? (
								<Table className='my-4' variant='simple'>
									<Thead>
										<Tr>
											<Th className='text-center'>Actions</Th>
											<Th className='text-center'>Offer Name</Th>
											<Th className='text-center'>Image</Th>
											<Th className='text-center'>Id</Th>
											<Th className='text-center'>Status</Th>
											<Th className='text-center'>Expiry Status</Th>
											<Th className='text-center'>Expiry Date</Th>
											<Th className='text-center'>Sorting</Th>
											{/* <Th>Comment</Th> */}
										</Tr>
									</Thead>
									<Tbody>
										{OfferListAdmin?.data?.map(res => {
											const dateDiffrence  = getDateDiffrence(res.expiry)
											return (
												<Tr key={res.id} className='whitespace-nowrap'>
													<Td className='text-center'>
														<DeleteOfferConfirmationModal
															id={res.id}
															updateList={updateList}
														/>
														<EditOfferModal offerData={res} updateList={updateList} />
													</Td>
													<Td className='text-center'>{res.name}</Td>
													<Td className='text-center'>
														<img
															className='inline rounded-full'
															style={{width: "35px", height: "35px"}}
															src={`${IMAGE_BASE_URL}/${res.img}`}
															alt="OfferImg"
														/>
													</Td>
													<Td className='text-center'>{res.id}</Td>
													<Td className='text-center'>{statusBox(res.status)}</Td>
													<Td className='text-center'><span>
													{(dateDiffrence === null|| dateDiffrence > 7) && moment(res.expiry).format("DD MMM YYYY")}
																{(dateDiffrence >= 1 && dateDiffrence < 7 ) && `Offer Expires in ${dateDiffrence} Day` }
																{dateDiffrence === 0 && " This offer Will Expires Today" }
																{dateDiffrence === "expires" && " Offer Expired" }	
														
														</span></Td>
														<Td className='text-center'>{moment(res.expiry).format("DD MMM YYYY")}</Td>
													<Td className='text-center'>{res.sort}</Td>
												</Tr>
											)
										})}
									</Tbody>
								</Table>
							) : (
								<Box marginTop='16' textAlign='center'>
									No Offers found!
								</Box>
							)}
						</Box>
					</>
				)}
			</PageContainer>
		</PrivateLayout>
	)
}

export default Offers
