import React from "react"
import {useHistory} from "react-router-dom"
const NoOffer = () => {
	const history = useHistory()
	return (
		<>
			<div className='flex flex-col justify-center items-center my-20'>
				<img src='/img/no-offer.png' alt='' width='500px' />
				<h6 className='mt-3 mb-5 sm:text-5xl text-xl text-center font-bold text-gray-500'>
					Sorry, we couldn't have any offer yet
				</h6>
				<p className='text-lg mb-2 text-center'>Please Check Services and Book Now</p>
				<div className='mt-2'>
					<button
						onClick={() => history.push("curly-service/all")}
						className='bg-gold text-white py-2 px-5 rounded-md text-xl font-normal'>
						Go to Services
					</button>
				</div>
			</div>
		</>
	)
}

export default NoOffer
