export const getMonths = () => [
	{id: 1, name: "January"},
	{id: 2, name: "February"},
	{id: 3, name: "March"},
	{id: 4, name: "April"},
	{id: 5, name: "May"},
	{id: 6, name: "June"},
	{id: 7, name: "July"},
	{id: 8, name: "August"},
	{id: 9, name: "September"},
	{id: 10, name: "October"},
	{id: 11, name: "November"},
	{id: 12, name: "December"}
]

export const getYears = () => {
	let maxOffset = 10
	let thisYear = 2021
	let allYears = new Set()

	for (let x = 0; x <= maxOffset; x++) {
		allYears.add(thisYear + x)
	}
	let allYearsArray = Array.from(allYears)
	return allYearsArray.sort((a, b) => a - b)
}

export const getListSummary = expenses => {
	const expenseTotal = expenses?.reduce((acc, curr) => acc + parseInt(curr.expense), 0)
	const cashTotal = expenses?.reduce((acc, curr) => acc + parseInt(curr.cash), 0)
	const cash1Total = expenses?.reduce((acc, curr) => acc + parseInt(curr.cash1), 0)
	const onlineTotal = expenses?.reduce((acc, curr) => acc + parseInt(curr.online), 0)
	const totalExpenses = expenses?.reduce((acc, curr) => acc + parseInt(curr.total), 0)
	// const closingTotal = expenses?.reduce((acc, curr) => acc + parseInt(curr.closing), 0)
	const onlineExpense = expenses?.reduce((acc, curr) => acc + parseInt(curr.expense_online), 0)

	return {
		expenseTotal,
		cashTotal,
		onlineTotal,
		totalExpenses,
		cash1Total,
		// closingTotal,
		onlineExpense
	}
}
export const getSalarySummary = (salary) => {
	const salaryCashTotal = salary.reduce((acc, curr) => acc + parseInt(curr.amount||0), 0);
	const salaryOnlineTotal =  salary.reduce((acc, curr) => acc + parseInt(curr.online_amount||0), 0);
	return {
		salaryCashTotal,
		salaryOnlineTotal
	}
}
