import React, {useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {addCartItem} from "../../store/actions/cart"
import {formatCurrency} from "../../utils/currencyFormat"
import {useHistory} from "react-router-dom"
import {Box, Skeleton, SkeletonCircle, SkeletonText, Stack, useToast} from "@chakra-ui/react"
import ProductsSummary from "../../components/Modals/ProductsSummary"

const Product = ({productsItmes}) => {
	const {Categorieslist} = useSelector(state => state.products)

	const dispatch = useDispatch()
	const History = useHistory()
	const toast = useToast()

	const {user} = useSelector(state => ({
		user: state.auth.user
	}))

	const CheckUser = element => {
		History.location.state = {...History.location.state, url: History.location.pathname}
		if (!user) {
			History.push({pathname: "/login", state: {...History.location.state, url: History.location.pathname}})
			toast({
				title: "Please Login first to Access Cart",
				status: "error",
				duration: 3000,
				isClosable: true
			})
		} else {
			dispatch(addCartItem(element))
		}
	}

	const {cartItems} = useSelector(state => state.cart)

	const isItemAddedInCart = itemId => {
		return cartItems.findIndex(item => item.id === itemId)
	}

	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		const loadingTimeout = setTimeout(() => {
			setIsLoading(false)
		}, 3000)
		return () => clearTimeout(loadingTimeout)
	}, [])

	return (
		<>
			<div className='flex p-1 w-full flex-wrap mb-10'>
				{productsItmes?.map(element => {
					let ArryaData = element.category_id.split(",")
					let newArry = []
					ArryaData.forEach(v => {
						newArry.push(parseInt(v))
					})
					const FilteredData = Categorieslist.filter(value => newArry.includes(value.id))

					const services = FilteredData.map(element => element.name)
					const comment = element.comment ? element.comment.replace(/<[^>]+>/g, "") : ""
					const PriceAfterDiscount = parseInt(element.price) - parseInt(element.discount)
					return (
						<div className='p-4 xl:w-1/3 sm:w-1/2 w-full ' key={element.id}>
							{isLoading ? (
						
								<Box padding='2' boxShadow='lg' className='relative'>
									<SkeletonText noOfLines={2} spacing='2' skeletonHeight='10' />
									<SkeletonText mt='4' noOfLines={1} spacing='2' skeletonHeight='40'></SkeletonText>
									<Box className=' flex m-auto justify-center'>
										<SkeletonCircle
											bg='white'
											style={{background: "white"}}
											size='10'
											width='200px'
											className='absolute flex m-auto justify-center bottom-8 text-center skeltonCircleWidth'
										/>
									</Box>
									<SkeletonCircle
										size='10'
										width='10'
										className='absolute md:block sm:none bottom-8 right-8'
									/>
								</Box>
							) : (

								<div className='h-full border-card-field card-zoom-effect-nails border shadow-slate-1001 flex flex-col relative overflow-hidden'>
								<div className='head p-6 bg-gray-100 flex justify-between items-center'>
									<div className='headOne'>
										<h2 className='text-lg tracking-widest title-font  mb-1 font-medium'>
											{element.name}
										</h2>
										<h1 className='text-xl font-semibold text-gray-900   border-gray-200 leading-none '>
											{element.discount >= 1
												? formatCurrency(Number(PriceAfterDiscount))
												: formatCurrency(Number(element.price))}
										</h1>
									</div>
									<span
										className={`bg-black text-span-column  text-white px-3 py-1 absolute whitespace-nowrap tracking-widest text-xs  right-0 top-0 rounded-bl ${
											FilteredData.length !== 0 ? "block " : "hidden"
										}
						`}>
										<div className={`flex flex-wrap sm:flex-row flex-col`}>
											<p className='text-white cursor-default text-inside-column'>
												{services.toString()}
											</p>
										</div>
									</span>
								</div>
								<div className='content p-6 downcontent relative text-center'>
									<p
										className={`items-center ${
											element.discount === 0 ||
											element.discount === "0.00" ||
											!element.discount
												? "hidden"
												: "block"
										} text-black  mb-2`}>
										<span className='w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0'>
											<svg
												fill='none'
												stroke='currentColor'
												strokeLinecap='round'
												strokeLinejoin='round'
												strokeWidth='2.5'
												className='w-3 h-3'
												viewBox='0 0 24 24'>
												<path d='M20 6L9 17l-5-5'></path>
											</svg>
										</span>{" "}
										<span className='line-through text-lg font-medium'>
											{Number(element.discount) === 0
												? ""
												: formatCurrency(Number(element.price))}{" "}
										</span>{" "}
										<span className='text-gray-600 text-lg font-semibold ml-3'>
											(₹ {element.discount} off)
										</span>
									</p>

									<div className='flex bg-green'>
										<button
											className={`flex items-center addhoverButtonRoom whitespace-nowrap text-white justify-center  ${
												isItemAddedInCart(element.id) === -1
													? "bg-gold hover:bg-gold-400"
													: "bg-black hover:bg-gray-900"
											} border-0 sm:py-2 sm:px-5 py-1 px-3 focus:outline-none  rounded-5xl mx-auto mt-7`}
											onClick={() => {
												CheckUser(element)
											}}>
											<i
												className={`mr-4 text-lg bx ${
													isItemAddedInCart(element.id) === -1
														? "bx-add-to-queue"
														: "bxs-check-circle"
												}`}></i>
											{isItemAddedInCart(element.id) === -1
												? "Add to My Room"
												: "Added to My Room"}
										</button>

										<div className={`viewMoreIcon  ${comment === "" ? "hidden" : "block"}`}>
											{comment.length > 36 && (
												<ProductsSummary productDetail={element} services={services} />
											)}
										</div>
									</div>

									{/* <div className={`mb-5 seeMoreView  ${comment === "" ? "hidden" : "block"}`}>
						{comment.length > 36 && (
							<ProductsSummary productDetail={element} services={services} />
						)}
					</div> */}
								</div>
							</div>
							)}
						</div>
					)
				})}
			</div>
			{productsItmes.length < 1 && (
				<div className='flex justify-center mb-5'>
					<h1 className=' text-base md:text-2xl font-bold '> No Product Match With Your Search </h1>
				</div>
			)}
		</>
	)
}

export default Product
