import {Stack, Select} from "@chakra-ui/react"
import {React, useEffect, useState} from "react"
import PageContainer from "../../../components/PageContainer"
import styles from "./index.module.css"
import {GET_APPOINTMENT_LISTING} from "../../../constants/api"
import RejcetedModal from "./RejectedModal"
import OrderListModal from "../../../components/Modals/OrderListModal"
import Header from "../../../components/Header"
import InfiniteScroll from "react-infinite-scroll-component"
import Loader from "../../../components/Loader/Loader"
// import Footer from "../../../components/Footer"
import moment from "moment"
import axios from "../../../utils/axios"
import EmptyAppointmentPage from "./EmptyAppointment"

const MyOrders = () => {
	const [appointmentList, setAppointmentList] = useState([])
	const [page, setPage] = useState(1)
	const [loading, setLoading] = useState(false)
	const [status, setStatus] = useState("")
	const [isDataEmpty, setIsDataEmpty] = useState(false)

	const fetchData = async () => {
		setLoading(true)
		const {data} = await axios.get(`${GET_APPOINTMENT_LISTING}?limit=5&page=${page}&status=${status}`)
		setLoading(false)
		setAppointmentList(prevState => prevState.concat(data?.data.data))
		data?.data?.data.length === 0 ? setIsDataEmpty(true) : setIsDataEmpty(false)
	}

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, status])
	const setStatusHandler = val => {
		setPage(0)
		setAppointmentList([])
		setStatus(val)
	}

	const UniqueValues = appointmentList.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i)

	return (
		<>
			<Header title='My Appointments | Curlys Unisex Hair & Makeup Studio' />
			<PageContainer>
				<div className='flex flex-col my-12'>
					<h2 className='sm:text-4xl text-2xl font-bold mb-4 text-center'>My Appointments</h2>
					<Stack spacing={4} direction='row' className='justify-end p-2'>
						<div className={styles.selectBox}>
							<Select placeholder='All' onChange={e => setStatusHandler(e.target.value)}>
								<option value='0'>In Review</option>
								<option value='1'>Approved</option>
								<option value='2'>Rejected</option>
							</Select>
						</div>
					</Stack>
					{!loading && isDataEmpty && appointmentList.length === 0 && <EmptyAppointmentPage />}
					<div
						id='ListWrapper'
						style={{
							height: appointmentList.length <= 4 || appointmentList.length === 0 ? "auto" : "600px",
							overflowY: "auto"
						}}>
						{appointmentList.length > 0 && (
							<InfiniteScroll
								className='sm:flex justify-between  flex-wrap'
								dataLength={appointmentList?.length}
								next={() => {
									setPage(prevPage => prevPage + 1)
								}}
								hasMore={!loading && appointmentList.length}
								scrollableTarget='ListWrapper'>
								{UniqueValues?.map(booking => {
									let services
									let bookingservices
									let length
									try {
										let servicesData = JSON.parse(booking.services);
										services = JSON.parse(servicesData?.service);
									} catch {
										bookingservices = booking.services
										var splitservices = !bookingservices ? [] : bookingservices.split(",");
										length = splitservices.length
									}
									let AdvancePayment = services.reduce((acc,curr)=> acc + parseInt(curr?.advance_payment),0);
									return (
										<>
											<div className='wrapper sm:w-1/2 p-2' key={booking.id}>
												<div className='bg-white shadow-slate-1001 p-3.5 rounded-xl mb-3'>
													<div className='head flex flex-wrap justify-between border-b pb-2 mb-2 border-dotted border-gray-400'>
														<p className='font-bold text-lg'>
															Appointment ID - {booking.id}
														</p>
														<p
															className={`font-bold cursor-pointer bg-green-900 py-1 px-3 rounded text-white ${
																booking.status === 1 ? "block" : "hidden"
															}`}>
															<span>Approved</span>
														</p>
														<p
															className={` ${
																booking.status === 2 ? "block" : "hidden"
															} font-bold`}>
															<RejcetedModal Rejected_text={booking.status_text} />
														</p>
														<p
															className={`font-bold cursor-pointer bg-gold-400 py-1 px-3 rounded text-white ${
																booking.status === 0 ? "block" : "hidden"
															}`}>
															<span>In Review</span>
														</p>
													</div>
													<div className='body'>
														<div className='flex flex-wrap justify-between py-1'>
															<h6 className='font-medium text-gray-400'>Name</h6>
															<p className='font-medium text-gray-700'>
																{booking.name ? booking.name : "Not Available "}
															</p>
														</div>
														<div className='flex flex-wrap justify-between py-1'>
															<h6 className='font-medium text-gray-400'>
																Appointment Date
															</h6>
															<p className='font-medium text-gray-700'>
																{booking.date === "0000-00-00 00:00:00" || !booking.date
																	? "Not Available "
																	: moment(booking.date).format("DD MMM YYYY")}
															</p>
														</div>
														<div className='flex flex-wrap justify-between py-1'>
															<h6 className='font-medium text-gray-400'>
																Appointment Time
															</h6>
															<p className='font-medium text-gray-700'>
																{booking.date === "0000-00-00 00:00:00" || !booking.date
																	? "Not Available "
																	: moment(booking.date).format("hh:mm a")}
															</p>
														</div>


                                                         {!! AdvancePayment && <div className='flex flex-wrap justify-between py-1'>
															 <h6 className='font-medium text-gray-400'>Advance Payment</h6>
															 <p className='font-medium text-gray-700'>
																 {AdvancePayment}
															 </p>
														     </div>
														 }

														{/* <div className='flex flex-wrap justify-between py-1'>
															<h6 className='font-medium text-gray-400'>
																Appointment Time
															</h6>
															<p className='font-medium text-gray-700'>
																{booking.date === "0000-00-00 00:00:00" || !booking.date
																	? "Not Available "
																	: moment(booking.date).format("hh:mm a")}
															</p>
														</div> */}

														{ !bookingservices &&  
                                                         <div className='flex flex-wrap justify-between py-1'>
														 <h6 className='font-medium text-gray-400'>
															 Price
														 </h6>
														 <p className='font-medium text-gray-700'>
															 {services?.reduce((acc , curr)=> acc + ((parseInt(curr?.price) * curr?.quantity) - (parseInt(curr.discount)*curr?.quantity)),0)}
														 </p>
													 </div>
														}
														 <div className='flex flex-wrap justify-between py-1'>
															<h6 className='font-medium text-gray-400'>
																No. of Services
															</h6>
															<p className='font-medium text-gray-700'>
																{services ? services.length || 0 : length || 0}
															</p>
														</div>
														<div className='flex flex-wrap justify-between py-1'>
															<p
																className={`text-gray-600 font-bold ${
																	!booking.services ? "hidden" : "block"
																}  `}>
																To View Details Please{" "}
																<OrderListModal bookingData={booking} />
															</p>
														</div>
													</div>
												</div>
											</div>
										</>
									)
								})}
							</InfiniteScroll>
						)}
						{loading && <Loader />}
					</div>
				</div>
			</PageContainer>
			{/* <Footer /> */}
		</>
	)
}
export default MyOrders
