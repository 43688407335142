import React from "react"
// import Footer from "../../components/Footer"
import Header from "../../components/Header"
const RepublicDay = ({history}) => {
	return (
		<>
			<Header />
			<section className={`section`}>
				<div className='container mx-auto max-w-7xl py-10'>
					<div className='image-section relative'>
						<div className='republic-img flex justify-center'>
							<img src='img/republic-day.jpg' alt='' />
						</div>
						<div className='logo-image absolute top-5 left-48'>
							<img src='img/curl-black.png' alt='' />
						</div>
						<button
							onClick={() => history.push("/offers")}
							className='absolute bottom-16 left-1/2 offer-btn'>
							Get Offers
						</button>
					</div>
				</div>
			</section>
			{/* <Footer /> */}
		</>
	)
}
export default RepublicDay
