import axios from "../../utils/axios";
import { DEFAULT_HEADERS } from "../../utils";
import {
  GET_SALARY_ENDPOINT,
  ADD_SALARY_ENDPOINT,
  UPDATE_SALARY_ENDPOINT,
  DELETE_SALARY_ENDPOINT,
} from "../../constants/api";


import {
  GET_SALARY_LIST,
} from "../constants/salary";



export const addSalary = (salaryData) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
       await axios.post(
        ADD_SALARY_ENDPOINT,
        salaryData,
        DEFAULT_HEADERS
      );
      dispatch(
        getSalaryList({
          month: new Date().getMonth() + 1,
          year: new Date().getFullYear(),
        })
      );
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};



export const updateSalary = (salaryData) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
       await axios.post(
        UPDATE_SALARY_ENDPOINT,
        salaryData,
        DEFAULT_HEADERS
      );
      dispatch(
        getSalaryList({
          month: new Date().getMonth() + 1,
          year: new Date().getFullYear(),
        })
      );

      resolve();
    } catch (error){
      reject(error);
    }
  });
};

export const getSalaryList =
  ({ start_date, end_date, sortby, direction }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${GET_SALARY_ENDPOINT}`;

        const { data } = await axios.get(url, {
          params: {
            start_date,
            end_date,
            sortby,
            direction,
          },
          DEFAULT_HEADERS,
        });
        dispatch({
          type: GET_SALARY_LIST,
          payload: data.data,
        });
        resolve(data.data);
      } catch (error) {
        reject(error);
      }
    });
  };
export const deleteSalary =
  ({ id }) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${DELETE_SALARY_ENDPOINT}`;
        const { data } = await axios.get(url, {
          params: {
            id,
          },
          DEFAULT_HEADERS,
        });
        dispatch(
          getSalaryList({
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
          })
        );
        resolve(data.data);
      } catch (error) {
        reject(error);
      }
    });
  };
