import React from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	// useDisclosure,
	Text,
	Stack,
	Button
} from "@chakra-ui/react"

const SuccessModal = ({isModalOpen, setIsModalOpen}) => {
	// const {onClose} = useDisclosure()

	return (
		<>
			<Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} scrollBehavior='inside' size='xl'>
				<ModalOverlay className='bg-gold-701 modalOverlay' />
				<ModalContent>
					<ModalHeader className='capitalize bg-gold text-center text-2xl font-medium text-white'>
						Payment Successful
					</ModalHeader>
					<ModalCloseButton className='text-white ' />
					<ModalBody>
						<Stack spacing={3} justifyContent='center' alignItems='center' className='mt-4'>
							<div className=''>
								<i className='bx bxs-badge-check text-7xl text-green-600'></i>
							</div>
							<Text className='text-xl font-medium text-gray-500 mb-2'>
								Thank You for Booking, your payment was successful
							</Text>
						</Stack>
					</ModalBody>
					<ModalFooter className='justify-center mb-4'>
						<Button
							onClick={() => setIsModalOpen(false)}
							className='px-4 py-1 rounded bg-gold mx-3 text-lg text-white'>
							Ok
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default SuccessModal
