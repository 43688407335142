                                                                              import React from "react"
import {
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	MenuGroup,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Stack,
	Input,
	FormLabel,
	FormControl,
	FormErrorMessage,
	useToast
} from "@chakra-ui/react"
import {useHistory, NavLink} from "react-router-dom"
import {useSelector, useDispatch} from "react-redux"
import {changeUserPassword, logoutUser} from "../../store/actions/auth"
import {IMAGE_BASE_URL} from "../../constants/api"

import {useState} from "react"
import * as Yup from "yup"
import {useForm} from "react-hook-form"
import {useYupValidationResolver} from "../../hooks/useValidationResolver"
import styles from "./index.module.css"

const validationSchema = Yup.object({
	current_password: Yup.string().required("Current Password is required!"),
	new_password: Yup.string().required("New Password is required!"),
	new_confirm_password: Yup.string().required("Confirm New Password is required!")
})

const AvtaarDropDown = () => {
	const dispatch = useDispatch()
	const toast = useToast()
	const history = useHistory()
	const {isOpen, onOpen, onClose} = useDisclosure()
	const [loading, setLoading] = useState(false)
	//const [phone, setPhone] = useState("")

	const {user} = useSelector(state => ({
		user: state.auth.user
	}))

	// useEffect(() => {
	// 	if (localStorage.getItem("user") !== null) {
	// 		setPhone(JSON.parse(localStorage.getItem("user")) || "")
	// 	} else {
	// 		setPhone("null")
	// 	}
	// }, [])

	const onLogout = () => {
		dispatch(logoutUser())
	}

	const resolver = useYupValidationResolver(validationSchema)
	const {
		register,
		handleSubmit,
		formState: {errors, touchedFields}
	} = useForm({
		resolver,
		mode: "onBlur"
	})

	const onChangePassword = data => {
		setLoading(true)
		dispatch(
			changeUserPassword({
				current_password: data.current_password,
				new_password: data.new_password,
				new_confirm_password: data.new_confirm_password
			})
		)
			.then(() => {
				setLoading(false)
				// reset the form
				onClose()
				history.push("/")
				toast({
					title: "Password Changed Successfully",
					status: "success",
					duration: 3000,
					isClosable: true
				})
			})
			.catch(err => {
				console.log(err, "err err")
				toast({
					// title: `${err.response.data.error}`,
					title: "Something went wrong",
					status: "error",
					duration: 3000,
					isClosable: true
				})
				setLoading(false)
				console.log("ERR => while login user", err)
			})
	}

	const onClickProfile = () => {
		history.push("/profile")
	}

	return (
		<>
			<Menu>
				<MenuButton
					as={Button}
					className={`rounded-full ${user ? "block" : "hidden"} ${styles.profiledropdown} `}>
					<img
						className={` ${styles.profileimage} rounded-full `}
						src={
							user.image !== ""
								? user?.image && `${IMAGE_BASE_URL}/${user?.image}`
								: "img/blankProfile.png"
						}
						alt='profileImg'
					/>
				</MenuButton>
				<MenuList className='py-4'>
					<MenuGroup>
						<MenuItem className='flex mb-3'>
							<img
								className={` ${styles.profileimage} rounded-full mr-3`}
								src={
									user.image !== ""
										? user?.image && `${IMAGE_BASE_URL}/${user?.image}`
										: "img/blankProfile.png"
								}
								alt='ProfileImage'
							/>
							<span className='font-bold text-lg'>{user.name}</span>
						</MenuItem>
						<MenuItem className='mb-2 font-medium'>{user.phone}</MenuItem>
					</MenuGroup>
					<NavLink to='/expenses'>
						<MenuItem
							className={`mb-2 ${user.user_type === 1 || user.user_type === 2 ? "block" : "hidden"}`}>
							<i className='bx bx-category'></i>
							<span className='ml-3 font-medium'> Go to Admin </span>
						</MenuItem>
					</NavLink>

					<MenuItem className='mb-2' onClick={onClickProfile}>
						<i className='bx bx-user text-lg font-medium'></i>{" "}
						<span className='ml-3 font-medium'> Profile </span>
					</MenuItem>

					<NavLink to='/my-appointments'>
						<MenuItem className='mb-2'>
							<i className='bx bx-info-circle text-lg font-medium'></i>
							<span className='ml-3 font-medium'> My Appointments </span>
						</MenuItem>
					</NavLink>

					<MenuItem className='mb-2' onClick={onOpen}>
						<i className='bx bx-revision text-lg font-medium'></i>{" "}
						<span className='ml-3 font-medium'> Change Password </span>{" "}
					</MenuItem>
					<MenuItem className='mb-2' onClick={onLogout}>
						<i className='bx bx-log-out text-lg font-medium'></i>{" "}
						<span className='ml-3 font-medium'> Logout </span>{" "}
					</MenuItem>
				</MenuList>
			</Menu>

			{/* <Menu>
        <MenuButton as="div" className="cursor-pointer">
          <button
            className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            id="user-menu"
            aria-haspopup="true"
          >
            <span className="sr-only">Open user menu</span>
            <Avatar
              src={user?.image && `${IMAGE_BASE_URL}/${user?.image}`}
              size="sm"
            />
          </button>
        </MenuButton>
        <MenuList
          _hover={{
            border: "none",
          }}
        >
          <MenuGroup title={`Hello, ${user.name}`}></MenuGroup>
          <MenuGroup title={phone ? phone.phone : "+91 9876543210"}></MenuGroup>
          <MenuItem onClick={onClickProfile}>Profile</MenuItem>
          <MenuItem onClick={onOpen}>Change Password</MenuItem>
          <MenuItem onClick={onLogout}>Logout</MenuItem>
        </MenuList>
      </Menu> */}

			<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
				<ModalOverlay />
				<form
					style={{overflow: "auto"}}
					noValidate
					onSubmit={handleSubmit(onChangePassword)}
					className='bg-white'>
					<ModalContent>
						<ModalHeader>Change Password</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Stack spacing={3}>
								<FormControl
									isInvalid={touchedFields?.current_password && errors?.current_password}
									id='current_password'
									isRequired>
									<FormLabel>Current Password</FormLabel>
									<Input
										{...register("current_password")}
										placeholder='Enter Current Password'
										type='password'
									/>
									<FormErrorMessage>{errors?.current_password?.message}</FormErrorMessage>
								</FormControl>

								<FormControl
									isInvalid={touchedFields?.new_password && errors?.new_password}
									id='new_password'
									isRequired>
									<FormLabel>New Password</FormLabel>
									<Input
										{...register("new_password")}
										placeholder='Enter New Password'
										type='password'
									/>
									<FormErrorMessage>{errors?.new_password?.message}</FormErrorMessage>
								</FormControl>

								<FormControl
									isInvalid={touchedFields?.new_confirm_password && errors?.new_confirm_password}
									id='new_confirm_password'
									className='mb-3'
									isRequired>
									<FormLabel>Confirm Password</FormLabel>
									<Input
										{...register("new_confirm_password")}
										placeholder='New Confirm Password'
										type='password'
										min='0'
									/>
									<FormErrorMessage>{errors?.new_confirm_password?.message}</FormErrorMessage>
								</FormControl>
							</Stack>
						</ModalBody>

						<ModalFooter>
							<Button className='mr-4' onClick={onClose}>
								Close
							</Button>
							<Button isLoading={loading} colorScheme='blue' type='submit'>
								Change Password
							</Button>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</>
	)
}

export default AvtaarDropDown
