import React from "react"
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
import {Button} from "@chakra-ui/button"

export const ExportToExcel = ({apiData, fileName, hidden,classes }) => {
	// console.log( "This is my API Data =============================> " , apiData)
	const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
	const fileExtension = ".xlsx"


	const exportToCSV = (apiData, fileName) => {
		const columnWidth  =  {wch:20};
		const wscols = [];
		for(let i =0; i<9;i++){
			wscols.push(columnWidth)
		};

		const data1 = [
			[{
			  value: 'Row 1, Col 1',
			  fontWeight: 'bold'
			}, {
			  value: 'Row 1, Col 2',
			  color: '#ffffff',
			  backgroundColor: '#cc0000'
			}],
			[{
			  value: 'Row 2, Col 1',
			  align: 'right',
			  alignVertical: 'top'
			}, {
			  value: 'Row 2, Col 2. Long Text \n Multi-line',
			  wrap: true
			}]
		  ]


		const ws = XLSX.utils.json_to_sheet(apiData);
		ws['!cols'] = wscols;
		ws['!rows'] = data1;
		// ws['B2'] = { f: `SUM(C2:C${apiData.length+2})`};
        // ws[`C${apiData.length+2}`] = { v: 'Total' };
		// ws['B2'] = { f: `SUM(C2:C${apiData.length+2})`};
        // ws[`C${apiData.length+2}`] = { v: 'Total' };
		const wb = {Sheets: {data: ws}, SheetNames: ["data"]}
		const excelBuffer = XLSX.write( wb, {bookType: "xlsx", type: "array"})
		const data = new Blob([excelBuffer], {type: fileType})
		FileSaver.saveAs(data, fileName + fileExtension)
	}

	return (
		<Button
			isDisabled={hidden}
			className={`mr-2 bg-gray-300 text-black ${classes}`}
			onClick={e => exportToCSV(apiData, fileName)}
			>
			Print
		</Button>
	)
}
