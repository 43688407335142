import React from "react"
// import { seo } from "../../hooks/dynamicTitle";
import ProcceedCheckout from "../../components/Modals/ProcceedCheckout"
import {useParams} from "react-router"
import queryString from "query-string"

const BillPage = ({history}) => {
	const params = useParams()
	const {print} = queryString.parse(window.location.search)

	// useEffect(() => {
	//   seo({
	//     title: "Billing",
	//     metaDescription: "With some meta description",
	//   });
	// });
	return <ProcceedCheckout history={history} uid={params.id} print={print} />
}

export default BillPage
