import {
  ADD_CART_ITEM,
  UPDATE_CART_ITEMS,
  FETCH_CART_DETAIL
} from "../constants/cart";

import {USER_SIGN_IN} from "../constants/auth"


const initialState = {
  cartItems : [],
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_SIGN_IN : 
    return {
      ...state ,
      cartItems : action.payload?.user?.cart ? JSON.parse(action.payload?.user?.cart?.data) : []
    }
    case FETCH_CART_DETAIL : 
    return {
      ...state ,
      cartItems : action.payload
    } 

    case ADD_CART_ITEM:
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload.cartItem],
      };

      
    case UPDATE_CART_ITEMS:
      return {
        ...state,
        cartItems: action.payload.cartItems,
      };

      case "EMPTY_CART" : return {
        ...state ,
        cartItems : action.payload.cartItems
      }
    default:
      return state;
  }
};
