import React from "react"

const PageContainer = ({children, className}) => {
	return <div className={`w-full mx-auto px-2 sm:px-6  ${className}`}>{children}</div>
}

export default PageContainer
PageContainer.defaultProps = {
	className: "max-w-7xl mt-6 lg:px-8"
}
