import React, {useState, useEffect} from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	IconButton,
	useToast,
	Stack,
	FormLabel,
	FormControl,
	FormErrorMessage,
	Input,
	Select,
	Textarea
} from "@chakra-ui/react"
import * as Yup from "yup"
import {useForm} from "react-hook-form"
import {useSelector, useDispatch} from "react-redux"
import {useYupValidationResolver} from "../../../hooks/useValidationResolver"
import EditIcon from "../../Icons/EditIcon"
import {addService} from "../../../store/actions/service"
import MultiSelect from "react-multiple-select-dropdown-lite"
import ReactQuill from "react-quill"
import { Bridal_ID } from "../../../constants/BridalServiceID"

const validationSchema = Yup.object({
	name: Yup.string().required("Name is required!"),
	price: Yup.number().typeError("Price must be a number").required("Price is required.").min(0, "Too little"),
	//.max(5000, "Very costly!"),
	category_id: Yup.string().required("Category is required!"),
	status: Yup.string().required("Status is required!")
	//comment: Yup.string().required("Comment is required!"),
})

const EditServiceModal = ({service, updateList}) => {
	const dispatch = useDispatch()
	const {Categorieslist} = useSelector(state => state.products)
	const [value, setValue] = React.useState("")
	const [cDefaultvalue, setCDefaultvalue] = React.useState("")
	const toast = useToast()
	const {isOpen, onOpen, onClose} = useDisclosure()
	const [loading, setLoading] = useState(false)
	const [categoryValue, setCategoryValue] = React.useState("")
	const resolver = useYupValidationResolver(validationSchema)
	const {
		register,
		handleSubmit,
		formState: {errors, touchedFields},
		reset
	} = useForm({
		defaultValues: service,
		resolver,
		mode: "onBlur"
	})

	useEffect(() => {
		reset(service)
		setValue(service?.comment)
		setCategoryValue(service?.category_id)
		let newValues = service?.category_id.split(",")
		let numValues = newValues.map(v => {
			return {value: Number(v)}
		})

		setCDefaultvalue(numValues)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [service, isOpen])

	// Common function to Capital first Letter












	const onUpdateService = data => {


		setLoading(true)
		dispatch(
			addService({
				id: service.id,
				name: data.name,
				discount: data.discount,
				// category_id: data.category_id,
				category_id: categoryValue,
				sorting: data.sorting,
				status: data.status,
				price: data.price,
				comment: value
			})
		)
			.then(() => {
				updateList();
				setLoading(false)
				toast({
					title: "Service has been updated!",
					status: "success",
					duration: 3000,
					isClosable: true
				})
				// reset the form

				reset()
				onClose()
			})
			.catch(err => {
				setLoading(false)
				console.log("ERR => while adding service", err)
			})
	}

	const optioncategory = Categorieslist.map((res, index) => ({
		value: res.id,
		label: res.name
	}))

	return (
		<>
			<IconButton
				className='mr-3'
				colorScheme='green'
				aria-label='Edit service'
				onClick={onOpen}
				icon={<EditIcon />}
			/>
			<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
				<ModalOverlay />
				<form
					style={{overflow: "auto"}}
					noValidate
					onSubmit={handleSubmit(onUpdateService)}
					className='bg-white'>
					<ModalContent>
						<ModalHeader className='capitalize'>Update Service</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Stack spacing={3}>
								<FormControl isInvalid={touchedFields?.name && errors?.name} id='name'>
									<FormLabel>Name</FormLabel>
									<Input {...register("name")} placeholder='Enter Name' type='text' />
									<FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
								</FormControl>
								<FormControl
									isInvalid={touchedFields?.price && errors?.price}
									id='price'
									className='mb-3'>
									<FormLabel>Price</FormLabel>
									<Input
										{...register("price")}
										placeholder='Enter Price'
										onWheel={event => event.currentTarget.blur()}
										type='number'
									/>
									<FormErrorMessage>{errors?.price?.message}</FormErrorMessage>
								</FormControl>
								<FormControl
									isInvalid={touchedFields?.category_id && errors?.category_id}
									id='category_id'
									className='mb-3'>
									<FormLabel>Category</FormLabel>
									<MultiSelect
										// onChange={handleOnchange}
										value={categoryValue}
										defaultValue={cDefaultvalue}
										onChange={setCategoryValue}
										options={optioncategory}
									/>
									<FormErrorMessage>{errors?.category_id?.message}</FormErrorMessage>
								</FormControl>
								<FormControl isInvalid={touchedFields?.discount && errors?.discount} id='discount'>
									<FormLabel>Discount</FormLabel>
									<Input
										{...register("discount")}
										placeholder='Enter Discount'
										type='number'
										onWheel={event => event.currentTarget.blur()}
									/>
									<FormErrorMessage>{errors?.discount?.message}</FormErrorMessage>
								</FormControl>
								<FormControl isInvalid={touchedFields?.sorting && errors?.sorting} id='sorting'>
									<FormLabel>Sorting</FormLabel>
									<Input {...register("sorting")} placeholder='Enter Sorting' type='text' />
									<FormErrorMessage>{errors?.sorting?.message}</FormErrorMessage>
								</FormControl>
								<FormControl isInvalid={touchedFields?.status && errors?.status} id='status'>
									<FormLabel>Status</FormLabel>
									<Select {...register("status")} placeholder='Select option'>
										<option value={1}>Enable</option>
										<option value={0}>Disable</option>
									</Select>
									<FormErrorMessage>{errors?.status?.message}</FormErrorMessage>
								</FormControl>
								<FormControl id='comment'>
									<FormLabel>Comment</FormLabel>
									<ReactQuill value={value} onChange={setValue} />
								</FormControl>
							</Stack>
						</ModalBody>

						<ModalFooter>
							<Button mr={3} onClick={onClose}>
								Close
							</Button>
							<Button isLoading={loading} type='submit' colorScheme='green' className='bg-gold'>
								Update
							</Button>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</>
	)
}

export default EditServiceModal
