import React, {useEffect, useState} from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Stack,
	Input,
	Textarea,
	FormLabel,
	FormControl,
	FormErrorMessage,
	useToast
} from "@chakra-ui/react"
import * as Yup from "yup"
import {useForm} from "react-hook-form"
import {useSelector, useDispatch} from "react-redux"
import {useYupValidationResolver} from "../../../hooks/useValidationResolver"
import {addExpense,getClosingExpense} from "../../../store/actions/expense"
import moment from "moment"

const validationSchema = Yup.object({
	date: Yup.string().required("Date is required!"),
	expense: Yup.string(),
	// total: Yup.string().required("Total expense is required!"),
	cash: Yup.string(),
	online: Yup.string(),
	comment: Yup.string()
})

const AddExpenseModal = ({updateExpenseList, classes}) => {
	const dispatch = useDispatch()
	const toast = useToast()
	const {user} = useSelector(state => ({
		user: state.auth.user
	}))

	const [expenseObj, setExpenseObj] = useState({
		cashExpense: "",
		cashPayment: "",
		expense_online:"",
		online:"",
		isChecked: false
	})

	const [date, setDate] = useState(new Date().toISOString().substr(0, 10))
	const {closingExpense} = useSelector(state => state.expense)
	const [loading, setLoading] = useState(false)
	const {isOpen, onOpen, onClose} = useDisclosure()
	const [closingBalance, setClosingBalance] = useState(0)
	const resolver = useYupValidationResolver(validationSchema)
	const {
		register,
		handleSubmit,
		formState: {errors, touchedFields},
		reset
	} = useForm({
		resolver,
		mode: "onBlur"
	})

	useEffect(()=>{
		isOpen && dispatch(getClosingExpense());
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	},[isOpen])


	useEffect(() =>{
		setClosingBalance(
			parseInt(closingExpense?.closing || 0)  +  (expenseObj.total || 0) - ((expenseObj.cashPayment || 0) + (expenseObj.cashExpense || 0)  + (expenseObj.online || 0))
		)
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expenseObj])

	const expenseValueHandler = e => {
		setExpenseObj(prev => ({
			...prev,
			[e.target.name]: parseInt(e.target.value)
		}))
	}

	// ==================== Common Error Toast ===================

	const errorToast = error => {
		toast({
			title: error,
			status: "error",
			duration: 3000,
			isClosable: true
		})
	}



const GetDaysDiffernce = ( lastExpenseDate , NewDate)=>{
		const dateofLastExpense = (moment(lastExpenseDate));
		const today = moment(NewDate)
		const diffTime = today.diff(dateofLastExpense, "days");
		return diffTime
}

const diffTime = !closingExpense ? null  :  GetDaysDiffernce(closingExpense?.date , date);


// const dateofLastExpense = (moment(closingExpense?.date));
// 	const today = moment(date)
// 	const diffTime = today.diff(dateofLastExpense, "days");

	

	const onAddExpense = data => {

       const diffTime = closingExpense ? GetDaysDiffernce(closingExpense?.date , date) : null;

		if (diffTime === 1 || diffTime === 0 || diffTime === null){
			setLoading(true)
			//   ====================== Checking Condition if Holiday Then This Dispatch run ======================
			if (expenseObj.isChecked) {
				dispatch(
					addExpense({
						id: user.id,
						total: 0,
						date: date,
						expense: 0.0,
						expense_online: 0,
						closing: closingBalance,
						cash: 0.0,
						online: 0.0,
						comment: `Holiday on (${moment(date).format("DD MMM YYYY")})`
					})
				)
					.then(() => {
						setLoading(false)
						updateExpenseList();
						setExpenseObj(prev => ({
							...prev,
							cashExpense: "",
							cashPayment: "",
							total : "",
							online : "",
							expense_online : "",
							isChecked: false
						}))
						onClose()
						toast({
							title: "Expense has been added!",
							status: "success",
							duration: 3000,
							isClosable: true
						})
						// reset the form
						reset()
						dispatch(getClosingExpense())
						setClosingBalance(0)
					})
					.catch(err => {
						setLoading(false)
						errorToast(err?.error)
						
					})
			}
			// ====================== Checking Condition no  Holiday Then This Dispatch run ======================
			else {
				dispatch(
					addExpense({
						id: user.id,
						total: expenseObj.total || 0.0,
						date: date,
						expense: expenseObj.cashExpense || 0.0,
						expense_online: data.expense_online || 0.0,
						closing: closingBalance || closingExpense.closing,
						cash: expenseObj.cashPayment || 0.0,
						online: data.online || 0.0,
						comment: data.comment
					})
				)
					.then(() => {
						setLoading(false)
						updateExpenseList()
						setExpenseObj(prev => ({
							...prev,
							cashExpense: "",
							cashPayment: "",
							total : "",
							online : "",
							expense_online : "",
							isChecked: false
						}))
						onClose()
						toast({
							title: "Expense has been added!",
							status: "success",
							duration: 3000,
							isClosable: true
						})
						// reset the form
						reset()
						dispatch(getClosingExpense())
						setClosingBalance(0)
					})
					.catch(err => {
						errorToast(err?.error)
						setLoading(false)
					})
			}
		} else if (diffTime < 0) {
			errorToast("You can not Enter Previous Date Expense")
		} else {
			errorToast("Please Enter Prevoius Date Expense Before Entering Next Date Expense")
		}
	}

	const closeModalHandler = ()=>{
		
		reset();
		onClose();
		setExpenseObj(prev => ({
			...prev,
			cashExpense: "",
			cashPayment: "",
			total : "",
			online : "",
			expense_online : "",
			isChecked: false
		}))

	}

	let closingExpenseDate = new Date(closingExpense?.date)

	// let startExpenseDate = `${closingExpenseDate.getFullYear()}-${closingExpenseDate.getMonth() + 1}-${
	// 	closingExpenseDate.getDate()
	// } `

	//  ============= Setting Start Expense Date ==============
	let startExpenseDate = moment(closingExpenseDate).add(1, "d")
	const startDateHandler = () => {
		let startDate = moment(startExpenseDate).format("YYYY-MM-DD")
		let ParseDate = new Date(startDate)
		setDate(ParseDate.toISOString().substr(0, 10))
	}

	return (
		<>
			{/* <Button colorScheme="green" className="mr-2" >
          Print
        </Button> */}
			<Button colorScheme='blue' onClick={onOpen} className={classes}>
				Add Expense
			</Button>
			<Modal isOpen={isOpen} onClose={closeModalHandler} scrollBehavior='inside' size='xl'>
				<ModalOverlay />

				<form style={{overflow: "auto"}} noValidate onSubmit={handleSubmit(onAddExpense)} className='bg-white'>
					<ModalContent>
						<ModalHeader> Add Expense </ModalHeader>
						<ModalCloseButton />
						<div className='flex mt-1 justify-between px-10 py-2'>
								<div>
									<h1 className='text-lg font-bold'>Opening : { closingExpense ? parseInt(closingExpense?.closing) :  0}</h1>
								</div>
								<div>
									<h1 className='text-lg font-bold text-red-900 '>Closing : {closingBalance}</h1>
								</div>
							</div>
						<ModalBody>

							{/* <div className='flex justify-between'>
								<span className='py-1 font-medium border-b'>*Last Entered Expense Date : </span>{" "}
								<span>{moment(closingExpenseDate).format("DD MMM YYYY")}</span>
							</div> */}

							{(diffTime || closingExpense) ? (<div className='flex justify-between'>
								<span className='my-3 font-medium'>*First add Expense With : </span>
								<span className='underline my-3  cursor-pointer' onClick={startDateHandler}>
									{" "}
									{moment(startExpenseDate).format("DD MMM YYYY")}
								</span>
							</div>) : "" } 
							<Stack spacing={3}>
								<FormControl isInvalid={touchedFields?.date && errors?.date} id='date' isRequired>
									<FormLabel>Date</FormLabel>
									<Input
										{...register("date")}
										placeholder='Select date'
										type='date'
										onChange={e => setDate(e.target.value)}
										value={date}
									/>
									<FormErrorMessage>{errors?.date?.message}</FormErrorMessage>
								</FormControl>

								<FormControl className='my-4'>
									<FormLabel> Holiday</FormLabel>
									<input
										className=' h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
										checked={expenseObj.isChecked}
										type='checkbox'
										onChange={() => setExpenseObj(prev => ({...prev, isChecked: !prev.isChecked}))}
									/>
									<label
										className='form-check-label inline-block text-gray-800'
										htmlFor='flexCheckDefault'>
										Check if Holiday
									</label>
								</FormControl>
								<FormControl
									isInvalid={touchedFields?.total && errors?.total}
									onWheel={event => event.currentTarget.blur()}
									id='total'
									className='mb-3'>
									<FormLabel>Total</FormLabel>
									<Input
										{...register("total")}
										disabled={expenseObj.isChecked}
										value={expenseObj.total}
										// onClick={() => setExpenseObj(prev => ({...prev, cashPayment: ""}))}
										onChange={expenseValueHandler}
										onWheel={event => event.currentTarget.blur()}
										name='total'
										placeholder='Enter total payment'
										type='number'
										min='0'
									/>

									<FormErrorMessage>{errors?.total?.message}</FormErrorMessage>
								</FormControl>
								<FormControl
									isInvalid={touchedFields?.cash && errors?.cash}
									onWheel={event => event.currentTarget.blur()}
									id='cash'
									className='mb-3'>
									<FormLabel>Cash Payment</FormLabel>
									<Input
										{...register("cash")}
										disabled={expenseObj.isChecked}
										value={expenseObj.cashPayment}
										// onClick={() => setExpenseObj(prev => ({...prev, cashPayment: ""}))}
										onChange={expenseValueHandler}
										onWheel={event => event.currentTarget.blur()}
										name='cashPayment'
										placeholder='Enter cash payment'
										type='number'
										min='0'
									/>

									<FormErrorMessage>{errors?.cash?.message}</FormErrorMessage>
								</FormControl>
								<FormControl
									isInvalid={touchedFields?.online && errors?.online}
									id='online'
									className='mb-3'>
									<FormLabel>Online Payment</FormLabel>
									<Input
										{...register("online")}
										disabled={expenseObj.isChecked}
										name= "online"
										value={expenseObj.online}
										onChange={expenseValueHandler}
										onWheel={event => event.currentTarget.blur()}
										placeholder='Enter online payment'
										type='number'
										min='0'
									/>
									<FormErrorMessage>{errors?.online?.message}</FormErrorMessage>
								</FormControl>

								<FormControl
									isInvalid={touchedFields?.cashExpense && errors?.cashExpense}
									id='cashExpense'
									className='mb-3'>
									<FormLabel> Cash Expense</FormLabel>
									<Input
										{...register("cashExpense")}
										disabled={expenseObj.isChecked}
										value={expenseObj.cashExpense}
										onChange={expenseValueHandler}
										name='cashExpense'
										onWheel={event => event.currentTarget.blur()}
										placeholder='Enter Your Cash Expense'
										type='number'
										min='0'
									/>
									<FormErrorMessage>{errors?.cashExpense?.message}</FormErrorMessage>
								</FormControl>
								<FormControl
									isInvalid={touchedFields?.expense_online && errors?.expense_online}
									id='expense_online'
									className='mb-3'>
									<FormLabel> Online Expense</FormLabel>
									<Input
										{...register("expense_online")}
										disabled={expenseObj.isChecked}
										onWheel={event => event.currentTarget.blur()}
										placeholder='Enter Your Online Expense'
										value={expenseObj.expense_online}
										name = "expense_online"
										onChange={expenseValueHandler}
										type='number'
										min='0'
									/>
									<FormErrorMessage>{errors?.expense_online?.message}</FormErrorMessage>
								</FormControl>
								<FormControl isInvalid={touchedFields?.comment && errors?.comment} id='comment'>
									<FormLabel>Comment</FormLabel>
									<Textarea
										rows='4'
										{...register("comment")}
										disabled={expenseObj.isChecked}
										placeholder='Enter comment...'
										type='text'
									/>
									<FormErrorMessage>{errors?.comment?.message}</FormErrorMessage>
								</FormControl>
							</Stack>
						</ModalBody>
						<ModalFooter>
							<Button className='mr-4' onClick={closeModalHandler}>
								Close
							</Button>
							<Button isLoading={loading} colorScheme='blue' className='bg-gold' type='submit'>
								Add
							</Button>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</>
	)
}

export default AddExpenseModal





