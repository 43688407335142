import React from "react"
import {NavLink} from "react-router-dom"

const ErrorPage = () => {
	return (
		<>
			<div className='bg-white flex justify-center items-center flex-col w-full h-full fixed top-0 right-0 bottom-0 left-0 z-20'>
				<img src='/img/404.png' alt='' style={{width: "500px"}} />

				<h4 className='mb-3 text-2xl font-bold'>404 - PAGE NOT FOUND</h4>
				<p className='mb-3'>
					The page you are looking for might have been removed, <br /> had its name changed or is temporarily
					unavailable
				</p>
				<NavLink to='/'>
					<button className='bg-blue-600 text-white rounded-3xl py-3 px-5 mt-3'>Go to homepage</button>
				</NavLink>
			</div>
		</>
	)
}

export default ErrorPage
