import React from "react"
import ReactDOM from "react-dom"
import "react-quill/dist/quill.snow.css"
//import firebase from './firebase'
import "react-multiple-select-dropdown-lite/dist/index.css"
import {Provider} from "react-redux"
import "./index.css"
// import serviceWorker from './serviceWorkerDev'
import App from "./App"

import {store} from "./store"
import swDev from "./swDev"
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
)

swDev()

// serviceWorkerRegistration.register();
