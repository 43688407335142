import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Table, Thead, Tbody, Tr, Th, Td, Box,
} from "@chakra-ui/react";




const ServiceView = ({ children ,ServiceView }) => {

  const { isOpen, onOpen, onClose } = useDisclosure();
 let parsedData ; 
 let StringData ;
 let total ;
 let discount ;  
 let totalafterDiscount;
 let quantity;
 try{
   let servicesData = JSON.parse(ServiceView.services)
   parsedData = JSON.parse(servicesData.service);
   total = parsedData.reduce((a,v)=>{
      return parseInt(a)  + parseInt(v.price) * parseInt(v.quantity)
   },0)

  quantity = parsedData.reduce((a,v)=>{
    return parseInt(a)  + parseInt(v.quantity)
 },0)

   discount =  parsedData.reduce(
    (a, v) => (a = parseInt(a) + (parseInt(v.discount)) * parseInt(v.quantity)),
    0
  )
   totalafterDiscount = total - discount ; 
 }

 catch{
   StringData = ServiceView.services
 }

 
  return (
    <>
    <span className="inline" onClick={onOpen}>
    {children}
    </span>
  
      
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay className="bg-gold-701 modalOverlay"/>
        <ModalContent>
          <ModalHeader className="capitalize bg-gold text-center text-2xl font-medium text-white">View Service</ModalHeader>
          <ModalCloseButton className="text-white " />
          <ModalBody>

           { parsedData &&  <Box overflowX='auto' w='100%'>
								<Table className='my-4' variant='simple'>
									<Thead>
											<Tr className='h-12 uppercase'>
												<Th className='text-left text-base p-2'>Service</Th>
												<Th className=' text-left text-base p-2 md:table-cell whitespace-nowrap'>
													<span className='text-left '>Quantity</span>
												</Th>
												<Th className=' text-left text-base p-2 md:table-cell whitespace-nowrap'>
													  Unit Charges
												</Th>
												<Th className='text-left text-base p-2 whitespace-nowrap md:table-cell'>
													  Discount
												</Th>
												<Th className=' text-left text-base p-2 whitespace-nowrap'>
													Total Charges
												</Th>
											</Tr>
										 
									</Thead>
                  <Tbody>
										{parsedData && parsedData.map(res => {
													return (
														<Tr key={res.id}>
															<Td className='whitespace-nowrap text-left p-2'>
																<div className='flex items-center justify-center'>
																	<p className='mb-2'>{res.name}</p>
																</div>
															</Td>

                              
															<Td className='justify-center md:flex p-2 text-left'>
																<div className='flex flex-row items-center justify-center w-full h-8'>
																	<Button className='w-8 h-8 px-2 m-1 cursor-auto bg-gray-200 md:text-base text-xs'>
																		<span>{res.quantity}</span>
																	</Button>
																</div>
															</Td>
															<Td className='text-left md:table-cell p-2'>
																<span className='text-sm lg:text-base md:text-base text-xs font-medium'>
																	₹ {res.price}
																</span>
															</Td>
															<Td className='text-center p-2'>
																<span className='text-sm lg:text-base md:text-base text-xs font-medium'>
																	{res.discount * res.quantity}
																</span>
															</Td>
															<Td className='text-center p-2'>
																<span className='text-sm lg:text-base font-medium'>
																	₹{" "}
																	{(res.price * res.quantity) - (res.discount * res.quantity)}
																</span>
															</Td>
														</Tr>
													)
											  })}
									</Tbody>
                  </Table>
                  </Box> }

            <h5 className="mt-5"><b>Name :</b> {ServiceView.name?ServiceView.name : "Not Available"}</h5>
            <h5 className="mt-5"><b>Phone :</b> {ServiceView.phone}</h5>
            <h5 className="mt-5"><b>Email :</b> {ServiceView.email ?ServiceView.email : "Not Available"}</h5>
            <h5 className="mt-5"><b>Date :</b> {!ServiceView.date || ServiceView.date === "0000-00-00 00:00:00" ? "Not Available" : ServiceView.date }</h5>  
            <h5 className={`mt-5  ${StringData  ?  "block" : "hidden"}`}><b>Services :</b> {StringData}</h5>
            <h5 className="mt-5"><b>Quantity :</b> {quantity ? quantity : "Not Available" }</h5>
            <h5 className="mt-5"><b>Total :</b> {total ? total : "Not Available"} </h5>
            <h5 className="mt-5"><b>Discount :</b> {discount ? discount : "Not Available"} </h5>
            <h5 className="mt-5"><b>Total After Dicount:</b> {totalafterDiscount ? totalafterDiscount : "Not Available"} </h5>
            <h5 className="mt-5"><b>Comment :</b> {ServiceView.comment ? ServiceView.comment : "Not Available"}</h5>
          </ModalBody>
          <ModalFooter>
              <button  className="px-3 py-1 rounded bg-gold text-white" onClick={onClose}> Close </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ServiceView;
