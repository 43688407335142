import React, {useState} from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	IconButton,
	useToast
} from "@chakra-ui/react"
import {deleteOffer} from "../../../store/actions/offer"
import DeleteIcon from "../../Icons/DeleteIcon"
import {useDispatch} from "react-redux"

const DeleteOfferConfirmationModal = ({id, updateList}) => {
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()
	const {isOpen, onOpen, onClose} = useDisclosure()

	const toast = useToast()

	const onDelete = () => {
		setLoading(true)
		dispatch(deleteOffer(id)).then(() => {
			setLoading(false)
			onClose()
			toast({
				title: "Offer has been Deleted!",
				status: "success",
				duration: 3000,
				isClosable: true
			})
			updateList()
		})
	}

	return (
		<>
			<IconButton
				colorScheme='red'
				aria-label='Delete Expense'
				onClick={onOpen}
				icon={<DeleteIcon />}
				size='sm'
			/>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className='capitalize'>Delete Offer</ModalHeader>
					<ModalCloseButton />
					<ModalBody>Are you sure you want to delete this Offer?</ModalBody>

					<ModalFooter>
						<Button mr={3} onClick={onClose}>
							Close
						</Button>
						<Button isLoading={loading} colorScheme='red' className='bg-gold' onClick={onDelete}>
							Delete
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default DeleteOfferConfirmationModal
