import React from "react"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	IconButton
} from "@chakra-ui/react"
import DeleteIcon from "../../Icons/DeleteIcon"

const DeleteConfirmModal = ({type, onDelete, user}) => {
	const {isOpen, onOpen, onClose} = useDisclosure()

	return (
		<>
			<IconButton
				colorScheme='red'
				aria-label='Delete Expense'
				onClick={onOpen}
				icon={<DeleteIcon />}
				disabled={user.user_type === 1 ? false : true}
				size='sm'
			/>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className='capitalize'>Delete {type}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>Are you sure you want to delete this {type}?</ModalBody>

					<ModalFooter>
						<Button mr={3} onClick={onClose}>
							Close
						</Button>
						<Button
							colorScheme='red'
							className='bg-gold'
							onClick={() => {
								onClose()
								onDelete()
							}}>
							Delete
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default DeleteConfirmModal
