import {CATEGORIES_LIST_ITEM, GET_PRODUCTS_BY_CATEGORY_ID,GET_CATEGORIES_FOR_FRONTEND} from "../constants/categorieslist";

const initialState = {
  Categorieslist: [],
  productsByCategoryId: [],
  ProductsByIdForFrontEnd : []
};

export const categoriesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORIES_LIST_ITEM:
      return {
        ...state,
        Categorieslist: action.payload.Categorieslist,
      };
      
    case GET_PRODUCTS_BY_CATEGORY_ID:
      return {
        ...state,
        productsByCategoryId: action.payload.productsByCategoryId,
      };

      case GET_CATEGORIES_FOR_FRONTEND : return {
        ...state ,
        ProductsByIdForFrontEnd : action.payload.productsByCategoryId
      }
    default:
      return state;
  }
};