import React, {useState, useEffect} from "react"
import {Table, Thead, Tbody, Tfoot, Tr, Th, Td, useToast, Box, Input} from "@chakra-ui/react"
import {useSelector, useDispatch} from "react-redux"
import PrivateLayout from "../../../layout/PrivateLayout"
import PageHeader from "../../../components/PageHeader"
import PageContainer from "../../../components/PageContainer"
import AddExpenseModal from "../../../components/Modals/AddExpenseModal"
import DeleteConfirmModal from "../../../components/Modals/DeleteConfirmModal"
import EditExpenseModal from "../../../components/Modals/EditExpenseModal"
import {ExportToExcel} from "../../../components/ExportData/ExportData"
import {getAllExpense, deleteExpense} from "../../../store/actions/expense"
import { getListSummary} from "../../../utils/list-expense"
import styles from "./index.module.css"
import Loader from "../../../components/Loader/Loader"
import ExpenseView from "../../../components/Modals/ExpenseView"
import {getStartOfMonth} from "../../../utils/common"
import {IoIosArrowUp, IoIosArrowDown} from "react-icons/io"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file

import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import {DateRangePicker} from "react-dates"
import moment from "moment"

const ListExpense = ({history}) => {
	const dispatch = useDispatch()
	const toast = useToast()
	// const months = getMonths()
	const {expenses} = useSelector(state => state.expense)
	const {user} = useSelector(state => state.auth)
	const [loading, setLoading] = useState()
	const [hidden, setHidden] = useState(false)
	const [showSummary , setShowSummary] = useState(false);


		// =======================Common Function for calling API Request ======================== 

const getExpense = ()=>{
	setLoading(true)
	dispatch(
		getAllExpense({
			start_date: dateObject.startDate
				? moment(dateObject.startDate).format("YYYY-MM-DD")
				: moment(startMonthDate).format("YYYY-MM-DD"),
			end_date: dateObject.startDate
				? moment(dateObject.endDate).format("YYYY-MM-DD")
				: moment(new Date()).format("YYYY-MM-DD")
		})
	)
		.then(() => {
			setLoading(false)
		})
		.catch(err => {
			setLoading(false)
		})
}



// =======================	This Action Dispatch With Initial Actio When First Time Page Loads ======================== 


	useEffect(()=>{
		getExpense();
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	},[])


    
	const result = expenses?.expenses ?  expenses?.expenses?.map(({id, cash1, ...rest}) => ({...rest})) : []
	const resultforSum = expenses?.expenses ? expenses?.expenses?.map(({id, cash, online, cash1, date, comment, created_at, updated_at, ...rest}) => ({
		...rest
	})) : [] ;


	//  =============================== state For Read More Handler ====================================

	const [readMore, setReadMore] = useState({
		isTrue: false,
		id: ""
	})

	const resultArray = result.map(function (elm) {
		return {
			Date: elm.date,
			Expenses: parseInt(elm.expense),
			Cash: parseInt(elm.cash),
			Online: parseInt(elm.online),
			Total: parseInt(elm.total),
			closing : parseInt(elm.closing),
			expense_online : parseInt(elm.expense_online)

			// Cash1: parseInt(elm.cash1),
		}
	})

	// ======================= commom Variable For Fetching starting date of Month ==================

	const startMonthDate = getStartOfMonth(new Date())

	const profitArray = resultforSum.map(v => {
		return {Profit: parseInt(v.total) - (parseInt(v.expense) + parseInt(v.expense_online))}
	})

	let finalArray = resultArray.map((item, i) => Object.assign({}, item, profitArray[i]));

	const expense = finalArray.reduce((acc, curr) => acc + curr.Expenses, 0)
	const cash = finalArray.reduce((acc, curr) => acc + curr.Cash, 0)
	const online = finalArray.reduce((acc, curr) => acc + curr.Online, 0)
	const expense_online = finalArray.reduce((acc, curr) => acc + curr.expense_online, 0)
	const total = finalArray.reduce((acc, curr) => acc + curr.Total, 0)
	// const closing = finalArray.reduce((acc,curr)=>acc+curr.closing,0)
	// const cash1 = finalArray.reduce((acc, curr) => acc + curr.Cash1, 0);
	const profit = profitArray.reduce((acc, curr) => acc + curr.Profit, 0)

      


	const resultedArray = expenses?.expenses?.map(function (elm) {
		return {
			Date: elm.date,	
			Total : parseInt(elm.total),
			Cash_Amount: parseInt(elm.cash),
	        Online_Amount : parseInt(elm.online),
	        Cash_Expense : parseInt(elm.expense),
			Online_Expense : parseInt(elm.expense_online),
	        Closing : elm?.closing,
			Added_By: elm?.user?.name || "",
			Comment: elm.comment,
		}
	});

	const Cash = resultedArray?.reduce((acc, curr) => acc + curr.Cash_Amount, 0);
	const cash_online = resultedArray?.reduce((acc, curr) => acc + curr.Online_Amount, 0);
	const cash_expense = resultedArray?.reduce((acc, curr) => acc + curr.Cash_Expense, 0);
	const Online_expense = resultedArray?.reduce((acc, curr) => acc + curr.Online_Expense, 0);
	 const Total = resultedArray?.reduce((acc, curr) => acc + curr.Total, 0);

const obj = {
	Total : Total,
	Cash_Amount: Cash,
	Online_Amount : cash_online,
	Cash_Expense : cash_expense,
	Online_Expense : Online_expense,
}

resultedArray?.push(obj);

	const sortOrder = {
		Date: 1,	
		Total : 2,
		Cash_Amount: 3,
		Online_Amount : 4,
		Cash_Expense : 5,
		Online_Expense : 6,
		Closing : 7,
		Added_By: 8 || "",
		Comment: 9,
	}

	const sortedArray = resultedArray?.map(o =>
		Object.assign(
			{},
			...Object.keys(o)
				.sort((a, b) => sortOrder[a] - sortOrder[b])
				.map(x => {
					return {[x]: o[x]}
				})
		)
	)







	// const lastArray = finalArray.splice(-1, 1, {
	// 	Cash: cash,
	// 	Online: online,
	// 	Total: total,
	// 	Profit: profit,
	// 	Expenses: expense
	// })

	// const expArray = [...lastArray, ...finalArray]

	// const sortOrder = {
	// 	Date: 1,
	// 	Total: 2,
	// 	Cash: 3,
	// 	Online: 4,
	// 	Expenses: 5,
	// 	Profit: 6
	// }

	// const sortedArray = expArray.map(o =>
	// 	Object.assign(
	// 		{},
	// 		...Object.keys(o)
	// 			.sort((a, b) => sortOrder[a] - sortOrder[b])
	// 			.map(x => {
	// 				return {[x]: o[x]}
	// 			})
	// 	)
	// )

	// =============================== state for date object of Date Range  =======================

	const [dateObject, setDateObject] = useState({
		startDate: null,
		endDate: null,
		focusedInput: null
	})









// =============================== state for Date Range Calender Focus Input Prop  =======================

const [focusedInput , setFocusIntput] = useState("");


	// ============================== state for Input cash and input Expense =======================
	const [inputPaymentObject, setInputPaymentObject] = useState({
		inputCash: "",
		inputExpense: "",
		inputCashSalary : parseInt(expenses?.amount),
		inputOnlineSalary : parseInt(expenses?.online_amount),
	})

	// ============================== state for Payment Object for  Total cash and Total Expense =======================

	const [totalPaymentObject, setTotalPaymentObject] = useState({
		totalCash: "",
		totalExpense: "",
	})

	// =================================== Intput Payment Handler ===============================

	const inputPaymentHandler = e => {
		setInputPaymentObject(prev => ({
			...prev,
			[e.target.name]: parseInt(e.target.value)
		}))
	}

	// ======================= Effect for Set Total Online and Total Cash When when Input Payment Object Changes =========================

	useEffect(() =>{
		const {inputCash, inputExpense} = inputPaymentObject
		setTotalPaymentObject(prev => ({
			...prev,
			totalCash: (inputCash || 0) + cash - expense - (expenses?.amount ||0),
			totalExpense: (inputExpense || 0) + online - expense_online - (expenses?.online_amount||0)
		}))
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ expenses, inputPaymentObject]);

	const fileName = "expense" // here enter filename for your excel file
	useEffect(() => {
		if(dateObject.startDate && dateObject.endDate){
			getExpense();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateObject])

	const onDelete = exp => {
		dispatch(deleteExpense(exp))
			.then(() => {
				getExpense();
				toast({
					title: "Expense has been deleted!",
					status: "success",
					duration: 3000,
					isClosable: true
				})
			})
			.catch(err => console.log("ERR => error while deleting expense"))
	}

	// const getHandle = e => {
	// 	setFilters(inputs => ({...inputs, [e.target.name]: e.target.value}))
	// }







	const summary = getListSummary(expenses?.expenses);

	const sortFilter = (data, colName) => {
		setLoading(true)
		dispatch(
			getAllExpense({
				direction: data,
				sortby: colName,
				start_date: dateObject.startDate
					? moment(dateObject.startDate).format("YYYY-MM-DD")
					: moment(startMonthDate).format("YYYY-MM-DD"),
				end_date: dateObject.endDate
					? moment(dateObject.endDate).format("YYYY-MM-DD")
					: moment(new Date()).format("YYYY-MM-DD")
			})
		)
			.then(() => {
				setLoading(false)
			})
			.catch(err => {
				setLoading(false)
			})
	}

	useEffect(() => {
		const handleHiddenButton = () => {
			if (expenses?.expenses?.length > 0) {
				setHidden(false)
			} else {
				setHidden(true)
			}
		}
		handleHiddenButton()
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expenses])

	//  =============================== Read More Handler ====================================

	const readMoreHandler = id => {
		setReadMore(prev => ({
			...prev,
			id: id,
			isTrue: !prev.isTrue
		}))
	}

	//  ================================ For Responsive Date Range Calendear ==========================

	let numberOfMonths = window.matchMedia("(max-width: 700px)").matches ? "1" : "2"
	let small =  window.matchMedia("(max-width: 700px)").matches ? true : false
	let large = window.matchMedia("(max-width: 700px)").matches ? false : true
	return (
		<PrivateLayout>
			{user.user_type === 1 && (
				<PageContainer>

             <div className="flex items-center pb-2 sm:pb-5">
					  <div className="lg:w-10/12 sm:w-9/12 w-7/12">
                        <hr className="border-indigo-700 border" />
					  </div>
					  <div className="lg:w-2/12 sm:w-3/12  w-5/12  flex items-center justify-center ">
						  <span className="cursor-pointer flex items-center " onClick={()=> setShowSummary((prev)=>!prev) }>
					  <span className="text-blue-800 select-none text-base"  >{ !showSummary ? "Show Summary" : "Hide Summary"}</span>
					  <i className={`bx bx-chevron-${!showSummary ? 'down' : 'up'} text-blue-800 text-2xl`}/>
					  </span>
					  </div>
					</div>

              { showSummary &&  <div>
					<div className='md:flex '>
						<div className='flex px-3 md:w-1/4 w-full'>
							<div className='relative my-1 w-full md:mr-3 mr-4'>
								<label>Enter Cash</label>
								<Input
									onWheel={event => event.currentTarget.blur()}
									placeholder='Enter Cash'
									name='inputCash'
									value={inputPaymentObject.inputCash}
									type='number'
									onChange={inputPaymentHandler}
									min='0'
								/>
							</div>
							<div className='relative my-1 w-full md:ml-0 ml-4'>
								<label>Enter Online</label>
								<Input
									onWheel={event => event.currentTarget.blur()}
									placeholder='Enter Online'
									name='inputExpense'
									value={inputPaymentObject.inputExpense}
									onChange={inputPaymentHandler}
									type='number'
									min='0'
								/>
							</div>
						</div>

						<div className='md:flex md:w-3/4 w-full md:justify-between'>
							<div className="md:w-1/2 w-full">
							<div className="flex justify-between ">
						<div className='relative w-full my-1 md:ml-8 ml-4 '>
								<label>Cash Salary</label>
								<div className='flex items-center flex-wrap py-1 mr-2 bg-white  border rounded shadow-lg'>
									<div className='flex items-center'>
									<em className='bx px-2 bxs-check-circle  text-2xl text-blue-600'></em>
										<p className='text-sky-500 text-xl'>{expenses?.amount}</p>
									</div>
								</div>
							</div>
							
							<div className='relative w-full my-1 md:ml-3 ml-4 '>
								<label>Online Salary</label>
								<div className='flex items-center flex-wrap py-1 mr-2 bg-white  border rounded shadow-lg'>
									<div className='flex items-center'>
										<em className='bx px-2 bxs-check-circle text-2xl text-sky-500'></em>
										<p className='text-sky-500 text-xl'>{expenses?.online_amount}</p>
									</div>
								</div>
							</div>
							</div>
                            </div>
							<div className="md:w-1/2 w-full sm:mt-0 mt-3">
							<div className=" flex justify-between ">
							<div className='relative w-full my-1 md:ml-3 ml-4  '>
								<label>Cash left</label>
								<div className='flex  items-center flex-wrap py-1 mr-2 bg-white border rounded shadow-lg'>
									<div className='flex items-center'>
										<em className='bx px-2 bxs-check-circle  text-2xl text-blue-600'></em>
										<p className='text-blue-600 text-xl'>{totalPaymentObject.totalCash}</p>
									</div>
								</div>
							</div>
							<div className='relative w-full my-1 md:ml-3 ml-4 '>
								<label>Online Left</label>
								<div className='flex items-center flex-wrap py-1 mr-2 bg-white  border rounded shadow-lg'>
									<div className='flex items-center'>
										<em className='bx px-2 bxs-check-circle text-2xl text-sky-500'></em>
										<p className='text-sky-500 text-xl'>{totalPaymentObject.totalExpense}</p>
									</div>
								</div>
							</div>
					</div>
					</div>
						</div>
					</div>

					 <Box w='100%' alignItems='baseline' className="mt-4" overflowX='auto'>
						<div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 my-3'>
							<div className='flex justify-between items-center flex-wrap bg-white border rounded p-4 shadow-lg'>
								<div className='flex items-center'>
									<em className='bx bxs-check-circle text-2xl text-pink-600'></em>
									<p className='pl-2 text-lg whitespace-nowrap'>Total</p>
								</div>
								<p className='text-pink-600 text-xl'>{summary.totalExpenses}</p>
							</div>
							{/* <div className='flex justify-between items-center flex-wrap bg-white border rounded p-4 shadow-lg'>
								<div className='flex items-center'>
									<em className='bx bxs-check-circle text-2xl text-blue-600'></em>
									<p className='pl-2 text-lg whitespace-nowrap'>Closing</p>
								</div>
								<p className='text-blue-600 text-xl'>{summary.closingTotal}</p>
							</div> */}
							<div className='flex justify-between items-center flex-wrap bg-white border rounded p-4 shadow-lg'>
								<div className='flex items-center'>
									<em className='bx bxs-check-circle text-2xl text-sky-500'></em>
									<p className='pl-2 text-lg whitespace-nowrap'>Cash</p>
								</div>
								<p className='text-sky-500 text-xl'>{summary.cashTotal}</p>
							</div>
							<div className='flex justify-between items-center flex-wrap bg-white border rounded p-4 shadow-lg'>
								<div className='flex items-center'>
									<em className='bx bxs-check-circle text-2xl text-sky-500'></em>
									<p className='pl-2 text-lg whitespace-nowrap'>Online</p>
								</div>
								<p className='text-sky-500 text-xl'>{summary.onlineTotal}</p>
							</div>
							<div className='flex justify-between items-center flex-wrap bg-white border rounded p-4 shadow-lg'>
								<div className='flex items-center'>
									<em className='bx bxs-check-circle text-2xl text-sky-500'></em>
									<p className='pl-2 text-lg whitespace-nowrap'>Cash Expense</p>
								</div>
								<p className='text-sky-500 text-xl'>{summary.expenseTotal}</p>
							</div>
							<div className='flex justify-between items-center flex-wrap bg-white border rounded p-4 shadow-lg'>
								<div className='flex items-center'>
									<em className='bx bxs-check-circle text-2xl text-sky-900'></em>
									<p className='pl-2 text-lg whitespace-nowrap'>Online Expense</p>
								</div>
								<p className='text-sky-900 text-xl'>{summary.onlineExpense}</p>
							</div>
							<div className='flex justify-between items-center flex-wrap bg-white border rounded p-4 shadow-lg'>
								<div className='flex items-center'>
									<em className='bx bxs-check-circle text-2xl text-pink-600'></em>
									<p className='pl-2 text-lg whitespace-nowrap'>Profit/Expense</p>
								</div>
								<p className='text-pink-600 text-xl'>{totalPaymentObject.totalCash + totalPaymentObject.totalExpense}/{summary.expenseTotal+summary.onlineExpense+expenses?.amount+expenses?.online_amount}</p>
							</div>
						</div>
					</Box>
					</div>}
				</PageContainer>
			)}

			{user.user_type === 1 && (
				<PageHeader
					// flex1Class='mt-3'
					className='lg:ml-0 flex-wrap'
					pageHeading={expenses?.expenses && `All expenses (${expenses?.expenses?.length})`}
					rightContent={
						<>
						<div className=" sm:flex sm:mt-0 mt-3 items-center">
                           <div>
						   <DateRangePicker
									hideKeyboardShortcutsPanel={true}
									startDateId='startDate'
									
									regular={large}
									small={small}
									numberOfMonths={numberOfMonths}
									isOutsideRange={() => false}
									displayFormat="DD MMM YYYY"
									endDateId='endDate'
									startDate={dateObject.startDate}
									startDatePlaceholderText={moment(startMonthDate).format("DD MMM YYYY")}
									endDatePlaceholderText={moment(new Date()).format("DD MMM YYYY")}
									endDate={dateObject.endDate}
									onDatesChange={({startDate, endDate}) =>
										setDateObject(prev => ({
											...prev,
											startDate: startDate,
											endDate: endDate
										}))
									}
									readOnly={true}
									inputIconPosition='after'
									focusedInput={focusedInput}
									customInputIcon={<i className='bx bxs-calendar' />}
									// orientation={orientation}
									// appendToBody={true}
									// autoFocus={true}
									onFocusChange={(focusedInput) =>{
										setFocusIntput(focusedInput)
										// setDateObject(prev => ({
										// 	...prev,
										// 	focusedInput: focusedInput
										// }))
									}}
								/>
						   </div>

							<div className="flex pb-3 ml-3">
							<ExportToExcel classes='sm:mt-3 mt-4 ' apiData={sortedArray} fileName={fileName} hidden={hidden} />
							<AddExpenseModal
								classes='sm:mt-3 mt-4 '
								updateExpenseList={getExpense}
							/>
							</div>
							</div>
								
						</>
					}
					title='Expenses'
				/>
			)}
			{user.user_type === 2 && (
				<PageHeader
					pageHeading='All expenses'
					rightContent={
						<>
							<div className={styles.month}>
								<AddExpenseModal
									updateExpenseList={getExpense}
								/>
							</div>
						</>
					}
					title='Expenses'
				/>
			)}
			<PageContainer>
				{loading ?(
					<div className='flex justify-center items-center mt-8'>
						<Loader className='items-center' style={{height: " calc(80vh - 18rem) "}} />
					</div>
				) : (
					<>
						<Box overflowX='auto' w='100%'>
							{expenses?.expenses?.length > 0 && !loading ? (
								<Table className={`my-4 whitespace-nowrap`} variant='simple'>
									<Thead>
										<Tr className={styles.trBackground}>
											<Th></Th>
											<Th className={styles.trColor}>Date</Th>
											<Th className={styles.trColor}> 
											
											<div className='flex items-center'>
													Total
													<div className='ml-2'>
														<span
															onClick={() => sortFilter("asc", "total")}
															className='cursor-pointer'>
															<IoIosArrowUp />
														</span>
														<span
															onClick={() => sortFilter("desc", "total")}
															className='cursor-pointer'>
															<IoIosArrowDown />
														</span>
													</div>
												</div>
											
											 </Th>
											
											<Th className={styles.trColor}>
												<div className='flex items-center'>
													Cash
													<div className='ml-2'>
														<span
															onClick={() => sortFilter("asc", "cash")}
															className='cursor-pointer'>
															<IoIosArrowUp />
														</span>
														<span
															onClick={() => sortFilter("desc", "cash")}
															className='cursor-pointer'>
															<IoIosArrowDown />
														</span>
													</div>
												</div>
											</Th>
											<Th className={styles.trColor}>
												<div className='flex items-center'>
													Expense
													<div className='ml-2'>
														<span
															onClick={() => sortFilter("asc", "expense")}
															className='cursor-pointer'>
															<IoIosArrowUp />
														</span>
														<span
															onClick={() => sortFilter("desc", "expense")}
															className='cursor-pointer'>
															<IoIosArrowDown />
														</span>
													</div>
												</div>
											</Th>
											{/* <Th className={styles.trColor}>
												<div className='flex items-center'>
													Online
													<div className='ml-2'>
														<span
															onClick={() => sortFilter("asc", "online")}
															className='cursor-pointer'>
															<IoIosArrowUp />
														</span>
														<span
															onClick={() => sortFilter("des", "online")}
															className='cursor-pointer'>
															<IoIosArrowDown />
														</span>
													</div>
												</div>
											</Th> */}
											<Th className={styles.trColor}>Added By</Th>
											<Th className={styles.trColor}>
												<div className='flex items-center'>
													Closing
													<div className='ml-2'>
														<span
															onClick={() => sortFilter("asc", "closing")}
															className='cursor-pointer'>
															<IoIosArrowUp />
														</span>
														<span
															onClick={() => sortFilter("desc", "closing")}
															className='cursor-pointer'>
															<IoIosArrowDown />
														</span>
													</div>
												</div>
											</Th>
											<Th className={styles.trColor}>Comment</Th>
										</Tr>
									</Thead>
									<Tbody>
										{expenses?.expenses?.map((exp, index) =>(
											<Tr key={index}>
												<Td >
													<span className='mt-2'>
														<ExpenseView viewExpense={exp} />
													</span>
													<span className='mt-2'>
														<EditExpenseModal
															expense={exp}
															updateExpenseList={getExpense}
														/>
													</span>
													<DeleteConfirmModal
														user={user}
														onDelete={() => {
															onDelete(exp.id);
														}}
														type='expense'
													/>
												</Td>
												<Td>{exp.date}</Td>
												<Td>{exp.total}</Td>
												
												
												<Td>{`${parseInt(exp.cash)}  /  ${parseInt(exp.online)}`}</Td>
												<Td>{`${parseInt(exp.expense)}  /  ${parseInt(
													exp.expense_online
												)}`}</Td>
												{/* <Td>{exp.online}</Td> */}
												<Td>{exp?.user?.name}</Td>
												<Td>{exp.closing}</Td>
												<Td className='flex items-center'>
													<span
														className={`cursor-pointer select-none ${
															readMore.id === exp.id && readMore.isTrue
																? "hidden"
																: "block"
														}`}
														onClick={() => readMoreHandler(exp.id)}>
														{exp?.comment?.slice(0, 15)}
													</span>
													<span
														className={` whitespace-pre-wrap cursor-pointer select-none ${
															readMore.id === exp.id && readMore.isTrue
																? "block"
																: "hidden"
														}`}
														onClick={() => readMoreHandler(exp.id)}>
														{exp?.comment}
													</span>
													<span
														className={`text-indigo-900 ml-1 select-none ${
															!exp.comment ||
															exp?.comment?.length < 15 ||
															(readMore.id === exp.id && readMore.isTrue)
																? "hidden"
																: "block"
														} cursor-pointer text-lg`}
														onClick={() => readMoreHandler(exp.id)}>
														...
													</span>
												</Td>
											</Tr>
										))}
									</Tbody>
									<Tfoot>
										<Tr className={styles.trBackground}>
											<Th className={styles.trColor}></Th>
											<Th className={styles.trColor}></Th>
											<Th className={styles.trColor}>{summary?.totalExpenses}</Th>
											{/* <Th className={styles.trColor}>{summary?.closing}</Th> */}
											<Th className={styles.trColor}>{summary?.cashTotal + summary?.onlineTotal}</Th>
											<Th className={styles.trColor}>{summary?.expenseTotal + summary?.onlineExpense}</Th>
											<Th className={styles.trColor}></Th>
											<Th className={styles.trColor}></Th>
											<Th className={styles.trColor}></Th>
										</Tr>
									</Tfoot>
								</Table>
							) : (
								<Box marginTop='16' textAlign='center'>
									No expense found!
								</Box>
							)}
						</Box>
					</>
				)}
			</PageContainer>
		</PrivateLayout>
	)
}

export default ListExpense

