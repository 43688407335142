import React, {useState, useEffect} from "react"
// import Footer from "../../components/Footer"
import Header from "../../components/Header"
//import {getToken} from "firebase/messaging"
//import {messaging} from "../../firebase"
import {useSelector} from "react-redux"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../Home/index.css"
import GetAppointmentModal from "../../components/Modals/GetAppointmentModal"
import styles from "../OffersFrontend/index.module.css"
import {Greetings} from "../../utils/getDateDiffrence"
// import GetTouchForm from "../../components/Modals/GetTouchForm"
// import Popup from "./Popup"

const Home = ({history}) => {
	const [count, setCount] = useState(0)

	useEffect(() => {
		const targetCount = 1000
		const animationDuration = 4000
		const intervalDuration = 10
		const steps = animationDuration / intervalDuration
		const increment = Math.ceil(targetCount / steps)

		let currentCount = 0
		let step = 0

		const intervalId = setInterval(() => {
			currentCount += increment
			step++

			if (currentCount >= targetCount || step >= steps) {
				clearInterval(intervalId)
				currentCount = targetCount
			}

			setCount(currentCount)
		}, intervalDuration)

		return () => {
			clearInterval(intervalId)
		}
	}, [])

	const {user} = useSelector(state => ({
		user: state.auth.user
	}))

	const settings = {
		className: "slider variable-width",
		dots: true,
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		swipeToSlide: true,
		autoplay: true,
		autoplaySpeed: 10000
	}

	// const [name, setName] = useState("")
	// const [email, setEmail] = useState("")
	// const [message, setMessage] = useState("")

	// const handleSubmit = e => {
	// 	e.preventDefault()
	// 	console.log(`Name: ${name}`)
	// 	console.log(`Email: ${email}`)
	// 	console.log(`Message: ${message}`)
	// 	setName("")
	// 	setEmail("")
	// 	setMessage("")
	// }

	return (
		<>
		<span id='sliderHome' ></span>
			<Header title='Curlys Unisex Hair & Makeup Studio' />
			{/* <PageHeader
				title='Curlys Unisex Hair & Makeup Studio'
				description='Best Unisex Salon in Ambala for Bridal MakeUp, Smoothening, Keratin, Hair Spa, Hair Color, Facial, Highlights, Manicure, Pedicure, Hair Care, Skin Care and other beauty parlour services.'
			/> */}

			{/* <Popup /> */}

			{/* ================ Slider Section ================ */}
			<Slider {...settings} className="sliderHome">
				<div className='relative item-slider'>
					<img src='/img/bride-make.jpg' alt='' />
					<div className='content-slider'>
						<h2 className='text-3xl sm:text-5xl text-white font-bold mb-5'>Makeup Forever</h2>
						<h3 className='text-5xl sm:text-7xl text-gold-400 font-semibold capitalize mb-5'>
							Bridal Makeup
						</h3>
						<p className='text-white mb-5 text-base sm:text-xl font-semibold'>
							BRINGING YOU THE BEST OF BRIDAL Bridal makeup starting from ₹5999*
						</p>
						<div className='flex justify-center flex-direction-mob gap-4 flex-wrap mt-5'>
							<button
								onClick={() => history.push(`/curly-service/bridal-makeup`)}
								className='btn m-0 bg-gold-400 buttonViewService font-medium text-white uppercase rounded px-5 p-2'>
								View Service
							</button>

							<GetAppointmentModal className='bg-transparent  transAppointBtn inline-block cursor-pointer whitespace-nowrap border-2 hover:border-black-border hover:bg-black-2xl  transition delay-150 ease-in-out  p-3 px-7 text-white font-bold uppercase' />
						</div>
					</div>
				</div>
				<div className='relative item-slider'>
					<img src='/img/beauti-hair.jpg' alt='' />
					<div className='content-slider'>
						<h2 className='text-3xl sm:text-5xl text-white font-bold mb-5'>Beautiful Hair</h2>
						<h3 className='text-5xl sm:text-7xl text-gold-400 font-semibold capitalize mb-5'>
							healthy hair
						</h3>
						<p className='text-white mb-5 text-base sm:text-xl font-semibold'>
							HAIR SMOOTHENING AT ₹2500* CYSTEINE TREATMENT AT ₹4999*
						</p>
						<div className='flex justify-center flex-wrap flex-direction-mob mt-5 gap-4'>
							<button
								onClick={() => history.push(`/curly-service/hair`)}
								className='btn m-0 bg-gold-400 buttonViewService font-medium text-white uppercase rounded px-5 p-2'>
								View Service
							</button>

							<GetAppointmentModal className='bg-transparent  transAppointBtn inline-block cursor-pointer whitespace-nowrap border-2 hover:border-black-border hover:bg-black-2xl  transition delay-150 ease-in-out  p-3 px-7 text-white font-bold uppercase' />
						</div>
					</div>
				</div>
				<div className='relative item-slider'>
					<img src='/img/hair-bg.jpg' alt='' />
					<div className='content-slider'>
						<h2 className='text-3xl sm:text-5xl text-white font-bold mb-5'>Beautiful Curly Hair</h2>
						<h3 className='text-5xl sm:text-7xl text-gold-400 font-semibold capitalize mb-5'>
							curly hairs
						</h3>
						<p className='text-white mb-5 text-base sm:text-xl font-semibold'>
							TREAT YOURSELF TO SOME TADKA WITH THE HOTTEST HUES FOR YOUR HAIR.
						</p>
						<div className='flex justify-center flex-wrap flex-direction-mob mt-5 gap-4'>
							<button
								onClick={() => history.push(`/curly-service/hair`)}
								className='btn m-0 bg-gold-400 buttonViewService font-medium text-white uppercase rounded px-5 p-2'>
								View Service
							</button>

							<GetAppointmentModal className='bg-transparent transAppointBtn inline-block cursor-pointer whitespace-nowrap border-2 hover:border-black-border hover:bg-black-2xl  transition delay-150 ease-in-out  p-3 px-7 text-white font-bold uppercase' />
						</div>
					</div>
				</div>
				<div className='relative item-slider'>
					<img src='/img/banner-1.jpg' alt='' />
					<div className='content-slider'>
						<h2 className='text-3xl sm:text-5xl text-white font-bold mb-5'>Face Care</h2>
						<h3 className='text-5xl sm:text-7xl text-gold-400  font-semibold capitalize mb-5'>
							healthy you
						</h3>
						<p className='text-white mb-5 text-base sm:text-xl font-semibold'>
							LOOKING GOOD IS NOT AN ACT, IT'S A HABIT.
						</p>
						<div className='flex justify-center flex-wrap flex-direction-mob mt-5 gap-4'>
							<button
								onClick={() => history.push(`/curly-service/face-bleach`)}
								className='btn m-0 bg-gold-400 buttonViewService font-medium text-white uppercase rounded px-5 p-2'>
								View Service
							</button>

							<GetAppointmentModal className='bg-transparent transAppointBtn inline-block cursor-pointer whitespace-nowrap border-2 hover:border-black-border hover:bg-black-2xl  transition delay-150 ease-in-out  p-3 px-7 text-white font-bold uppercase' />
						</div>
					</div>
				</div>
			</Slider>





			{/* ================ About Section ================ */}

			<section id="#about" className='aboutCurliesSec text-gray-600 body-font px-4 md:px-20 md:py-24 py-10' >
			<span className="aboutjs-mob mt-48" id='about'></span>
				<div className='container mx-auto flex   lg:flex-row flex-col items-center'>
					<div className='lg:flex-grow lg:w-1/2 lg:pr-24  flex flex-col md:items-start md:text-left mb-0 md:mb-0 items-center text-center'>
						<div className='inline-block mb-4'>
							<h1 className='title-font  font-bold sm:text-4xl text-2xl   text-gray-800'>
								{" "}
								ABOUT <span className='text-t-gold'>CURLY'S</span>
							</h1>
							<h4 className='uppercase salon-tagline text-xs mb-3 font-semibold rounded px-2 py-1 bg-red-500 text-black'>
								Most Economical salon in Ambala
							</h4>
							<img src='/img/barber.png' alt='barberFotter' />
						</div>
						<h3 className='mb-4 font-medium sm:text-xl text-lg  '>
							Best Unisex Salon in Ambala for Bridal MakeUp, Smoothening, Keratin, Hair Spa, Hair Color,
							Facial, Highlights, Manicure, Pedicure, Hair Care, Skin Care and other beauty parlour
							services.
						</h3>
						<p className='mb-8 leading-relaxed '>
							Curlys Beauty Parlour provides Hair Styling, Hair Care, Skin Care, Bridal MakeUp and other
							services like Facials, Waxing, Spa, Smoothening, Keratin Treatment, Party Makeup, Hair
							Colorant, Highlights, Manicure Pedicure and many more. Curlys is having best hair stylist,
							makeup artist and beautician who have long experience to deliver best quality to clients.
							<br />
						</p>
					</div>

					<div className='lg:max-w-lg  lg:w-1/2 flex justify-center items-center'>
						<img className='object-cover object-center rounded' alt='about-png' src='/img/about.jpg' />
					</div>
				</div>
				<br />
				<div className='hygiene-custom-div container'>
					<p className='text-lg'>
						We prioritize hygiene in our services, using sanitizers before and after each service helps to
						maintain cleanliness and prevent the spread of germs. Disinfecting the floor, outdoor areas,
						equipment, and washrooms multiple times ensures a high level of cleanliness throughout your
						establishment. Using masks and gloves is essential for preventing cross-contamination between
						staff members and clients. Using original branded products for all your services is a good
						practice as it ensures effectiveness of the products used. Using moisturizer, cleanser, primer,
						and highlighter for basic services shows that we prioritize both hygiene and the overall
						experience of our clients. By incorporating these measures and maintaining high standards of
						cleanliness, be create a safe and hygienic environment for both our staff and clients.
					</p>
					{/* For Hygiene, we are using:
					<ul style={{listStyleType: "disc", marginLeft: "50px", marginRight: "50px"}}>
						<li>Sanitizers pre and post service</li>
						<li>Disinfectants twice / thrice for floor, outdoor, equipment and washroom cleaning</li>
						<li>Masks and Gloves to avoid cross contamination</li>
					</ul>
					We maintain quality in service by using original branded products for all services. We use
					moisturizer, cleanser, primer, highlighter for basic services.
				 */}
					<br />
					<br />
					<span className='festive-offer-span '>
						Curlys offer affordable and competitive pricing for all its beauty salon services. We launch
						salon discount offers during all festivals. We welcome the clients feedback to improve our
						service standards.
					</span>
				</div>
			</section>

			{/* Section 3 */}
			<section className='body-font bg-gray-100'>
				<div className='container mx-auto   px-4 md:px-20 md:py-18 py-10 '>
					<h1 className='title-font text-center  font-bold sm:text-4xl text-2xl text-gray-800'>
						<span className='text-t-gold'> Curly's </span> Unisex Hair & Makeup Studio{" "}
					</h1>

					<div className='flex flex-row flex-wrap justify-around pt-12 custom-shape-divider-top-trinagle'>
						<svg
							data-name='Layer 1'
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 1200 120'
							preserveAspectRatio='none'>
							<path
								d='M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z'
								class='shape-fill'></path>
						</svg>
						<div className='elementZoom flex justify-center card-zoom-effect  items-center flex-col mb-3 wrapper sm:w-250px sm:h-270px w-200px p-3 text-center rounded-xl border border-black-border'>
							<div className='wrap flex justify-center '>
								<img src='/img/1.png' className='' alt='makeup' />
							</div>

							<div className='hover mt-3'>
								<p className='text-xl font-medium heading uppercase tracking-wide mb-3'>MakeUp</p>
								<p className='hover-text  text-black'>
									Makeup services for every vocations and it starts from ₹1500 only.
								</p>
								<button onClick={() => history.push(`/curly-service/bridal-makeup`)} className='btn'>
									View Service
								</button>
							</div>
						</div>

						<div className='elementZoom flex justify-center card-zoom-effect  items-center flex-col mb-3 wrapper sm:w-250px sm:h-270px w-200px p-3 text-center rounded-xl border border-black-border'>
							<div className='wrap flex justify-center '>
								<img src='/img/nails.png' className='nailsImg' alt='makeup' />
							</div>

							<div className='hover mt-3'>
								<p className='text-xl font-medium heading uppercase tracking-wide mb-3'>Nails</p>
								<p className='hover-text  text-black'>
									Nails services for every vocations and it starts from ₹1500 only.
								</p>
								<button onClick={() => history.push(`/curly-service/nail`)} className='btn'>
									View Service
								</button>
							</div>
						</div>

						<div className='elementZoom flex justify-center card-zoom-effect items-center flex-col mb-3 wrapper sm:w-250px sm:h-270px w-200px p-3 text-center rounded-xl border border-black-border'>
							<div className='wrap flex justify-center '>
								<img src='/img/2.png' className='' alt='Bridal' />
							</div>

							<div className='hover mt-3'>
								<p className='text-xl font-medium heading uppercase tracking-wide mb-3'>Bridal</p>
								<p className='hover-text text-black'>
									Bridal service starts from ₹5999, Pre work is complementary.
								</p>
								<button onClick={() => history.push(`/curly-service/bridal-makeup`)} className='btn'>
									View Service
								</button>
							</div>
						</div>

						<div className='elementZoom flex justify-center card-zoom-effect items-center flex-col mb-3 wrapper sm:w-250px sm:h-270px w-200px p-3 text-center rounded-xl border border-black-border'>
							<div className='wrap flex justify-center '>
								<img src='/img/3.png' className='' alt='Hair Care' />
							</div>

							<div className='hover mt-3'>
								<p className='text-xl font-medium heading uppercase tracking-wide mb-3'>Hair Care</p>
								<p className='hover-text text-black'>
									Curly's unisex Hair & Makeup studio specialized in hair services.
								</p>
								<button onClick={() => history.push(`/curly-service/hair`)} className='btn'>
									View Service
								</button>
							</div>
						</div>

						<div className='elementZoom flex justify-center card-zoom-effect items-center flex-col mb-3 wrapper sm:w-250px sm:h-270px w-200px p-3 text-center rounded-xl border border-black-border'>
							<div className='wrap flex justify-center '>
								<img src='/img/4.png' className='' alt='Facial' />
							</div>

							<div className='hover mt-3'>
								<p className='text-xl font-medium heading uppercase tracking-wide mb-3'>Facial</p>
								<p className='hover-text text-black'>
									Good skin makes you confident and curlys provide all skin care services
								</p>
								<button onClick={() => history.push(`/curly-service/facial`)} className='btn'>
									View Service
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* =================== Curly's Unisex Hair & Makeup Studio Section ================== */}

			<section className='text-gray-600 body-font'>
				<div className='container mx-auto flex px-4 content-basis-container md:px-10 md:pt-24 py-10   flex-col items-center'>
					<div className='wow padding-out-space bounceInLeft lg:flex-grow md:w-1/2 lg:pr-24 padding-content-first-div flex flex-col md:items-start md:text-left mb-0 md:mb-0 items-center text-center'>
						<h1 className='title-font mb-3 font-bold sm:text-4xl text-2xl text-gray-800'>
							{" "}
							<span className='text-t-gold'> Curly's </span> Unisex Hair & Makeup Studio suitable for{" "}
						</h1>
						<h3 className='mb-4 font-medium sm:text-xl text-lg  '>
							Bridal and Makeup, Waxing, Facials, Body Care, Hair Care, face Bleach, Manicure
						</h3>
						<p className='mb-8 leading-relaxed '>
							Curlys Beauty Parlour provides Hair Styling, Hair Care, Skin Care, Bridal MakeUp and other
							services like Facials, Waxing, Spa, Smoothening, Keratin Treatment, Party Makeup, Hair
							Colorant, Highlights, Manicure Pedicure and many more, which not only enhance your outer
							beauty but make you feel confident from inside. Our massage sessions help you relax,
							detoxify and stimulate your body. With an array of brands in our bucket, we strive to
							deliver an enriching experience and high customer satisfaction at the same time.
							<p className=' leading-relaxed hidetextCurliesFound'>
								     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span className="font-semibold text-xl">"</span>At Curly's Beauty Parlor, we believe that healthy skin is the key to looking and feeling
								beautiful. That's why we offer a variety of facials and skincare treatments to address
								your specific concerns. Whether you're looking to hydrate your skin, reduce signs of
								aging, or tackle acne, our skilled estheticians will customize the treatment to suit
								your needs. We use premium skincare products and techniques to deliver visible results
								and give your skin a healthy, radiant glow.<span className="font-semibold text-lg">"</span>
							</p>
						</p>
					</div>

					<div className='lg:max-w-lg padding-out-space-second lg:w-full md:w-1/2 w-5/6'>
						<img className='object-cover object-center rounded' alt='Makeup-jpg' src='/img/makeup.jpg' />
					</div>
				</div>
			</section>

			{/* Section 6*/}

			<section className='body-font custom-shape-divider-top-1685626350'>
				<div className='background'>
					<svg
						data-name='Layer 1'
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 1200 120'
						preserveAspectRatio='none'>
						<path d='M1200 120L0 16.48 0 0 1200 0 1200 120z' class='shape-fill'></path>
					</svg>

					<div className='flex cardCenterView py-14 lg:px-28 xl:px-60 md:px-8 sm:px-0 white-card-count justify-around flex-wrap'>
						<div className='text-black card-zoom-effect bg-red-50 flex items-center justify-center flex-col rounded-xl shadow-lg w-300px  text-center py-5 px-3 mb-3 md:mb-0'>
							<img src='img/user-prof.png' alt='icon' className='w-16 h-16 mb-5' />
							<p className='text-4xl font-semibold mb-3'>10+</p>
							<p className='text-lg'>Specialists</p>
						</div>
						<div className='text-black card-zoom-effect bg-red-50 flex items-center justify-center flex-col rounded-xl shadow-md w-300px text-center py-5 px-3 mb-3 md:mb-0'>
							<img src='img/sheild.png' alt='icon' className='w-16 h-16 mb-5' />
							<p className='text-4xl font-semibold mb-3'>{count}+</p>
							<p className='text-lg'>Happy Users</p>
						</div>
						<div className='text-black card-zoom-effect bg-red-50 flex items-center justify-center flex-col rounded-xl shadow-md w-300px text-center py-5 px-3 mb-3 md:mb-0'>
							<img src='img/icon1.png' alt='icon' className='w-16 h-16 mb-5' />
							<p className='text-4xl font-semibold mb-3'>{count}+</p>
							<p className='text-lg'>Services</p>
						</div>
					</div>
				</div>
			</section>

			{/* Section 7*/}
			<section className='body-font'>
				<div className='container mx-auto px-3 md:px-5 py-14'>
					<h5 className='text-center text-gold-400 text-xl mb-7'>Testimonials</h5>
					<h1 className='title-font text-center sm:text-5xl text-2xl text-gray-800'>
						WORDS THAT KEEP US GOING
					</h1>
					<div className='p-4'>
						<div className='flex'>
							<a
								href='https://search.google.com/local/writereview?placeid=ChIJXSzlUhe2DzkRKGtN3V5srcA'
								target='_blank'
								className='text-right px-4 w-6/12 font-medium xl:text-base sm:text-base hover:text-t-gold '
								rel='noreferrer'>
								<span className='border-b border-solid border-white hover:border-b-2 hover:border-solid hover:border-t-gold'>
									Write a review
								</span>
							</a>
							<a
								href='https://search.google.com/local/reviews?placeid=ChIJXSzlUhe2DzkRKGtN3V5srcA'
								target='_blank'
								className='text-left px-4 w-6/12 font-medium xl:text-base sm:text-base hover:text-t-gold'
								rel='noreferrer'>
								<span className='border-b border-solid border-white hover:border-b-2 hover:border-solid hover:border-t-gold'>
									Read More
								</span>
							</a>
						</div>
					</div>
					<div className='p-2 w-full testimonial'>
						<Slider {...settings}>
							<div className=' w-full lg:w-1/2 block md:mx-auto dest'>
								<p className='text-center text-lg p-after'>
									I HIGHLY recommend Curly's Salon for your wedding day. Priyanka mam &amp; her team
									are incredible. I am not one who enjoys to wear makeup and really wanted a natural
									look. They did an amazing job. Their down to earth personalities made the day that
									much more enjoyable for my family and myself. Thank you for making my day so
									special!
								</p>
								<div className=' star-rating flex justify-center mt-4'>
									<i className='bi bi-star-fill'> </i>
									<i className='bi bi-star-fill'> </i>
									<i className='bi bi-star-fill'> </i>
									<i className='bi bi-star-fill'> </i>
									<i className='bi bi-star-fill'> </i>
								</div>
							</div>
							<div className=' w-full lg:w-1/2 block md:mx-auto dest'>
								<p className='text-center text-lg p-after'>
									Beautiful experience, very nice parlour treatment and makeup was quite glowy and
									good. Best bridal artist available in curlys. Thankyou for making my day so special.
									Awesome eye makeup and contouring was the best! Many suggestions were given to me
									for natural glow of face.🤩 5 star for Curlys
								</p>
								<div className='star-rating flex justify-center mt-4'>
									<i className='bi bi-star-fill'> </i>
									<i className='bi bi-star-fill'> </i>
									<i className='bi bi-star-fill'> </i>
									<i className='bi bi-star-fill'> </i>
									<i className='bi bi-star-fill'> </i>
								</div>
							</div>
							<div className=' w-full lg:w-1/2 block md:mx-auto dest'>
								<p className='text-center text-lg p-after'>
									Staff is so good n cooperative...my wedding makeup experience was so good with
									curly's Jesa look dikhaya wese hi makeup hua More than expectations Just wowwwwww
								</p>
								<div className='star-rating flex justify-center mt-4'>
									<i className='bi bi-star-fill'> </i>
									<i className='bi bi-star-fill'> </i>
									<i className='bi bi-star-fill'> </i>
									<i className='bi bi-star-fill'> </i>
									<i className='bi bi-star-fill'> </i>
								</div>
							</div>
						</Slider>
					</div>
				</div>
			</section>
			{/* {new section book} */}

			<section className='text-gray-600 body-font overflow-hidden book-bg'>
				<div className='container px-5 sm:py-24 py-12 mx-auto flex flex-wrap'>
					<div className='lg:w-1/2 mx-auto hidden lg:block img-overlay relative container-Anim'>
						<img
							alt='ecommerce'
							className={` w-full object-cover object-center rounded image`}
							src='img/book-img.jpg'
						/>
					</div>
					<div className=' lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-0 z-10'>
						<div className='col-wrap bg-white text-center lg:-ml-28 lg:mr-28 lg:mt-20 sm:p-28 p-12'>
							<h2 className='text-xl mb-7 title-font text-gray-500 tracking-widest'>Appointment</h2>
							<h1 className='text-gray-900 sm:text-4xl text-3xl mb-7 title-font font-medium'>
								BOOK TIME TO GET APPOINTMENT
							</h1>

							<p className='leading-relaxed  mb-7'>
								"{Greetings()} <b className='font-bold'>{user ? user.name : "Guest"}!</b> We’re so
								excited to have you in Curly's. You can contact us for special offers, packages, and
								many more. call/Whatsapp us at{" "}
								<a className='font-bold' href='tel:+91-70150-36702'>
									+91-70150-36702
								</a>{" "}
								or email us{" "}
								<a className='font-bold' href='mailto:hellocurlys@gmail.com'>
									hellocurlys@gmail.com
								</a>{" "}
								Click button below to Book Appointment .”
							</p>

							<div className='flex'>
								<GetAppointmentModal
									book='Book Now'
									spanText='text-sm'
									className='bg-black-2xl inline-block cursor-pointer mx-auto whitespace-nowrap border-2 border-black-border   transition delay-150 ease-in-out  p-3 px-7 text-white text-sm'
								/>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='text-gray-600 body-font overflow-hidden custom-shape-divider-top-1685627044 newSectionColumn'>
				<svg
					data-name='Layer 1'
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 1200 120'
					preserveAspectRatio='none'>
					<path
						d='M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z'
						class='shape-fill'></path>
				</svg>

				<div className='container px-5 sm:pt-4 pt-12 mx-auto flex flex-wrap'>
					<div className='flex flex-wrap justify-between wrapPaddingInner items-center w-full '>
						<h1 className='text-gray-900 sm:text-4xl text-xl title-font font-medium uppercase'>
							categories
						</h1>
						<button
							onClick={() => history.push(`/curly-service/all`)}
							className='text-lg text-black flex items-center gap-3 font-medium'>
							View All{" "}
							<em className='bx bx-chevron-right  bg-gold-400 font-medium uppercase  text-white p-3 rounded text-xl '></em>
						</button>
					</div>
					<div className={` flex p-1 w-full flex-wrap mb-10 ${styles.ViewServiceCard}`}>
						<div className={` relative p-4 xl:w-1/4 sm:w-1/2 w-full ${styles.categoriesService}`}>
							<div className='h-full border shadow-slate-1001 flex flex-col relative overflow-hidden'>
								<img src='/img/bridal-home.jpg' alt='' className='h-250px object-cover' />
								<div
									className={` head px-6 py-8 flex sm:grid gap-0 sm:gap-6 justify-between items-center ${styles.viewServiceBtn}`}>
									<div className='headOne'>
										<h2 className='text-lg tracking-widest title-font  mb-1 font-medium'>
											Bridal/ Party Makeup
										</h2>
										<h1 className='text-lg font-bold text-gray-900   border-gray-200 leading-none'>
											Starts from ₹5999.00/₹1500.00
										</h1>
									</div>
									<span
										className='bg-black bridal-kit-text  text-white px-3 py-1 absolute whitespace-nowrap tracking-widest text-xs  right-0 top-0 rounded-bl block 
										'>
										<div className='flex flex-wrap sm:flex-row flex-col'>
											<p className='text-white cursor-default'>Popular,Bridal/Makeup</p>
										</div>
									</span>
									<button
									style={{ maxWidth: '185px', maxHeight: '44px' }}
										onClick={() => history.push(`/curly-service/bridal-makeup`)}
										className='flex absolute bottom-6 items-center whitespace-nowrap text-white justify-center  bg-gold-400 hover:bg-gold border-0 sm:py-2 sm:px-5 py-1 px-3 focus:outline-none  rounded-5xl'>
										<i className='mr-4 text-lg bx bx-add-to-queue'></i>View Service
									</button>
								</div>
							</div>
						</div>
						<div className={` p-4 xl:w-1/4 sm:w-1/2 w-full ${styles.categoriesService}`}>
							<div className='h-full border shadow-slate-1001 flex flex-col relative overflow-hidden'>
								<img src='/img/hair-spa.jpg' alt='' className='h-250px object-cover' />
								<div
									className={` head px-6 py-8 flex sm:grid gap-0 sm:gap-6 justify-between items-center ${styles.viewServiceBtn}`}>
									<div className='headOne'>
										<h2 className='text-lg tracking-widest title-font  mb-1 font-medium'>
											Hair Care
										</h2>
										<h1 className='text-lg font-bold text-gray-900   border-gray-200 leading-none'>
											Starts from ₹599.00
										</h1>
									</div>
									<span
										className='bg-black bridal-kit-text text-white px-3 py-1 absolute whitespace-nowrap tracking-widest text-xs  right-0 top-0 rounded-bl block 
										'>
										<div className='flex flex-wrap sm:flex-row flex-col'>
											<p className='text-white cursor-default'>Hair,Women</p>
										</div>
									</span>
									<button
									style={{ maxWidth: '185px', maxHeight: '44px' }}
										onClick={() => history.push(`/curly-service/hair`)}
										className='flex absolute bottom-6 items-center whitespace-nowrap text-white justify-center   bg-gold-400 hover:bg-gold border-0 sm:py-2 sm:px-5 py-1 px-3 focus:outline-none  rounded-5xl'>
										<i className='mr-4 text-lg bx bx-add-to-queue'></i>View Service
									</button>
								</div>
							</div>
						</div>
						<div className={` p-4 xl:w-1/4 sm:w-1/2 w-full ${styles.categoriesService}`}>
							<div className='h-full border shadow-slate-1001 flex flex-col relative overflow-hidden'>
								<img src='/img/body-care.jpg' alt='' className='h-250px object-cover' />
								<div
									className={` head px-6 py-8 flex sm:grid gap-0 sm:gap-6 justify-between items-center ${styles.viewServiceBtn}`}>
									<div className='headOne'>
										<h2 className='text-lg tracking-widest title-font  mb-1 font-medium'>
											Body spa+polishing
										</h2>
										<h1 className='text-lg font-bold text-gray-900   border-gray-200 leading-none'>
											Starts from ₹699.00
										</h1>
									</div>
									<span
										className='bg-black bridal-kit-text text-white px-3 py-1 absolute whitespace-nowrap tracking-widest text-xs  right-0 top-0 rounded-bl block 
										'>
										<div className='flex flex-wrap sm:flex-row flex-col'>
											<p className='text-white cursor-default'>Body Care</p>
										</div>
									</span>
									<button
									style={{ maxWidth: '185px', maxHeight: '44px' }}
										onClick={() => history.push(`/curly-service/body-care`)}
										className='flex absolute bottom-6 items-center whitespace-nowrap text-white justify-center   bg-gold-400 hover:bg-gold border-0 sm:py-2 sm:px-5 py-1 px-3 focus:outline-none  rounded-5xl'>
										<i className='mr-4 text-lg bx bx-add-to-queue'></i>View Service
									</button>
								</div>
							</div>
						</div>
						<div className={` p-4 xl:w-1/4 sm:w-1/2 w-full ${styles.categoriesService}`}>
							<div className='h-full border shadow-slate-1001 flex flex-col relative overflow-hidden'>
								<img src='/img/Hydrafacial.webp' alt='' className='h-250px object-cover' />
								<div
									className={` head px-6 py-8 flex sm:grid gap-0 sm:gap-6 justify-between items-center ${styles.viewServiceBtn}`}>
									<div className='headOne'>
										<h2 className='text-lg tracking-widest title-font  mb-1 font-medium'>
										Hydra facial
										</h2>
										<h1 className='text-lg font-bold text-gray-900 border-gray-200 leading-none'>
											Starts from ₹2500.00
										</h1>
									</div>
									<span
										className='bg-black bridal-kit-text text-white px-3 py-1 absolute whitespace-nowrap tracking-widest text-xs  right-0 top-0 rounded-bl block 
										'>
										<div className='flex flex-wrap sm:flex-row flex-col'>
											<p className='text-white cursor-default'>HydraFacial</p>
										</div>
									</span>
									<button
									style={{ maxWidth: '185px', maxHeight: '44px' }}
										onClick={() => history.push(`/curly-service/facial`)}
										className='flex absolute bottom-6 items-center whitespace-nowrap text-white justify-center   bg-gold-400 hover:bg-gold border-0 sm:py-2 sm:px-5 py-1 px-3 focus:outline-none  rounded-5xl'>
										<i className='mr-4 text-lg bx bx-add-to-queue'></i>View Service
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Last Section */}

			<section className='body-font'>
				<div className='container mx-auto px-5 py-14 flex flex-col xl:flex-row md:flex-row lg:flex-row items-center justify-center company-logo'>
					<div className='products'>
						<img src='/img/lorel.jpg' alt='' />
					</div>
					<div className='products slide'>
						<img src='/img/matrix-1.jpg' alt='' />
					</div>
					<div className='products slide'>
						<img src='/img/schawarz.jpg' alt='' />
					</div>
					<div className='products slide'>
						<img src='/img/gk-logo.png' alt='' />
					</div>
				</div>
			</section>

			{/* <section className='text-gray-600 body-font overflow-hidden'>
				<div className='containerYellowImg'>
					<form className='formGetTouch' onSubmit={handleSubmit}>
						<h2>Get in Touch</h2>
						<GetAppointmentModal  />
						<input
							type='text'
							placeholder='Your Name'
							value={name}
							onChange={e => setName(e.target.value)}
							required
						/>
						<input
							type='email'
							placeholder='Your Email'
							value={email}
							onChange={e => setEmail(e.target.value)}
							required
						/>
						<textarea
							placeholder='Your Message'
							style={{ minHeight: "100px" }}
							value={message}
							onChange={e => setMessage(e.target.value)}
							required></textarea>
						<button className='buttonGetTouch' type='submit'>
							Submit
						</button>
					</form>
				</div>
			</section> */}

			{/* <section className='text-gray-600 body-font overflow-hidden'>
				<div>
					<GetTouchForm />
				</div>
			</section> */}

			{/* <Footer /> */}
		</>
	)
}

export default Home
