import React, {useState, useEffect} from "react"
import {Table, Thead, Tbody, Select, Tr, Th, Td, Box, IconButton, Button} from "@chakra-ui/react"
import {useSelector, useDispatch} from "react-redux"
import PrivateLayout from "../../layout/PrivateLayout"
import PageHeader from "../../components/PageHeader"
import PageContainer from "../../components/PageContainer"
import Loader from "../../components/Loader/Loader"
import {getYears, getMonths} from "../../utils/list-expense"
import {IoIosArrowUp, IoIosArrowDown, IoLogoWhatsapp, IoMdPrint} from "react-icons/io"
import styles from "../Expense/ListExpense/index.module.css"
import {getOrderList} from "../../store/actions/order"
import ReactPaginate from "react-paginate"
import {getStaffUserList} from "../../store/actions/getUser"
import {getProductsByCategoryId} from "../../store/actions/categorieslist"
import OrderView from "../../components/Modals/OrderView"
import PendingPriceModal from "./PendingPriceModal"
import {IoIosEye} from "react-icons/io"

const Listing = ({history}) => {
	const dispatch = useDispatch()
	const {user} = useSelector(state => state.auth);

	const [filters, setFilters] = useState({
		months: new Date().getMonth() + 1,
		years: new Date().getFullYear(),
		limit: 10,
		staff: user?.user_type === 2 ? user.id : "",
		page:1,
		services: ""
	})
	const months = getMonths()
	const {order, getUsers} = useSelector(state => state)
	const {orderList} = order
	const {getStaffUser} = getUsers
	const [loading, setLoading] = useState()
	const listPerPage = 10



	

	const {data} = orderList
	useEffect(() => {
		setLoading(true)
		dispatch(
			getOrderList({
				month: filters.months,
				year: filters.years,
				page: filters.page,
				staff: filters.staff,
				services: filters.services
			})
		)
			.then(() => {
				setLoading(false)
			})
			.catch(err => {
				setLoading(false)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters])

	// Function that updates Payment when Pay Due Ammount

	const UpdatePaymentStatus = () => {
		dispatch(
			getOrderList({
				month: filters.months,
				year: filters.years,
				limit: filters.limit,
				staff: filters.staff,
				services: filters.services
			})
		)
	}

	const displayList =
		data && data.map((ord, index) => (
			<Tr key={index}>
				{ user?.user_type === 1 && 
				<>
				<Td>
					<span className='mt-2'>
						<OrderView data={ord} icon={IoIosEye()} url={window.location.href} print={false} />
					</span>
					<span className='mt-2'>
						<OrderView data={ord} icon={IoMdPrint()} url={window.location.href} print={true} />
					</span>
					<span className='mt-2'>
						<IconButton
							className='mr-3'
							colorScheme='teal'
							aria-label='Edit Expense'
							onClick={() => {
								window.open(
									`https://wa.me/+91${ord?.cust?.phone}?text=Hello%2C%20check%20your%20invoice%20here%2C%0A${window.location.host}%2Fbilling%2F${ord.uid}`,
									"_blank"
								)
							}}
							icon={<IoLogoWhatsapp />}
							size='sm'
						/>
					</span>
				</Td>
				<Td
					className={`${
						ord.total_amount - (parseInt(ord.cash) + parseInt(ord.online)) === 0 ? "hidden" : "block"
					} text-left`}>
					<PendingPriceModal paymentData={ord} UpdatePaymentStatus={UpdatePaymentStatus}>
						<button className={` bg-red-600 rounded px-4 py-1 text-white `}> Pay </button>
					</PendingPriceModal>
				</Td>
				<Td
					className={`${
						ord.total_amount - (parseInt(ord.cash) + parseInt(ord.online)) === 0 ? "block" : "hidden"
					} `}>
					<button className={` bg-green-900 rounded px-4 py-1 text-white `}> Paid </button>
				</Td>
				<Td className='text-center'>{ord.total_amount - (parseInt(ord.cash) + parseInt(ord.online))}</Td>
				</>
				}
				<Td>{ ord?.cust1?.name|| ord?.cust?.name}</Td>
				<Td>{ord.bill_date.slice(0, 10)}</Td>
				<Td>{ord.total_amount}</Td>
				<Td>{ord.cash}</Td>
				<Td>{ord.online}</Td>
			</Tr>
		))

	useEffect(() => {
		setLoading(true)
		dispatch(getStaffUserList())
			.then(() => {
				setLoading(false)
			})
			.catch(err => {
				setLoading(false)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		dispatch(getProductsByCategoryId({categoryId: ""}))
			.then(() => {})
			.catch(err => {
				console.log("ERR => while fetching all Categories", err)
			})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getHandle = e => {
		console.log(e.target)
		setFilters(inputs => ({...inputs, [e.target.name]: e.target.value}))
	}

	const sortFilter = (data, colName) => {
		dispatch(
			getOrderList({
				direction: data,
				sortby: colName,
				month: filters.months,
				year: filters.years,
				limit: filters.limit
			})
		)
			.then(() => {})
			.catch(err => {})
	}

	const listPageCount = Math.ceil(orderList && orderList?.total / listPerPage);

	// const changePage = ({selected}) => {
	// }



	const changePage = ({selected}) => {
		 setFilters((prev)=>({
			 ...prev,
			 page:selected+1
		 }))
	  };


	return (
		<PrivateLayout>
			<PageContainer>
				<PageHeader
					pageHeading='Order List'
					rightContent={
						<>
							<div className={styles.month}>
								<div className={styles.monthpadding}>
									<Select
										placeholder='Select Month'
										name='months'
										value={filters.months}
										onChange={getHandle}>
										{months.map((res , i) => (
											<option key={i} value={res.id}>
												{res.name}{" "}
											</option>
										))}
									</Select>
								</div>

								<div className={styles.monthpadding}>
									<Select
										placeholder='Select Year'
										name='years'
										value={filters.years}
										onChange={getHandle}>
										{getYears().map((x,i) => {
											return <option key={i}>{x}</option>
										})}
									</Select>
								</div>
								{/* <div className={styles.monthpadding}>
									<Select placeholder='Limit' name='limit' value={filters.limit} onChange={getHandle}>
										<option value='10'>10</option>
										<option value='100'>100</option>
										<option value='1000'>1000</option>
									</Select>
								</div> */}
								{ user?.user_type===1 && <div className={styles.monthpadding}>
									<Select placeholder='Staff' name='staff' value={filters.staff} onChange={getHandle}>
										{getStaffUser.length > 0 &&
											getStaffUser.map((v, i) => (
												<option key={i} value={v.id}>
													{v.name}
												</option>
											))}
									</Select>
								</div> }
								{/* <div className={styles.monthpadding}>
									<Select
										placeholder='Services'
										name='services'
										value={filters.services}
										onChange={getHandle}>
										{productsByCategoryId.length > 0 &&
											productsByCategoryId.map((v, i) => <option value={v.id}>{v.name}</option>)}
									</Select>
								</div> */}
								<Button
									colorScheme='blue'
									onClick={() => {
										history.push("quickInvoice")
									}}>
									Add Order
								</Button>
								{/* <Checkbox className='mx-2'>Brief</Checkbox> */}
							</div>
						</>
					}
					title='Listing'
				/>
				<PageContainer>
					{loading ? (
							<Loader className="items-center" style={{height : "calc(100vh - 16rem)"}} />
					) : (
						<>
							<Box overflowX='auto' w='100%'>
								<Table className={`my-4 whitespace-nowrap ${styles.tableListing}`} variant='simple'>
									{data && data.length > 0 && !loading ? (
										<>
											<Thead>
												<Tr className={styles.trBackground}>
													{ user?.user_type === 1 && 
													<>		
													<Th className={styles.trColor}>Actions</Th>
													<Th className={styles.trColor}>Payment</Th>
													<Th className={styles.trColor}>Due Payment</Th>
													</>
													 }
													<Th className={styles.trColor}>Name</Th>
													<Th className={styles.trColor}>Date</Th>
													<Th className={styles.trColor}>
														<span className='flex items-center'>
															Total
															<span className='ml-2'>
																<span
																	onClick={() => sortFilter("asc", "total")}
																	className='cursor-pointer'>
																	<IoIosArrowUp />
																</span>
																<span
																	onClick={() => sortFilter("desc", "total")}
																	className='cursor-pointer'>
																	<IoIosArrowDown />
																</span>
															</span>
														</span>
													</Th>
													<Th className={styles.trColor}>
														<span className='flex items-center'>
															Cash
															<span className='ml-2'>
																<span
																	onClick={() => sortFilter("asc", "cash")}
																	className='cursor-pointer'>
																	<IoIosArrowUp />
																</span>
																<span
																	onClick={() => sortFilter("desc", "cash")}
																	className='cursor-pointer'>
																	<IoIosArrowDown />
																</span>
															</span>
														</span>
													</Th>
													<Th className={styles.trColor}>
														<span className='flex items-center'>
															Online
															<span className='ml-2'>
																<span
																	onClick={() => sortFilter("asc", "expense")}
																	className='cursor-pointer'>
																	<IoIosArrowUp />
																</span>
																<span
																	onClick={() => sortFilter("desc", "expense")}
																	className='cursor-pointer'>
																	<IoIosArrowDown />
																</span>
															</span>
														</span>
													</Th>
												</Tr>
											</Thead>
											<Tbody>{displayList}</Tbody>
										</>
									) : ( 
										<Tbody>
											<Tr>
												<Td className="text-center my-5">
												No Listing found!
												</Td>
											
											</Tr></Tbody>
										
									)}
								</Table>

								
							</Box>
							{listPageCount> 1 ? (
									<ReactPaginate
									breakLabel='...'
									nextLabel='Next >'
									onPageChange={changePage}
									pageRangeDisplayed={3}
									pageCount={listPageCount}
									forcePage={filters.page - 1}
									marginPagesDisplayed={2}
									previousLabel='< Previous'
									breakClassName="text-indigo-900"
									previousLinkClassName="bg-green-900 rounded select-none text-white px-3 py-1"
									nextLinkClassName="bg-green-900 rounded select-none text-white px-3 py-1"
									breakLinkClassName="flex itmes-center"
									renderOnZeroPageCount={null}
									activeLinkClassName=' bg-black-2xl rounded text-white py-1 px-3'
									className='flex mb-2 justify-between lg:w-5/12 w-10/12 rounded-md  mx-auto px-4 py-2 '
									nextClassName='text-black'
									previousClassName='text-black'
									/>
								) : (
									""
								)}
						</>
					)}
				</PageContainer>
			</PageContainer>
		</PrivateLayout>
	)
}

export default Listing
