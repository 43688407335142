import React, {useState} from "react"
import {updateStatus} from "../../../store/actions/getAppointment"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	IconButton,
	Textarea,
	useToast
} from "@chakra-ui/react"
import {useDispatch} from "react-redux"

const RejectServiceView = ({userId, updatelist}) => {
	const toast = useToast()

	const [loading, setLoading] = useState(false)
	const Dispatcher = useDispatch()
	const [inputVal, setInputVal] = useState("")
	const {isOpen, onOpen, onClose} = useDisclosure()
	const SubmitHandler = () => {
		const RejectObj = {
			id: userId,
			text: inputVal,
			status: 2
		}
		setLoading(true)
		Dispatcher(updateStatus(RejectObj)).then(() => {
			setLoading(false)

			setInputVal("")
			updatelist()
			onClose()
			toast({
				title: "Rejected Successfully",
				status: "success",
				duration: 4000,
				isClosable: true
			})
		})
	}

	const TextChangeHandler = e => {
		setInputVal(e.target.value)
	}

	return (
		<>
			<IconButton
				className='mr-3 bg-red-700'
				aria-label='Reject'
				// disabled={status===0 ? false : true}
				icon={<i className='bx bx-x text-white text-3xl bg-transparent'></i>}
				size='sm'
				onClick={onOpen}
			/>
			<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
				<ModalOverlay className='bg-gold-701 modalOverlay' />
				<ModalContent>
					<ModalHeader className='capitalize bg-gold text-center text-2xl font-medium text-white'>
						Reason of Rejection
					</ModalHeader>
					<ModalCloseButton className='text-white ' />
					<ModalBody>
						<Textarea onChange={TextChangeHandler} value={inputVal} placeholder='Write Your Reason' />
					</ModalBody>
					<ModalFooter>
						<Button colorScheme='gray' className='px-3 py-1 rounded mx-2 ' onClick={onClose}>
							{" "}
							Close{" "}
						</Button>
						<Button
							isLoading={loading}
							className='px-3 py-1 rounded bg-gold mx-2 text-white'
							onClick={SubmitHandler}>
							{" "}
							Submit{" "}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default RejectServiceView
