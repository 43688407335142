import React from "react"
// import Footer from "../../components/Footer"
import Header from "../../components/Header"
import styles from "../OffersFrontend/index.module.css"
import "./index.css"
import Carousel from "./Carousel"
import ListingImagesSection from "./ListingImagesSection"

const Bridal = ({history}) => {
	const images = [
		"img/couple-bridal.jpg",
		"img/Wed1.jpg",
		"img/couple-bridal-img.jpg",
		"img/bridal-crousal.jpg",
		"img/bridal-second-crousal.jpeg",
		"img/bridal-img-third.jpg"
	]

		const imagesList = [
			{imgpath: "img/engment.jpg", textName: "Engagement Makeup"},
			{imgpath: "img/bridal.jpg", textName: "Bridal Makeup"},
			{imgpath: "img/party4.png", textName: "Party Makeup"},
			{imgpath: "img/reception2.png", textName: "Reception Makeup"},
			{imgpath: "img/roka2.png", textName: "Roka Makeup"},
			{imgpath: "img/shoot2.png", textName: "Shoot Makeup"}
		]

	return (
		<>
			<span id='bridal' ></span>
			<Header title='Bridal | Curlys Unisex Hair & Makeup Studio' />
			<section
				className={`bg-bridal-banner  bg-cover flex items-center justify-center relative z-10 h-80 ${styles.bannerSection} `}
				style={{backgroundPosition: "0 90%"}}>
				<div className='container '>
					<div className='w-full text-center relative z-20 text-4xl font-bold text-white'>
						<h3 className='border-b-4 border-white inline pb-1'>Bridal</h3>
					</div>
				</div>
				<span id="bridalImg"></span>
			</section>

			<section className='section' >
				<div className='container mx-auto bridal-img-height-parent-div'>
					<ListingImagesSection imagesList={imagesList} />
				</div>
			</section>

			{/* 
			<section className='section'>
				<div className="container mx-auto bridal-img-height-parent-div">
				<div className='md:flex my-10 bg-white rounded-3xl p-5 shadow-xl bridal-img-height-parent'>
					<div className='md:w-2/5 w-full md:mr-5 mb-5 md:mb-0 bridal-img-height' >
						<img src='img/Wed5.jpeg'  alt='bridal image' className='rounded-xl' />
					</div>
					<div className='md:w-2/5 w-full md:mr-5 mb-5 md:mb-0 bridal-img-height' >
						<img src='img/couple-bridal-img.jpg'  alt='bridal image' className='rounded-xl' />
					</div>
					<div className='md:w-2/5 w-full md:mr-5 mb-5 md:mb-0 bridal-img-height' >	
						<img src='img/couple-bridal.jpg'  alt='bridal image' className='rounded-xl' />
					</div>
					</div>
				</div>
			</section> */}

			<section className='section'>
				<div className='container mx-auto max-w-7xl py-10 px-3'>
					{/* <h3 className='text-4xl font-medium mb-5'>Bridal</h3> */}
					<p className='text-center font-semibold text-xl mb-16'>
						"Congratulations! You've found the partner of your dreams – now it's time to get perfect skin
						and hair.Its your wedding so your hair and makeup should be on fleek! If you're looking for a
						one-stop destination for all your bridal wraps – from Mehndi to Reception– Curlys Unisex Hair &
						Makeup Studio will never get you disappointed. With its great experience in the fashion world,
						we are confident that will give you a unique look. Regardless of your bridal makeup, We provide
						you with personal advice that leads to the wedding to get you on a beauty regimen."
					</p>

					<h3 className='text-4xl font-medium underline SpecializedServe card-box-bg p-4 mb-5'>
						Our Specialized Services
					</h3>

					<div className='shadow-xl'>
						<div className='md:flex my-10 bg-white rounded-3xl p-5 '>
							<div className='md:w-2/5 w-full md:mr-5 mb-5 md:mb-0 bridal-slider'>
								<h5 className='text-center text-xl font-medium mb-10'>Your Wedding</h5>
								{/* <img src='img/couple-bridal-img.jpg' width='479px' alt='' className='rounded-xl' /> */}
								<Carousel images={images} />
							</div>
							<div className='md:w-3/5 w-full'>
								<h5 className='text-center py-2 px-4 w-fit m-auto text-white card-box-bg  text-xl font-medium mb-10'>
									MAC Studio Bridal Makeup
								</h5>
								<div className='wrapper'>
									<h4 className='font-medium text-2xl mb-3'>Bridal & Pre-Bridal</h4>
									<div className='grid sm:grid-cols-2 gap-5'>
										<div className='box bg-pink-600 card-box-bg rounded-lg p-3 shadow-lg text-white relative'>
											<h4 className='absolute birdal-day-kit bg-black  text-sm  px-2 py-0.5'>
												Day 1
											</h4>
											<div className='flex mt-3 justify-between flex-wrap pt-5'>
												<ul>
													<li>
														<span>Face Bleach (Pearl)</span>
													</li>
													<li>
														<span>Facial (Pearl)</span>
													</li>
												</ul>
											</div>
										</div>
										<div className='box card-box-bg bg-blue-600 rounded-lg p-3 shadow-lg text-white relative'>
											<h4 className='absolute top-0 birdal-day-kit  bg-black left-0 text-sm rounded-b-lg px-2 py-0.5'>
												Day 2
											</h4>
											<div className='flex mt-3 justify-between flex-wrap pt-5'>
												<ul>
													<li>
														<span>Face Bleach (Aroma)</span>
													</li>
													<li>
														<span>Facial (Aroma)</span>
													</li>
												</ul>
											</div>
										</div>
										<div className='box card-box-bg bg-sky-900 rounded-lg p-3 shadow-lg text-white relative'>
											<h4 className='absolute top-0 birdal-day-kit  bg-black left-0 text-sm rounded-b-lg px-2 py-0.5'>
												Day 3
											</h4>
											<div className='flex mt-3 justify-between items-center flex-wrap pt-5'>
												<ul>
													<li>
														<span>Facial</span>
													</li>
													<li>
														<span>Face D-tan pack (o3+ Whitening) </span>
													</li>
												</ul>
												<ul>
													<li>
														<span>Manicure/Pedicure (Lotus Crystal)</span>
													</li>
													<li>
														<span>Hair spa (L’Oréal)</span>
													</li>
												</ul>
											</div>
										</div>
										<div className='box card-box-bg bg-sky-500 rounded-lg p-3 shadow-lg text-white relative'>
											<h4 className='absolute top-0 birdal-day-kit  bg-black left-0 text-sm rounded-b-lg px-2 py-0.5'>
												Day 4 (Pre work)
											</h4>
											<div className='flex mt-3 justify-between flex-wrap pt-5'>
												<ul>
													<li>
														<span>Facial (Kasmara)</span>
													</li>
													<li>
														<span>Face Bleach (Kasmara)</span>
													</li>
													<li>
														<span>Hair spa (Biolage) </span>
													</li>
													<li>
														<span>Manicure/Pedicure (Lotus Crystal)</span>
													</li>
													<li>
														<span>Full Body Bleach (Haldi Herbal Bleach)</span>
													</li>
												</ul>

												<ul>
													<li>
														<span>Full Body Wax (Rica)</span>
													</li>
													<li>
														<span>Full Body Polishing (Lotus Crystal)</span>
													</li>
													<li>
														<span>Hair Highlights</span>
													</li>
													<li>
														<span>Full Face Threading</span>
													</li>
												</ul>
											</div>
										</div>
										<div className='box card-box-bg bg-pink-600 rounded-lg p-3 shadow-lg text-white relative'>
											<h4 className='absolute birdal-day-kit  top-0 bg-black left-0 text-sm rounded-b-lg px-2 py-0.5'>
												Day 5
											</h4>
											<div className='flex mt-3 justify-between flex-wrap pt-5'>
												<ul>
													<li>
														<span>Hair-Do</span>
													</li>
													<li>
														<span>Dress-up</span>
													</li>
													<li>
														<span>Air Brush Bridal Makeup</span>
													</li>
												</ul>
											</div>
										</div>
									</div>

									<div className='bottom-content none-display-content mt-14'>
										<h3 className='text-3xl font-medium text-center mb-10'>We Make it Special</h3>
										<p className='font-semibold text-base text-center'>
											Your wedding is one of the most important day of your life. You want it to
											be unforgettable. At Curlys Unisex Hair & Makeup Studio, we offer one of the
											most comprehensive bridal beauty treatment in the region. Our goal is to
											make you look and feel your best, ensuring that your wedding day will be all
											that you dreamed it would be.
										</p>
										<div className='button-content text-center mt-5'>
											<button
												// Setting Bridal Category When user Click book Now Button
												onClick={() => history.push(`/curly-service/bridal-makeup`)}
												className={`${styles.bookBtn} border bookBtn border-black-border text-black my-3 py-2 px-4 rounded-md `}>
												Book Now
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='bottom-content show-display-content mt-14'>
							<h3 className='text-3xl font-medium text-center mb-10'>We Make it Special</h3>
							<p className='font-semibold text-base text-center'>
								Your wedding is one of the most important day of your life. You want it to be
								unforgettable. At Curlys Unisex Hair & Makeup Studio, we offer one of the most
								comprehensive bridal beauty treatment in the region. Our goal is to make you look and
								feel your best, ensuring that your wedding day will be all that you dreamed it would be.
							</p>
							<div className='button-content text-center mt-5'>
								<button
									// Setting Bridal Category When user Click book Now Button
									onClick={() => history.push(`/curly-service/bridal-makeup`)}
									className={`${styles.bookBtn} border bookBtn border-black-border text-black my-3 py-2 px-4 rounded-md `}>
									Book Now
								</button>
							</div>
						</div>
					</div>

					<div className='shadow-xl'>
						<div className='md:flex my-10 bg-white rounded-3xl p-5 '>
							<div className='md:w-2/5 w-full md:mr-5 mb-5 md:mb-0 bridal-slider'>
								<h5 className=' text-center text-xl font-medium mb-10'>Your Wedding</h5>
								<Carousel images={images} />
							</div>
							<div className='md:w-3/5 w-full'>
								<h5 className='text-center py-2 px-4 w-fit m-auto text-white card-box-bg  text-xl font-medium mb-10'>
									NASS Bridal Makeup
								</h5>
								<div className='grid sm:grid-cols-2 gap-5'>
									<div className='box bg-pink-600 card-box-bg rounded-lg p-3 shadow-lg text-white relative'>
										<h4 className='absolute top-0 bg-black birdal-day-kit left-0 text-sm rounded-b-lg px-2 py-0.5'>
											Day 1
										</h4>
										{/* grid text-center sm:grid-cols-2 grid-cols-1 sm:h-auto h-full sm:place-content-stretch place-content-center  */}
										<div className='flex mt-3 justify-between flex-wrap pt-5'>
											<ul>
												<li>
													<span>Face Bleach (Lotus Gold))</span>
												</li>
												<li>
													<span>Facial (Lotus Gold)</span>
												</li>
											</ul>
										</div>
									</div>
									<div className='box bg-blue-600 card-box-bg rounded-lg p-3 shadow-lg text-white relative'>
										<h4 className='absolute top-0 birdal-day-kit bg-black left-0 text-sm rounded-b-lg px-2 py-0.5'>
											Day 2
										</h4>
										<div className='flex mt-3 justify-between flex-wrap pt-5'>
											<ul>
												<li>
													<span>Face Bleach (Aroma)</span>
												</li>
												<li>
													<span>Facial (Aroma)</span>
												</li>
											</ul>
										</div>
									</div>
									<div className='box bg-sky-900 card-box-bg rounded-lg p-3 shadow-lg text-white relative'>
										<h4 className='absolute top-0 birdal-day-kit bg-black left-0 text-sm rounded-b-lg px-2 py-0.5'>
											Day 3
										</h4>
										<div className='flex mt-3 justify-between flex-wrap pt-5'>
											<ul>
												<li>
													<span>Face cleansing</span>
												</li>
												<li>
													<span>Face D-tan pack (Sara) </span>
												</li>
											</ul>
										</div>
									</div>
									<div className='box bg-sky-500 card-box-bg rounded-lg p-3 shadow-lg text-white relative'>
										<h4 className='absolute top-0 birdal-day-kit bg-black left-0 text-sm rounded-b-lg px-2 py-0.5'>
											Day 4 (Pre work)
										</h4>
										<div className='flex mt-3 justify-between flex-wrap pt-5'>
											<ul>
												<li>
													<span>Facial (Kasmara)</span>
												</li>
												<li>
													<span>Face Bleach (Kasmara)</span>
												</li>
												<li>
													<span>Hair spa (L'Oréal) </span>
												</li>
												<li>
													<span>Manicure/Pedicure (Crystal)</span>
												</li>
											</ul>
											<ul>
												<li>
													<span>Full Body Wax (Chocolate)</span>
												</li>
												<li>
													<span>Full Body Polishing (Crystal)</span>
												</li>
												<li>
													<span>Hair Highlights</span>
												</li>
												<li>
													<span>Full Face Threading</span>
												</li>
											</ul>
										</div>
									</div>
									<div className='box bg-pink-600 card-box-bg rounded-lg p-3 shadow-lg text-white relative'>
										<h4 className='absolute top-0 birdal-day-kit bg-black left-0 text-sm rounded-b-lg px-2 py-0.5'>
											Day 5
										</h4>
										<div className='flex mt-3 justify-between flex-wrap pt-5'>
											<ul>
												<li>
													<span>Hair-Do</span>
												</li>
												<li>
													<span>Dress-up</span>
												</li>
												<li>
													<span>Air Brush Bridal Makeup</span>
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className='bottom-content none-display-content mt-14'>
									<h3 className='text-3xl font-medium text-center mb-10'>We Make it Special</h3>
									<p className='font-semibold text-base text-center'>
										People have grand plans for their weddings. They want this once in a lifetime
										event to be super special. They will go overboard with each plan linked to their
										wedding. Can a bridal makeup lag behind. We have a super luxury variant of our
										luxury bridal package. Have a look. We will love to discuss this with you and if
										required further customize for you.
									</p>
									<div className='button-content text-center mt-5'>
										<button
											// Setting Bridal Category When user Click book Now Button
											onClick={() => history.push(`/curly-service/bridal-makeup`)}
											className={`${styles.bookBtn} border bookBtn border-black-border text-black my-3 py-2 px-4 rounded-md `}>
											Book Now
										</button>
									</div>
								</div>
							</div>
						</div>

						<div className='bottom-content show-display-content mt-14'>
							<h3 className='text-3xl font-medium text-center mb-10'>We Make it Special</h3>
							<p className='font-semibold text-base text-center'>
								Your wedding is one of the most important day of your life. You want it to be
								unforgettable. At Curlys Unisex Hair & Makeup Studio, we offer one of the most
								comprehensive bridal beauty treatment in the region. Our goal is to make you look and
								feel your best, ensuring that your wedding day will be all that you dreamed it would be.
							</p>
							<div className='button-content text-center mt-5'>
								<button
									// Setting Bridal Category When user Click book Now Button
									onClick={() => history.push(`/curly-service/bridal-makeup`)}
									className={`${styles.bookBtn} border bookBtn border-black-border text-black my-3 py-2 px-4 rounded-md `}>
									Book Now
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
export default Bridal
