import React, {useRef, useEffect, useState} from "react"
import "./index.css"

const WhatsAppLogoField = () => {
	const dropdownBtnRef = useRef(null)
	const dropdownContentRef = useRef(null)
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	useEffect(() => {
		const dropdownBtn = dropdownBtnRef.current
		const dropdownContent = dropdownContentRef.current
		const openMenu = () => {
			setIsMenuOpen(true)
		}
		const closeMenu = () => {
			setIsMenuOpen(false)
		}
		dropdownBtn.addEventListener("mouseenter", openMenu)
		dropdownBtn.addEventListener("mouseleave", closeMenu)
		dropdownContent.addEventListener("mouseenter", openMenu)
		dropdownContent.addEventListener("mouseleave", closeMenu)
		return () => {
			dropdownBtn.removeEventListener("mouseenter", openMenu)
			dropdownBtn.removeEventListener("mouseleave", closeMenu)
			dropdownContent.removeEventListener("mouseenter", openMenu)
			dropdownContent.removeEventListener("mouseleave", closeMenu)
		}
	}, [])

	return (
		<div>
			<button
				ref={dropdownBtnRef}
				className='dropdown-btn'
				aria-label='menu button'
				aria-haspopup='menu'
				aria-expanded={isMenuOpen ? "true" : "false"}
				aria-controls='dropdown-menu'>
				<i className='bx WhatsAppLg  mx-1 bxl-whatsapp'></i>
			</button>

			<ul
				ref={dropdownContentRef}
				className={`dropdown-content-whatsApp ${isMenuOpen ? "menu-open" : ""}`}
				role='menu'
				id='dropdown-menu'>
				<li style={{"--delay": 1}}>
					<a
						href='https://wa.me/+917015036702'
						target='_blank'
						rel='noreferrer'
						className='text-white whitespace-nowrap flex items-center'>
						<span>+917015036702</span>
					</a>
				</li>
			</ul>
		</div>
	)
}

export default WhatsAppLogoField
