import React, {useState} from "react"
import {useDispatch} from "react-redux"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	Select,
	Input,
	Stack,
	useToast
} from "@chakra-ui/react"
import {updateOrder} from "../../store/actions/order"

const PendingPriceModal = ({children, paymentData, UpdatePaymentStatus}) => {
	const [paymentMethod, setPaymentMethod] = useState("")
	const [cashPayment, setCashPayment] = useState(0)
	const [onlinePayment, setOnlinePayment] = useState(0)
	const [adjustPayment2, setAdjustPayment2] = useState(0)
	const [loading, setLoading] = useState(false)
	// const [duePayment,  setDuePayment] = useState( paymentData.total_amount  - parseInt(paymentData.online) - parseInt(paymentData.cash))
	const {isOpen, onOpen, onClose} = useDisclosure()
	const toast = useToast()
	const dispatch = useDispatch()

	const TotalDueAmmount = paymentData.total_amount - paymentData.cash - paymentData.online

	const onFinalSubmit = () => {
		const updatedOrderData = {
			online: parseInt(paymentData.online) + onlinePayment || parseInt(paymentData.online),
			cash: parseInt(paymentData.cash) + parseInt(cashPayment + adjustPayment2) || parseInt(paymentData.cash),
			uid: paymentData?.uid,
			paymentId : ""
		}

		setLoading(true)
		dispatch(updateOrder(updatedOrderData))
			.then(() => {
				setLoading(false)
				setOnlinePayment("")
				UpdatePaymentStatus()
				setCashPayment("")
				onClose()
			})
			.catch(err => {
				setLoading(false)
				toast({
					title: err[1],
					status: "error",
					duration: 3000,
					isClosable: true
				})
			})
	}

	return (
		<>
			<div className='cursor-pointer' onClick={onOpen}>
				{children}
			</div>

			<Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size='xl'>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className='capitalize'> Pending Payment </ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<div className=' w-full'>
							<div className=''>
								<Stack className='flex'>
									<Select
										placeholder='Select Payment'
										mr={3}
										onChange={e => {
											setOnlinePayment(0)
											setAdjustPayment2(0)
											setCashPayment(0)
											setPaymentMethod(e.target.value)
										}}>
										<option value='cash'>Cash</option>
										<option value='online'>Online</option>
										<option value='both'>Both</option>
									</Select>

									<Input
										className='appearance-none'
										placeholder={paymentMethod === "cash" ? "Cash Payment" : "Online Payment"}
										value={paymentMethod === "cash" ? cashPayment : onlinePayment}
										onWheel={event => event.currentTarget.blur()}
										onClick={() => {
											if (paymentMethod === "cash") {
												setCashPayment("")
											} else {
												setOnlinePayment("")
											}
										}}
										onChange={e => {
											if (paymentMethod === "cash") {
												setOnlinePayment(0)
												setCashPayment(parseInt(e.target.value))
												// setDuePayment(paymentData.total_amount - (parseInt(paymentData.cash) + parseInt(paymentData.online))  -  e.target.value)
											} else {
												setCashPayment(0)
												setOnlinePayment(parseInt(e.target.value))
												// setDuePayment(paymentData.total_amount - (parseInt(paymentData.cash) + parseInt(paymentData.online))  - e.target.value)
											}
										}}
										size='md'
										type='number'
										disabled={paymentMethod !== "" ? false : true}
									/>
									{paymentMethod === "both" ? (
										<Input
											className='appearance-none mt-2'
											placeholder='Cash Payment'
											value={adjustPayment2}
											onWheel={event => event.currentTarget.blur()}
											onClick={() => setAdjustPayment2("")}
											onChange={e => {
												// setDuePayment(paymentData.total_amount -  (parseInt(paymentData.cash) + parseInt(paymentData.online))  - (parseInt(onlinePayment) + parseInt(e.target.value)));
												setAdjustPayment2(parseInt(e.target.value))
											}}
											size='md'
											type='number'
										/>
									) : (
										""
									)}
								</Stack>
							</div>
							<table className='border mt-5 w-full'>
								<thead className='w-full '>
									<th className='border-b text-center p-4 text-gray-600 font-bold'>Total</th>
									<th className='border-b text-center p-4 text-gray-600 font-bold'>You Payed</th>
									<th className='border-b text-center p-4 text-gray-600 font-bold'>Due Amount</th>
								</thead>

								<tbody>
									<td className={`border-b text-center p-4 text-gray-600 font-bold`}>
										{paymentData.total_amount}
									</td>
									<td className='border-b text-center p-4 text-gray-600 font-bold'>
										{parseInt(paymentData.cash) + parseInt(paymentData.online)}
									</td>
									<td
										className={`border-b text-center p-4 text-gray-600 font-bold text-lg ${
											TotalDueAmmount - onlinePayment - cashPayment - adjustPayment2 === 0
												? "textgreen-800"
												: "text-red-800"
										} `}>
										{TotalDueAmmount -
											(onlinePayment || 0) -
											(cashPayment || 0) -
											(adjustPayment2 || 0)}
									</td>
								</tbody>
							</table>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme='gray'
							mr={3}
							onClick={() => {
								setAdjustPayment2(0)
								setCashPayment(0)
								setOnlinePayment(0)
								onClose()
							}}>
							Close
						</Button>
						<Button
							colorScheme='green'
							className='bg-gold'
							variant='solid'
							isLoading={loading}
							onClick={onFinalSubmit}>
							Submit
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default PendingPriceModal
