import {useEffect, useState} from "react"
import {NavLink} from "react-router-dom"
import {FaLocationArrow} from "react-icons/fa"
import GetAppointmentModal from "../Modals/GetAppointmentModal"
import styles from "./index.module.css"
import {targetLocation, generateDirectionsUrl} from "../../utils/maps"
import useUserLocation from "../../hooks/useUserLocation"
import WithMapRoute from "../WithMapRoute"
import {Link, position, useFocusEffect} from "@chakra-ui/react"
import {useLocation} from "react-router-dom"

const Footer = () => {
	const [show, setShow] = useState(false)
	const [showLocation, setShowLocation] = useState()
	const Year = new Date().getFullYear()
	const userLocation = useUserLocation()
	const location = useLocation()

	const footerRoutes = [
		"/",
		"/cart",
		"/my-appointments",
		"/curly-service/:slug",
		"/bridal",
		"/services",
		"/offers",
		"/republic-day",
		"/installation",
		"/nails"
	]

	const handleShowMap = () => {
		if (!userLocation) {
			const newTab = window.open(
				"https://www.google.com/maps/dir//Curlys+Unisex+Hair+%26+Makeup+Studio,+5437%2F1%2F2,+Nicholson+Road,+Cinema,+opp.+NIGAR+THEATRE,+Ambala+Cantt,+Haryana+134001/@30.3404405,76.800472,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x390fb61752e52c5d:0xc0ad6c5edd4d6b28!2m2!1d76.8416716!2d30.3403729?entry=ttu",
				"_blank"
			)
			newTab.focus()
		}
		userLocation && window.open(generateDirectionsUrl(userLocation, targetLocation))
	}

	// const handleShowMap = () => {
	// 	if (!userLocation) {
	// 		alert("Location access denied!")
	// 		return
	// 	}
	// 	userLocation && window.open(generateDirectionsUrl(userLocation, targetLocation))
	// }

	if (!footerRoutes.includes(location.pathname)) {
		return null;
	}

	return (
		<>
			<div
				className='bg-black footercenter lg:px-20 md:px-20 sm:px-3.5 xl:px-20 flex'
				style={{borderBottom: "1px solid rgba(255, 255, 255, 0.1)"}}>
				<div className='lg:flex xl:flex sm:flex-col xl:flex-row lg:flex-row md:flex md:flex-col'>
					<div className='lg:w-1/3 xl:w-1/3 sm:w-full md:w-full p-2'>
						<div className={`flex p-2 items-center withMapWeb ${styles.withMapWeb}`}>
							<i className={`bi bi-geo-alt mr-3 ${styles.locArrow}`}></i>
							<span onClick={handleShowMap} className={` cursor-pointer ${styles.hoverButton}`}>
								Get Direction
							</span>
						</div>
						<hr />

						<div className='w-full h-full'>
							{!userLocation ? (
								<iframe
									src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11582.110260861555!2d76.83945720905152!3d30.3400922034666!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc0ad6c5edd4d6b28!2sCurlys%20Unisex%20Hair%20%26%20Makeup%20Studio!5e0!3m2!1sen!2sin!4v1639734057918!5m2!1sen!2sin'
									style={{border: "0", marginTop: "16px", padding: "0px 10px"}}
									//allowfullscreen=''
									loading='lazy'
									height='300'
									title='map'
									className='w-full'></iframe>
							) : (
								<div
									className='w-full relative'
									style={{height: 300, marginTop: "16px", padding: "0px 10px"}}>
									<WithMapRoute />
									<div className='absolute right-8 bottom-8 cursor-pointer' onClick={handleShowMap}>
										<div className='bg-white w-10 h-10 rounded-full flex justify-center items-center'>
											<FaLocationArrow color='red' />
										</div>
									</div>
								</div>
							)}
						</div>
					</div>

					<div className='lg:w-1/3 xl:w-1/3 sm:w-full md:w-full'>
						<div className='p-3.5'>
							<div className={`flex p-2 items-center ${styles.appointBookWeb}`}>
								<img src='/img/bookicon.png' alt='' className='mr-3' />
								<GetAppointmentModal book='Book An Appointment' />
							</div>
							<hr />
							<div className=''>
								<p className='text-2xl p-2 text-white'>Get In Touch</p>
								<a
									href='https://www.google.com/maps/place/Curlys+Unisex+Hair+%26+Makeup+Studio/@30.3400539,76.8417241,15z/data=!4m2!3m1!1s0x0:0xc0ad6c5edd4d6b28?sa=X&ved=2ahUKEwjfiPfPxur0AhVi8XMBHZZmAlgQ_BJ6BAgREAU'
									target='_blank'
									rel='noreferrer'>
									<div className='flex p-2 items-center'>
										<span className='text-white text-2xl hover:text-blue-200'>
											<i className='bx bx-current-location'></i>
										</span>
										<span onClick={handleShowMap} className='text-white pl-6 text-base hover:text-gold-400'>
											5437-1-2 Nicholson Road Opp. Nigar Cinema 133001 Ambala Cantt India.
										</span>
									</div>
								</a>
								<a href='tel:9354721082'>
									<div className='flex p-2 items-center'>
										<span className='text-white text-2xl hover:text-blue-200'>
											<i className='bx bx-phone'></i>
										</span>
										<span className='text-white pl-6 text-base hover:text-gold-400'>
											+91 7015036702
										</span>
									</div>
								</a>
								<a href='tel:9354721082'>
									<div className='flex p-2 items-center'>
										<span className='text-white text-2xl hover:text-blue-200'>
											<i className='bx bx-phone'></i>
										</span>
										<span className='text-white pl-6 text-base hover:text-gold-400'>
											+91 9354721082
										</span>
									</div>
								</a>
								<a href='mailto:hellocurlys@gmail.com'>
									<div className='flex p-2 items-center'>
										<span className='text-white text-2xl hover:text-blue-200'>
											<i className='bx bx-envelope'></i>
										</span>

										<span className='text-white pl-6 text-base hover:text-gold-400'>
											hellocurlys@gmail.com
										</span>
									</div>
								</a>
							</div>
						</div>
					</div>
					<div className='lg:w-1/3 xl:w-1/3 sm:w-full md:w-full'>
						<div className='p-3.5'>
							<div className=''>
								<a href='/#about' className={`flex p-1.5 items-center ${styles.aboutUsWeb}`}>
									<i className={`bi bi-people mr-3 ${styles.locArrow}`}></i>
									<span className={` cursor-pointer ${styles.hoverButton}`}>About Us</span>
								</a>
								<hr />

								<p className='text-white text-base p-2'>
									Best Unisex Salon in Ambala for Bridal MakeUp, Smoothening, Keratin, Hair Spa, Hair
									Color, Facial, Highlights, Manicure, Pedicure, Hair Care, Skin Care and other beauty
									parlour services.
								</p>
							</div>
							<div className='ml-2 text-white text-2xl'>Follow Us At: </div>
							<div className='flex items-center py-3.5 ml-2'>
								<a
									href='https://www.facebook.com/curlysambala'
									className='text-white text-32px'
									target='_blank'
									rel='noreferrer'>
									<i className='bx bxl-facebook-square'></i>
								</a>
								<a
									href='https://instagram.com/curlysambalasalon/'
									target='_blank'
									rel='noreferrer'
									className='text-white text-32px mx-2'>
									<i className='bx bxl-instagram'></i>
								</a>
								<a
									href='https://www.justdial.com/Ambala/Curlys-Unisex-Hair-Makeup-Studio-Opp-Nigar-Cinema-Ambala-Cantt/9999PX171-X171-190722121914-W4E9_BZDET?xid=QW1iYWxhIEN1cmx5cyBVbmlzZXggSGFpciBNYWtldXAgc3R1ZGlvIE9wcCBOaWdhciBDaW5lbWEgQW1iYWxhIENhbnR0'
									target='_blank'
									rel='noreferrer'
									className=''>
									<img className='w-7' src='/img/jd.jpg' width='32px' height='33px' alt='' />
								</a>
							</div>
							<div className='flex items-center'>
								<NavLink to='/installation'>
									<em className='bx bxl-android text-white text-5xl'></em>
								</NavLink>
								<NavLink to='/installation'>
									<em className='bx bxl-apple text-white text-5xl'></em>
								</NavLink>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='bg-black py-6'>
				<div className='w-full'>
					<p className='text-white text-center text-base'>{`© ${Year} All Rights Reserved.`}</p>
				</div>
			</div>

			<div className={`flex flex-col fixed bottom-5 right-5 md:right-12 z-10 ${styles.bottomIcons}`}>
				{/* <div
					className={`flex flex-col ${styles.topBtn} ${
						show ? "block transition-all" : "hidden transition-all"
					}`}>
					<div className={styles.btnDiv}>
						<div className={styles.title}>Book Appointment</div>
						<button
							style={{color: "#e88125"}}
							className='text-4xl bg-white shadow-3xl focus:shadow-3xl rounded-full w-14 h-14 flex justify-center items-center mb-3.5'>
							<GetAppointmentModal book='hide' icon={<i className='bx bx-calendar-plus'></i>} />
						</button>
					</div>

					<div className={styles.btnDiv}>
						<div className={styles.title}>Whatsapp</div>
						<button
							style={{color: "#25D366"}}
							className='text-4xl bg-white shadow-3xl focus:shadow-3xl rounded-full w-14 h-14 flex justify-center items-center mb-3.5'>
							<a href='https://wa.me/+917015036702' target='_blank' rel='noreferrer'>
								<i className='bx bxl-whatsapp'></i>
							</a>
						</button>
					</div>
					<div className={styles.btnDiv}>
						<div className={styles.title}>Feedback</div>
						<button
							style={{color: "#55acd5"}}
							className='text-4xl bg-white shadow-3xl  focus:shadow-3xl rounded-full w-14 h-14 flex justify-center items-center mb-3.5'>
							<a
								href='https://docs.google.com/forms/d/1VQSTUZqg5sBRLuqC9BxtHbJVTz8gq3ddnqDFTl4_gE4/viewform?edit_requested=true'
								target='_blank'
								rel='noreferrer'>
								<i className='bx bx-comment-check'></i>
							</a>
						</button>
					</div>
				</div> */}


				{/* <div className={styles.btnDiv}>
					{show ? <div className={`${styles.title}`}>Hide</div> : ""}

					<button
						onClick={() => setShow(prev => !prev)}
						onMouseEnter={() => setShow(true)}
						style={{color: "#55acd5"}}
						className={`${styles.chatBtn} ${
							show ? "" : "animie"
						} text-4xl bg-indigo-500 text-white  rounded-full w-14 h-14 flex justify-center items-center mb-3.5`}>
						<i
							className={`bx ${
								show === true ? "bx-x transition-all rotate-icon" : "bxs-chat transition-all"
							}`}></i>
					</button>
				</div> */}

				<div className={styles.btnDiv}>
					{showLocation ? <div className={`${styles.title}`}>Get Directions</div> : ""}
					<button
						onClick={handleShowMap}
						onMouseEnter={() => setShowLocation(true)}
						className={`${styles.locationBtn} ${
							showLocation ? "" : ""
						} text-4xl bg-red-500 text-white  rounded-full w-14 h-14 flex justify-center items-center mb-3.5`}>
						<i
							className={`bx ${showLocation === true ? "bi bi-send " : "bi bi-send "}`}
							style={{
								fontSize: "32px",
								color: "white",
								opacity: "0.8",
								webkitTextStrokeWidth: "0px"
							}}></i>
					</button>
				</div>
			</div>
		</>
	)
}
export default Footer
