import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { IoIosEye } from "react-icons/io";

const ExpenseView = ({ viewExpense }) => {
  
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        className="mr-3"
        colorScheme="teal"
        aria-label="Edit Expense"
        onClick={onOpen}
        icon={<IoIosEye />}
        size="sm"
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="capitalize">View Expense</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <h5>Date: {viewExpense.date}</h5>
            <h5 className="mt-5">Total: {viewExpense.total}</h5>
            <h5 className="mt-5">Cash: {viewExpense.cash}</h5>
            <h5 className="mt-5">Online: {viewExpense.online}</h5>
            <h5 className="mt-5">Expense: {viewExpense.expense}</h5>
            <h5 className="mt-5">Comment: {viewExpense.comment}</h5>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={() => window.print()} colorScheme="teal">
              Print
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ExpenseView;
