import axios from "axios"
import jwt_decode from "jwt-decode"
import {logout} from "./autoLogout"

const isExpiredDate = (date1, date2) => !(date1 < date2)

axios.interceptors.request.use(
	config => {
		const token = localStorage.getItem("token")
		if (!token) {
			return config
		}
		const decoded = jwt_decode(token)
		const {exp} = decoded
		const isExpiredToken = isExpiredDate(exp, Date.now())
		if (isExpiredToken) {
			logout()
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
)



axios.interceptors.response.use(
	config => {
		return config
	},
	error => {
		//console.log(error.response.data.message,'jhjkh')
		//console.log(error.response?.statusText, error.response?.status)
		if (error?.response?.data?.message === "Unauthenticated."){
			localStorage.removeItem("token");
			console.log( "Axios error  =========================>" , error?.response.data)
			localStorage.removeItem("user")
			localStorage.removeItem("cart")
			localStorage.removeItem("userRoles")
			window.location.href = "/"
			// error.response?.statusText === "Unauthenticated." &&
			// logout()
			return Promise.reject(error)
		}
	}
)

export default axios
