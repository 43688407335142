export const ADD_CART_ITEM = "ADD_CART_ITEM";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";

export const FETCH_CART_DETAIL = "FETCH_CART_DETAIL";

export const UPDATE_CART_ITEMS = "UPDATE_CART_ITEMS";

export const INC_PRODUCT_QUANTITY = "INC_PRODUCT_QUANTITY";

export const DEC_PRODUCT_QUANTITY = "DEC_PRODUCT_QUANTITY";

